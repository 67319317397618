import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./TranslatePage.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RiArrowLeftLine } from "react-icons/ri";
import TranslateComponent from "../../Components/TranslateComponent/TranslateComponent";
import useApi from "../../api/useAPi";
import apiUrls from "../../api/apiUrl";
import Loader from "../../Components/Loader/Loader";
import linebr from "../../assets/linebr.svg";
import optmor from "../../assets/optmor.svg";
import infoImg from "../../assets/info.svg";
import user from "../../assets/contactIcon.svg";
import paginationTypes from "../../Constants/PaginationTypes";

import { fetchPairedDataRedux, setPairedBookData } from "../../App/DashboardActions/action";
import useStatus from "../../hooks/useStatus";

const TranslatePage = ({popupEmail}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pairedData, isLoading } = useSelector((state) => state.main);

  const { data, loading, error, fetchData } = useApi();

  const [queryParameters] = useSearchParams();

  const [pairingData, setPairingData] = useState({});

  const [showEditPairing, setShowEditPairing] = useState(false);
  const [currentPage, setCurrentPage] = useState();

  const statusHook = useStatus()
  const userId = localStorage.getItem('userId')

  useEffect(() => {
    statusHook.checkStatus(userId)
  },[userId])

  const handleGoBack = () => {
    
    localStorage.removeItem('myLang')
    localStorage.removeItem('myTemp')
    localStorage.removeItem('myModel')

    // localStorage.removeItem('page')
    // localStorage.removeItem('pageData')
    navigate("/");
  };

  useEffect(()=>{
    let myPage=1;
    if(localStorage.getItem('pageData') ){
      const pageData=JSON.parse(localStorage.getItem('pageData'))
        if(pageData.pairedBookId===queryParameters.get("pairedBookId")){
             myPage=pageData.pageNo
        }
    }
   setCurrentPage(myPage)
  },[])

  useEffect(() => {
    if (pairedData?.auto_ai_resource?.resources?.length > 0) {
      setPairingData(pairedData);
    }
  }, [pairedData]);

  useEffect(() => {
    // setCurrentPage(localStorage.getItem('page'))
    if(currentPage){
      const pairedBookId = queryParameters.get("pairedBookId");
      dispatch(fetchPairedDataRedux({ fetchData, pairedBookId, currentPage }));
    }
  }, [currentPage]);

  console.log("data from local", pairingData);

  const handleEditPairingClick = () => {
    setShowEditPairing(!showEditPairing);
  };

  return (
    <div className="container-pair-page">
       <div className="header">    
      </div>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <div className="error-container">
          <div className="error-message">Error in loading data</div>
        </div>
      ) : pairedData?.auto_ai_resource?.resources?.length === 0 ? (
        <div className="error-container">
          <div className="error-message">No data Found</div>
          <button
              className="view-btn"
              onClick={() => {
                navigate("/dashboard")
              }}
          >
              Go Back
          </button>
        </div>) : (
        <>
          {/* //Pagination mode ['paragraph','chapter']

          <div className="line-container">
            {/* <p className="title">
              Please go through the translation pairs and perform suitable
              actions:
            </p> */}

          {pairingData?.auto_ai_resource?.resources.length && (
            <TranslateComponent
              sourceColumnHeader={"ENGLISH"}
              // targetColumnHeader={"TRANSLATED DUTCH"}
              // paginationMode={paginationTypes.PARAGRAPH}
              paginationMode={paginationTypes.CHAPTER}
              paragraphPerPage={3}
              infiniteScroll={false}
              targetColumnHeader={"AI TRANSLATION"}
              pairingDatas={pairingData}
              setPairingData={setPairingData}
              showEditPairing={showEditPairing}
              showViewAsEnglish
              onEditPairingClick={handleEditPairingClick}
              handleGoBack={handleGoBack}
              bookTitle={pairedData.book_title}
              authorName={pairedData.author_name}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              popupEmail={popupEmail}
            />
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(TranslatePage);
