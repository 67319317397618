import React, { useEffect, useState, useRef, useCallback, use } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./TranslateParagraphPairComponent.scss";
import TextareaComponent from "../TextareaComponent/TextareaComponent";
import likeIcon from "../../assets/Frame 1.svg";
import marker from "../../assets/Frame 4.svg";
import deleteIcon from "../../assets/deleteIcon.svg";
import addComment from "../../assets/add_comment.svg";
import palette from "../../assets/palette.svg";
import comapreIcon from "../../assets/compare.svg";
import closeIcon from "../../assets/close-icon.svg";
import tickIcon from "../../assets/tick-icon.svg";
import checkedCircle from "../../assets/check_circle.svg";
import issueIcon from "../../assets/issuelist.svg";
import mergeIcon from "../../assets/mergeIcon.svg";
import regenerateIcon from "../../assets/regenerateIcon.svg";
import splitIcon from "../../assets/splitIcon.svg";
import download from "../../assets/download_icon.svg";
import regenerate from "../../assets/translation refresh icon.svg";
import compareIcon from "../../assets/icon_compare.svg";

import apiUrls from "../../api/apiUrl";
import useApi from "../../api/useAPi";
import Loader from "../../Components/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RangeSlider from "../Slider/RangeSlider";
import PostComponent from "../PostComponent/PostComponent";
import MergeComponent from "../MergeComponent/mergeComponent";
import paginationTypes from "../../Constants/PaginationTypes";
import { Tooltip, Typography } from "@mui/material";
import { Button as MUIButton } from "@mui/material";

import Button from "../Button";
import Switch from "../Switch";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { setGlossaryItem, setIsItemLoad, setPairedBookData } from "../../App/DashboardActions/action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { convertJsonData, findChangesToMatchBase } from "../../utils/getWords";
import {
  addKeywords,
  applyGlossaryToBook,
  setAddKeyWord,
  setGlossaryItemWithKeyword,
  setIsGlossaryCalledFalse,
} from "../../App/TranslationActions/action";
import { faCaretDown, faCaretUp, faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import DOMPurify from "dompurify";
import { async } from "@firebase/util";
import { BorderStyle } from "@mui/icons-material";
import shortcuts from "../../Constants/Shortcuts";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";
import WordDropDown, {WordVariantPopover} from "../WordDropdown/WordDropdown";
import css from "./TranslateParagraphPairComponent.module.css";
import useStatus from '../../hooks/useStatus'

// import { Button, Typography } from "@mui/material";
import { set } from "firebase/database";
import ParagraphCompareModel from "./ParagraphCompareModel/ParagraphCompareModel";

// import pairingData from "../../test.json";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TranslateBoxComponent from "../TranslateBoxComponent/TranslateBoxComponent";

// import { Box, Button, Typography } from "@mui/material";

// function deepClone(obj) {
//   if (obj === null || typeof obj !== 'object') {
//     return obj;
//   }

//   if (Array.isArray(obj)) {
//     const arrCopy = [];
//     for (let i = 0; i < obj.length; i++) {
//       arrCopy[i] = deepClone(obj[i]);
//     }
//     return arrCopy;
//   }

//   const objCopy = {};
//   for (const key in obj) {
//     if (obj.hasOwnProperty(key)) {
//       objCopy[key] = deepClone(obj[key]);
//     }
//   }

//   return objCopy;
// }

function isNullOrUndefined(varToCheck) {
  if (varToCheck !== undefined && varToCheck !== null) {
    return false
  }
  return true
}

const removeSpecialCharactersFromWord = (word) => {
  return word.replace(/[^a-zA-Z ]/g, "")
}

const AllModelDetails = [
  {
    title: "Google",
    key: "google",
    value: "google",
  },
  {
    title: "BestAI",
    key: "bestai2",
    value: "bestai2",
  },
  {
    title: "Azure",
    key: "azure",
    value: "azure",
  },
];

const AllLanguagesDetails = [
  {
    title: "Dutch",
    key: "dutch",
    value: "dutch",
  },
  {
    title: "French",
    key: "french",
    value: "french",
  },
];

function shouldWordBeHiglighted(
  wordToCheck, 
  wordIndexStartPositionOfHighlight, 
  wordIndexEndPositionOfHighlight,
  sentence
){
  const words = sentence.split(" ");

  for (let wordIndex = 0; wordIndex < words.length; wordIndex++) {
    let word = words[wordIndex];
    word = word;
    
    if (
      word === wordToCheck &&
      wordIndexStartPositionOfHighlight <= wordIndex &&
      wordIndexEndPositionOfHighlight >= wordIndex
    ) {
      return true;
    } 
  }

  return false;

}

// function compareSentencesAndGenerateVariants(sentences) {

  /**
   * return should look like below
   * sentences: [{
   *    sentence: ""
   *    variants: [{
   *      startWordIndexPosition: number,
   *      endWordIndexPosition: number
   *    }]
   * }]
   */

  // function isWordSameInAllSentences(wordIndex, sentences) {

  //   const wordAtIndexes={};

  //   for (let sentenceIndex = 0; sentenceIndex < sentences.length; sentenceIndex++) {
  //     const sentence = sentences[sentenceIndex];
  //     const words = sentence.split(/\s+/);
  //     const word = words[wordIndex];
  //     if (wordAtIndexes[wordIndex] === undefined) {
  //       wordAtIndexes[wordIndex] = word
  //     } else if (wordAtIndexes[wordIndex] !== word) {
  //       return false
  //     }
  //   }
  //   return true;
  // }


  const sentencesData = [];

  // for (let sentenceIndex = 0; sentenceIndex < sentences.length; sentenceIndex++) {
  //   const sentence = sentences[sentenceIndex];
  //   const words = sentence.split(/\s+/);
  //   let sentenceData={
  //     sentence: sentence,
  //     variants: []
  //   }

  //   let isVariantEndIndexCapturingStarted = false;
  //   for (let wordIndex = 0; wordIndex < words.length; wordIndex++) {
  //     const word = words[wordIndex];
  //     if (!isWordSameInAllSentences(wordIndex, sentences)) {
  //       if (!isVariantEndIndexCapturingStarted) {
  //         // creating a new variant
  //         sentenceData.variants.push({
  //           startWordIndexPosition: wordIndex,
  //           endWordIndexPosition: null
  //         })
  //       } else {
  //         // closing last opened variant
  //         sentenceData.variants[sentenceData.variants.length-1]?.endWordIndexPosition = wordIndex;
  //       }
  //     }
  //   }

  //   sentencesData.push(sentenceData);
  // }


  // return sentencesData;
  
  
  // const wordsOfFirstSentence = sentences?.[0]?.split(" ");

  // for (let wordIndex = 0; wordIndex < wordsOfFirstSentence.length; wordIndex++) {
  //   const wordOfFirstSentence = wordsOfFirstSentence[wordIndex];
    
  // }
// }
// function compareSentencesAndGenerateVariants(sentences) {

//   /**
//    * return should look like below
//    * sentences: [{
//    *    sentence: ""
//    *    variants: [{
//    *      startWordIndexPosition: number,
//    *      endWordIndexPosition: number
//    *    }]
//    * }]
//    */

//   // function isWordSameInAllSentences(wordIndex, sentences) {

//   //   const wordAtIndexes={};

//   //   for (let sentenceIndex = 0; sentenceIndex < sentences.length; sentenceIndex++) {
//   //     const sentence = sentences[sentenceIndex];
//   //     const words = sentence.split(/\s+/);
//   //     const word = words[wordIndex];
//   //     if (wordAtIndexes[wordIndex] === undefined) {
//   //       wordAtIndexes[wordIndex] = word
//   //     } else if (wordAtIndexes[wordIndex] !== word) {
//   //       return false
//   //     }
//   //   }
//   //   return true;
//   // }


//   const sentencesData = [];

//   for (let sentenceIndex = 0; sentenceIndex < sentences.length; sentenceIndex++) {
//     const sentence = sentences[sentenceIndex];
//     const words = sentence.split(/\s+/);
//     let sentenceData={
//       sentence: sentence,
//       variants: []
//     }

//     let isVariantEndIndexCapturingStarted = false;
//     for (let wordIndex = 0; wordIndex < words.length; wordIndex++) {
//       const word = words[wordIndex];
//       if (!isWordSameInAllSentences(wordIndex, sentences)) {
//         if (!isVariantEndIndexCapturingStarted) {
//           // creating a new variant
//           sentenceData.variants.push({
//             startWordIndexPosition: wordIndex,
//             endWordIndexPosition: null
//           })
//         } else {
//           // closing last opened variant
//           sentenceData.variants[sentenceData.variants.length-1]?.endWordIndexPosition = wordIndex;
//         }
//       }
//     }

//     sentencesData.push(sentenceData);
//   }


//   return sentencesData;
  
  
//   // const wordsOfFirstSentence = sentences?.[0]?.split(" ");

//   // for (let wordIndex = 0; wordIndex < wordsOfFirstSentence.length; wordIndex++) {
//   //   const wordOfFirstSentence = wordsOfFirstSentence[wordIndex];
    
//   // }
// }

const getHighlightStyle = (word, index, startWordIndexPosition, endWordIndexPosition, text)=>{
  if (shouldWordBeHiglighted(word, startWordIndexPosition, endWordIndexPosition, text)) {
    return {backgroundColor: "yellow",}
  }
  return {};
}
const getHighlightClass = (word, index, startWordIndexPosition, endWordIndexPosition, text)=>{
  if (shouldWordBeHiglighted(word, startWordIndexPosition, endWordIndexPosition, text)) {
    return css.backgroundYellow;
  }
  return "";
}

const TranslateParagraphPairComponent = ({
  handleCheckboxChange,
  checkedParagraphs,
  handleProgressBar,
  chapterKeys,
  startIndex,
  endIndex,
  paragraphsToShowForScroll,
  pairingData,
  showViewAsEnglish,
  pairingEdges,
  textSize,
  setPairingData,
  getDownloadLink,
  totalParagraphs,
  setShowConfirmTranslation,
  showConfirmTranslation,
  infiniteScroll,
  paginationMode,
  currentPage,
  paragraphPerPage,
  pairedBookId,
  mergeParaMode,
  mergeParagraphData,
  setMergeParagraphData,
  selectedLanguage,
  selectedModelName,
  selectedTemperature,
  selectedComparisionTemperature,
  selectedComparisionModelName,
  showCompare=false,
  showModelSelection=false,
  setDownloadModalVisible
}) => {
  const { fetchData } = useApi();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pairedData, isItemLoadingId, glossaryItem, isGetKeywordApiCalled } = useSelector(
    (state) => state.main
  );

  useEffect(()=>{
    console.log("glossaryItem", glossaryItem)
  }, [glossaryItem])

  const glossaryItemRef = useRef(glossaryItem);
  glossaryItemRef.current = glossaryItem;

  //const [mergeParaMode] = useState(true)
  const { diff_match_patch } = require("diff-match-patch");
  const [currentVariant, setCurrentVarientText] = useState("");

  const statusHook = useStatus()
  useEffect(() => {
    statusHook.checkStatus()
  },[])

  const [lastChangedText, setLastChangesText] = useState("");
  const [translatedDutchToEnglish, setTranslatedDutchToEnglish] = useState({});
  const [loadingParagraphs, setLoadingParagraphs] = useState({});
  const [loadingComparisionParagraphs, setLoadingComparisionParagraphs] = useState({});
  const [loadingLatestParagraphs, setLatestLoadingParagraphs] = useState({});
  const [errorLatestParagraphs, setErrorLatestParagraphs] = useState({});
  const [loadingLatestComparisionParagraphs, setLatestLoadingComparisionParagraphs] = useState({});
  const [errorLatestComparisionParagraphs, setErrorLatestComparisionParagraphs] = useState({});
  const [editingParagraphs, setEditingParagraphs] = useState({});
  const [editedContent, setEditedContent] = useState({});
  const [feedbackContent, setFeedbackContent] = useState({});
  const [compareModeParagraphs, setCompareModeParagraphs] = useState([]);
  const [paletteModeParagraphs, setPaletteModeParagraphs] = useState([]);
  const [feedbackModeParagraphs, setFeedbackModeParagraphs] = useState([]);
  const [sentenceColors, setSentenceColors] = useState({});
  const [sentenceEditMode, setSentenceEditMode] = useState([]);
  const [sentenceEditConfirm, setSentenceEditConfirm] = useState([]);
  const [sentenceEditFeedbackMode, setSentenceEditFeedbackMode] = useState("");
  const [feedbackSelectorVisible, setFeedbackSelectorVisible] = useState(false);
  const [currentKeyWordWithVariant, setCurrentKeywordWithVariant] = useState({});
  const [prevVariantText, setprevVariantText] = useState("")

  useEffect(()=>{
    console.log("currentKeyWordWithVariant", currentKeyWordWithVariant)
  }, [currentKeyWordWithVariant])
  
  const [selectedVariant, setSelectedVariant] = useState("");

  const [wordVariantPopover, setWordVariantPopover] = useState({
    open: false,
    baseWord: "",
    variants: [],
    anchorElement: undefined
  })
  
  const [editingSentences, setEditingSentences] = useState({});
  // const [showConfirmTranslation, setShowConfirmTranslation] = useState([]);

  const [historyVisible, setHistoryVisible] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [sliderValueFromChild, setSliderValueFromChild] = useState(0);
  const [textareaHeight, setTextareaHeight] = useState("auto");
  const [divClick, setDivClick] = useState(false);
  const [showEditPairing, setShowEditPairing] = useState("");
  const outsideClickRef = useRef(null);
  const [isHoverOriginalParaId, setIsOriginalParaId] = useState([]);
  const [isGlossaryVisible, setIsGlossaryVisible] = useState(false);
  const [isExtended, setIsExtended] = useState(false);
  const [glossaryIndex, setGlossaryIndex] = useState(0);

  useEffect(()=>{
    console.log("glossaryIndex", glossaryIndex)
  }, [glossaryIndex])
  
  const [paragraphWords, setParagraphWords] = useState([]);
  const [wordVariant, setWordVariant] = useState(false);
  const [applyGlossary, setApplyGlossary] = useState(false);
  const [paraWord, setParaWrod] = useState("");
  const [loader, setLoader] = useState(false);
  const [enableInputBox, setEnableInputBox] = useState(false);
  const [byPopupAddWordInGlossaryScenario, setbyPopupAddWordInGlossaryScenario] = useState(false)
  useEffect(()=>{
    console.log("enableInputBox", enableInputBox)
  }, [enableInputBox])
  useEffect(()=>{
    console.log("paraWord", paraWord)

  }, [paraWord])

  const [userEnteringGlossaryForVariantState, setUserEnteringGlossaryForVariantState] = useState({
    isOn: false,
    variantWord: "",
    keywordWithVariant: null
  })
  
  const [enableVariantInputBox, setEnableVariantInputBox] = useState("");
  useEffect(()=>{
    console.log("enableVariantInputBox", enableVariantInputBox)
  }, [enableVariantInputBox])
  const [enableGlossaryWord, setEnableGlossaryWord] = useState("");
  useEffect(()=>{
    console.log("enableGlossaryWord", enableGlossaryWord)
  }, [enableGlossaryWord])
  
  const [textAreaIndex, setTextAreaIndex] = useState("");
  const [textAreaWordIndex, setTextAreaWordIndex] = useState("");
  const textAreaRef = Array.from({ length: 50 }, () => React.createRef(null));
  const [colorCode, setColorCode] = useState("rgba(255, 255, 255, 0.5)");
  const [isMergeComponentOpen, setIsMergeComponentOpen] = useState(false);
  const [chooseWordDropDown, setChooseWordDropDown] = useState(false)
  const [chooseWord, setChooseWord] = useState('')
  const [anchorElement, setAnchorElement] = useState()
  const [dropDownSentence, setDropDownSentence] = useState('')
  const [currentSentenceId,setCurrentSentenceId] = useState('')
  const [currentSentenceWordVariantArray,setSentenceWordVariantArray] = useState([])
  const [currentSentence,setCurrentSentence] = useState({
    text:'',
    index:'',
    variants:[],
  })
  



  
  useEffect(()=>{
    console.log("Glossary Index", glossaryIndex);
  },[glossaryIndex])


  const baseText1="Er was iets in de toon van de directeur waardoor agente Laura Smith beter ging opletten."
  const modals1=["Er was lets in de doon van de directeur waardoor agents Laura Smith seter ging opletten.","Er was gets in de koon van de directeur waardoor agents Laura Smith keter ging opletten."]

//const result = findChangesToMatchBase('We hebben weer sdfd een moeilijke zaak toebedeeld gekregen.', ["We mebben weer een moeilijke haak toebedeeld rer gekregen.", "'We rebben weer een moeilijke baak toebedeeld gekregen."])



  const [showComparison, setShowComparison] = useState(false);

  const [editingColumn, setEditingColumn] = useState(null);
  // const [selectedLanguage, setSelectedLanguage] = useState(AllLanguagesDetails[0]);
  // const [selectedComparisonModels, setSelectedComparisonModels] = useState([AllModelDetails[0]]);

  let paragraphsToShow = [];
  if (infiniteScroll) {
    if (paginationMode === paginationTypes.PARAGRAPH) {
      paragraphsToShow = paragraphsToShowForScroll;
    } else {
      paragraphsToShow = paragraphsToShowForScroll;
    }
  } else {
    let currentCount = 0;
    if (paginationMode === paginationTypes.PARAGRAPH && pairingData) {
      for (let chapter of pairingData?.auto_ai_resource?.resources) {
        const chapterNumber = chapter.chapter.sequenceNumber;
        const paragraphNumber = chapter.paragraph.sequenceNumber;
        if (currentCount >= startIndex && currentCount < endIndex) {
          paragraphsToShow.push({
            chapterKey: chapterNumber,
            paragraphKey: paragraphNumber,
          });
        }
        currentCount++;
      }
    }
    if (paginationMode === paginationTypes.CHAPTER) {
      for (let chapter of pairingData?.auto_ai_resource.resources) {
        const chapterNumber = chapter.chapter.sequenceNumber;
        const paragraphNumber = chapter.paragraph.sequenceNumber;
        paragraphsToShow.push({
          chapterKey: chapterNumber,
          paragraphKey: paragraphNumber,
        });
      }
    }
  }
 
  const [selectedModel, setSelectedModel] = useState(paragraphsToShow.map(()=>selectedModelName))

  const [assistantOptions, setAssistantOptions] = useState([]);
  const getAllAssistants = async () => {
    const assistants = await fetchData(apiUrls.getAssistants, "GET");
    const resultArray = assistants.map(({ model_name, assistant_name, target_language, temperature }) => ({
      model_name,
      assistant_name,
      target_language,
      temperature
    }));
    setAssistantOptions(resultArray);
  };
  

 const checkContainsSpecialCharacters =(inputText)=>{
      // Define a regular expression for special characters
  let specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?"~\\\/\-=]/;

  let singleQuoteCount = (inputText.match(/'/g) || []).length;
  
  // Check if the input contains any special characters
  if (specialCharacterRegex.test(inputText)|| singleQuoteCount > 1) 
     return true

  return false;
 }

  const updateContentWithVariant=async (parameters)=>{

    setWordVariantPopover(oldState=>{
      return {
        ...oldState,
        open: false
      }
    })
    
    // let wordArray=currentSentence.text.split(' ')

    // setPairingData(oldState=>{

    //   if (oldState?.auto_ai_resource?.resources?.length>0) {
    //     for (let resourceIndex = 0; resourceIndex < oldState?.auto_ai_resource?.resources.length; resourceIndex++) {
    //       const resource = oldState?.auto_ai_resource?.resources[resourceIndex];
    //       if (resource._id === parameters.chapterId) {
    //         if (resource?.textTranslatedLanguageSentences?.length>0) {
    //           for (let textTranslatedLanguageSentenceIndex = 0; textTranslatedLanguageSentenceIndex < resource?.textTranslatedLanguageSentences.length; textTranslatedLanguageSentenceIndex++) {
    //             const textTranslatedLanguageSentence = resource?.textTranslatedLanguageSentences[textTranslatedLanguageSentenceIndex];
    //             if (textTranslatedLanguageSentence?.sentence?.text === )
    //           }
    //         }
    //       }
    //     }
    //   }
      
    //   return oldState;
    // })
    
    // console.log(value,"selectedvalue",wordArray)
  // setEditedContent({...editedContent,[currentSentence.index]:currentSentence.text})

  let translatedPairParagraph = parameters.chapter?.textTranslatedLanguageSentences?.
  filter((sentence) => (
    sentence.target_language===selectedLanguage
    && sentence.model_name===selectedModelName
    && +sentence.temperature === +selectedTemperature
    ))  

    /**
     * @param {string} sentenceText 
     * @param {[number, number]} position 
     * @param {string[]} variantWords 
     * @returns {string}
     */
    const performReplaceAction = (sentenceText, position, variantWords) => {
      if (variantWords?.length>0) {
        variantWords = JSON.parse(JSON.stringify(variantWords));
      }

      const startPosition = position[0];
      const endPosition = position[1];

      // let words = sentenceText.split(/\s+/);
      let words = sentenceText.split(" ");

      // words = words.filter(word=>word ? true : false)

      for (let wordIndex = 0; wordIndex < words?.length; wordIndex++) {
        const word = words?.[wordIndex];
        if (wordIndex >= startPosition && wordIndex < endPosition) {
          // fetchig first element from array and removing it as well
          const wordToReplaceWith = variantWords.shift();
          if (!isNullOrUndefined(wordToReplaceWith)) {
            words[wordIndex] = wordToReplaceWith
          }
        }
      }

      // making sentence again from words array
      let sentence = words.join(" ");

      return sentence;
    }

    /**
     * @param {string} sentenceText 
     * @param {[number, number]} position 
     * @param {string[]} variantWords 
     * @returns {string}
     */
    const performDeleteAction = (sentenceText, position) => {
      const startPosition = position[0];
      const endPosition = position[1];

      // let words = sentenceText.split(/\s+/);
      let words = sentenceText.split(" ");

      // words = words.filter(word=>word ? true : false)

      for (let wordIndex = 0; wordIndex < words?.length; wordIndex++) {
        const word = words?.[wordIndex];
        if (wordIndex >= startPosition && wordIndex <= endPosition) {

          words.splice(wordIndex, 1);

          
        }
      }

      // making sentence again from words array
      let sentence = words.join(" ");

      return sentence;
    }

    let sentenceUpdated = "";

    // updating sentence using variant
    translatedPairParagraph = (()=>{
      if (translatedPairParagraph?.length>0) {
        for (let translatedSentenceJsonIndex = 0; translatedSentenceJsonIndex < translatedPairParagraph.length; translatedSentenceJsonIndex++) {
          const translatedSentenceJson = translatedPairParagraph[translatedSentenceJsonIndex];
          if (translatedSentenceJson.sentenceId === parameters.sentenceId) {
            if (translatedSentenceJson?.sentence?.text) {
              if (parameters.action === "replace_action") {
                translatedSentenceJson.sentence.text = performReplaceAction(translatedSentenceJson?.sentence?.text, parameters.position, parameters.variantWords)
                sentenceUpdated = translatedSentenceJson.sentence.text;
              } else if (parameters.action === "delete_action") {
                translatedSentenceJson.sentence.text = performDeleteAction(translatedSentenceJson?.sentence?.text, parameters.position)
                sentenceUpdated = translatedSentenceJson.sentence.text;                
              } else if (parameters.action === "insert_action") {
                // translatedSentenceJson.sentence.text = performInsertAction(translatedSentenceJson?.sentence?.text, parameters.position, parameters.variantWords)

              }
            }
          }
        }
      }
      return translatedPairParagraph
    })();

    setPairingData(oldPairingData=>{

      if (oldPairingData?.auto_ai_resource?.resources?.length>0) {
        for (const resource of (oldPairingData?.auto_ai_resource?.resources || [])) {
          if (resource._id === parameters.chapterId) {
            for (const translatedSentence of (resource.textTranslatedLanguageSentences || [])) {
              if (translatedSentence.sentenceId === parameters.sentenceId) {
                if (!translatedSentence.sentence) {
                  translatedSentence.sentence = {};
                }
                translatedSentence.sentence.text = sentenceUpdated;
                break;
              }
            }
          }
        }
      }

      return {...oldPairingData}
    })

    setEditedContent(oldState=>{
      return {
        ...oldState,
        [parameters.sentenceId]: sentenceUpdated
      }
    })

    await new Promise(resolve=>{
      setTimeout(() => {
        resolve();
      }, 1000);
    })

    
  
    updateTheNodeConnectionConfirmPair(
      parameters.chapterId,
      parameters.chapterKey,
      wordVariantPopover.paragraphKey,
      "confirm",
      translatedPairParagraph,
      parameters.sentenceId,
      sentenceUpdated,
      true
    )
  }

  useEffect(() => {
    setHistoryVisible(false)
  }, [selectedModel, selectedTemperature, selectedLanguage])

  useEffect(() => {
    getAllAssistants();
  }, []);

  useEffect(() => {
    setSelectedModel(paragraphsToShow.map(() => selectedModelName));
  },[selectedModelName])

  const handleModelChange = async (event, latestPara, chapterKey, paragraphKey, index) => {
    const updatedSelectedModel = [...selectedModel];
    updatedSelectedModel[index] = event.target.value;
    setSelectedModel(updatedSelectedModel);
    //1. hit api using pairedBookId, chapterKey, paragraphKey
    //2. fetch the one with model=event.target.value
    //3. assign it to translatedPairParagraphs
    // console.log("www", updatedSelectedModel);
    // console.log("www", selectedModel)

    // const assistants = await fetchData(apiUrls.getAssistants, "GET");
    // const assistantName = assistants.find((assistant) => 
    //   assistant.target_language===selectedLanguage && 
    //   assistant.model_name===event.target.value
    // );
    // console.log("Assistant Name", assistantName.assistant_name);

    // console.log("www", assistants);

    // const latestData = {
    //   assistant_name: assistantName.assistant_name,
    //   author_name: pairingData?.author_name,
    //   auto_ai_resource: {
    //     resources: [latestPara],
    //     totalCount: pairingData?.auto_ai_resource?.totalCount,
    //   },
    //   book_title: pairingData?.book_title,
    //   dutch_file_name: pairingData?.dutch_file_name,
    //   english_file_name: pairingData?.english_file_name,
    //   pair_style: "Paragraph",
    //   pair_type: pairingData?.pair_type,
    //   unique_string: pairingData?.unique_string,
    //   total_chapters: pairingData?.total_chapters,
    //   chapters: pairingData?.chapters
    // };

    // try {
    //   setLatestLoadingParagraphs((prevState) => ({
    //     ...prevState,
    //     [`Chapter ${chapterKey}-Paragraph ${paragraphKey}`]: true,
    //   }));
    //   const responseApi = await fetchData(apiUrls.getLatestParagraph, "POST", latestData);
    //   console.log("www", responseApi);

    //   // const response = demoApiData;

    //   // const demoHere = JSON.parse(JSON.stringify(pairingData));
    //   // console.log("GGG:\n", demoHere)
    //   // const response = responseApi?.filter((resource) => (
    //   //   resource.chapter.sequenceNumber===chapterKey &&
    //   //   resource.paragraph.sequenceNumber===paragraphKey
    //   // ));
    //   // console.log("Test k", response)
    //   responseApi.textTranslatedLanguageSentences = responseApi.textTranslatedLanguageSentences.filter(sentence => sentence.model_name===event.target.value && sentence.target_language===selectedLanguage)
    //   console.log("www", responseApi);

    //   // response?.forEach(resource => {
    //   //   resource.textTranslatedLanguageSentences = resource.textTranslatedLanguageSentences.filter(sentence => sentence.model===event.target.value)
    //   // });

    //   let updatedResourse = pairingData?.auto_ai_resource?.resources?.map((resource) =>
    //   (resource.chapter.sequenceNumber===chapterKey && resource.paragraph.sequenceNumber===paragraphKey) ? responseApi : resource
    //   );
    //   // console.log("www value 10:\n", updatedResourse);

    //   pairingData.auto_ai_resource.resources = updatedResourse;
    //   setPairingData(pairingData);
    //   dispatch(setPairedBookData(pairingData));
    //   setLatestLoadingParagraphs((prevState) => ({
    //     ...prevState,
    //     [`Chapter ${chapterKey}-Paragraph ${paragraphKey}`]: false,
    //   }));
    // } catch (error) {
    //   console.error("Error fetching data:", error);
    //   setLatestLoadingParagraphs((prevState) => ({
    //     ...prevState,
    //     [`Chapter ${chapterKey}-Paragraph ${paragraphKey}`]: false,
    //   }));
    // }
  };

  const notify = (toastMessage) => toast(toastMessage);
  const refs = paragraphsToShow.reduce((acc, value) => {
    acc[value?.paragraphKey] = React.createRef();
    return acc;
  }, {});

  // const addNewSelectedModel = (value) => {
  //   setSelectedComparisonModels((state) => {
  //     let newList = JSON.parse(JSON.stringify(state));
  //     let newModelCol = AllModelDetails.find((item) => item.value === value);
  //     newList.push(newModelCol);
  //     return newList;
  //   });
  // };

  // const removeSelectedModel = (id) => {
  //   setSelectedComparisonModels((state) => {
  //     let newList = JSON.parse(JSON.stringify(state));
  //     newList = newList.filter((item) => item.key !== id);
  //     return newList;
  //   });
  // };

  const handleClick = (id) =>
    refs[id]?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  //console.log(glossaryKeywords,"keyyy")

  const handleRadioChange = (value) => {
    // Update the selectedValue when a radio button is selected
    setSelectedVariant(value);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (outsideClickRef.current && !outsideClickRef.current.contains(event.target)) {
        setSentenceEditFeedbackMode("");
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleParagraphHower = (transPara) => {
    setIsOriginalParaId(transPara.connectedWithOriginalLanguageTextSentenceIds);
  };

  const getParagraph = async (chapterKey, paragraphKey) => {

    setLoadingParagraphs((prevState) => ({
      ...prevState,
      [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`]: true,
    }));
    setLoadingComparisionParagraphs((prevState) => ({
      ...prevState,
      [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedComparisionModelName}-Temperature ${selectedComparisionTemperature}`]: true,
    }));
    // Example usage:

    const selectedChapter = pairingData?.auto_ai_resource?.resources?.find(
      (chapter) =>
        chapter.chapter.sequenceNumber === chapterKey && chapter.paragraph.sequenceNumber === paragraphKey
    );

    const chpater = selectedChapter?.textTranslatedLanguageSentences?.filter((sentence) => (
      sentence.target_language===selectedLanguage
      && sentence.model_name===selectedModelName
      && +sentence.temperature === +selectedTemperature
      ))

    const dutchParagraph = chpater?.map(
      (sentence) => sentence.sentence.text
    );

    const translateRequest = {
      sentences: dutchParagraph,
      originaLanguage: selectedLanguage,
      targetLanguage: "english",
      target_language_unicode: "en",
    };

    try {
      const res = await fetchData(apiUrls.googleApiTranslateDutchToEnglish, "POST", translateRequest);

      logEvent(analytics, "view_as_english", { success: true });

      const translatedParagraph = res.sentences;

      setTranslatedDutchToEnglish((prevState) => ({
        ...prevState,
        [`Chapter ${chapterKey}-Paragraph ${paragraphKey}`]: translatedParagraph,
      }));
    } catch (err) {
      logEvent(analytics, "view_as_english_error", { error: err.message });
      console.log(err);
    } finally {
      setLoadingParagraphs((prevState) => ({
        ...prevState,
        [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`]: false,
      }));
      setLoadingComparisionParagraphs((prevState) => ({
        ...prevState,
        [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedComparisionModelName}-Temperature ${selectedComparisionTemperature}`]: false,
      }));
      }
  };

  const [paragraphToggleStates, setParagraphToggleStates] = useState({});

  const toggleLanguageForParagraph = (chapterKey, paragraphKey) => {
    if (historyVisible[`${chapterKey}-${paragraphKey}`]) {
      setHistoryVisible((prevState) => ({
        ...prevState,
        [`${chapterKey}-${paragraphKey}`]: false,
      }));
    }
    if (!paragraphToggleStates[`${chapterKey}-${paragraphKey}`]) {
      setSentenceEditConfirm([]);
      getParagraph(chapterKey, paragraphKey);
    }
    setParagraphToggleStates((prevState) => ({
      ...prevState,
      [`${chapterKey}-${paragraphKey}`]: !prevState[`${chapterKey}-${paragraphKey}`],
    }));
  };


  const handleGoToDashboard=()=>{
    localStorage.removeItem('myLang')
    localStorage.removeItem('myTemp')

    // localStorage.removeItem('page')
    localStorage.removeItem('pageData')
    navigate("/dashboard")
  }

  // const handleUpdateTranslatedText = (chapterKey, paragraphKey) => {
  //   setEditingParagraphs((prevState) => ({
  //     ...prevState,
  //     [`${chapterKey}-${paragraphKey}`]: false,
  //   }));

  //   const editedKey = `${chapterKey}-${paragraphKey}`;
  //   const editedText = editedContent[editedKey];

  //   const translatedKey = `Chapter ${chapterKey}-Paragraph ${paragraphKey}`;
  //   const translatedText = translatedDutchToEnglish[translatedKey] || "";

  //   const updatedContent = editedText || translatedText;

  //   setTranslatedDutchToEnglish((prevState) => ({
  //     ...prevState,
  //     [translatedKey]: updatedContent,
  //   }));
  // };

  const automateTranslationFetchHandler = async (selectedParagraphIndex, isInCompare) => {
    const currentIndex = pairingData?.auto_ai_resource?.resources?.findIndex((resource) =>
      Array.isArray(selectedParagraphIndex)
        ? selectedParagraphIndex.includes(resource._id)
        : resource._id === selectedParagraphIndex
    );

    // let fetchNextIndex = currentIndex;
    let fetchNextTwoParaIndexs = [];

    //From the window size of 5,trying to get the first next data that is not translated
    for (
      let i = currentIndex + 1;
      i <= currentIndex + 5 && i < pairingData?.auto_ai_resource?.resources.length;
      i++
    ) {
      const sentenceText =
        pairingData?.auto_ai_resource?.resources[i]?.textTranslatedLanguageSentences[0]?.sentence?.text;
        const chapter = pairingData?.auto_ai_resource?.resources[i];
      
        let myFilter = isInCompare ? (sentence) => (
          sentence.target_language===selectedLanguage
          && sentence.model_name===selectedComparisionModelName
          && +sentence.temperature === +selectedComparisionTemperature
          )
          :
          (sentence) => (
          sentence.target_language===selectedLanguage
          && sentence.model_name===selectedModelName
          && +sentence.temperature === +selectedTemperature
          ); 
        let translatedPairParagraph = chapter?.textTranslatedLanguageSentences?.
                filter(myFilter)
        translatedPairParagraph =  translatedPairParagraph[0]?.sentence?.text 
      if (!translatedPairParagraph) {
        // fetchNextIndex = i;
        fetchNextTwoParaIndexs.push(i);
        // break;
      }
    }

    fetchNextTwoParaIndexs.forEach(async (currIndexToFetch) => {
      if (currIndexToFetch === currentIndex) return;
      const newResource = pairingData?.auto_ai_resource?.resources[currIndexToFetch];
      const newChapterKey = newResource?.chapter?.sequenceNumber ?? null;
      const newParagraphKey = newResource?.paragraph?.sequenceNumber ?? null;
      let isNextResourceLoading;
      if (!isInCompare)
      isNextResourceLoading=loadingLatestParagraphs[`Chapter ${newChapterKey}-Paragraph ${newParagraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`] ?? false;
      else
      isNextResourceLoading=loadingLatestComparisionParagraphs[`Chapter ${newChapterKey}-Paragraph ${newParagraphKey}-Model ${selectedComparisionModelName}-Temperature ${selectedComparisionTemperature}`] ?? false;
      if (!isNextResourceLoading && newChapterKey && newParagraphKey && newResource) {
        if (isInCompare) {
          await updateLatestParagraphCompare(
            newResource,
            newResource.chapter.sequenceNumber,
            newResource.paragraph.sequenceNumber,
            "nextFivePara",
            0
          );
          } else {
          await updateLatestParagraph(
          newResource,
          newResource.chapter.sequenceNumber,
          newResource.paragraph.sequenceNumber,
          "nextFivePara",
          0
        );
        }
      }
    });
  };


  const handleCloseTranslatedText = (chapterKey, paragraphKey) => {
    setEditingParagraphs((prevState) => ({
      ...prevState,
      [`${chapterKey}-${paragraphKey}`]: false,
    }));
  };

  const toggleCompareMode = (chapterKey, paragraphKey) => {
    const paragraphIdentifier = `${chapterKey}-${paragraphKey}`;
    if (compareModeParagraphs.includes(paragraphIdentifier)) {
      setCompareModeParagraphs((prevCompareModeParagraphs) =>
        prevCompareModeParagraphs.filter((item) => item !== paragraphIdentifier)
      );
    } else {
      setCompareModeParagraphs((prevCompareModeParagraphs) => [
        ...prevCompareModeParagraphs,
        paragraphIdentifier,
      ]);
    }
  };

  const updateTheMatch = async (data) => {
    const newData = {
      ...pairingData,
      auto_ai_resource: {
        ...pairingData?.auto_ai_resource,
        resources: pairingData?.auto_ai_resource?.resources?.map((resource) =>
          resource._id === data?.[0]?._id ? data[0] : resource
        ),
      },
    };

    const updateData = {
      author_name: pairingData?.author_name,
      auto_ai_resource: {
        resources: data,
      },
      book_title: pairingData?.book_title,
      dutch_file_name: pairingData?.dutch_file_name,
      english_file_name: pairingData?.english_file_name,
      pair_style: pairingData?.pair_style,
      pair_type: pairingData?.pair_type,
      unique_string: pairingData?.unique_string,
    };

    try {
      const res = await fetchData(apiUrls.updatepairs, "POST", updateData);
      if (res.status === "success") {
        // setIsLoading(false);
        // setSentenceEditMode([]);
        notify("Updated Successfully");
        setPairingData(newData);
      }
      // onEditPairingClick();
    } catch (err) {
      console.log(err);
    }
  };

  const updateTheNodeConnectionConfirmPair = async (
    // resourceId
    selectedParagraphIndex,
    // chapter sequence no.
    chapterKey,
    // paragraph sequence no.
    paragraphKey,
    // "confirm"
    pressed,
    // string[] paraTranslated
    sentenceIdOnConfirm,

    translatedSentenceId,

    translatedSentenceToSave,

    dontOpenEditMode
  ) => {
    const matchingResources = pairingData?.auto_ai_resource?.resources?.filter((resource) =>
      Array.isArray(selectedParagraphIndex)
        ? selectedParagraphIndex.includes(resource._id)
        : resource._id === selectedParagraphIndex
    );
    if (pressed === "confirm") {
      const sentanceInfo = await getSentenceInfo(chapterKey, (parseInt(paragraphKey) + 1).toString(), 0);
      // setEditingSentences({
      //   [`${chapterKey}-${parseInt(paragraphKey) + 1}`]: true,
      // });
      const currentIndex = pairingData?.auto_ai_resource?.resources?.findIndex((resource) =>
        Array.isArray(selectedParagraphIndex)
          ? selectedParagraphIndex.includes(resource._id)
          : resource._id === selectedParagraphIndex
      );
      const sentenceText =
        pairingData?.auto_ai_resource?.resources[currentIndex + 1]?.textTranslatedLanguageSentences[0]
          ?.sentence?.text;

      const chapter = pairingData?.auto_ai_resource?.resources[currentIndex + 1];

      let translatedPairParagraph = chapter?.textTranslatedLanguageSentences?.
        filter((sentence) => (
          sentence.target_language===selectedLanguage
          && sentence.model_name===selectedModelName
          && +sentence.temperature === +selectedTemperature
          ))

      translatedPairParagraph =  translatedPairParagraph?.[0]?.sentence?.text   
      if (translatedPairParagraph) {
        setShowConfirmTranslation([`${chapterKey}-${parseInt(paragraphKey) + 1}`]);
        if (!dontOpenEditMode) {
          setSentenceEditMode([`${chapterKey}-${parseInt(paragraphKey) + 1}`]);
          setEditingSentences({
            [`${chapterKey}-${parseInt(paragraphKey) + 1}`]: true,
          });
        }
      } else {
        if (!dontOpenEditMode) {
          setEditingSentences({
            [`${chapterKey}-${parseInt(paragraphKey)}`]: false,
          });
          setSentenceEditMode([]);
        }
      }
      setIsOriginalParaId(sentanceInfo?.translationPara?.connectedWithOriginalLanguageTextSentenceIds);
      handleTextAreaClick(sentanceInfo?.sentenceText?.length, 0);
      textAreaRef?.current?.setSelectionRange(0, 0);
      textAreaRef?.current?.focus();
    }
    if (pressed === "confirm") {
      if (matchingResources[0].status !== "approved") handleProgressBar(1);
      // matchingResources[0].status = 'approved'

      if(selectedTemperature === '1') {
        selectedTemperature = '1.0'
      }

      matchingResources[0].status = {
          ...matchingResources[0].status,
          [selectedLanguage]: {
            ...matchingResources[0].status[selectedLanguage],
            [selectedTemperature]: 'approved'
          }
      };

    }
    const existingCSV = matchingResources[0].csv;
    const existingCSVJson = existingCSV;
    if (!existingCSVJson["Area_of_improvement"]) existingCSVJson["Area_of_improvement"] = [];
    matchingResources[0].textTranslatedLanguageSentences.forEach((sentence, index) => {
      if (editedContent[sentence.sentenceId] !== undefined) {
        sentence.sentence.predictedText = sentence.sentence.text;
        sentence.sentence.text = editedContent[sentence.sentenceId];
        if (translatedSentenceToSave && translatedSentenceId === sentence.sentenceId) {
          sentence.sentence.text = translatedSentenceToSave
        }        
      } else {
        sentence.sentence.text = sentence.sentence.text;
      }
      setLastChangesText(editedContent[sentence.sentenceId]);

      if (pressed === "confirm" && feedbackContent[sentence.sentenceId]) {
        const index = existingCSVJson["Area_of_improvement"].findIndex((obj) => sentence.sentenceId in obj);

        if (index !== -1) {
          const key = Object.keys(existingCSVJson["Area_of_improvement"][index])[0];

          const elements = [
            ...existingCSVJson["Area_of_improvement"][index][key],
            ...feedbackContent[sentence.sentenceId],
          ];
          const uniqueElements = [...new Set(elements)];

          existingCSVJson["Area_of_improvement"][index][key] = uniqueElements;
        } else {
          existingCSVJson["Area_of_improvement"].push({
            [sentence.sentenceId]: [...feedbackContent[sentence.sentenceId]],
          });
        }
      }
    });
    const modifiedCSV = existingCSVJson;
    if (pressed === "confirm") matchingResources[0].csv = modifiedCSV;

    const updateData = {
      author_name: pairingData?.author_name,
      auto_ai_resource: {
        resources: matchingResources,
      },
      book_title: pairingData?.book_title,
      dutch_file_name: pairingData?.dutch_file_name,
      english_file_name: pairingData?.english_file_name,
      pair_style: pairingData?.pair_style,
      pair_type: pairingData?.pair_type,
      unique_string: pairingData?.unique_string,
    };
    // return
    try {
      // setIsLoading(true);
      setLoadingParagraphs((prevState) => ({
        ...prevState,
        [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`]: true,
      }));
      setLoadingComparisionParagraphs((prevState) => ({
        ...prevState,
        [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedComparisionModelName}-Temperature ${selectedComparisionTemperature}`]: true,
      }));
      const res = await fetchData(apiUrls.updatepairs, "POST", updateData);
      if (res.status === "success") {
        // setIsLoading(false);
        setLoadingParagraphs((prevState) => ({
          ...prevState,
          [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`]: false,
        }));
        setLoadingComparisionParagraphs((prevState) => ({
          ...prevState,
          [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedComparisionModelName}-Temperature ${selectedComparisionTemperature}`]: false,
        }));
        toggleFeedbackMode(chapterKey, paragraphKey);
        // toggleConfirmMode(chapterKey, paragraphKey);
        setSentenceEditFeedbackMode("");
        if (pressed === "tick") {
          setSentenceEditConfirm((prev) => [...prev, sentenceIdOnConfirm]);
        }
        // setSentenceEditMode([]);
        if (pressed === "tick") notify("Updated Successfully");
      }

      automateTranslationFetchHandler(selectedParagraphIndex, showCompare?true:false);
      logEvent(analytics, "update_pairs_success", { success: true });
    } catch (err) {
      console.log(err);
      logEvent(analytics, "update_pairs_failed", { error: err.message });
      // setIsLoading(false);
      setLoadingParagraphs((prevState) => ({
        ...prevState,
        [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`]: false,
      }));
      setLoadingComparisionParagraphs((prevState) => ({
        ...prevState,
        [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedComparisionModelName}-Temperature ${selectedComparisionTemperature}`]: false,
      }));
    }
  };

  const updateTheNodeConnectionConfirmPairComparision = async (
    selectedParagraphIndex,
    chapterKey,
    paragraphKey,
    pressed,
    sentenceIdOnConfirm,
    translatedSentenceId,

    translatedSentenceToSave    
  ) => {
    const matchingResources = pairingData?.auto_ai_resource?.resources?.filter((resource) =>
      Array.isArray(selectedParagraphIndex)
        ? selectedParagraphIndex.includes(resource._id)
        : resource._id === selectedParagraphIndex
    );
    if (pressed === "confirm") {
      const sentanceInfo = await getSentenceInfo(chapterKey, (parseInt(paragraphKey) + 1).toString(), 0);
      // setEditingSentences({
      //   [`${chapterKey}-${parseInt(paragraphKey) + 1}`]: true,
      // });
      const currentIndex = pairingData?.auto_ai_resource?.resources?.findIndex((resource) =>
        Array.isArray(selectedParagraphIndex)
          ? selectedParagraphIndex.includes(resource._id)
          : resource._id === selectedParagraphIndex
      );
      const sentenceText =
        pairingData?.auto_ai_resource?.resources[currentIndex + 1]?.textTranslatedLanguageSentences[0]
          ?.sentence?.text;

      const chapter = pairingData?.auto_ai_resource?.resources[currentIndex + 1];

      let translatedPairParagraph = chapter?.textTranslatedLanguageSentences?.
        filter((sentence) => (
          sentence.target_language===selectedLanguage
          && sentence.model_name===selectedComparisionModelName
          && +sentence.temperature === +selectedComparisionTemperature
          ))
      translatedPairParagraph =  translatedPairParagraph?.[0]?.sentence?.text
      if (translatedPairParagraph) {
        setShowConfirmTranslation([`${chapterKey}-${parseInt(paragraphKey) + 1}`]);
        setSentenceEditMode([`${chapterKey}-${parseInt(paragraphKey) + 1}`]);
        setEditingSentences({
          [`${chapterKey}-${parseInt(paragraphKey) + 1}`]: true,
        });
      } else {
        setEditingSentences({
          [`${chapterKey}-${parseInt(paragraphKey)}`]: false,
        });
        setSentenceEditMode([]);
      }
      setIsOriginalParaId(sentanceInfo?.translationPara?.connectedWithOriginalLanguageTextSentenceIds);
      handleTextAreaClick(sentanceInfo?.sentenceText?.length, 0);
      textAreaRef?.current?.setSelectionRange(0, 0);
      textAreaRef?.current?.focus();
    }

    if (pressed === "confirm") {
      if (matchingResources[0].status !== "approved") handleProgressBar(1);
      matchingResources[0].status = "approved";
    }
    const existingCSV = matchingResources[0].csv;
    const existingCSVJson = existingCSV;
    if (!existingCSVJson["Area_of_improvement"]) existingCSVJson["Area_of_improvement"] = [];
    matchingResources[0].textTranslatedLanguageSentences.forEach((sentence, index) => {
      if (editedContent[sentence.sentenceId] !== undefined) {
        sentence.sentence.predictedText = sentence.sentence.text;
        sentence.sentence.text = editedContent[sentence.sentenceId];
        if (translatedSentenceToSave && translatedSentenceId === sentence.sentenceId) {
          sentence.sentence.text = translatedSentenceToSave
        }
      } else {
        sentence.sentence.text = sentence.sentence.text;
      }
      setLastChangesText(editedContent[sentence.sentenceId]);

      if (pressed === "confirm" && feedbackContent[sentence.sentenceId]) {
        const index = existingCSVJson["Area_of_improvement"].findIndex((obj) => sentence.sentenceId in obj);

        if (index !== -1) {
          const key = Object.keys(existingCSVJson["Area_of_improvement"][index])[0];

          const elements = [
            ...existingCSVJson["Area_of_improvement"][index][key],
            ...feedbackContent[sentence.sentenceId],
          ];
          const uniqueElements = [...new Set(elements)];

          existingCSVJson["Area_of_improvement"][index][key] = uniqueElements;
        } else {
          existingCSVJson["Area_of_improvement"].push({
            [sentence.sentenceId]: [...feedbackContent[sentence.sentenceId]],
          });
        }
      }
    });
    const modifiedCSV = existingCSVJson;
    if (pressed === "confirm") matchingResources[0].csv = modifiedCSV;

    const updateData = {
      author_name: pairingData?.author_name,
      auto_ai_resource: {
        resources: matchingResources,
      },
      book_title: pairingData?.book_title,
      dutch_file_name: pairingData?.dutch_file_name,
      english_file_name: pairingData?.english_file_name,
      pair_style: pairingData?.pair_style,
      pair_type: pairingData?.pair_type,
      unique_string: pairingData?.unique_string,
    };

    try {
      // setIsLoading(true);
      setLoadingComparisionParagraphs((prevState) => ({
        ...prevState,
        [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedComparisionModelName}-Temperature ${selectedComparisionTemperature}`]: true,
      }));
      setLoadingParagraphs((prevState) => ({
        ...prevState,
        [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`]: true,
      }));

      const res = await fetchData(apiUrls.updatepairs, "POST", updateData);
      if (res.status === "success") {
        // setIsLoading(false);
        setLoadingComparisionParagraphs((prevState) => ({
          ...prevState,
          [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedComparisionModelName}-Temperature ${selectedComparisionTemperature}`]: false,
        }));
        setLoadingParagraphs((prevState) => ({
          ...prevState,
          [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`]: false,
        }));
        toggleFeedbackMode(chapterKey, paragraphKey);
        // toggleConfirmMode(chapterKey, paragraphKey);
        setSentenceEditFeedbackMode("");
        if (pressed === "tick") {
          setSentenceEditConfirm((prev) => [...prev, sentenceIdOnConfirm]);
        }
        // setSentenceEditMode([]);
        if (pressed === "tick") notify("Updated Successfully");
      }

      automateTranslationFetchHandler(selectedParagraphIndex);
      logEvent(analytics, "update_pairs_success", { success: true });
    } catch (err) {
      console.log(err);
      logEvent(analytics, "update_pairs_failed", { error: err.message });
      // setIsLoading(false);
      setLoadingComparisionParagraphs((prevState) => ({
        ...prevState,
        [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedComparisionModelName}-Temperature ${selectedComparisionTemperature}`]: false,
      }));
      setLoadingParagraphs((prevState) => ({
        ...prevState,
        [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`]: false,
      }));
    }
  };

  const togglePaletteMode = (chapterKey, paragraphKey) => {
    const paragraphIdentifier = `${chapterKey}-${paragraphKey}`;
    if (paletteModeParagraphs.includes(paragraphIdentifier)) {
      setPaletteModeParagraphs((prevPaletteModeParagraphs) =>
        prevPaletteModeParagraphs.filter((item) => item !== paragraphIdentifier)
      );
    } else {
      setPaletteModeParagraphs((prevPaletteModeParagraphs) => [
        ...prevPaletteModeParagraphs,
        paragraphIdentifier,
      ]);
    }
  };

  const toggleFeedbackMode = (chapterKey, paragraphKey) => {
    const paragraphIdentifier = `${chapterKey}-${paragraphKey}`;
    if (feedbackModeParagraphs.includes(paragraphIdentifier)) {
      setFeedbackModeParagraphs((prevPaletteModeParagraphs) =>
        prevPaletteModeParagraphs.filter((item) => item !== paragraphIdentifier)
      );
    } else {
      setFeedbackModeParagraphs((prevFeedbackModeParagraphs) => [
        ...prevFeedbackModeParagraphs,
        paragraphIdentifier,
      ]);
    }
  };

  const toggleConfirmMode = (chapterKey, paragraphKey) => {
    const paragraphIdentifier = `${chapterKey}-${paragraphKey}`;
    if (showConfirmTranslation.includes(paragraphIdentifier)) {
      setShowConfirmTranslation((prevPaletteModeParagraphs) =>
        prevPaletteModeParagraphs.filter((item) => item !== paragraphIdentifier)
      );
    } else {
      setShowConfirmTranslation((prevFeedbackModeParagraphs) => [
        ...prevFeedbackModeParagraphs,
        paragraphIdentifier,
      ]);
    }
  };

  const toggleConfirmButton = (chapterKey, paragraphKey) => {
    const paragraphIdentifier = `${chapterKey}-${paragraphKey}`;
    if (showConfirmTranslation.includes(paragraphIdentifier)) {
      // setShowConfirmTranslation((prevPaletteModeParagraphs) =>
      //   prevPaletteModeParagraphs.filter((item) => item !== paragraphIdentifier)
      // );
      return;
    } else {
      setShowConfirmTranslation(() => [paragraphIdentifier]);
    }
  };

  
  const toggleEditMode = (chapterKey, paragraphKey, sentenceId, cursorPosition) => {
    // setSentenceEditMode((prevState) => [...prevState, sentenceId]);
    setSentenceEditMode(() => [`${chapterKey}-${paragraphKey}`]);
    setColorCode("#FFFFE5");
    // setEditingSentences({
    //   ...editingSentences,
    //   [`${chapterKey}-${paragraphKey}`]: true,
    // });
    setEditingSentences({
      [`${chapterKey}-${paragraphKey}`]: true,
    });
    setFeedbackSelectorVisible("");

    // adjustTextareaHeight();
    // toggleConfirmMode(chapterKey, paragraphKey);

    // set 'view as english' to false whenever editing
    setParagraphToggleStates((prevState) => ({
      ...prevState,
      [`${chapterKey}-${paragraphKey}`]: false,
    }));
  };

  const toggleEditFeedbackMode = (sentenceId, chapterKey, paragraphKey) => {
    setDivClick(true);
    if (sentenceEditFeedbackMode === "") {
      // setSentenceEditFeedbackMode(sentenceId);
    } else {
      // setSentenceEditFeedbackMode("");
      setFeedbackSelectorVisible("");
    }
    setSentenceEditMode([]);
    // setSentenceEditFeedbackMode(sentenceId);

    if (sentenceEditFeedbackMode === sentenceId) {
      setSentenceEditFeedbackMode("");
    } else {
      setSentenceEditFeedbackMode(sentenceId);
    }
    setEditingSentences(() => ({
      [`${chapterKey}-${paragraphKey}`]: false,
    }));
  };

  const getSentenceInfo = (chapterSequenceNumber, paragraphSequenceNumber, sentanceIndex) => {
    const result = {};

    // Find the object with the specified chapter and paragraph sequence numbers
    const matchingObject = pairingData.auto_ai_resource.resources.find((obj) => {
      return (
        obj.chapter.sequenceNumber === chapterSequenceNumber &&
        obj.paragraph.sequenceNumber === paragraphSequenceNumber
      );
    });

    if (matchingObject) {
      // Get sentenceId and sentence text from the first sentence in textOriginalLanguageSentences array
      const originalSentence = matchingObject.textTranslatedLanguageSentences[sentanceIndex];
      result.sentenceId = originalSentence.sentenceId;
      result.sentenceText = originalSentence.sentence.text;
      result.translationPara = matchingObject.textTranslatedLanguageSentences;
    }
    return result;
  };

  const handleCloseEditededText = async (
    chapterKey,
    paragraphKey,
    sentenceIdArr = [],
    pressed,
    sentanceIndex,
    paragraphId
  ) => {
    let forDeletion = sentenceIdArr?.map((sentence) => sentence.sentenceId);

    const tempSentenceIds = sentenceEditMode?.filter((item) => !forDeletion.includes(item));

    setSentenceEditMode(tempSentenceIds);
    setEditingSentences((prevState) => ({
      ...prevState,
      [`${chapterKey}-${paragraphKey}`]: false,
    }));
    setSentenceEditFeedbackMode("");
    if (pressed === "Close") {
      setEditedContent({});
    }
    let nextSentence = "";
    let nextRefIndex = "";
    if (pressed == "onKeyDown" && typeof sentanceIndex !== "undefined") {
      const hasNextSentance = sentenceIdArr.length - 1 != sentanceIndex;
      let nextSentanceId = hasNextSentance ? sentenceIdArr[sentanceIndex + 1].sentenceId : "";
      let nextParagraphKey = paragraphKey;
      if (nextSentanceId === "" && !hasNextSentance) {
        await updateTheNodeConnectionConfirmPair(paragraphId, chapterKey, paragraphKey, "confirm");
        handleClick(paragraphKey);
        nextParagraphKey = paragraphsToShow.length - 1 != paragraphKey ? +paragraphKey + 1 : "";
        if (nextParagraphKey != "") {
          const sentanceInfo = await getSentenceInfo(chapterKey, (+nextParagraphKey).toString(), 0);
          nextSentanceId = sentanceInfo.sentenceId;
          nextSentence = sentanceInfo.sentenceText;
        }
        nextRefIndex = 0;
      } else if (nextSentanceId != "") {
        const sentanceInfo = await getSentenceInfo(
          chapterKey,
          (+nextParagraphKey).toString(),
          sentanceIndex + 1
        );
        nextSentence = sentanceInfo.sentenceText;
        nextRefIndex = sentanceIndex + 1;
      }
      setShowConfirmTranslation(() => [`${chapterKey}-${nextParagraphKey}`]);
      setSentenceEditFeedbackMode(nextSentanceId);
      setSentenceEditMode([nextSentanceId]);
      setEditingSentences({
        [`${chapterKey}-${nextParagraphKey}`]: true,
      });
      setTimeout(() => {
        handleTextAreaClick(nextSentence?.length, nextRefIndex);
        textAreaRef?.current?.focus();
      }, 10);
    }
  };

  useEffect(() => {
    setSentenceEditMode([]);
    const newSentenceColors = {};
    pairingEdges.forEach((edge) => {
      const sourceId = edge.source.split("-")[1];
      const targetId = edge.target.split("-")[1];

      if (!newSentenceColors[sourceId]) {
        const color = getRandomColor();
        newSentenceColors[sourceId] = color;
        newSentenceColors[targetId] = color;
      }
    });

    setSentenceColors(newSentenceColors);
  }, [pairingEdges]);

  const getRandomColor = () => {
    const colors = ["#D3D2FF75", "#FFE28F75", "#C2DAFF75", "#FFBDD375", "#C7FFD675", "#BEBEBE75"];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const colors = [
    "Accuracy",
    "Fluency",
    "Consistency",
    "Appropriateness",
    "Grammar",
    "Style",
    "Formatting",
    "Cultural Adaptation",
  ];
  const [selectedColors, setSelectedColors] = useState([]);

  const handleColorChange = (color, id) => {
    // Toggle selected state for the color

    if (id in feedbackContent) {
      if (feedbackContent[`${id}`].includes(color)) {
        const finlRes = feedbackContent[`${id}`].filter((selectedColor) => selectedColor !== color);
        setFeedbackContent({ ...feedbackContent, [`${id}`]: finlRes });
      } else {
        setFeedbackContent((prevFeedbackContent) => {
          return {
            ...feedbackContent,
            [`${id}`]: [...prevFeedbackContent[`${id}`], color],
          };
        });
      }
    } else {
      setFeedbackContent({ ...feedbackContent, [`${id}`]: [color] });
    }

    if (selectedColors.includes(color)) {
      setSelectedColors((prevSelectedColors) =>
        prevSelectedColors.filter((selectedColor) => selectedColor !== color)
      );

      // setFeedbackContent({
      //   ...feedbackContent,
      //   [`${id}`]: [...selectedColors,color],
      // });
    } else {
      setSelectedColors((prevSelectedColors) => [...prevSelectedColors, color]);
      // setFeedbackContent({
      //   ...feedbackContent,
      //   [`${id}`]: [...selectedColors,color],
      // });
    }
    // setFeedbackContent({
    //   ...feedbackContent,
    //   [`${id}`]: selectedColors,
    // });
  };

  const handleAddKeyWordOnEnter = (event) => {
    //event.preventDefault();
    if (event?.key === "Enter" || event?.type === "click") {
      if (paraWord?.length < 2) {
        return true;
      }
       
       setbyPopupAddWordInGlossaryScenario(false)

      if(checkContainsSpecialCharacters(paraWord)){
        notify("Special characters are not allowed for this field")
        return true
      }
       
      const storeGlossary = glossaryItem.book_level;
      let payload = {
        unique_string: pairedBookId,
        glossary_item: {
          book_level: [
            ...(storeGlossary.length
              ? [
                  ...storeGlossary,
                  {
                    [paraWord]: {
                      values:  userEnteringGlossaryForVariantState?.isOn ? [userEnteringGlossaryForVariantState.variantWord] : [],
                      application_scope: "book_level",
                      glossary_scope: "Book",
                      language: "dutch",
                      },
                  },
                ]
              : [
                  {
                    [paraWord]: {
                      values: userEnteringGlossaryForVariantState?.isOn ? [userEnteringGlossaryForVariantState.variantWord] : [],
                      application_scope: "book_level",
                      glossary_scope: "Book",
                      language: "dutch",
                    },
                  },
                ]),
          ],
        },
      };
      //     dispatch(addKeywords({
      //   payload:payload,
      // fetchData
      // }))
      let lengthOfKeywordArray = payload.glossary_item.book_level.length - 1;
      dispatch(setAddKeyWord(payload.glossary_item.book_level));
      setEnableGlossaryWord(lengthOfKeywordArray);

      setCurrentKeywordWithVariant({ [paraWord]: { values: userEnteringGlossaryForVariantState?.isOn ? [userEnteringGlossaryForVariantState?.variantWord] : [] } });
      // Update your state with the new values
      //dispatch(setGlossaryItemWithKeyword(updatedStore.glossaryItem));

      setParaWrod("");
     
     
      setEnableInputBox(false);
      
      console.log("updated Values :::: ",enableVariantInputBox,currentKeyWordWithVariant,userEnteringGlossaryForVariantState)
      
      setUserEnteringGlossaryForVariantState(oldState=>{
        return {
          isOn: false,
          variantWord: "",
          keywordWithVariant: null          
        }
      })

    }
  };

  const handleEditPairingClick = (paraId, chapterKey, paragraphKey) => {
    setEditingSentences({
      [`${chapterKey}-${paragraphKey}`]: true,
    });
    // setEditingSentences({});
    setSentenceEditMode([]);
    setShowConfirmTranslation([]);
    setShowEditPairing(paraId);
  };

  const handleSliderValueChange = (value) => {
    setSliderValueFromChild(value);
  };
  const openColorSelector = () => {
    setFeedbackSelectorVisible((prev) => !prev);
  };
  // const adjustTextareaHeight = () => {
  //   const spanHeight = document.querySelector(".paragraph-sentence").getBoundingClientRect().height;
  //   setTextareaHeight(`${spanHeight}px`);
  // };
  const adjustTextareaHeight = (event) => {
    const clickedSpan = event.currentTarget; // Get the clicked span element
    const spanHeight = clickedSpan.getBoundingClientRect().height;
    const fontSize = 16; // Font size in pixels

    // Define a minimum factor and a maximum factor for adjustment
    const minFactor = 1.2;
    const maxFactor = 5.0;

    // Calculate the factor based on the span's height
    const factor = Math.min(maxFactor, minFactor + (spanHeight / 500) * (maxFactor - minFactor));

    // Calculate the adjusted height using the dynamic factor
    const adjustedHeight = spanHeight + fontSize * factor;

    // Set the textarea height to the adjusted height
    setTextareaHeight(`${adjustedHeight}px`);
  };

  const updateLatestParagraph = async (latestPara, chapterKey, paragraphKey, nextFivePara, index = 0) => {
    if (historyVisible[`${chapterKey}-${paragraphKey}`]) {
      setHistoryVisible((prevState) => ({
        ...prevState,
        [`${chapterKey}-${paragraphKey}`]: false,
      }));
    }
    if (!nextFivePara) {
      setEditingSentences({});
      setSentenceEditMode([]);
    }

    const assistants = await fetchData(apiUrls.getAssistants, "GET");
    const assistantName = assistants.find((assistant) => 
      assistant.target_language===selectedLanguage && 
      assistant.model_name===selectedModelName
    );

    const latestData = {
      custom_temperature: +selectedTemperature,
      assistant_name: assistantName?.assistant_name,
      author_name: pairingData?.author_name,
      auto_ai_resource: {
        resources: [latestPara],
        totalCount: pairingData?.auto_ai_resource?.totalCount,
      },
      book_title: pairingData?.book_title,
      dutch_file_name: pairingData?.dutch_file_name,
      english_file_name: pairingData?.english_file_name,
      pair_style: "Paragraph",
      pair_type: pairingData?.pair_type,
      unique_string: pairingData?.unique_string,
      total_chapters: pairingData?.total_chapters,
      chapters: pairingData?.chapters,
      genre: pairingData?.genre
    };

    try {
      setErrorLatestParagraphs((prevState) => ({
        ...prevState,
        [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`]: false,
      }));
      setLatestLoadingParagraphs((prevState) => ({
        ...prevState,
        [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`]: true,
      }));
      let response;
      try {
        response = await fetchData(apiUrls.getLatestParagraph, "POST", latestData);
      } catch(error) {
        console.error("API error")
      }
      logEvent(analytics, "regenerate_translation", { status: "success" });

      // console.log("gotLatest", response);
      // const dataForGlobalStorage = {
      //   ...pairingData,//1st 2nd(old)
      //   auto_ai_resource: {
      //     ...pairingData?.auto_ai_resource,
      //     resources: pairingData?.auto_ai_resource?.resources?.map((resource) =>
      //       resource.csv.local_id === response.csv.local_id ? response : resource
      //     ),
      //   },
      // };

      /**
       * @todo Using Pairing data as a reference here instead of immutable recheck this code later
       */
      let updatedResourse = pairingData?.auto_ai_resource?.resources?.map((resource) =>
        resource.csv.local_id === response.csv.local_id ? response : resource
      );
      pairingData.auto_ai_resource.resources = updatedResourse;
      // resource.csv.local_id === response.csv.local_id ? response : resource

      // dispatch(setPairedBookData(dataForGlobalStorage));
      // setPairingData(pairedData);

      setPairingData(pairingData);
      dispatch(setPairedBookData(pairingData));
      setLatestLoadingParagraphs((prevState) => ({
        ...prevState,
        [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`]: false,
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
      logEvent(analytics, "regenerate_translation_error", { error: error.message });
      setLatestLoadingParagraphs((prevState) => ({
        ...prevState,
        [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`]: false,
      }));
      setErrorLatestParagraphs((prevState) => ({
        ...prevState,
        [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`]: true,
      }));
    }
  };

  /**
   * A clone of updateLatestParagraph used in Compare column
   * @param {*} latestPara 
   * @param {*} chapterKey 
   * @param {*} paragraphKey 
   * @param {*} nextFivePara 
   * @param {*} index 
   * @todo refactor this later
   */
  const updateLatestParagraphCompare = async (latestPara, chapterKey, paragraphKey, nextFivePara, index = 0) => {
    if (historyVisible[`${chapterKey}-${paragraphKey}`]) {
      setHistoryVisible((prevState) => ({
        ...prevState,
        [`${chapterKey}-${paragraphKey}`]: false,
      }));
    }
    if (!nextFivePara) {
      setEditingSentences({});
      setSentenceEditMode([]);
    }

    const assistants = await fetchData(apiUrls.getAssistants, "GET");
    const assistantName = assistants.find((assistant) => 
      assistant.target_language===selectedLanguage && 
      assistant.model_name===selectedComparisionModelName
    );

    const latestData = {
      custom_temperature: +selectedComparisionTemperature,
      assistant_name: assistantName?.assistant_name,
      author_name: pairingData?.author_name,
      auto_ai_resource: {
        resources: [latestPara],
        totalCount: pairingData?.auto_ai_resource?.totalCount,
      },
      book_title: pairingData?.book_title,
      dutch_file_name: pairingData?.dutch_file_name,
      english_file_name: pairingData?.english_file_name,
      pair_style: "Paragraph",
      pair_type: pairingData?.pair_type,
      unique_string: pairingData?.unique_string,
      total_chapters: pairingData?.total_chapters,
      chapters: pairingData?.chapters,
    };

    try {
      setErrorLatestComparisionParagraphs((prevState) => ({
        ...prevState,
        [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedComparisionModelName}-Temperature ${selectedComparisionTemperature}`]: false,
      }));
      setLatestLoadingComparisionParagraphs((prevState) => ({
        ...prevState,
        [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedComparisionModelName}-Temperature ${selectedComparisionTemperature}`]: true,
      }));
      const response = await fetchData(apiUrls.getLatestParagraph, "POST", latestData);
      logEvent(analytics, "regenerate_translation", { status: "success" });

      // console.log("gotLatest", response);
      // const dataForGlobalStorage = {
      //   ...pairingData,//1st 2nd(old)
      //   auto_ai_resource: {
      //     ...pairingData?.auto_ai_resource,
      //     resources: pairingData?.auto_ai_resource?.resources?.map((resource) =>
      //       resource.csv.local_id === response.csv.local_id ? response : resource
      //     ),
      //   },
      // };

      /**
       * @todo Using Pairing data as a reference here instead of immutable recheck this code later
       */
      
      let updatedResourse = pairingData?.auto_ai_resource?.resources?.map((resource) =>
        resource.csv.local_id === response.csv.local_id ? response : resource
        );
        pairingData.auto_ai_resource.resources = updatedResourse;
      
      // resource.csv.local_id === response.csv.local_id ? response : resource

      // dispatch(setPairedBookData(dataForGlobalStorage));
      // setPairingData(pairedData);

      setPairingData(pairingData);
      dispatch(setPairedBookData(pairingData));
      setLatestLoadingComparisionParagraphs((prevState) => ({
        ...prevState,
        [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedComparisionModelName}-Temperature ${selectedComparisionTemperature}`]: false,
      }));      
    } catch (error) {
      console.error("Error fetching data:", error);
      logEvent(analytics, "regenerate_translation_error", { error: error.message });
      setLatestLoadingComparisionParagraphs((prevState) => ({
        ...prevState,
        [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedComparisionModelName}-Temperature ${selectedComparisionTemperature}`]: false,
      }));
      setErrorLatestComparisionParagraphs((prevState) => ({
        ...prevState,
        [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedComparisionModelName}-Temperature ${selectedComparisionTemperature}`]: true,
      }));
    }
  };


  //Handle Glossary items
  const convertResponse = (response) => {
    const convertedData = [];

    for (const key in response) {
      if (response.hasOwnProperty(key)) {
        const categoryObj = {
          [key]: response[key],
        };
        convertedData.push(categoryObj);
      }
    }

    return convertedData;
  };

  /**
   * 
   * @param {number} index 
   * @param {Object} chapter 
   * @param {{
   *  scenario: "userWantsToAddGlossaryWordForAVariantByClickingOnYesOnAPopup",
   *  variantWordIfuserWantsToAddGlossaryWordForAVariantByClickingOnYesOnAPopup:string
   *  glossaryIndexToSet
   * }} options 
   */
  const handleGlossary = async (index, chapter, options) => {

    setGlossaryIndex(index);

    const scenario = options?.scenario || "";
    
    setParaWrod("");
    setSelectedVariant("");
    setCurrentVarientText("");
    dispatch(setIsGlossaryCalledFalse());
    setCurrentKeywordWithVariant({});
    setLoader(true);
    setEnableInputBox(false);
    setParagraphWords([]);
    // setSentenceEditMode([]);
    // setEditingSentences({});
    const paragraphData = {
      unique_string: chapter?.unique_string,
      resources: [chapter],
      glossary_scope: "Book",
      language: selectedLanguage,
    };
    try {
      const result = await fetchData(apiUrls.getGlossaryItem, "POST", paragraphData);
      if (result.glossary_items) {
        const allValues = Object.keys(result.glossary_items["book_level"]);

        // Remove duplicates using Set
        const convertedData = convertResponse(result.glossary_items.book_level);
        const newData = { ...result.glossaryItem, book_level: convertedData };
        dispatch(setGlossaryItem(newData));
      } else {
        dispatch(setGlossaryItem({}));
      }
    } catch (err) {
      console.log(err);
      dispatch(setGlossaryItem({}));
    }
    setLoader(false);
    setWordVariant(false);
    setApplyGlossary(false);
    setIsExtended(!isExtended);
    if (scenario === "userWantsToAddGlossaryWordForAVariantByClickingOnYesOnAPopup") {
      setIsGlossaryVisible(true);
    }
  };

  const handlePayloadForGlossary = (chapter, level) => {
    let key = Object.keys(currentKeyWordWithVariant)[0];
    const bookLevelIndex = glossaryItem?.book_level.findIndex((item) => Object.keys(item)[0] === key);
    let values = glossaryItem?.book_level?.find((data, i) => i === bookLevelIndex)[key];
    setCurrentKeywordWithVariant({
      [key]: { values: [...values.values], application_scope: level },
    });

    let [chapterKey, paragraphKey] = showConfirmTranslation.length
      ? showConfirmTranslation[0].split("-")
      : [currentPage, "0"];

    const payloadForAdd = {
      glossaryItem: {
        ...glossaryItem,
        book_level: glossaryItem.book_level.map((item, index) => {
          if (index === bookLevelIndex) {
            const testKey = Object.keys(item)[0];
            return {
              [testKey]:
                 {
                      application_scope: "book_level",
                      values: [...item[key]?.values],
                      glossary_scope: level,
                      language: selectedLanguage,
                      author_name: chapter?.author_name,
                      unique_string: pairedBookId,
                      genre: pairedData.genre
                    },
            };
          }
          return item;
        }),
      },
    };

    console.log("Genre Check DDD", chapter)

    const payloadForApply =
      {
            unique_string: pairedBookId,
            current_chapter: currentPage,
            glossary_item: {
              book_level: [
                {
                  [key]: {
                    values: [selectedVariant],
                    application_scope: "book_level",
                    glossary_scope: level,
                    language: selectedLanguage,
                    author_name: chapter?.author_name,
                    genre: pairedData.genre,
                    unique_string: pairedBookId
                  },
                },
              ],
            },
      }


    return {
      payloadForAdd: {
        unique_string: pairedBookId,
        glossary_item: payloadForAdd?.glossaryItem,
      },
      payloadForApply: payloadForApply,
    };
  };  

  const applyGlossaryToWholeBook = async (level, chapter) => {
    setApplyGlossary(false);
    const requestPayload = handlePayloadForGlossary(chapter, level);
    dispatch(
      addKeywords({
        payload: requestPayload?.payloadForAdd,
        fetchData,
      })
    );
    const payload = requestPayload?.payloadForApply;
    const id = toast.loading("Please wait...");
    const data = await dispatch(applyGlossaryToBook({ payload, fetchData }));
    const updatedResources = pairingData?.auto_ai_resource?.resources?.map((resource) => {
      const matchingInput =
        data?.resources?.length > 0 && data?.resources?.find((item) => item._id === resource._id);
      if (matchingInput) {
        return { ...resource, ...matchingInput };
      }
      return resource;
    });
    setLoader(false)
    pairingData.auto_ai_resource.resources = updatedResources;
    if (data?.resources?.length > 0)
      toast.update(id, {
        render: "Glossary Applied Successfully",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
    else
      toast.update(id, {
        render: "Please try again with another variant",
        type: "warning",
        isLoading: false,
        autoClose: 5000,
      });
  };

  useEffect(() => {
    if (isGetKeywordApiCalled) {
      let lengthOfKeywordArray = glossaryItem?.book_level?.length - 1;
      if (lengthOfKeywordArray + 1 !== 0) {
        let paraWord = Object.keys(
          glossaryItem?.book_level?.find((data, i) => i === lengthOfKeywordArray)
        )?.[0];
        let values = glossaryItem?.book_level?.find((data, i) => i === lengthOfKeywordArray)[paraWord];
        //dispatch(setAddKeyWord(payload.glossary_item.book_level))
        setEnableGlossaryWord(lengthOfKeywordArray);
        setSelectedVariant(values?.values[0]);
        setCurrentKeywordWithVariant({
          [paraWord]: { values: [...values.values], application_scope: values?.application_scope },
        });
        // Update your state with the new values
        //dispatch(setGlossaryItemWithKeyword(updatedStore.glossaryItem));

        setParaWrod("");
      }
    }
  }, [isGetKeywordApiCalled]);

  const handleRemoveKeword = (keyWord)=>{
    const data = glossaryItem
    data.book_level = data.book_level.filter(item => !item.hasOwnProperty(keyWord));
    handleAddVariant(data, "deleteKeyword")
  }

  const handleVariantData = (action, item, key, value, variant) => {
    if (action === "deleteVariant") {
      return item[key]?.values.filter((item) => item !== value);
    } else if (action === "updateVariant") {
      const array = item[key]?.values;
      array[array.indexOf(variant)] = value;
      return array;
    } else {
      return [...item[key]?.values, value];
    }
  };

  const addVariantToKeyword = (keyword, value, action, variant, chapter,genre) => {
    // console.log(isValuePresent ? "Value found" : "Value not found");
     setbyPopupAddWordInGlossaryScenario(false)
    /* Checking Keyword and Variant input feilds before saving */
    if(checkContainsSpecialCharacters(value)){
      notify("Special characters are not allowed for this field")
      return true
    }

    if(action!=="deleteVariant"){
      const keywordInput = userEnteringGlossaryForVariantState?.isOn
        ? paraWord
        : Object.keys(currentKeyWordWithVariant)[0]

      if (!value && !keywordInput) {
        notify("Please enter the glossary keyword/ phrase and the variant associated with it.");
        return true
      }

      if (!keywordInput) {
        notify("Please enter the English keyword/ phrase");
        return true
      }

      if (keywordInput && !value) {
        notify(`Please enter the variant for the keyword "${keywordInput}"`);
        return true
      }

      if (userEnteringGlossaryForVariantState.isOn) {
        notify('Please save the glossary keyword/phrase first')
        return true
      }
   }
    if (action === "addVariant") {
      const isValuePresent = glossaryItem?.book_level.some((item) =>
        (item[keyword]?.values || []).includes(value)
      );
      if (isValuePresent) {
        notify("The variant is already in the list");
        return true;
      }
    }

    const bookLevelIndex = glossaryItem?.book_level.findIndex((item) => Object.keys(item)[0] === keyword);

    if (bookLevelIndex !== -1) {
      // If the keyword is found, update the values array for that item
      const updatedStore = {
        glossaryItem: {
          ...glossaryItem,
          book_level: glossaryItem.book_level.map((item, index) => {
            if (index === bookLevelIndex) {
              const testKey = Object.keys(item)[0];
              const variantsArray = handleVariantData(action, item, testKey, value, variant);
              console.log("chp",chapter,genre);
              return {
                [testKey]: {
                  application_scope: "book_level",
                  values: variantsArray,
                  glossary_scope: "Book",
                  author_name:chapter.author_name,
                  language: selectedLanguage,
                  genre:genre,
                  unique_string:chapter.unique_string
                },
              };
            }
            return item;
          }),
        },
      };
      const data = handleVariantData(action, currentKeyWordWithVariant, keyword, value, variant);
      if (data?.length > 0) {
        setSelectedVariant(data?.[0]);
      }

      setCurrentKeywordWithVariant({
        [keyword]: {
          application_scope: "",
          values: handleVariantData(action, currentKeyWordWithVariant, keyword, value, variant),
        },
      });
      // Update your state with the new values
      // dispatch(setGlossaryItemWithKeyword(updatedStore.glossaryItem));
      handleAddVariant(updatedStore.glossaryItem, action);
    }
    //handleAddVariant(keyword)

    setEnableInputBox(false);
  };

  const handleAddVariant = (payload, action) => {
    // Assuming 'store' is your current state

    // Find the index of the book_level array that corresponds to the given keyword
    let newpayload = {
      unique_string: pairedBookId,
      glossary_item: payload,
    };
    dispatch(
      addKeywords({
        payload: newpayload,
        fetchData,
      })
    );

    setCurrentVarientText("");
    setEnableVariantInputBox("");
    if (action === "deleteVariant") notify("Variant Deleted Successfully");
    else if (action === "updateVariant") notify("Variant Updated Successfully");
    else if(action === "deleteKeyword") notify("Keyword Deleted Successfully");
    else notify("Keyword Added Successfully");
  };

  const handleHistoryToggle = (chapterKey, paragraphKey, predictedText) => {
    setSentenceEditMode([]);
    setShowConfirmTranslation([]);
    setParagraphToggleStates({});
    if (historyVisible[`${chapterKey}-${paragraphKey}`]) {
      setHistoryVisible((prevState) => ({
        ...prevState,
        [`${chapterKey}-${paragraphKey}`]: false,
      }));
    } else {
      setHistoryVisible((prevState) => ({
        ...prevState,
        [`${chapterKey}-${paragraphKey}`]: true,
      }));
    }
  };

  const showDifference = (predictedText, changesText) => {
    let diff = new diff_match_patch();
    var d = diff.diff_main(predictedText, changesText);
    diff.diff_cleanupSemantic(d);

    const wordDiffs = [];
    for (const [diffType, diffText] of d) {
      if (diffType === diff_match_patch.DIFF_DELETE) {
        // Split deleted characters into words
        const deletedWords = diffText.split(/\s+/);
        wordDiffs.push(...deletedWords.map((word) => [diff_match_patch.DIFF_DELETE, word]));
      } else if (diffType === diff_match_patch.DIFF_INSERT) {
        // Split inserted characters into words
        const insertedWords = diffText.split(/\s+/);
        wordDiffs.push(...insertedWords.map((word) => [diff_match_patch.DIFF_INSERT, word]));
      } else {
        // No change at character level, so add unchanged words
        const unchangedWords = diffText.split(/\s+/);
        wordDiffs.push(...unchangedWords.map((word) => [diff_match_patch.DIFF_EQUAL, word]));
      }
    }
    var ds = diff.diff_prettyHtml(d);
    const diffText = ds;
    return diffText;
  };

  useEffect(() => {
    if (isGlossaryVisible) setIsGlossaryVisible(false);
    handleSingleClickText(textAreaWordIndex, textAreaIndex);
  }, [textAreaIndex, textAreaWordIndex, currentPage]);

  const handleTextAreaClick = (i, index) => {
    setTextAreaIndex(index);
    setTextAreaWordIndex(i);
  };

  const handleSingleClickText = (wordIndex, index) => {
    if (textAreaRef[index]?.current) {
      textAreaRef[index].current.setSelectionRange(wordIndex, wordIndex);
      textAreaRef[index].current.focus();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [showConfirmTranslation, pairingData]);

  const handleKeyPress = (event) => {
    for (const shortcut in shortcuts) {
      const { action } = shortcuts[shortcut];
      const shortcutMatch = checkShortcut(event);
      // const allKeysPressed = event.getModifierState(keys[0]);
      if (action === "updateParagraph" && shortcutMatch === "Shift-F5") {
        event.preventDefault();
        let [chapterKey, paragraphKey] = showConfirmTranslation.length
          ? showConfirmTranslation[0].split("-")
          : [currentPage.toString(), "0"];
        const chapter = findChapter(chapterKey, paragraphKey);
        updateLatestParagraph(chapter, chapterKey, paragraphKey,"",0);
      } else if (action === "mergeSentences" && shortcutMatch === "Ctrl-M") {
        event.preventDefault();
        let [chapterKey, paragraphKey] = showConfirmTranslation.length
          ? showConfirmTranslation[0].split("-")
          : [currentPage.toString(), "0"];
        const chapter = findChapter(chapterKey, paragraphKey);
        let paraId = chapter?._id;
        // setIsMergeComponentOpen((prevState) => {
        //   handleEditPairingClick(prevState ? "" : paraId, chapterKey,paragraphKey);
        //   return !prevState;
        // }); // Toggle the state

        if(showEditPairing){
          handleEditPairingClick("",chapterKey,paragraphKey)
        }else{
          handleEditPairingClick(paraId,chapterKey,paragraphKey)
        }

      }
    }
  };
  const findChapter = (chapterKey, paragraphKey) => {
    return pairingData?.auto_ai_resource?.resources?.find(
      (chapter) =>
        chapter.chapter.sequenceNumber === parseInt(chapterKey) && chapter.paragraph.sequenceNumber === parseInt(paragraphKey)
    );
  };
  const checkShortcut = (event) => {
    for (const shortcut in shortcuts) {
      const { keys } = shortcuts[shortcut];
      if (event.shiftKey && event.key === keys[keys.length - 1]) {
        return shortcut; // Returns the matching shortcut key for regeneration
      } else if (
        event.ctrlKey &&
        (event.key === keys[keys.length - 1] || event.key === keys[keys.length - 1].toUpperCase())
      ) {
        return shortcut; // Returns the matching shortcut key for merge sentences
      }
    }
    return null; // Returns null if no shortcut matches
  };

  const handleClickOnDutchSentence = async (e, chapter, chapterKey, paragraphKey) => {
    handleParagraphHower(chapter);
    toggleEditFeedbackMode(chapter.sentenceId, chapterKey, paragraphKey);
    toggleEditMode(chapterKey, paragraphKey, chapter.sentenceId);
    toggleConfirmButton(chapterKey, paragraphKey);
    setIsGlossaryVisible(false);
    adjustTextareaHeight(e);
    setEditedContent({});
  };

  const handleAddSuggestedWordToGlossary = async (wordSuggested, variantWord, index, chapter, chapterKey, paragraphKey) => {
    setWordVariantPopover(oldState=>{
      return {
        ...oldState,
        open: false
      }
    })
    // setIsGlossaryVisible(true);
    setCurrentKeywordWithVariant({});

    // setIsLoading(true);
    setLatestLoadingParagraphs((prevState) => ({
      ...prevState,
      [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`]: true,
    }));
    await handleGlossary(index, chapter);

    
    await new Promise(resolve=>{
      setTimeout(() => {
        resolve();
      }, 1000);
    })
    
    setParaWrod(wordSuggested)
    // if (paraWord?.length < 2) {
    //   return true;
    // }

    const glossaryItem = glossaryItemRef.current;

    const storeGlossary = glossaryItem.book_level;
    let payload = {
      unique_string: pairedBookId,
      glossary_item: {
        book_level: [
          ...(storeGlossary.length
            ? [
                ...storeGlossary,
                {
                  [wordSuggested]: {
                    application_scope: "book_level",
                    values: [
                        variantWord
                    ],
                    glossary_scope: "Book",
                    language: "dutch",
                    author_name: "Shreyay"
                  },
                },
              ]
            : [
                {
                  [wordSuggested]: {
                    application_scope: "book_level",
                    values: [
                      variantWord
                    ],
                    glossary_scope: "Book",
                    language: "dutch",
                    author_name: "Shreyay"
                  },
                },
              ]),
        ],
      },
    };
    let lengthOfKeywordArray = payload.glossary_item.book_level.length - 1;
    dispatch(setAddKeyWord(payload.glossary_item.book_level));
    setEnableGlossaryWord(lengthOfKeywordArray);
    setCurrentKeywordWithVariant({ [paraWord]: { values: [] } });
    setParaWrod("");

    dispatch(addKeywords({
        payload:payload,
      fetchData
    }))
      
    setTimeout(() => {
      setIsGlossaryVisible(false);
      setLatestLoadingParagraphs((prevState) => ({
        ...prevState,
        [`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`]: false,
      }));
      setGlossaryIndex(+paragraphKey);
    }, 1000);
  }

  const generateTextWithSpans = (text, i, originalPairParagraph, translatedPairParagraph, chapter, isSentenceHovering,chapterkey,paragraphKey, sentenceId, doNotShowWordChangePopup, paragraphIndex) => {


    //const baseSentence = " 'We hebben weer een moeilijke zaak toebedeeld gekregen.’" || text;
     const baseSentence = text;

   // const getSentenceToCompareWith = [" We hebben weer een moeilijke zaak toebedeeld gekregen.", " \"Nous avons un autre cas difficile entre les mains.\”"] || (()=>{
     const getSentenceToCompareWith =  (()=>{

      /** @type {string[]} */
      const sentencesToCompareWith = [];

      /** @type {string} */
      const connectedWithOriginalLanguageTextSentenceId = (()=>{
        if (pairingData?.auto_ai_resource?.resources?.length>0) {
          for (let resourceIndex = 0; resourceIndex < pairingData?.auto_ai_resource?.resources?.length; resourceIndex++) {
            const resource = pairingData?.auto_ai_resource?.resources?.[resourceIndex];

            if (resource?.textTranslatedLanguageSentences?.length>0) {
         
              for (let translatedLanguageSentenceIndex = 0; translatedLanguageSentenceIndex < resource?.textTranslatedLanguageSentences.length; translatedLanguageSentenceIndex++) {
               
                const translatedLanguageSentence = resource?.textTranslatedLanguageSentences[translatedLanguageSentenceIndex];
                const sentenceText = translatedLanguageSentence?.sentence?.text;
                // if(translatedLanguageSentence.target_language==="dutch"){
                if(translatedLanguageSentence.target_language===selectedLanguage){
                if (sentenceText === baseSentence) {
                  if (translatedLanguageSentence?.connectedWithOriginalLanguageTextSentenceIds?.[0]?.sentenceId) {
                    return translatedLanguageSentence?.connectedWithOriginalLanguageTextSentenceIds?.[0]?.sentenceId
                  } else {
                    return ""
                  }
                }
                }
            }
            }
          }
        }

        return "";
      })();

      if (pairingData?.auto_ai_resource?.resources?.length>0) {
        for (let resourceIndex = 0; resourceIndex < pairingData?.auto_ai_resource?.resources?.length; resourceIndex++) {
          const resource = pairingData?.auto_ai_resource?.resources?.[resourceIndex];
          if (resource?.textTranslatedLanguageSentences?.length>0) {
            for (let translatedLanguageSentenceIndex = 0; translatedLanguageSentenceIndex < resource?.textTranslatedLanguageSentences.length; translatedLanguageSentenceIndex++) {
              const translatedLanguageSentence = resource?.textTranslatedLanguageSentences[translatedLanguageSentenceIndex];
              const sentenceText = translatedLanguageSentence?.sentence?.text;
              // if (translatedLanguageSentence.target_language==="dutch") {
              if (translatedLanguageSentence.target_language===selectedLanguage) {
                if (
                  translatedLanguageSentence?.connectedWithOriginalLanguageTextSentenceIds?.[0]?.sentenceId === connectedWithOriginalLanguageTextSentenceId &&
                  sentenceText !== baseSentence
                ) {
                  sentencesToCompareWith.push(sentenceText)
                }
              }
            }
          }
        }
      }      

      return sentencesToCompareWith;
      
    })(); 

  
    // if (baseSentence.includes("testing HOOF")) {
      const variantresults = findChangesToMatchBase(baseSentence, getSentenceToCompareWith)
    // }

    const getWordVairantJsonWhichIsBiggest = (word, variantresults, wordIndex) => {

      const specialCharacterRemovedWord = removeSpecialCharactersFromWord(word);
      
      if (variantresults?.length>0) {
        let highestVariantJsonLength = 0;
        let highestWordVariantJson = undefined;
        for (const variantResult of variantresults) {
          if (
            variantResult?.word.includes(specialCharacterRemovedWord) &&
            wordIndex >= variantResult?.position?.[0] &&
            wordIndex < variantResult?.position?.[1] &&
            (variantResult?.position?.[1] - variantResult?.position?.[0]) >= highestVariantJsonLength
          ) {
            highestVariantJsonLength = variantResult?.position?.[1] - variantResult?.position?.[0];
            highestWordVariantJson = variantResult;
          }
        }
        return highestWordVariantJson
      }
      return undefined
    }

    // let words = text.split(/\s+/);
    let words = text.split(" ");

    // words = words.filter(word=>word ? true : false)    

    const startWordIndexPosition = 1;
    const endWordIndexPosition = 6;

    // const wordsToHighlight = ["We", "hebben", "weer"]
    
    return words.map((word,index)=>{

      let specialCharacterRemovedWord = word;

      if (specialCharacterRemovedWord === "stond." && baseSentence.includes("Hij hield zijn mok halverwege tuss")) {
        
      }      

      const wordVairantJsonWhichIsBiggest = getWordVairantJsonWhichIsBiggest(
        specialCharacterRemovedWord, variantresults, index
      );

        // const variant = (()=>{
        //   if (variantresults?.length>0) {
        //     for (const variantResult of variantresults) {
        //       if (variantResult?.includes())
        //     }
        //   }
        // })();


      
      const wordToHighlightStartIndexWordPosition = wordVairantJsonWhichIsBiggest?.position?.[0];
      const wordToHighlightEndIndexWordPosition = wordVairantJsonWhichIsBiggest?.position?.[1] - 1;

      const doesWordIndexMatchingWithvariantIndex = (() =>{

        if (
          index >= wordToHighlightStartIndexWordPosition &&
          index <= wordToHighlightEndIndexWordPosition
        ) {
          return true;
        }
        
        
        return false;
        
      })();

        const wordId = Math.random().toString(16).slice(2);
       let isClickable=doesWordIndexMatchingWithvariantIndex && getHighlightClass(specialCharacterRemovedWord, index, wordToHighlightStartIndexWordPosition, wordToHighlightEndIndexWordPosition, text)
      return (
        // <span key={index} style={getHighlightStyle(word, index, wordToHighlightStartIndexWordPosition, wordToHighlightEndIndexWordPosition, text)}>
        <span 
        
        key={index} id={wordId} className={`${ doesWordIndexMatchingWithvariantIndex && getHighlightClass(specialCharacterRemovedWord, index, wordToHighlightStartIndexWordPosition, wordToHighlightEndIndexWordPosition, text)}`}
          onContextMenu={(e)=>{
            if(currentVariant.length > 0 && isGlossaryVisible) {
              notify("Please save the current variant or else it will be discarded")
              // setCurrentVarientText('')
            }
            e.preventDefault();
            e.stopPropagation();
            if (doNotShowWordChangePopup) {
              return;
            }
            if(isClickable){
            // setCurrentSentence((prev)=>({...prev,text,index:`${chapterkey}-${paragraphKey}`}))
            setWordVariantPopover({
              englishParagraph: originalPairParagraph,
              open: true,
              baseWord: word,
              variants: wordVairantJsonWhichIsBiggest?.variant?.[0]?.variant,
              anchorElement: document.getElementById(wordId),
              position: wordVairantJsonWhichIsBiggest?.variant?.[0]?.position,
              action: wordVairantJsonWhichIsBiggest?.variant?.[0]?.action,
              chapterId:  chapter._id,
              paragraphKey: paragraphKey,
              chapterKey: chapterkey,
              chapter: chapter,
              sentenceId: sentenceId,
              paragraphIndex: index
            })
          }}
        }
          onClick={(e)=>{
            // e.stopPropagation();
            if(isClickable){
            setCurrentSentence((prev)=>({...prev,text,index:`${chapterkey}-${paragraphKey}`}))
            // setWordVariantPopover({
            //   open: true,
            //   baseWord: word,
            //   variants: wordVairantJsonWhichIsBiggest?.variant?.[0]?.variant,
            //   anchorElement: document.getElementById(wordId)
            // })
          }}
        }
        >
          {word}{" "}
        </span>  
      );
    })

    return text.split("").map((char, index) => (
      <span key={index} onClick={() => handleTextAreaClick(index, i)}>
        {char}
      </span>
    ));
  };

  

  const renderViewAsEnglishAction = useCallback(
    ({ chapterKey, paragraphKey, translatedPairParagraph }) => {
      if (!showViewAsEnglish) return null;

      const id = `${chapterKey}-${paragraphKey}-view-as-english`;
      return (
        <Switch
          id={id}
          // disable view as english when the sentence is being edited
          disabled={
            translatedPairParagraph?.[0] === "" || translatedPairParagraph.length === 0 ||
            Boolean(editingSentences[`${chapterKey}-${paragraphKey}`]) ||
            loadingLatestParagraphs[`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`] ||
            loadingLatestComparisionParagraphs[`Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedComparisionModelName}-Temperature ${selectedComparisionTemperature}`]
          }
          label="View as English"
          checked={paragraphToggleStates[`${chapterKey}-${paragraphKey}`]}
          onChange={() => toggleLanguageForParagraph(chapterKey, paragraphKey)}
        />
      );
    },
    [
      paragraphToggleStates,
      editingSentences,
      showViewAsEnglish,
      toggleLanguageForParagraph,
      loadingLatestParagraphs,
      loadingLatestComparisionParagraphs
    ]
  );

  const calculateTextareaHeight = (text) => {
    const lineHeight = text.length >= 160 ? 21 : 21;
    const rows = showCompare ? Math.ceil(text.length / 40) : Math.ceil(text.length / 65);
    const heightiNRem = lineHeight * rows;
    return `${heightiNRem}px`;
  };

  const handleChange = async (
    index,
    e,
    sentenceId,
    paragraphId,
    chapterKey,
    paragraphKey,
    translationPara
  ) => {
    setIsOriginalParaId(sentenceId[index]?.connectedWithOriginalLanguageTextSentenceIds);
    const isCursorAtEnd =
      textAreaRef[index].current?.selectionEnd === textAreaRef[index].current?.value.length;
    const isCursorAtStart = textAreaRef[index].current?.selectionStart === 0;
    // Check if the right arrow key is pressed and the cursor is at the end
    if ((e.key === "ArrowRight" || e.key === "ArrowDown") && isCursorAtEnd) {
      textAreaRef[index + 1]?.current?.focus();
      if (index + 1 !== sentenceId?.length) {
        setIsOriginalParaId(sentenceId[index + 1]?.connectedWithOriginalLanguageTextSentenceIds);
      }
    }
    if ((e.key === "ArrowLeft" || e.key === "ArrowUp") && isCursorAtStart) {
      textAreaRef[index - 1]?.current?.focus();
      textAreaRef[index - 1]?.current?.setSelectionRange(
        textAreaRef[index - 1].current?.value.length,
        textAreaRef[index - 1].current?.value.length
      );
      if (index !== 0) {
        setIsOriginalParaId(sentenceId[index - 1]?.connectedWithOriginalLanguageTextSentenceIds);
      }
    }
    if ((e.key === "Enter" && !e.shiftKey) || (e.key === "s" && e.ctrlKey)) {
      e.preventDefault();
      if (index + 1 !== sentenceId?.length) {
        setIsOriginalParaId(sentenceId[index + 1]?.connectedWithOriginalLanguageTextSentenceIds);
        textAreaRef[index + 1]?.current?.setSelectionRange(0, 0);
        textAreaRef[index + 1]?.current?.focus();
      }
      if (index + 1 === sentenceId?.length) {
        await updateTheNodeConnectionConfirmPair(
          paragraphId,
          chapterKey,
          paragraphKey,
          "confirm",
          translationPara
        );
        setFeedbackSelectorVisible("");
        const sentanceInfo = await getSentenceInfo(chapterKey, (parseInt(paragraphKey) + 1).toString(), 0);
        setEditingSentences({
          [`${chapterKey}-${parseInt(paragraphKey) + 1}`]: true,
        });
        setIsOriginalParaId(sentanceInfo?.translationPara?.connectedWithOriginalLanguageTextSentenceIds);
        handleTextAreaClick(sentanceInfo?.sentenceText?.length, 0);
        textAreaRef?.current?.setSelectionRange(0, 0);
        textAreaRef?.current?.focus();
      }
    }
  };

  const handleUserWantToAddVariantCorrespondingGlossaryWord = async (wordSuggested, variantWord, index, chapter, chapterKey, paragraphKey, wordDropdownProps) => {
    setWordVariantPopover(oldState=>{
      return {
        ...oldState,
        open: false
      }
    })  

    await updateContentWithVariant({
      ...wordDropdownProps,
    })

    await handleGlossary(Number(paragraphKey), chapter, {scenario: "userWantsToAddGlossaryWordForAVariantByClickingOnYesOnAPopup"});    

    setEnableInputBox(true);

    setbyPopupAddWordInGlossaryScenario(true);
    
    setParagraphWords("");

    setParaWrod("");

    setUserEnteringGlossaryForVariantState({
      isOn: true,
      variantWord: variantWord,
      keywordWithVariant: {
        application_scope: "book_level",
        values: [variantWord]
      }
    })
    setSelectedVariant(variantWord)
    setEnableVariantInputBox(variantWord)
    setCurrentVarientText(variantWord)
  }

  return (
    <>
      <div style={{ marginTop: "40px" }}>
        <ToastContainer hideProgressBar={true} />
        {isLoading ? (
          <Loader />
        ) : (
          <div
            className="paragraph-container"
            // onClick={() => {
            //   console.log("hello")

            //   if(divClick === false){
            //     return
            //   }
            //   if (sentenceEditFeedbackMode.length) {

            //     setSentenceEditFeedbackMode("");
            //     setFeedbackSelectorVisible((feedbackSelectorVisible) => !feedbackSelectorVisible);
            //   }
            // }}
          >
            {true &&
              paragraphsToShow?.map(({ chapterKey, paragraphKey }, index) => {
                      
                 return (

                 <TranslateBoxComponent 
                    index={index}
                    chapterKey={chapterKey}
                    paragraphKey={paragraphKey}
                    pairingData={pairingData}
                    paragraphsToShow={paragraphsToShow}
                    selectedLanguage={selectedLanguage}
                    selectedModelName ={selectedModelName}
                    selectedTemperature ={selectedTemperature}
                    showCompare={showCompare}
                    editingParagraphs={editingParagraphs}
                    editingSentences={editingSentences}
                    compareModeParagraphs ={compareModeParagraphs}
                    paletteModeParagraphs ={paletteModeParagraphs}
                    showConfirmTranslation   ={showConfirmTranslation}
                    editedContent  ={editedContent}
                    selectedComparisionModelName={selectedComparisionModelName}
                    selectedComparisionTemperature={selectedComparisionTemperature}
                    historyVisible={historyVisible}
                    sentenceEditMode={sentenceEditMode}
                    refs={refs}
                    isGlossaryVisible={isGlossaryVisible}
                    colorCode={colorCode}
                    showEditPairing={showEditPairing}
                    mergeParaMode={mergeParaMode}
                    mergeParagraphData={mergeParagraphData}
                    notify={notify}
                    setMergeParagraphData={setMergeParagraphData}
                    textSize={textSize}
                    isHoverOriginalParaId={isHoverOriginalParaId}
                    calculateTextareaHeight={calculateTextareaHeight}
                    currentKeyWordWithVariant={currentKeyWordWithVariant}
                    loadingParagraphs={loadingParagraphs}
                    showViewAsEnglish={showViewAsEnglish}
                    paragraphToggleStates={paragraphToggleStates}
                    translatedDutchToEnglish={translatedDutchToEnglish}
                    setEditedContent={setEditedContent}
                    handleCloseTranslatedText={handleCloseTranslatedText}
                    loadingLatestParagraphs={loadingLatestParagraphs}
                    sentenceEditFeedbackMode={sentenceEditFeedbackMode}
                    feedbackContent={feedbackContent}
                    sentenceEditConfirm={sentenceEditConfirm}
                    handleClickOnDutchSentence={handleClickOnDutchSentence}
                    setIsOriginalParaId={setIsOriginalParaId}
                    handleParagraphHower={handleParagraphHower}
                    toggleEditFeedbackMode={toggleEditFeedbackMode}
                    toggleConfirmMode={toggleConfirmMode}
                    toggleConfirmButton={toggleConfirmButton}
                    adjustTextareaHeight={adjustTextareaHeight}
                    setEditingColumn={setEditingColumn}
                    generateTextWithSpans={generateTextWithSpans}
                    textAreaRef={textAreaRef}
                    handleChange={handleChange}
                    errorLatestParagraphs={errorLatestParagraphs}
                    updateLatestParagraph={updateLatestParagraph}
                    automateTranslationFetchHandler={automateTranslationFetchHandler}
                    loadingComparisionParagraphs={loadingComparisionParagraphs}
                    loadingLatestComparisionParagraphs={loadingLatestComparisionParagraphs}
                    updateLatestParagraphCompare={updateLatestParagraphCompare}
                    handleHistoryToggle={handleHistoryToggle}
                    showDifference={showDifference}
                    sentenceColors={sentenceColors}
                    handleEditPairingClick={handleEditPairingClick}
                    updateTheMatch={updateTheMatch}
                    glossaryIndex={glossaryIndex}
                    userEnteringGlossaryForVariantState={userEnteringGlossaryForVariantState}
                    setEnableVariantInputBox={setEnableVariantInputBox}
                    setUserEnteringGlossaryForVariantState={setUserEnteringGlossaryForVariantState}
                    setCurrentKeywordWithVariant={setCurrentKeywordWithVariant}
                    setIsGlossaryVisible={setIsGlossaryVisible}
                    handleGlossary={handleGlossary}
                    setbyPopupAddWordInGlossaryScenario={setbyPopupAddWordInGlossaryScenario}
                    updateTheNodeConnectionConfirmPair={updateTheNodeConnectionConfirmPair}
                    setFeedbackSelectorVisible={setFeedbackSelectorVisible}
                    editingColumn={editingColumn}
                    updateTheNodeConnectionConfirmPairComparision={updateTheNodeConnectionConfirmPairComparision}
                    setEditingSentences={setEditingSentences}
                    setSentenceEditMode={setSentenceEditMode}
                    showModelSelection={showModelSelection}
                    selectedModel={selectedModel}
                    handleModelChange={handleModelChange}
                    assistantOptions={assistantOptions}
                    renderViewAsEnglishAction={renderViewAsEnglishAction}
                    loader={loader}
                    paragraphWords={paragraphWords}
                    byPopupAddWordInGlossaryScenario={byPopupAddWordInGlossaryScenario}
                    setEnableInputBox={setEnableInputBox}
                    setParagraphWords={setParagraphWords}
                    errorLatestComparisionParagraphs={errorLatestComparisionParagraphs}
                    handleCloseEditededText={handleCloseEditededText}
                    glossaryItem={glossaryItem}
                    enableGlossaryWord={enableGlossaryWord}
                    setEnableGlossaryWord={setEnableGlossaryWord}
                    setWordVariant={setWordVariant}
                    wordVariant={wordVariant}
                    setApplyGlossary={setApplyGlossary}
                    setSelectedVariant={setSelectedVariant}
                    enableInputBox={enableInputBox}
                    paraWord={paraWord}
                    setParaWrod={setParaWrod}
                    handleAddKeyWordOnEnter={handleAddKeyWordOnEnter}
                    enableVariantInputBox={enableVariantInputBox}
                    setCurrentVarientText={setCurrentVarientText}
                    setprevVariantText={setprevVariantText}
                    currentVariant={currentVariant}
                    addVariantToKeyword={addVariantToKeyword}
                    prevVariantText={prevVariantText}
                    handleRadioChange={handleRadioChange}
                    selectedVariant={selectedVariant}
                    applyGlossary={applyGlossary}
                    applyGlossaryToWholeBook={applyGlossaryToWholeBook}

                    

                    // Give me all variable names as comma saperated list
                    />)
              })}
          </div>
        )}
        <div className="dashboard-download-container">
          <button
            onClick={handleGoToDashboard}
            className="confirm-post-btn "
            style={{ marginTop: "0px" }}
          >
            Go To Dashboard
          </button>
          <button className="download-btn" onClick={() => setDownloadModalVisible(true)}>
            Download Book
            <img src={download} alt="Download" className="download-icon" />
          </button>
        </div>
      </div>
      {
        wordVariantPopover.open &&
        <WordVariantPopover
          englishParagraph={wordVariantPopover.englishParagraph}
          onChange={updateContentWithVariant}
          modalWord={wordVariantPopover.baseWord}
          variantWords={wordVariantPopover.variants}
          anchorEl={wordVariantPopover.anchorElement}
          position={wordVariantPopover.position}
          action={wordVariantPopover.action}
          chapterId={wordVariantPopover.chapterId}
          paragraphKey={wordVariantPopover.paragraphKey}
          chapterKey={wordVariantPopover.chapterKey}
          chapter={wordVariantPopover.chapter}
          sentenceId={wordVariantPopover.sentenceId}
          handleAddSuggestedWordToGlossary={handleAddSuggestedWordToGlossary}
          paragraphIndex={wordVariantPopover.paragraphIndex}
          handleUserWantToAddVariantCorrespondingGlossaryWord={handleUserWantToAddVariantCorrespondingGlossaryWord}
          onClose={()=>{
            setWordVariantPopover(oldState=>{
              return {
                ...oldState,
                open: false
              }
            })
          }}
        />
      }
    </>
  );
};

export default TranslateParagraphPairComponent;
