import React from 'react'
import { ReactComponent as GlossaryDeleteIcon } from "../../assets/glossaryDeleteIcon.svg";
import CloseIcon from "@mui/icons-material/Close";

function GlossaryWarningModal({closePopup,handleDelete,deleteWarningData}) {
  return (
    <div className="w-full h-full flex absolute top-0 left-0 justify-center items-center">
          <div className="w-full h-full absolute rounded-md bg-black opacity-50 z-10">
          </div>
          <div style={{padding:"15px 15px 15px 15px"}} className="z-20 w-[352px] rounded-md bg-white relative flex-col gap-4 flex ">
            <div className="flex  justify-between">
            <div style={{border:"8px solid var(--Error-50, #FEF3F2)",background:"var(--Error-100, #FEE4E2)"}} className="flex w-12 h-12 justify-center rounded-[28px] items-center">
                 <GlossaryDeleteIcon   className="w-6 h-6"/>
            </div>
            <CloseIcon onClick={closePopup} className="w-6 h-6"/>
            </div>
             <div className="text-xl text-[#141414]">Delete {deleteWarningData.type}?</div>
             <div style={{fontFamily:"Times New Roman"}} className="text-lg text-[#000] ">{deleteWarningData.englishWord || deleteWarningData.variant}</div>
             <div className="text-xs text-[#475467]">Are you sure you want to delete this {deleteWarningData?.type?.toLowerCase()}. This action cannot be undone.</div>
             {deleteWarningData.type ==="Last Variant" && <div className='text-lg text-[#FF3B30]'>This will also delete the Glossary</div>}
            
            <div className="w-full flex items-center gap-3 mt-4 ">
               <button style={{border:"1px solid #D0D5DD",background:"none"}} onClick={closePopup}   className="px-[18px] cursor-pointer w-1/2 py-[10px] rounded-lg text-base text-[#344054]">Cancel</button>
               <button style={{border:"none",background:"#FF3B30"}} onClick={handleDelete}  className="px-[18px] cursor-pointer w-1/2 py-[10px] rounded-lg text-base text-white">Delete</button>
            </div>

          </div>
      </div>
  )
}

export default GlossaryWarningModal