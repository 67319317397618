import React, {useEffect} from "react";
import { Box, Button } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import CommentIcon from "@mui/icons-material/Comment";
import { Padding } from "@mui/icons-material";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";

const MergeComponent = ({ pairingDatas, paraId, onUpdateMatch, onEditPairingClick, translatedPairParagraph }) => {
  const [checked, setChecked] = React.useState([]);
  const [showConfirm, setShowConfirm] = React.useState(false);
  let paraData = pairingDatas?.auto_ai_resource?.resources.filter((item) => item._id === paraId);
  let newData = paraData?.[0]
  paraData = [{...newData, textTranslatedLanguageSentences:translatedPairParagraph}]
  const [datas, setData] = React.useState(paraData);
  const initialData = pairingDatas?.auto_ai_resource?.resources.filter((item) => item._id === paraId);
  const [selectedSentences, setSelectedSentences] = React.useState([]);
  const originalParaSentence = datas?.[0]?.textOriginalLanguageSentences;
  const translatedParaSentences = datas?.[0]?.textTranslatedLanguageSentences;

  const handleToggle = (value, isTranslated) => {
    setShowConfirm(false);
    if (
      (initialData[0].textTranslatedLanguageSentences.length !==
        datas[0].textTranslatedLanguageSentences.length &&
        datas[0].textTranslatedLanguageSentences.length === 1) ||
      initialData[0].textTranslatedLanguageSentences.length === 1
    ) {
      setShowConfirm(true);
      return;
    }
    const currentIndex = checked?.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      if (checked.length === 1 && Math.abs(value?.sequenceNumber - checked[0]?.sequenceNumber) !== 1) {
        return;
      }
      if (newChecked.length === 2) {
        return;
      }
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    if (checked.length === 2 && isTranslated) {
      const [firstSentence, secondSentence] = newChecked;
      const isConsecutive = secondSentence.sequenceNumber - firstSentence.sequenceNumber === 1;

      if (isConsecutive) {
        return;
      }
    }

    setSelectedSentences([...newChecked]);
    return;
  };

  const handleMerge = () => {
    if (selectedSentences.length === 2) {
      let sortedArray = selectedSentences.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
      const [firstSentence, secondSentence] = sortedArray;

      // Update the original data
      const updatedOriginalData = {
        ...originalParaSentence.filter((d) => d.sequenceNumber === firstSentence.sequenceNumber)[0],
        sentence: {
          ...originalParaSentence.filter((d) => d.sequenceNumber === firstSentence.sequenceNumber)[0]
            ?.sentence,
          text:
            originalParaSentence.filter((d) => d.sequenceNumber === firstSentence.sequenceNumber)[0]?.sentence
              .text +
            " " +
            originalParaSentence.filter((d) => d.sequenceNumber === firstSentence.sequenceNumber + 1)[0]
              .sentence.text,
        },
      };

      let newOrigPredictedText =
        originalParaSentence.filter((d) => d.sequenceNumber === firstSentence.sequenceNumber)[0]?.sentence
          .predictedText +
        " " +
        originalParaSentence.filter((d) => d.sequenceNumber === firstSentence.sequenceNumber + 1)[0].sentence
          .predictedText;

      let newOrigText =
        originalParaSentence.filter((d) => d.sequenceNumber === firstSentence.sequenceNumber)[0]?.sentence
          .text +
        " " +
        originalParaSentence.filter((d) => d.sequenceNumber === firstSentence.sequenceNumber + 1)[0].sentence
          .text;

      const updateTranslatedData = {
        ...translatedParaSentences.filter((d) => d.sentenceId === firstSentence.sentenceId)[0],
        sentence: {
          ...translatedParaSentences.filter((d) => d.sequenceNumber === firstSentence.sequenceNumber)[0]
            ?.sentence,
          text:
            translatedParaSentences.filter((d) => d.sentenceId === firstSentence.sentenceId)[0]?.sentence
              ?.text +
            " " +
            translatedParaSentences.filter((d) => d.sentenceId === secondSentence.sentenceId)[0].sentence
              .text,
        },
      };

      let newTranPredictedText =
        translatedParaSentences.filter((d) => d.sentenceId === firstSentence.sentenceId)[0]?.sentence
          ?.predictedText +
        " " +
        translatedParaSentences.filter((d) => d.sentenceId === secondSentence.sentenceId)[0].sentence
          .predictedText;

      let newTranText =
        translatedParaSentences.filter((d) => d.sentenceId === firstSentence.sentenceId)[0]?.sentence?.text +
        " " +
        translatedParaSentences.filter((d) => d.sentenceId === secondSentence.sentenceId)[0].sentence.text;
      // let newData={
      //     ...datas,

      // }

      const replaceAndRemoveNext = (index, newValue, newPredictedText, sentencesArray) => {
        const updatedArray = [...sentencesArray];

        // Replace the value at the specified index
        updatedArray[index] = {
          ...updatedArray[index],
          sentence: { predictedText: newValue, text: newValue },
        };

        // Remove the next index value
        updatedArray.splice(index + 1, 1);

        // Update sequence numbers throughout the array
        updatedArray.forEach((item, i) => {
          item.sequenceNumber = i + 1;
        });

        return updatedArray;
      };

      const updatedTransArray = replaceAndRemoveNext(
        firstSentence.sequenceNumber - 1,
        newTranText,
        newTranPredictedText,
        translatedParaSentences
      );
      const updatedOtigArray = replaceAndRemoveNext(
        firstSentence.sequenceNumber - 1,
        newOrigText,
        newOrigPredictedText,
        originalParaSentence
      );
      let newAllData = {
        ...datas[0],
        textOriginalLanguageSentences: updatedOtigArray,
        textTranslatedLanguageSentences: updatedTransArray,
      };
      setData([newAllData]);
      console.log("textttttt", updatedOriginalData, updateTranslatedData);
      const mergedText = firstSentence.sentence.text + " " + secondSentence.sentence.text;
      setChecked([]);
      setSelectedSentences([]);
      setShowConfirm(true);
    }
  };
  const onConfirm = () => {
    logEvent(analytics, 'merge_success', {success: true})
    onUpdateMatch(datas);
    onEditPairingClick("");
    setShowConfirm(false);
  };

  const isChecked = (checked, value) => {
    let isSelected = checked?.some((item) =>
      item.connectedWithOriginalLanguageTextSentenceIds.some((d) => d.sentenceId === value.sentenceId)
    );
    return isSelected;
  };

  const disableConfirmButtom = initialData[0].textTranslatedLanguageSentences.length === 1;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <List sx={{ width: "100%" }}>
        {originalParaSentence?.map((value, i) => {
          const labelId = `checkbox-list-label-${value}`;

          return (
            <ListItem key={"ooo" + i} disablePadding>
              <ListItemButton role={undefined} dense>
                <ListItemIcon>
                  <Box edge="start" height={"20px"} width={"20px"}></Box>
                </ListItemIcon>
                <Box
                  bgcolor={isChecked(checked, value) ? "#3B37FF" : ""}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderWidth: "1px",
                    borderColor: "black",
                    borderStyle: "solid",
                    width: "100%",
                    color: isChecked(checked, value) ? "white" : "",
                    border: "1px solid #1E1C80",
                    borderRadius: "8px",
                  }}
                  padding={"5px"}
                >
                  {value.sentence.text}
                </Box>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      <List sx={{ width: "100%" }}>
        {translatedParaSentences?.map((value, i) => {
          const labelId = `checkbox-list-label-${value}`;

          return (
            <ListItem key={"ooo" + i} disablePadding>
              <ListItemButton
                role={undefined}
                onClick={() => {
                  handleToggle(value);
                }}
                dense
              >
                <ListItemIcon>
                  {/* <Checkbox
                    edge="start"
                    checked={}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  /> */}
                  <Box
                    edge="start"
                    sx={{
                      borderWidth: "1px",
                      // borderStyle: "solid",
                      // borderBlockColor: "black",
                      borderColor: checked.indexOf(value) !== -1 ? "" : "black",
                      borderStyle: checked.indexOf(value) !== -1 ? "ridge" : "solid",
                      outline: checked.indexOf(value) !== -1 ? "1px solid #3B37FF" : "none",
                    }}
                    bgcolor={checked.indexOf(value) !== -1 ? "#3B37FF" : "white"}
                    height={"15px"}
                    width={"15px"}
                  ></Box>
                </ListItemIcon>
                <Box
                  bgcolor={checked.indexOf(value) !== -1 ? "#3B37FF" : ""}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderWidth: "1px",
                    borderColor: "black",
                    borderStyle: "solid",
                    width: "100%",
                    color: checked.indexOf(value) !== -1 ? "white" : "",
                    border: "1px solid #1E1C80",
                    borderRadius: "8px",
                  }}
                  padding={"5px"}
                >
                  {value.sentence.text}
                </Box>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      <div
        style={{
          height: "100%",
          display: "flex",
          // justifyContent: "space-around",
          justifyContent: "flex-end",
          flexDirection: "column",
          alignItems: "space-between",
          marginLeft:"10px"
        }}
      >
        {!showConfirm && datas[0].textTranslatedLanguageSentences.length > 1 ? (
          <button
            className={`${selectedSentences.length !== 2 ? "merge-sent-btn-disable" : "merge-sent-btn"}`}
            onClick={handleMerge}
            disabled={selectedSentences.length !== 2}
          >
            Merge
          </button>
        ) : (
          <button
            className="merge-sent-btn"
            disabled={disableConfirmButtom}
            onClick={onConfirm}
            style={{ backgroundColor: disableConfirmButtom !== true ? "#3b37ff" : "#7777" }}
          >
            Confirm
          </button>
        )}
        <button
          className="merge-sent-btn"
          onClick={() => {
            onEditPairingClick("");
          }}
        >
          Cancel
        </button>
      </div>
    </Box>
  );
};

export default MergeComponent;
