
import { Plugin } from "@ckeditor/ckeditor5-core";
import { ButtonView } from "@ckeditor/ckeditor5-ui";

import saveIcon from "../assets/save.svg";

class SaveDocumentButton extends Plugin {
    init() {
      const editor = this.editor;
      const saveDocument = (editor.config.get("saveDocument") as any)
        .saveDocument as any;
      editor.ui.componentFactory.add("saveDocumentButton", () => {
        const button = new ButtonView();
  
        button.set({
          label: "Save",
          withText: true,
          tooltip: "Save chapter",
          class: "plugin-button",
          icon: saveIcon,
        });
  
        // Execute a callback function when the button is clicked.
        button.on("execute", () => {
          saveDocument(editor);
        });
  
        return button;
      });
    }
  }

export default SaveDocumentButton