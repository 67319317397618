// Assuming CacheContext, CacheContextProvider, and useCacheContext are imported from elsewhere

const cachedState = {};
let externalLookupFunction = null;

const reducer = (action) => {
  switch (action.type) {
    case "CREATE_NEW_CHAPTER": {
      const { currentChapter, translationId } = action.payload;
      console.log("handleAddNewChapter-2", action);

      const transformedId = generateTranslationId(
        currentChapter,
        translationId
      );
      cachedState[transformedId] = {};
      // return { ...state, [transformedId]: {} };
      return cachedState;
    }
    case "ADD_NEW_PARAGRAPH": {
      const { currentChapter, translationId, paragraph, result } =
        action?.payload;
      const transformedId = generateTranslationId(
        currentChapter,
        translationId
      );
      cachedState[transformedId] =
        {
          ...cachedState[transformedId],
        } || {};
      cachedState[transformedId] = {
        ...cachedState[transformedId],
        [paragraph]: result,
      };
      return cachedState;
      // const updatedState = {
      //   ...state,
      //   [transformedId]: {
      //     ...state[transformedId],
      //     [paragraph]: result,
      //   },
      // };
      // console.log("entry-3", updatedState);
      // return updatedState;
    }
    default:
      return cachedState;
  }
};

const generateTranslationId = (currentChapter, translationId) => {
  return `${translationId}_chapter_${currentChapter}`;
};

const handleAddNewChapter = (payload) => {
  console.log("handleAddNewChapter-1", payload);
  return reducer({ type: "CREATE_NEW_CHAPTER", payload });
};

const handleAddNewParagraph = (payload) => {
  return reducer({ type: "ADD_NEW_PARAGRAPH", payload });
};

const hasItemInCache = ({ paragraph, currentChapter, translationId }) => {
  const transformedId = generateTranslationId(currentChapter, translationId);
  return cachedState?.[transformedId]?.[paragraph] ? true : false;
};

const getItemFromCache = ({ currentChapter, translationId, paragraph }) => {
  if(externalLookupFunction) {
    console.log("externalLookup", externalLookupFunction(paragraph));
    const result = externalLookupFunction(paragraph)?.result;
    if(result) {
      console.log(({
        "closest_english_sentence": result.sourceSentence,
        "sentence_index": result.sourceIndex,
        "similarity_score": 1
    }), "returning")
      return ({
          "closest_english_sentence": result.sourceSentence,
          "sentence_index": result.sourceIndex,
          "similarity_score": 1
      })
    }
  }
  const transformedId = generateTranslationId(currentChapter, translationId);
  return cachedState?.[transformedId]?.[paragraph]
    ? cachedState?.[transformedId]?.[paragraph]
    : false;
};

const setExternalLookupFunction = (func) => {
  externalLookupFunction = func;
  window.externalLookupFunction = externalLookupFunction;
  console.log(externalLookupFunction, 'external lookup fn set')
}

const getCachedState = () => cachedState;

window.cachedState = cachedState;

export {
  handleAddNewChapter,
  handleAddNewParagraph,
  getCachedState,
  hasItemInCache,
  getItemFromCache,
  setExternalLookupFunction
};
