import { Box, Menu, MenuItem, Popover, Select, Button, Paper, Typography, IconButton, Autocomplete, TextField } from "@mui/material";
import './WordDropDown.scss'
import { findChangesToMatchBase } from "../../utils/getWords";
import { useEffect, useState, useMemo } from "react";
import { Textarea } from "@mui/joy";
import CloseIcon from '@mui/icons-material/Close';
// import styles from "./FlexibleDropDown.module.scss";
import css from "./WordDropdown.module.css";

const WordDropDown = ({
    // base word
    label="", 
    id,
    labelId,
    onChange = function() {},
    options,
    anchorEl,
    modalWord,
    setModalWord,
    dropDownSentence,
    setDropDownSentence,
    setEditedContent,
    editedContent,
    chapterKey,
    paragraphKey,
    action
}) => {

    const hoverItem= {
        '&:hover': {
          backgroundColor: '#FFC0C1', // Add your hover styles here
        },
      }

    const handleOption = (value) => {
        console.log('haha',value);
        onChange(value);

        const modifiedSentence = replaceWord(dropDownSentence, label.trim(), value.trim());
        setEditedContent({...editedContent, [`${chapterKey}-${paragraphKey}`]: modifiedSentence})

        // setDropDownSentence(async(prevSentence) => {
        //     const modifiedSentence = replaceWord(prevSentence, label.trim(), value.trim());
        //     setEdit(modifiedSentence);
        //     await setEditedContent({...editedContent, [`${chapterKey}-${paragraphKey}`]: edit})
        //     return modifiedSentence;
        // });

            console.log('jjjjj,', dropDownSentence, editedContent);
       
    };

    function replaceWord(originalSentence, targetWord, replacement) {
        const modifiedSentence = originalSentence.split(targetWord).join(replacement);
        return modifiedSentence;
      }


    // const result = findChangesToMatchBase(dropDownSentence, ["'We mebben weer een moeilijke haak toebedeeld gekregen.", "'We rebben weer een moeilijke baak toebedeeld gekregen.", "'We nebben weer een moeilijke oaak toebedeeld gekregen.", "'We lebben weer een moeilijke paak toebedeeld gekregen."])

    //   console.log("result", result)

    // // const resultArray = Object.values(JSON.parse(result)).map((item) => {
    // const resultArray = Object.values(result).map((item) => {
    //     const baseWithPairs = item[0].reduce((acc, action) => {
    //       const baseWord = action.base[0];
    //       const withWord = action.with[0];
      
    //       // Find existing entry for the base word or create a new one
    //       const entry = acc.find((entry) => entry.word === baseWord) || {
    //         word: baseWord,
    //         pairs: [],
    //       };
      
    //       // Add the with word to the pairs array
    //       entry.pairs.push(withWord);
      
    //       // Update or add the entry in the accumulator
    //       if (!acc.some((existingEntry) => existingEntry.word === baseWord)) {
    //         acc.push(entry);
    //       }
      
    //       return acc;
    //     }, []);
      
    //     return baseWithPairs;
    //   });

    //   const ans = resultArray.map(item => item.filter(data => data.word === label))

    //   if(ans[0].length === 0) return null;


    return (
        <>
            {modalWord && <Menu anchorEl={anchorEl} open={modalWord} onClick={() => setModalWord(!modalWord)}>
            {/* {  options?.length > 0 &&
                options.map(item => <MenuItem sx={hoverItem} onClick={() => handleOption(item.value)} key={item.id} value={item.value}><div>{item.title}</div></MenuItem> )
            } */}

            {/* {ans?.length > 0 &&
                ans.map((item, id) => (
                    <MenuItem sx={hoverItem} onClick={() => handleOption(item[0]?.pairs[0])} key={id} >
                        <div>
                            {item[0]?.pairs?.join(', ')}
                        </div>
                    </MenuItem>
            ))} */}
            </Menu>}
        </>
    )
}

export const WordVariantPopover = (props) => {

    let {
        // array of sentences
        englishParagraph,
        variantWords,
        onClose,
        label,
        id,
        labelId,
        onChange = function() {},
        options,
        anchorEl,
        modalWord,
        setModalWord,
        dropDownSentence,
        setDropDownSentence,
        setEditedContent,
        editedContent,
        chapterKey,
        chapter,
        paragraphKey,
        paragraphIndex,
        handleAddSuggestedWordToGlossary = function () {},
        handleUserWantToAddVariantCorrespondingGlossaryWord
    } = props

    const shouldShowWordAsDeleted = (() => {

        for (const variant of (variantWords || [])) {
            if (variant) {
                return false
            }
        }

        return true
    })(); 
    
    
    console.log('shouldShowWordAsDeleted ~ shouldShowWordAsDeleted:', shouldShowWordAsDeleted)    

    console.log('modalWord:', modalWord)

    console.log("English Paragraph", englishParagraph)
    const mergedEnglishParagraph=englishParagraph.join(' ');
    console.log("English Paragraph 1", mergedEnglishParagraph)

    const hoverItem= {
        '&:hover': {
          backgroundColor: '#FFC0C1', // Add your hover styles here
        },
      }

    const [wordSuggested, setWordSuggested] = useState('')
    const [suggestedWord, setSuggestedWord] = useState(false)
    const [newWord, setNewWord] = useState(false)
    const [wordToGlossary, setWordToGlossary] = useState('')

    /**
     * @type {{
    *  scenario: "askingUserIfHeWantsToAddVariantToGLossary" | "makingUserTypeTheWordFromEnglishParagraph"
    * }}
    */
   const [addVariantCorrespondingGlossaryWordState, setAddVariantCorrespondingGlossaryWordState] = useState({
       scenario: "",
       variantWordSelectedByUser: "",
       glossaryTextWhichUserHasTyped: {
        value: "",
        isDirty: false,
        isTouched: false
       }
   }) 

   const [isAddToGlossaryWordInputFieldInFocusRightNow, setIsAddToGlossaryWordInputFieldInFocusRightNow] = useState(false);

    const englishParagraphAsString = englishParagraph.join(" ");

    const hintsToShowToUserWhileWeAreMakingUserTypeTheWordFromEnglishParagraph = useMemo(() => {
        const englishParagraphWords = englishParagraphAsString.split(" ");
        const userTypedString = addVariantCorrespondingGlossaryWordState.glossaryTextWhichUserHasTyped.value;

        /** @type {string} */
        const userLastTypedWord = (()=>{
            /** @type {string[]} */
            const userTypedWords = userTypedString.trim().split(" ");
            return userTypedWords[userTypedWords.length-1]
        })();

        console.log('userLastTypedWord ~ userLastTypedWord:', userLastTypedWord)

        /** @type {string[]} */
        const userSuggestionWords = [];
        
        for (let wordIndex = 0; wordIndex < englishParagraphWords.length; wordIndex++) {
            const englishWord = englishParagraphWords[wordIndex];

            if (!userLastTypedWord) {
                userSuggestionWords.push(englishWord);
            } else if (englishWord === userLastTypedWord) {
                continue;
            }   else  if (englishWord.toLowerCase().startsWith(userLastTypedWord.toLowerCase())) {
                console.log('debug hintsToShowToUserWhileWeAreMakingUserTypeTheWordFromEnglishParagraph ~ englishWord:', englishWord)
                console.log('debug hintsToShowToUserWhileWeAreMakingUserTypeTheWordFromEnglishParagraph ~ userLastTypedWord:', userLastTypedWord)
                userSuggestionWords.push(englishWord);
            }
        }
        return userSuggestionWords;
    }, [addVariantCorrespondingGlossaryWordState.glossaryTextWhichUserHasTyped.value])   
    // })  
    
    /** @type {boolean} */
    const doesVariantGlossaryStringTypedByUserIsNotPartEnglishLanguage = useMemo(() => {
        /** @type {string} */
        const userTypedString = addVariantCorrespondingGlossaryWordState.glossaryTextWhichUserHasTyped.value;

        if (englishParagraphAsString.includes(userTypedString)) {
            return false;
        }
        return true
    }, [addVariantCorrespondingGlossaryWordState.glossaryTextWhichUserHasTyped.value, englishParagraphAsString])
   
   const onVariantWordSelectedByUser = (variantWord) => {
    setAddVariantCorrespondingGlossaryWordState(oldState=>{
        return {
            ...oldState,
            variantWordSelectedByUser: variantWord,
            scenario: "askingUserIfHeWantsToAddVariantToGLossary",
            glossaryTextWhichUserHasTyped: {
                value: "",
                isDirty: false,
                isTouched: false                
            }
        }
    })
   }

   const handleUserWantsToAddVariantToGlossary = () => {
    handleUserWantToAddVariantCorrespondingGlossaryWord(wordSuggested, addVariantCorrespondingGlossaryWordState.variantWordSelectedByUser,paragraphIndex, chapter, chapterKey, paragraphKey, props);

    return;
    setAddVariantCorrespondingGlossaryWordState(oldState=>{
        return {
            ...oldState,
            scenario: "makingUserTypeTheWordFromEnglishParagraph",
            glossaryTextWhichUserHasTyped: {
                value: "",

                // meaning user has edited the input field
                isDirty: false,

                // meaning user has lost focus from input field after first attaining focus
                isTouched: false                
            }
        }
    })
   }
   const handleUserDoesntWantToAddVariantToGlossary = () => {
    setAddVariantCorrespondingGlossaryWordState(oldState=>{
        return {
            ...oldState,
            scenario: "",
            glossaryTextWhichUserHasTyped: {
                value: "",
                isDirty: false,
                isTouched: false                
            }
        }
    })
    props.onChange(props)
   }

   const replaceLastWordWhichUserTypedWithHintWhichUserClicked = (hintSelectedByUser) => {
    /** @type {string} */
    const userTypedString = addVariantCorrespondingGlossaryWordState.glossaryTextWhichUserHasTyped.value;
    console.log('replaceLastWordWhichUserTypedWithHintWhichUserClicked ~ userTypedString:', userTypedString)
    
    /** @type {string[]} */
    const userTypedWords = userTypedString.split(" ");    

    // replacing user last word with hint
    userTypedWords[userTypedWords.length-1] = hintSelectedByUser;

    // framing words array back in sentence
    const userTypedStringToSet =  userTypedWords.join(" ");
    console.log('replaceLastWordWhichUserTypedWithHintWhichUserClicked ~ userTypedStringToSet:', userTypedStringToSet)

    setAddVariantCorrespondingGlossaryWordState(oldState=>({
        ...oldState,
        glossaryTextWhichUserHasTyped: {
            value: userTypedStringToSet,
            isDirty: true,
            isTouched: true  
        }
    }))
   }

   const onUserHintClick = (hintSelectedByUser) => {
    replaceLastWordWhichUserTypedWithHintWhichUserClicked(hintSelectedByUser);
   }
    
    const handleWordSuggested = (e) => {
        console.log("stuck", e.target.value)
        setWordSuggested(e.target.value)
    }

    const handleWordToGlossary = (e) => {
        setWordToGlossary(e.target.value);
    }

    const handleNewWord = () => {
        setNewWord(!newWord);
    }

    const handleXClick = () => {
        setNewWord(false);
    }

    const handleClick = () => {
        handleAddSuggestedWordToGlossary(wordSuggested, addVariantCorrespondingGlossaryWordState.variantWordSelectedByUser, paragraphIndex, chapter);
        if (variantWords.includes(wordSuggested)) return;
        setSuggestedWord(true)
        // const temp=[...variantWordsHere]
        // temp.push(wordSuggested);
        // setVariantWordsHere(temp)
        // console.log("QQQQQ", temp)
        // variantWords=variantWordsHere
    }

    const handleOption = (value) => {
        console.log('haha',value);
        onChange(value);

        const modifiedSentence = replaceWord(dropDownSentence, label.trim(), value.trim());
        setEditedContent({...editedContent, [`${chapterKey}-${paragraphKey}`]: modifiedSentence})

        // setDropDownSentence(async(prevSentence) => {
        //     const modifiedSentence = replaceWord(prevSentence, label.trim(), value.trim());
        //     setEdit(modifiedSentence);
        //     await setEditedContent({...editedContent, [`${chapterKey}-${paragraphKey}`]: edit})
        //     return modifiedSentence;
        // });

            console.log('jjjjj,', dropDownSentence, editedContent);
       
    };

    const markUserTypeVariantGlossaryStringInputFieldAsDirtyAndTouched = () => {
        setAddVariantCorrespondingGlossaryWordState(oldState=>{
            return {
                ...oldState,
                glossaryTextWhichUserHasTyped: {
                    ...oldState,
                    isDirty: true,
                    isTouched: true
                }
            }
        })
    }

    function replaceWord(originalSentence, targetWord, replacement) {
        const modifiedSentence = originalSentence.split(targetWord).join(replacement);
        return modifiedSentence;
      }

    console.log("kokoko", variantWords)
    const [variantWordsHere, setVariantWordsHere] = useState(variantWords)


    // const result = findChangesToMatchBase(dropDownSentence, ["'We mebben weer een moeilijke haak toebedeeld gekregen.", "'We rebben weer een moeilijke baak toebedeeld gekregen.", "'We nebben weer een moeilijke oaak toebedeeld gekregen.", "'We lebben weer een moeilijke paak toebedeeld gekregen."])

    //   console.log("result", result)

    // // const resultArray = Object.values(JSON.parse(result)).map((item) => {
    // const resultArray = Object.values(result).map((item) => {
    //     const baseWithPairs = item[0].reduce((acc, action) => {
    //       const baseWord = action.base[0];
    //       const withWord = action.with[0];
      
    //       // Find existing entry for the base word or create a new one
    //       const entry = acc.find((entry) => entry.word === baseWord) || {
    //         word: baseWord,
    //         pairs: [],
    //       };
      
    //       // Add the with word to the pairs array
    //       entry.pairs.push(withWord);
      
    //       // Update or add the entry in the accumulator
    //       if (!acc.some((existingEntry) => existingEntry.word === baseWord)) {
    //         acc.push(entry);
    //       }
      
    //       return acc;
    //     }, []);
      
    //     return baseWithPairs;
    //   });

    //   const ans = resultArray.map(item => item.filter(data => data.word === label))

    //   if(ans[0].length === 0) return null;

    const isAddToVarintWordGLossaryButtonDisabled = (() => {
        return !addVariantCorrespondingGlossaryWordState.glossaryTextWhichUserHasTyped.value
                ? true
                : addVariantCorrespondingGlossaryWordState.glossaryTextWhichUserHasTyped.isDirty && doesVariantGlossaryStringTypedByUserIsNotPartEnglishLanguage
                    ? true
                    : false
    })();

    const [value, setValue] = useState('');

    return (
        <>
            {modalWord && 
            // <Popover>

            // </Popover>
            <Popover anchorEl={anchorEl} open={modalWord} 
            // onClick={() => setModalWord(!modalWord)}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Paper 
                    sx={{
                        backgroundColor: '#f0f0f0', // Add your desired background color here
                        padding: '10px',
                        border:'1px solid #ddd'
                    }}
                >
            {/* {  options?.length > 0 &&
                options.map(item => <MenuItem sx={hoverItem} onClick={() => handleOption(item.value)} key={item.id} value={item.value}><div>{item.title}</div></MenuItem> )
            } */}

            {/* {ans?.length > 0 &&
                ans.map((item, id) => (
                    <MenuItem sx={hoverItem} onClick={() => handleOption(item[0]?.pairs[0])} key={id} >
                        <div>
                            {item[0]?.pairs?.join(', ')}
                        </div>
                    </MenuItem>
            ))} */}
            {/* { */}
                {/* // variantWords?.map(variantWord=>{ */}
                    {/* // return (
                            // <MenuItem onClick={()=>{onChange(variantWord)}}> */}
                    
                    {
                        !addVariantCorrespondingGlossaryWordState.scenario &&
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'column'
                            }}
                        >
                            <Typography variant="body1" sx={{color:'#1e1c80'}}>
                                Variant Word:
                            </Typography>
                            <div
                                style={{overflow: "auto", padding:'10px'}}
                                onClick={()=>{
                                    // props.variantWords=variantWordsHere
                                    // onChange(props)
                                }}
                            >
                                {
                                    shouldShowWordAsDeleted 
                                    ?   <Paper onClick={()=>{onChange(props)}}style={{textDecoration: "line-through", display:'inline-block', backgroundColor:'#fff'}}>{modalWord}</Paper>
                                    :
                                        variantWordsHere?.map(variantWord=>{
                                            return (
                                                <Paper style={{padding:'4px', display:'inline-block', height:'30px', margin:'10px', cursor: "pointer"}}
                                                    onClick={()=>{onVariantWordSelectedByUser(variantWord)}}
                                                >{variantWord}&nbsp;</Paper>
            
                                            )
                                        })
                                }
                            </div>
                        </div>
                    }

                    {
                        addVariantCorrespondingGlossaryWordState.scenario === "askingUserIfHeWantsToAddVariantToGLossary" &&
                        <div className={css.askingUserIfHeWantsToAddVariantToGLossarySection}>
                            <div className={css.askingUserIfHeWantsToAddVariantToGLossaryQuestion}>
                                Do you want to add "{addVariantCorrespondingGlossaryWordState.variantWordSelectedByUser}" to the Glossary?
                            </div>
                            <div
                                className={css.askingUserIfHeWantsToAddVariantToGLossaryButtonRow}
                            >
                                <Button size="small" color="primary" variant="contained" className={css.askingUserIfHeWantsToAddVariantToGLossaryYesButton} onClick={handleUserWantsToAddVariantToGlossary}>Yes</Button>
                                <Button size="small" onClick={handleUserDoesntWantToAddVariantToGlossary} className={css.askingUserIfHeWantsToAddVariantToGLossaryNoButton}>No</Button>
                            </div>
                        </div>
                    }

                    {
                        addVariantCorrespondingGlossaryWordState.scenario === "makingUserTypeTheWordFromEnglishParagraph" &&
                        <div className={css.makingUserTypeTheWordFromEnglishParagraphSection}>
                            <div className={css.makingUserTypeTheWordFromEnglishParagraphSectionHeading}>Type the corresponding word from english paragraph</div>
                            
                            <div className={css.makingUserTypeTheWordFromEnglishParagraphInputSectionContainer}>
                                <div className={css.makingUserTypeTheWordFromEnglishParagraphInputSection}>
                                    {/* <input type="text" 
                                        onFocus={(e)=>{
                                            setIsAddToGlossaryWordInputFieldInFocusRightNow(true)
                                        }}
                                        className={css.makingUserTypeTheWordFromEnglishParagraphInputSectionInputField}
                                        value={addVariantCorrespondingGlossaryWordState.glossaryTextWhichUserHasTyped.value}
                                        onBlur={()=>{
                                            setAddVariantCorrespondingGlossaryWordState(oldState=>({
                                                ...oldState,
                                                glossaryTextWhichUserHasTyped: {
                                                    ...oldState.glossaryTextWhichUserHasTyped,
                                                    isTouched: true,
                                                }
                                            }))   
                                            setTimeout(() => {
                                                // hiding list after a delay so that hint click should get called
                                                setIsAddToGlossaryWordInputFieldInFocusRightNow(false)                                     
                                            }, 300);
                                        }}
                                        onInput={(e)=>{
                                            setAddVariantCorrespondingGlossaryWordState(oldState=>({
                                                ...oldState,
                                                glossaryTextWhichUserHasTyped: {
                                                    ...oldState.glossaryTextWhichUserHasTyped,
                                                    isDirty: true,
                                                    value: e.target.value
                                                }
                                            }))                                        
                                        }}
                                    />
                                    {
                                        isAddToGlossaryWordInputFieldInFocusRightNow &&
                                        <ul className={css.makingUserTypeTheWordFromEnglishParagraphInputSectionSuggestionList}>
                                        {
                                            hintsToShowToUserWhileWeAreMakingUserTypeTheWordFromEnglishParagraph?.map((hintToShowToUserWhileWeAreMakingUserTypeTheWordFromEnglishParagraph, hintWordIndex)=>{
                                                return (
                                                    <li key={hintWordIndex}
                                                        className={css.makingUserTypeTheWordFromEnglishParagraphInputSectionSuggestionListItem}
                                                        onClick={(e)=>{
                                                            onUserHintClick(hintToShowToUserWhileWeAreMakingUserTypeTheWordFromEnglishParagraph)
                                                        }}
                                                    >
                                                        {hintToShowToUserWhileWeAreMakingUserTypeTheWordFromEnglishParagraph}
                                                    </li>
                                                )
                                            })
                                        }
                                        </ul>                             
                                    } */}
                                    <Autocomplete
                                        id="autocomplete-dropdown"
                                        options={hintsToShowToUserWhileWeAreMakingUserTypeTheWordFromEnglishParagraph || []}
                                        getOptionLabel={(option) => option}
                                        // value={value}
                                        value={addVariantCorrespondingGlossaryWordState.glossaryTextWhichUserHasTyped.value}
                                        freeSolo
                                        filterOptions={(options, state)=>{
                                            return hintsToShowToUserWhileWeAreMakingUserTypeTheWordFromEnglishParagraph
                                        }}
                                        // onChange={(e, newValue) => setValue(newValue)}
                                        onChange={(e, newValue) => {
                                            if (!newValue) {
                                                setAddVariantCorrespondingGlossaryWordState(oldState=>({
                                                    ...oldState,
                                                    glossaryTextWhichUserHasTyped: {
                                                        ...oldState.glossaryTextWhichUserHasTyped,
                                                        isDirty: true,
                                                        value: ""
                                                    }
                                                }))                                                 
                                            } else {
                                                onUserHintClick(newValue)
                                            }
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            type="text"
                                            error={
                                                doesVariantGlossaryStringTypedByUserIsNotPartEnglishLanguage
                                                ? true
                                                : false
                                            }
                                            onFocus={(e) => {
                                                setIsAddToGlossaryWordInputFieldInFocusRightNow(true)
                                            }}
                                            // onBlur={() => {
                                            //     setAddVariantCorrespondingGlossaryWordState(oldState=>({
                                            //         ...oldState,
                                            //         glossaryTextWhichUserHasTyped: {
                                            //             ...oldState.glossaryTextWhichUserHasTyped,
                                            //             isTouched: true,
                                            //         }
                                            //     }))   
                                            //     setTimeout(() => {
                                            //         // hiding list after a delay so that hint click should get called
                                            //         setIsAddToGlossaryWordInputFieldInFocusRightNow(false)                                     
                                            //     }, 300);
                                            // }}
                                            className={css.makingUserTypeTheWordFromEnglishParagraphInputSectionInputField}
                                            // value={value}
                                            // onInput={(e) => setValue(e.target.value)}
                                            // onChange={(e) => {
                                            //     onUserHintClick(e.target.value)
                                            // }}
                                            onChange={(e)=>{
                                            setAddVariantCorrespondingGlossaryWordState(oldState=>({
                                                    ...oldState,
                                                    glossaryTextWhichUserHasTyped: {
                                                        ...oldState.glossaryTextWhichUserHasTyped,
                                                        isDirty: true,
                                                        value: e.target.value
                                                    }
                                                }))                                                 
                                            }}
                                            style={{backgroundColor:'white'}}
                                          />
                                        )}
                                        renderOption={(props, option) => {
                                            console.log("renderOption option", option)
                                                return (
                                                <li {...props} className={css.makingUserTypeTheWordFromEnglishParagraphInputSectionSuggestionListItem}>
                                                    {option}
                                                </li>

                                                )
                                        }}
                                        MenuProps={{
                                            anchorOrigin: {
                                              vertical: 'bottom',
                                              horizontal: 'left',
                                            },
                                            transformOrigin: {
                                              vertical: 'top',
                                              horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                          }}
                                        PaperComponent={({ children }) => (
                                            <Paper elevation={3} style={{ marginTop: '8px' }}>
                                              {children}
                                            </Paper>
                                        )}
                                    />
                                </div>
                                
                                <div 
                                    className={`
                                        ${css.variantFlossaryStringInputFieldErrorMessage}
                                        ${!doesVariantGlossaryStringTypedByUserIsNotPartEnglishLanguage ? css.visibilityHidden : ""}
                                    `}
                                >
                                    {
                                        doesVariantGlossaryStringTypedByUserIsNotPartEnglishLanguage
                                        ? "Please mention word from its original language sentence"
                                        : ""
                                    }
                                </div>
                            </div>


                                {/* ${isAddToVarintWordGLossaryButtonDisabled ? css.addToGlossaryButtonDisabled : css.addToGlossaryButtonWhenNotDisabled} */}
                            <div className={css.addToGlossaryButtonRow}>
                                <Button variant="contained" 
                                    className={`
                                        ${css.addToGlossaryButton}
                                        ${css.addToGlossaryButtonWhenNotDisabled}
                                    `}
                                    onClick={()=>{

                                        if (!addVariantCorrespondingGlossaryWordState.glossaryTextWhichUserHasTyped.value) {
                                            markUserTypeVariantGlossaryStringInputFieldAsDirtyAndTouched();
                                            return;
                                        }

                                        onChange(props)
                                        setAddVariantCorrespondingGlossaryWordState(oldState=>{})
                                        handleAddSuggestedWordToGlossary(addVariantCorrespondingGlossaryWordState.glossaryTextWhichUserHasTyped.value, addVariantCorrespondingGlossaryWordState.variantWordSelectedByUser, paragraphIndex, chapter)
                                    }}
                                    // disabled={isAddToVarintWordGLossaryButtonDisabled}
                                >Add to Glossary</Button>
                            </div>
                        </div>
                    }
                            
                    {/* // ) */}
                {/* // }) */}
            {/* // } */}
            {   false && !addVariantCorrespondingGlossaryWordState.scenario &&
                <div style={{display:'flex', flexDirection:'column'}}>
                    <Box style={{padding:'2'}}/>
                    <Typography variant="body1" sx={{color:'#1e1c80', textDecoration:!newWord?'underline':''}}
                        onClick={() => {
                            handleNewWord()
                        }}
                    >
                        Suggest an Alternative?
                    </Typography>
                    {/* {newWord && 
                        <IconButton
                            onClick={setNewWord(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    } */}
                    {newWord && 
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            padding:'10px',
                        }}
                    >
                        <Textarea
                            placeholder="Suggest word…"
                            onInput={handleWordSuggested}
                            value={wordSuggested}
                            autoFocus={true}
                            style={{
                                height:'40px',
                                padding:'10px',
                                marginBottom:'10px'
                            }}
                        />
                        <Button
                            variant="contained"
                            style={{
                                border: "1px solid #3B37FF",
                                backgroundColor: wordSuggested?.length>0 ? '#1e1c80' : '',
                                color:'white',
                            }}
                            onClick={handleClick}
                            disabled={wordSuggested?.length===0}
                        >
                            Apply
                        </Button>
                    </div>
                    }
                    {suggestedWord &&
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            padding:'10px',
                        }}
                    >
                        <Textarea
                            placeholder="Word for Glossary…"
                            onInput={handleWordToGlossary}
                            value={wordToGlossary}
                            autoFocus={true}
                            style={{
                                height:'40px',
                                padding:'10px',
                                marginBottom:'10px'
                            }}
                        />
                        <Button
                            variant="contained"
                            style={{
                                border: "1px solid #3B37FF",
                                backgroundColor: wordToGlossary?.length>0 ? '#1e1c80' : '',
                                color:'white',
                            }}
                            onClick={handleClick}
                            disabled={wordToGlossary?.length===0}
                        >
                            Add to Glossary
                        </Button>
                    </div>
                }
                </div>
            }
            </Paper>
            </Popover>
            }
        </>
    )
}

export default WordDropDown;