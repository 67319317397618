import axios from "axios";
import apiUrls from "../api/apiUrl";
import { getFireStoreData ,getCurrentBookUserList, userLastActiveTime, loggedInUserActivity} from "./firebaseActions";
import { ADD_APIS_RESPONSE_STATUS, CONCURRENT_EDITS_TRACKER } from "./fireStoreTypes";
import store from "../App/store";
import { Tooltip } from "@mui/material";
import { styled } from '@mui/system';

export const replaceOverBookButtonQuery =
      ".ck .ck-find-and-replace-form__actions .replaceOverBookButton";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  zIndex: 2147483647, // Set your desired z-index value
});

const BLOCKED_TRANLATION_MESSAGE =
  "ERROR IN TRANSLATION or BLOCKED TRANSLATION";
/**
 * Makes sure source and translated text are aligned based on the connected source sentence Id.
 * This is done by finding the difference and adding a spacer DIV in respective side
 * with the offset height.
 * @param {*} sourceContent
 * @param {*} translatedContent
 * @returns
 */
export const fixParagraphAlignment = (sourceContent, translatedContent) => {
  // console.log("fixParagraphAlignment", sourceContent, translatedContent);
  // Create temporary divs to hold source and translated content
  const tempDivSource = document.createElement("div");
  const tempDivTranslated = document.createElement("div");

  // Array to store source paragraphs with data attribute 'data-first-sentence-id'
  const sourceParagraphs = [
    ...document.querySelectorAll("#source p[data-first-sentence-id]"),
  ];

  // Initialize variables to track total adjustments for source and translated paragraphs
  let totalAdjustmentForSource = 0;
  let totalAdjustmentForTranslated = 0;

  // Set innerHTML of temporary divs to source and translated content respectively
  tempDivSource.innerHTML = sourceContent;
  tempDivTranslated.innerHTML = translatedContent;

  // Iterate through each source paragraph
  sourceParagraphs.forEach((paragraph) => {
    // Get the 'data-first-sentence-id' attribute value
    const firstSentenceId = paragraph.getAttribute("data-first-sentence-id");

    // Calculate the offset of the source paragraph
    const sourceElementOffset = paragraph.offsetTop + totalAdjustmentForSource;

    // Find the corresponding translated paragraph using 'data-connected-first-sentence-id' attribute
    const targetElement = document.querySelector(
      `p[data-connected-first-sentence-id="${firstSentenceId}"]`
    );

    // If a corresponding translated paragraph exists
    if (targetElement) {
      // Calculate the offset of the translated paragraph
      const targetElementOffset =
        targetElement.offsetTop + totalAdjustmentForTranslated;

      // Calculate the difference in offsets between source and translated paragraphs
      let offsetDifference = targetElementOffset - sourceElementOffset;
      // console.log("offsetDifference", offsetDifference);
      // If translated paragraph is below source paragraph
      if (offsetDifference > 0) {
        // Set spacer paragraph height for source content to maintain alignment
        tempDivSource.querySelector(
          `p[data-spacer-paragraph-id="${firstSentenceId}"]`
        ).style.height = offsetDifference + "px";
        tempDivSource.querySelector(
          `p[data-spacer-paragraph-id="${firstSentenceId}"]`
        ).style.display = "block";
        // Update total adjustment for source content
        totalAdjustmentForSource += offsetDifference;
      }
      // If translated paragraph is above source paragraph
      else if (offsetDifference < 0) {
        // Calculate absolute difference
        offsetDifference = Math.abs(offsetDifference);
        // Set spacer paragraph height for translated content to maintain alignment

        // console.log(
        //   "test******",
        //   firstSentenceId,
        //   tempDivTranslated.querySelector(
        //     `p[data-spacer-paragraph-id="${firstSentenceId}"]`
        //   ),
        //   tempDivTranslated
        // );
        if (
          tempDivTranslated.querySelector(
            `p[data-spacer-paragraph-id="${firstSentenceId}"]`
          )
        ) {
          // console.log("ele", firstSentenceId);
          tempDivTranslated.querySelector(
            `p[data-spacer-paragraph-id="${firstSentenceId}"]`
          ).style.height = offsetDifference + "px";
          tempDivTranslated.querySelector(
            `p[data-spacer-paragraph-id="${firstSentenceId}"]`
          ).style.display = "block";
        }
        // Update total adjustment for translated content
        totalAdjustmentForTranslated += offsetDifference;
      }
    }
  });

  return {
    sourceContent: tempDivSource.innerHTML,
    translatedContent: tempDivTranslated.innerHTML,
  };
};

/**
 * @todo add description
 * @param {*} pageLayoutConfig
 */
export const applyPageLayout = (pageLayoutConfig,widthUnits) => {
  if (pageLayoutConfig?.document_size_width) {
    document.documentElement.style.setProperty(
      "--hc-cke-width",
      pageLayoutConfig?.document_size_width + widthUnits
    );
  }

  if (pageLayoutConfig?.document_size_height) {
    document.documentElement.style.setProperty(
      "--hc-cke-height",
      pageLayoutConfig?.document_size_height + "px"
    );
  }

  if (pageLayoutConfig?.document_margin_left) {
    document.documentElement.style.setProperty(
      "--hc-cke-margin-left",
      pageLayoutConfig?.document_margin_left + "px"
    );
  }

  if (pageLayoutConfig?.document_margin_right) {
    document.documentElement.style.setProperty(
      "--hc-cke-margin-right",
      pageLayoutConfig?.document_margin_right + "px"
    );
  }

  if (pageLayoutConfig?.document_margin_top) {
    document.documentElement.style.setProperty(
      "--hc-cke-margin-top",
      pageLayoutConfig?.document_margin_top + "px"
    );
  }

  if (pageLayoutConfig?.document_margin_bottom) {
    document.documentElement.style.setProperty(
      "--hc-cke-margin-bottom",
      pageLayoutConfig?.document_margin_bottom + "px"
    );
  }

  // adjustEditorLayout("source");
  // adjustEditorLayout("translated");
};

/**
 * @todo add description
 * @param {*} selector
 */
const adjustEditorLayout = (selector) => {
  const ele = document.querySelector(selector);
  const sourceWidth = ele?.getBoundingClientRect();
  const sourceInnerWidth = ele?.firstChild?.getBoundingClientRect();

  ele.style.transform = `scale(calc(${sourceWidth} / ${sourceInnerWidth}))`;
}

export const updateElementStyles=(cssSelector,style)=>{
  const ele = document.querySelector(cssSelector);
  if(!ele) return;
  
  Object.keys(style).forEach((key)=>{ 
    ele.style[key] = style[key];
  });

}

export const scaleEditor = (elementId,value) => {
  const ele = document.getElementById(elementId);
  if (!ele ||!ele.firstChild) return;
  // const { width: parentRectWidth } = ele?.getBoundingClientRect();
  document.documentElement.style.setProperty(`--hc-cke-${elementId}-scale`,value);

  return new Promise((res)=>{
    setTimeout(()=>{
      const rect = ele?.firstChild?.getBoundingClientRect();
      ele.style.width = rect.width + 'px';
      ele.style.height = rect.height + 'px';
      res(rect)
    },0);
  })
};

/**
 * @todo add description
 * @param {*} sourceContent
 * @param {*} isSuccess
 * @param {*} isPending
 * @param {*} isError
 * @returns
 */
export const getContentForEditor = (
  sourceContent,
  isSuccess,
  isPending,
  isError
) => {
  if (isPending) {
    return `<p style="margin:32px; text-align:center; color: #998; font-style: italic">Loading...</p>`;
  }
  if (isError) {
    return `<p style="margin:32px; text-align:center; color: #bc0000; font-style: italic">Failed to load content. Please try again later.</p>`;
  }
  if (isSuccess) {
    return sourceContent;
  }
  // return `<p style="margin:32px; text-align:center; color: #bc0000; font-style: italic">Something went wrong!</p>`;
};

/**
 * This function accepts a sentence and add first sentence id and connected first sentence id to the
 * paragraph tag so that source and translated paragraphs can be connected
 * @deprecated addPairingMetadataToParagraph to be used and avoiding sentence level manipulations as sentence level HTML can be abruptly cut
 * @param {*} sentence
 * @param {*} index
 * @param {*} sentenceId
 * @param {*} connectedSourceSentenceId
 * @param {*} key  - Key is either 'translatedSentences' or 'sourceSentences'
 * @returns
 */
export const addReferenceIdToFirstSentence = (
  sentence,
  index,
  sentenceId,
  connectedSourceSentenceId,
  key
) => {
  if (key === "translatedSentences" && index === 0) {
    sentence = sentence.replace(
      "<p>",
      `<p data-first-sentence-id='${sentenceId}'  data-connected-first-sentence-id='${connectedSourceSentenceId}'>`
    );
  } else if (key === "sourceSentences" && index === 0) {
    sentence = sentence.replace(
      "<p>",
      `<p data-first-sentence-id='${sentenceId}'>`
    );
  }
  return sentence;
};

/**
 * This function accepts a paragraph and adds required metadata to the paragraph
 * in the form of HTML data attributes
 * @param {*} paragraphHTMLString
 * @param {*} paragraphObject
 * @param {*} key  - Key is either 'translatedSentences' or 'sourceSentences'
 * @returns
 */
export const addPairingMetadataToParagraphHTMLString = (
  paragraphHTMLString,
  paragraphObject,
  key
) => {
  const div = document.createElement("div");
  const connectedSourceFirstSentenceId =
    paragraphObject?.[key]?.[0]?.connectedSourceSentenceIds?.[0];
  const sentenceId = paragraphObject?.[key]?.[0]?.sentenceId;

  // create a temp div for processing HTML string
  div.innerHTML = paragraphHTMLString;

  // Get reference to the block container paragraph that holds all other child tags
  const blockContainer = div.querySelector("p");

  // create a spacer paragraph
  const spacerParagraph = document.createElement("p");

  if (spacerParagraph) {
    spacerParagraph.setAttribute(
      "data-spacer-paragraph-id",
      key === "translatedSentences"
        ? connectedSourceFirstSentenceId
        : sentenceId
    );
    spacerParagraph.setAttribute("class", "spacer");
    // add spacer before paragraph
    // commented as this is causing an issue when working with caching
    // if (blockContainer) div.insertBefore(spacerParagraph, blockContainer);
  }

  if (blockContainer) {
    // add metadata to the paragraph
    blockContainer.setAttribute("data-first-sentence-id", sentenceId);
    blockContainer.setAttribute(
      "data-connected-first-sentence-id",
      connectedSourceFirstSentenceId || ""
    );
  }

  // get HTML as string
  paragraphHTMLString = div.innerHTML;
  // console.log("sabith3", paragraphHTMLString);

  return paragraphHTMLString;
};

/**
 * This functions get all paragraph data from Backend and returns HTML data to be rendered
 * @param {*} paragraphs
 * @param {*} key - Key indicates  'sourceSentences' or 'translatedSentences'
 * @param {*} addPairingMetadata @default true To control if data attributes has to be added
 * @returns
 */
export const getHTMLContentForEditor = (
  paragraphs,
  key,
  addPairingMetadata = true
) => {
  if (!paragraphs) return "";
  return paragraphs.reduce((acc, paragraph) => {
    const paragraphHTMLString = paragraph?.[key].reduce((acc2, sentenceEle) => {
      let sentence = sentenceEle?.sentence?.text || "";
      return acc2 + (sentence ?? "");
    }, "");
    acc += addPairingMetadata
      ? addPairingMetadataToParagraphHTMLString(
          paragraphHTMLString,
          paragraph,
          key
        )
      : paragraphHTMLString;
    return acc;
  }, "");
};

function mouseoutSentence() {
  resetSourceRealTime();
  // switch (element?.tagName) {
  //   case "P":
  //     resetSourceRealTime();
  //     // resetSource(element);
  //     break;
  //   case "SPAN":
  //   case "STRONG":
  //     resetSourceRealTime();
  //     // resetSource(getNearestBlock(element));
  //     break;
  //   default:
  //     resetSourceRealTime();
  //     // resetSource(getNearestBlock(element));
  // }
}

export const mouseoverSentence = (
  element,
  englishChapter,
  handleAddNewParagraph,
  fetchFromCache,
  currentChapter,
  translationId
) => {
  switch (element?.tagName) {
    case "P":
      let content = element.outerHTML;
      if (element.innerText === BLOCKED_TRANLATION_MESSAGE) {
        content += [...document.querySelectorAll("#translated > div > p")]
          .filter((p) => p.innerText === BLOCKED_TRANLATION_MESSAGE)
          .indexOf(element);
      }
      highlightSourceRealTime(
        content,
        englishChapter,
        handleAddNewParagraph,
        fetchFromCache,
        currentChapter,
        translationId
      );
      break;
    // case "SPAN":
    // case "STRONG":
    //   highlightSourceRealTime(
    //     getNearestBlock(element).outerHTML,
    //     englishChapter,
    //     handleAddNewParagraph,
    //     fetchFromCache,
    //     currentChapter,
    //     translationId
    //   );
    //   break;
    // default:
    // getNearestBlock(element) &&
    //   highlightSourceRealTime(
    //     getNearestBlock(element).outerHTML,
    //     englishChapter,
    //     handleAddNewParagraph,
    //     fetchFromCache,
    //     currentChapter,
    //     translationId
    //   );
  }
};

export const mouseOutEventListner = (event) => {
  event.stopPropagation();
  const { target } = event;
  mouseoutSentence();
};

/**
 * Enables the feature of highlighting source based on the connected tranlsated sentence
 * @param {*} translatedElementSelector
 */
export const enableSourceHighlightingOnHover = (
  translatedElementSelector,
  englishChapter,
  handleAddNewParagraph,
  fetchFromCache,
  currentChapter,
  translationId
) => {
  const allTranslatedParagraphs = document.querySelectorAll(
    `${translatedElementSelector} .ck-editor__editable > p`
  );

  console.log("querySelector", allTranslatedParagraphs);
  const mouseOverEventListner = (event) => {
    console.log("eventlog", event.target);
    event.stopPropagation();
    const { target } = event;
    mouseoverSentence(
      target,
      englishChapter,
      handleAddNewParagraph,
      fetchFromCache,
      currentChapter,
      translationId
    );
  };

  allTranslatedParagraphs?.forEach((e) => {
    e.addEventListener("mouseenter", mouseOverEventListner);

    if (!e.eventListeners) {
      e.eventListeners = {};
    }
    if (!e.eventListeners["mouseenter"]) {
      e.eventListeners["mouseenter"] = [];
    }
    e.eventListeners["mouseenter"].push(mouseOverEventListner);

    console.log(e);
    e.addEventListener("mouseleave", mouseOutEventListner);
  });

  return function () {
    // allTranslatedParagraphs.removeEventListener(
    //   "mouseover",
    //   mouseOverEventListner
    // );
    // allTranslatedParagraphs.removeEventListener(
    //   "mouseout",
    //   mouseOutEventListner
    // );
  };
};

/**
 * Highlight the source counterpart of the given paragraph element
 * @param {*} paragraphElement
 */
export const highlightSource = (paragraphElement, englishChapter) => {
  const connectedSourceFirstSentenceId = paragraphElement?.getAttribute(
    "data-connected-first-sentence-id"
  );
  if (connectedSourceFirstSentenceId) {
    paragraphElement?.classList?.add("highlighted");
    const sourceParagraph = document.querySelector(
      `p[data-first-sentence-id="${connectedSourceFirstSentenceId}"]`
    );
    sourceParagraph?.classList?.add("highlighted");
  }
};

let counter = 0;

export const highlightSourceRealTime = async (
  translatedParagraph,
  sourceChapter,
  handleAddNewParagraph,
  fetchFromCache,
  currentChapter,
  translationId
) => {
  counter++;
  const myIndex = counter;
  const sourceParagraph = await getSourceParagraphForTranslatedParagraph(
    translatedParagraph,
    sourceChapter,
    handleAddNewParagraph,
    fetchFromCache,
    currentChapter,
    translationId
  );
  console.log(
    myIndex,
    counter,
    "handling async request queue",
    translatedParagraph
  );
  if (sourceParagraph?.sentence_index >= 0 && myIndex === counter) {
    document
      .querySelector(
        `#source > div > p:nth-child(${sourceParagraph?.sentence_index + 1})`
      )
      ?.classList?.add("highlighted");
  }
};

/**
 * The function `getSourceParagraphForTranslatedParagraph` retrieves a source paragraph corresponding
 * to a translated paragraph, either from cache or by finding the closest match in the source chapter.
 * @param translatedParagraph - The `translatedParagraph` parameter in the
 * `getSourceParagraphForTranslatedParagraph` function represents the paragraph that has been
 * translated and for which you want to find the corresponding paragraph in the source text.
 * @param sourceChapter - `sourceChapter` is the original chapter or text from which the
 * `translatedParagraph` was translated.
 * @param cacheNewParagraph - The `cacheNewParagraph` parameter is a function that is used to cache a
 * new paragraph along with its result for future reference.
 * @param fetchFromCache - The `fetchFromCache` parameter is a function that retrieves cached data
 * based on the provided parameters such as `currentChapter`, `translationId`, and `paragraph`. It is
 * used to check if the translated paragraph has already been cached and return the cached result if
 * available.
 * @param currentChapter - The `currentChapter` parameter in the
 * `getSourceParagraphForTranslatedParagraph` function represents the current chapter or section of the
 * text from which the translated paragraph is being sourced or compared against. It is used to
 * identify the specific chapter within the text for operations such as caching and matching
 * paragraphs.
 * @param translationId - The `translationId` parameter in the
 * `getSourceParagraphForTranslatedParagraph` function is used to identify the specific translation
 * being worked with. It helps in caching and fetching translated paragraphs for a particular
 * translation identified by its unique ID.
 * @returns The function `getSourceParagraphForTranslatedParagraph` returns a Promise.
 */
const getSourceParagraphForTranslatedParagraph = async (
  translatedParagraph,
  sourceChapter,
  cacheNewParagraph,
  fetchFromCache,
  currentChapter,
  translationId
) => {
  const cachedResult = fetchFromCache({
    currentChapter,
    translationId,
    paragraph: translatedParagraph,
  });

  console.log("cacheNewParagraph", cacheNewParagraph);
  if (cachedResult && cachedResult?.["closest_english_sentence"]) {
    return Promise.resolve(cachedResult);
  } else if (cachedResult === null) {
    console.log("one api call in progress", cachedResult);
  } else {
    cacheNewParagraph({
      currentChapter,
      translationId,
      paragraph: translatedParagraph,
      result: null,
    });

    const result = await findClosestMatch(
      sourceChapter,
      translatedParagraph,
      currentChapter,
      translationId
    );

    cacheNewParagraph({
      currentChapter,
      translationId,
      paragraph: translatedParagraph,
      result,
    });
    return result;
  }
};

/**
 * Reset the highlighting of source counterpart of the given paragraph element
 * @param {*} paragraphElement
 */
export const resetSource = (paragraphElement) => {
  const connectedSourceFirstSentenceId = paragraphElement?.getAttribute(
    "data-connected-first-sentence-id"
  );
  if (connectedSourceFirstSentenceId) {
    paragraphElement?.classList?.remove("highlighted");
    const sourceParagraph = document.querySelector(
      `p[data-first-sentence-id="${connectedSourceFirstSentenceId}"]`
    );
    sourceParagraph?.classList?.remove("highlighted");
  }
};

/**
 * The function `resetSourceRealTime` removes the "highlighted" class from all elements with the class
 * "highlighted" within the "#source" element.
 */
export const resetSourceRealTime = () => {
  [...document?.querySelectorAll("#source .highlighted")].forEach((ele) =>
    ele?.classList?.remove("highlighted")
  );
};

/**
 * The `getNearestBlock` function returns the nearest `<p>` block element with the attribute
 * `data-connected-first-sentence-id` that is a parent of the given element.
 * @param ele - The `ele` parameter in the `getNearestBlock` function is expected to be an HTML
 * element. The function will then traverse up the DOM tree from that element to find the nearest
 * ancestor `<p>` element with a `data-connected-first-sentence-id` attribute.
 * @returns The `getNearestBlock` function returns the nearest `<p>` block element with the attribute
 * `data-connected-first-sentence-id` that is a parent of the input element `ele`.
 */
export const getNearestBlock = (ele) => {
  return ele?.parentNode?.closest("p[data-connected-first-sentence-id]");
};

/**
 * The `findClosestMatch` function sends a POST request to a backend API endpoint with specific
 * parameters and returns the response data.
 * @param sourceChapter - The `sourceChapter` parameter represents the chapter or section in the
 * original English text that you want to find the closest match for in the translated text.
 * @param translatedParagraph - The `translatedParagraph` parameter in the `findClosestMatch` function
 * represents the paragraph that has been translated into Dutch. This parameter is used to find the
 * closest match in the English source chapter for the translated paragraph.
 * @param currentChapter - The `currentChapter` parameter in the `findClosestMatch` function represents
 * the chapter number of the current chapter you are working with in your application. It is used to
 * specify the chapter number for which you want to find the closest match for a translated paragraph.
 * @param translationId - The `translationId` parameter in the `findClosestMatch` function represents
 * the unique identifier for the translation being processed. It is used to identify the specific
 * translation for which the closest match is being searched.
 * @returns The `findClosestMatch` function is returning the data received from the API call made using
 * Axios. The function sends a POST request to the specified backend URL with the provided parameters
 * (sourceChapter, translatedParagraph, currentChapter, translationId), and then logs the response data
 * to the console before returning it. If there is an error during the API call, the function logs the
 * error message to the
 */
export const findClosestMatch = (
  sourceChapter,
  translatedParagraph,
  currentChapter,
  translationId
) => {
  return axios
    .post(`${process.env.REACT_APP_BACKEND_URL}/${apiUrls.findClosestMatch}`, {
      english_chapter: sourceChapter,
      dutch_paragraph: translatedParagraph,
      translation_id: translationId,
      chapter_number: currentChapter,
    })
    .then((response) => {
      console.log("findClosestApi res ::", response.data);
      return response.data;
    })
    .catch((error) => {
      console.log("error in findClosestMatch API :::", error);
    });
};


/**
 * replaceOverBook function makes a API call to replace a 
 * word or text all over the book
 * @param {*} translationId  
 * @param {*} searchText  : Text from which word will going to replace
 * @param {*} replaceText : text which going to replace
 * @param {*} matchCase  : Boolean value 
 * @param {*} wholeWords : Boolean value 
 * @returns 
 */
export const replaceOverBook = async (
  translationId,
  searchText,
  replaceText,
  matchCase,
  wholeWords
) => {
  return axios
    .post(`${process.env.REACT_APP_BACKEND_URL + apiUrls.replaceOverBook}`, {
      translation_id: translationId,
      find_word: searchText,
      replacement_word: replaceText,
      match_case: matchCase,
      whole_words: wholeWords,
    })
    .then((res) => {
      console.log("Word replaced successfully", res.data);
      loggedInUserActivity({
        activity: ADD_APIS_RESPONSE_STATUS,
        apiResponse: {
          status: "success",
          apiEndPoint: apiUrls.replaceOverBook,
          responseMessage:"Text successfully replaced over book",
        },
      });
    })
    .catch((err) => {
      console.log("error in replacing word all over book", err);
      loggedInUserActivity({
        activity: ADD_APIS_RESPONSE_STATUS,
        apiResponse: {
          status: "error",
          apiEndPoint: apiUrls.replaceOverBook,
          responseMessage:err.message ?? "Error in replacing text over book",
        },
      });
    });
};


/**
* This functions accepts translated Content word count and source content word count
*  and returns difference between them example output (+36), (-40)
* @param {*} translateWords 
* @param {*} sourceWords 
* @returns 
*/
export const getWordDifference = (translateWords, sourceWords) => {
  let wordsDifference = translateWords - sourceWords;
  return wordsDifference > 0
    ? `+${wordsDifference}`
    : `${wordsDifference}`;
};

/**
 * updateInstantLearningValue functions makes an api call to update instant learning value
 * in the backend
 * @param {*} translationId 
 * @param {*} instantLearningToggleValue : boolean value
 * @returns 
 */
export const  updateInstantLearningValue=async (translationId,instantLearningToggleValue)=>{
  return  axios.post(`${process.env.REACT_APP_BACKEND_URL + apiUrls.instantLearningToggle}`,{
   translation_id:translationId,
   instant_learning_toggle:instantLearningToggleValue,
 }).then((res)=>{
    console.log("Updated Instant learning value",res.data)
 }).catch((err)=>{
    console.log("error in updating instant learning value",err)
 })
}

/**
 * translateChapter function makes an api call to translate given chapter 
 * @param {*} translationId 
 * @param {*} chapterNoForTranslation 
 * @returns 
 */
export const  translateChapter=async (translationId,chapterNoForTranslation)=>{
  return  axios.post(`${process.env.REACT_APP_BACKEND_URL + apiUrls.translateParticularChapters}`,{
    translation_id:translationId,
   chapter_number_list:[chapterNoForTranslation],
   assistant_name:"dutch-assistant",
   custom_temperature: 1.0,
   PRE_APPLIED_GLOSSARY: true
 }).then((res)=>{
    console.log("Updated Instant learning value",res.data)
 }).catch((err)=>{
    console.log("error in updating instant learning value",err)
 })
}

/**
 * getCurrentTime makes an api call to get current time 
 * @returns timestamp : string
 */
export const getCurrentTime= async ()=>{
  try {
    const response =await axios.get(`${process.env.REACT_APP_BACKEND_URL + apiUrls.serverTimestamp}`)
    return response.data.timestamp
  } catch (error) {
    console.log("error in getting timestamp",error)
  }
}



export const checkIsUserActive= (
  currentTime,
  userLastActiveTime
) => {
  // Convert string dates to Date objects
  const date1 = new Date(userLastActiveTime);
  const date2 = new Date(currentTime);

  // Calculate the time difference in milliseconds
  const timeDifference = date2 - date1;

  // Convert the time difference to minutes
  const differenceInMinutes = timeDifference / (1000 * 60);

  // Check if the time difference is more than 15 minutes
  return !(differenceInMinutes > 15);
};

/**
 * checkIsCurrentBookEngaged accepts translationId and logged in user email 
 * and returns current book is engaged or not, It calculates on the bases of lastActiveTime which it gets from firestore
 *  if its 15 mins lesser than current time
 * then its considered as not engaged and vice versa ,
 * @param {*} translationId 
 * @param {*} loggedInUserEmail 
 * @returns 
 */
export const checkIsCurrentBookEngaged =async(translationId,loggedInUserEmail)=>{
  let isThisBookEngaged = false;
  let currentBookOccupiedUser=""
  const storeData=await getFireStoreData(CONCURRENT_EDITS_TRACKER)
  const currentBookUsersList =storeData[translationId]
  const currentTime = await getCurrentTime();
  currentBookUsersList?.forEach((user) => {
    console.log(
      "its activeUsersList :::",
      checkIsUserActive(currentTime, user?.lastActiveTime)
    );
    if (
      user.user !== loggedInUserEmail &&
      checkIsUserActive(currentTime, user?.lastActiveTime)
    ) {
      isThisBookEngaged = true;
      currentBookOccupiedUser = getUserNameFromEmail(user?.user)
    }
  });

 console.log("its isThisBookEngaged",isThisBookEngaged)

  return {isThisBookEngaged,currentBookOccupiedUser}
}


export const getUserNameFromEmail=(email) =>{
  if(!email) return ;

  // Split the email at the '@' character
  const parts = email.split('@');
  
  // Return the first part (local part)
  return parts[0];
}

export function scrollToHighlightedText(editorType,individualScroll) {
  setTimeout(() => {
    const footer = document.querySelector('#sourced-editor-word-count');
    const containerQuery=individualScroll?editorType==="translate"?"#translatedContainer":"#sourceContainer":"#bookEditorContainer"
    const mainContainer = document.querySelector(containerQuery);
    const footerHeight = footer ? footer.offsetHeight : 0;
    const buffer = 20;

    // Use getBoundingClientRect to get the current scroll position relative to the viewport
    const containerRect = mainContainer.getBoundingClientRect();
    const currentScrollPosition = mainContainer.scrollTop;
    const viewportHeight = containerRect.height;
    const documentHeight = mainContainer.scrollHeight;

    // Calculate the maximum scroll position
    const maxScroll = documentHeight - viewportHeight;

    // Find the currently highlighted element
    const highlightedElement = mainContainer.querySelector('.ck-find-result_selected');
    
    if (highlightedElement) {
      const elementRect = highlightedElement.getBoundingClientRect();
      const elementTopRelativeToContainer = elementRect.top - containerRect.top;

      // Calculate the new scroll position
      let newScrollPosition = currentScrollPosition + elementTopRelativeToContainer - footerHeight - buffer;

      // Ensure we don't scroll past the bottom of the document
      newScrollPosition = Math.max(0, Math.min(newScrollPosition, maxScroll));

      mainContainer.scrollTo({
        top: newScrollPosition,
        
      });
    }
  }, 100); 
}

/**
 * updateUserActiveTime function triggers updation users last active time and book mark data
 * into firestore
 * @param {*} translationId 
 * @param {*} loggedInUserEmail  : Logged in user email
 * @param {*} bookMarkData : book mark data contains current chapter number, 
 * scroll lock value and scroll positions
 */
export const updateUserActiveTime = async (
  translationId,
  loggedInUserEmail,
  bookMarkData
) => {
  if (!translationId) return;
  console.log("user email",loggedInUserEmail)
  const lastActiveTime = await getCurrentTime();
  if (lastActiveTime && loggedInUserEmail) {
    await userLastActiveTime(
      translationId,
      "update",
      loggedInUserEmail,
      lastActiveTime,
      bookMarkData
    );
  }
};


export const getFormattedDate = (dateStr) => {
  const date = new Date(dateStr);

  const options = { day: "2-digit", month: "long", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-GB", options);

  return formattedDate;
};

/**
 * searchTextOverBook func makes api call to to search a text all over the book
 * @param {*} translationId 
 * @param {*} searchText  text which have to search
 * @param {*} matchCase : Boolean value 
 * @param {*} wholeWords : Boolean value
 * @returns a total matches count all over the book
 */
export const searchTextOverBook = async (translationId,searchText,matchCase,wholeWords,language)=>{
   try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL + apiUrls.searchOverBook}`, {
      translation_id: translationId,
      find_word: searchText,
      match_case: `${matchCase}`,
      whole_words: `${wholeWords}`,
      language:language
    })
    return response.data
   } catch (error) {
     console.log("error in finding text over book",error.message)
   }
}

/**
 * resetTotalMatchesElement func resets find and replace Total matches element like 
 * makes Total matches over book count 0 and disbale navigations 
 * @param {*} handleNextButtonClick 
 * @param {*} handlePrevButtonClick 
 * @param {*} totalCountsOverBook 
 * @returns 
 */
export const resetTotalMatchesElement = (
  bookType,
  handleNextButtonClick,
  handlePrevButtonClick,
  totalCountsOverBook,
) => {
    
  const checkBookOverCountEleQuery =
    `.ck.ck-find-and-replace-form .ck-find-and-replace-form__inputs .${bookType}countBookOver`;
  const bookOverMatchesElement = document.querySelector(
    `${checkBookOverCountEleQuery} .totalMatchesCount`
  );
  if (!bookOverMatchesElement) return;

  bookOverMatchesElement.innerHTML = `Total matches over book : <span class="totalCount"> ... </span>`;

  if(totalCountsOverBook!==undefined && totalCountsOverBook!==null)
   bookOverMatchesElement.innerHTML = `Total matches over book : <span class="totalCount"> ${totalCountsOverBook} </span>`;
  const nextButtonElement = document.querySelector(
    `${checkBookOverCountEleQuery} .chapterNavigations .nextArrowButton`
  );
  nextButtonElement.style.backgroundColor = "transparent";
  const backButtonElement = document.querySelector(
    `${checkBookOverCountEleQuery} .chapterNavigations .backArrowButton`
  );
  backButtonElement.style.backgroundColor = "transparent";

  handleNextButtonClick &&
    nextButtonElement.removeEventListener("click", handleNextButtonClick);
  handlePrevButtonClick &&
    backButtonElement.removeEventListener("click", handlePrevButtonClick);
};

export const createTotalMatchesOverBookElement = (
  createRoot,
  NextArrowIcon,
  BackArrowIcon,
  bookType
) => {
  const checkBookOverCountEleQuery =
    `.ck.ck-find-and-replace-form .ck-find-and-replace-form__inputs .${bookType}countBookOver`;
  const checkBookOverCountEle = document.querySelector(
    checkBookOverCountEleQuery
  );
  if (!checkBookOverCountEle) {
    const inputForm = document.querySelector(
      ".ck.ck-find-and-replace-form .ck-find-and-replace-form__inputs"
    );
    const replaceOverBookButton = document.createElement("div");
    replaceOverBookButton.innerHTML = `<span class='totalMatchesCount'>Total matches over book : <span class="totalCount"> N/A </span> </span>`;

    // Create a div for chapter navigation buttons
    const chapterNavigations = document.createElement("div");
    chapterNavigations.className = "chapterNavigations";

    // Create a button for the back arrow
    const backArrowButton = document.createElement("button");
    backArrowButton.className = "backArrowButton";

    const backArrowRoot = createRoot(backArrowButton);
    backArrowRoot.render(
      <CustomTooltip title="Prev chapter matches">
        <BackArrowIcon style={{ cursor: "pointer" }} />
      </CustomTooltip>
    );

    // Create a button for the next arrow
    const nextArrowButton = document.createElement("button");
    nextArrowButton.className = "nextArrowButton";

    const nextArrowRoot = createRoot(nextArrowButton);
    nextArrowRoot.render(
      <CustomTooltip title="Next chapter matches">
        <NextArrowIcon style={{ cursor: "pointer" }} />
      </CustomTooltip>
    );

    // Append the buttons to the chapter navigations div
    chapterNavigations.appendChild(backArrowButton);
    chapterNavigations.appendChild(nextArrowButton);
    replaceOverBookButton.append(chapterNavigations);
    replaceOverBookButton.classList.add(`${bookType}countBookOver`);
    inputForm.append(replaceOverBookButton);
  } else {
    resetTotalMatchesElement(bookType);
  }
};

export const updateTotalMatchesOverBookElement = (
  wordOccurrencesOverBook,
  handleNextButtonClick,
  handlePrevButtonClick,
  bookType,
  currentChapterMatches=0,
) => {
  if (!wordOccurrencesOverBook) return;
  let currentChapterNo = store.getState().editor.currentChapter;
   if(!currentChapterNo) return ;
   currentChapterNo=Number(currentChapterNo)
  const checkBookOverCountEleQuery =
    `.ck.ck-find-and-replace-form .ck-find-and-replace-form__inputs .${bookType}countBookOver`;
  let totalCountsOverBook = Number(currentChapterMatches);
  for (const key in wordOccurrencesOverBook) {
    if(Number(key)!==currentChapterNo)
      totalCountsOverBook += wordOccurrencesOverBook[key];
  }
  
  const replaceOverBookButton =
              document.querySelector(replaceOverBookButtonQuery) || {style:{}};

  if(totalCountsOverBook && bookType==="translate"){
    replaceOverBookButton.style.opacity = "1";
    replaceOverBookButton?.classList?.add("enableHover");
  }else{
    replaceOverBookButton.style.opacity = "0.5";
    replaceOverBookButton?.classList?.remove("enableHover");
    resetTotalMatchesElement(bookType,handleNextButtonClick,handlePrevButtonClick,totalCountsOverBook)
  }

  const checkBookOverCountEle = document.querySelector(
    `${checkBookOverCountEleQuery} .totalMatchesCount`
  );

  if (!checkBookOverCountEle) return;

  checkBookOverCountEle.innerHTML = `Total matches over book : <span class="totalCount"> ${totalCountsOverBook} </span>`;

  const nextButtonElement = document.querySelector(
    `${checkBookOverCountEleQuery} .chapterNavigations .nextArrowButton`
  );
  const backButtonElement = document.querySelector(
    `${checkBookOverCountEleQuery} .chapterNavigations .backArrowButton`
  );
  
  if (currentChapterNo) currentChapterNo = Number(currentChapterNo);

  const { isLastChapterNo: isLastNextChap } = getNextChapterNumber(
    "checkNext",
    wordOccurrencesOverBook,
    currentChapterNo
  );

  if (!isLastNextChap) {
    nextButtonElement.style.backgroundColor = "#f3f3f3";
  } else {
    nextButtonElement.style.backgroundColor = "transparent";
  }

  const { isLastChapterNo: isLastPrevChap } = getNextChapterNumber(
    "checkPrev",
    wordOccurrencesOverBook,
    currentChapterNo
  );

  if (!isLastPrevChap) {
    backButtonElement.style.backgroundColor = "#f3f3f3";
  } else {
    backButtonElement.style.backgroundColor = "transparent";
  }


  nextButtonElement.removeEventListener("click", handleNextButtonClick);
  backButtonElement.removeEventListener("click", handlePrevButtonClick);
  nextButtonElement.addEventListener("click", handleNextButtonClick);
  backButtonElement.addEventListener("click", handlePrevButtonClick);
};

export const getNextChapterNumber = (
  chapterType,
  wordOccurrencesOverBook,
  currentChapter
) => {
  const keys = Object.keys(wordOccurrencesOverBook).map(Number);
  keys.sort((a, b) => a - b);

  const isWordMatches = keys.length !== 0 && keys.indexOf(currentChapter) === -1;
  if(isWordMatches){
    keys.push(currentChapter);
    keys.sort((a, b) => a - b);
  }
  const currentIndex = keys.indexOf(currentChapter);
  // Return the next key if it exists
  let data = { nextChapterNo: "", isLastChapterNo: false };

  if (chapterType === "next") {
    let nextChapIndex = currentIndex + 1;
    if (currentIndex !== -1 && nextChapIndex < keys.length)
      data.nextChapterNo = keys[nextChapIndex];

    if (currentIndex === -1 || nextChapIndex + 1 >= keys.length)
      data.isLastChapterNo = true;
  }

  if (chapterType === "prev") {
    let prevChapIndex = currentIndex - 1;
    if (currentIndex !== -1 && prevChapIndex >= 0)
      data.nextChapterNo = keys[prevChapIndex];

    if (currentIndex === -1 || prevChapIndex <= 0) data.isLastChapterNo = true;
  }

  if (chapterType === "checkNext") {
    if (currentIndex === -1 || currentIndex + 1 >= keys.length)
      data.isLastChapterNo = true;
  }

  if (chapterType === "checkPrev") {
    if (currentIndex === -1 || currentIndex - 1 < 0)
      data.isLastChapterNo = true;
  }
  console.log(
    "debug chapter details ",
    chapterType,
    currentIndex,
    currentChapter,
    keys,
    data
  );

  return data;
};



export const scrollBarData = (type, individualScroll, scrollBarDetail) => {
  const translatedElement = document.querySelector("#translatedContainer");
  const sourceElement = document.querySelector("#sourceContainer");
  const bookEditorContainer=document.querySelector("#bookEditorContainer")
  switch (type) {
    case "get":
      if (individualScroll) {
        return {
          individualScroll,
          translatedElementScroll: translatedElement?.scrollTop,
          sourceElementScroll: sourceElement?.scrollTop,
        };
      }

      return {
        individualScroll,
        windowScroll: bookEditorContainer?.scrollTop,
      };
    case "set":
      if (!scrollBarDetail) break;
      const {
        sourceElementScroll,
        translatedElementScroll,
        windowScroll,
      } = scrollBarDetail;
      const scrollLockButton = document.querySelector(
        '[data-cke-tooltip-text="Unlock scroll"]'
      );

      if (individualScroll) {
        scrollLockButton?.click();
        setTimeout(()=>{
          const translatedElement = document.querySelector("#translatedContainer");
          const sourceElement = document.querySelector("#sourceContainer");
          translatedElement.scrollTo(0,translatedElementScroll);
          sourceElement.scrollTo(0,sourceElementScroll)
        },0)
        return;
      }
      
      bookEditorContainer?.scrollTo(0, windowScroll);
      
  }
};



export const  getEmailThroughSession = async()=>{
  try {
   const response = await fetch(apiUrls.appsecAuthSessionUrl, {
     method: 'GET',
     credentials: 'include',
   });
   const data=await response.json();
   if(data?.user?.email)
     return data?.user?.email
  } catch (error) {
     console.log("error in getting email through session")
  }
}


export const setEditorLayout=(fetchTranslatedChapterPending,translatedContent,sourceContent) =>{
  const translatedContentElem = document.querySelector(
    "#translated .ck-editor__editable"
  );
  const sourcedContentElem = document.querySelector(
    "#source .ck-editor__editable"
  );

  const translatedEditorCont = document.querySelector(
    "#translated"
  );
  


  if(!translatedContentElem || !sourcedContentElem) return ;

  setTimeout(()=>{
   
    if(!translatedEditorCont?.getAttribute('editors-min-height')){
      translatedEditorCont.setAttribute('editors-min-height',`${sourcedContentElem?.offsetHeight}px`)
      document.documentElement.style.setProperty(
        "--hc-cke-editors-minHeight",
        `${sourcedContentElem?.offsetHeight}px`
      );
    }else{
      document.documentElement.style.setProperty(
        "--hc-cke-editors-minHeight",
        translatedEditorCont?.getAttribute('editors-min-height')
      );
    }
    
    if(!fetchTranslatedChapterPending && translatedContent && translatedContentElem?.offsetHeight)
      document.documentElement.style.setProperty(
        "--hc-cke-sourced-height",
        `${translatedContentElem?.offsetHeight}px`
      );
    
    if(!fetchTranslatedChapterPending && !translatedContent && sourceContent && sourcedContentElem?.offsetHeight)
      document.documentElement.style.setProperty(
        "--hc-cke-translated-height",
        `${sourcedContentElem?.offsetHeight}px`
      );
  },0)

 

  if(fetchTranslatedChapterPending){
    document.documentElement.style.removeProperty("--hc-cke-translated-height");
    document.documentElement.style.removeProperty("--hc-cke-sourced-height");
    document.documentElement.style.removeProperty("--hc-cke-editors-minHeight");
    translatedEditorCont.removeAttribute('editors-min-height')
  }
}



/**
 * The function `customizeSourceFindDialog` modifies the CKEditor Find and Replace dialog.
 * It changes the dialog label to 'Find in Document', hides the replace input field,
 * hides the replace and replace all buttons, and adjusts the layout of the actions div
 * if only one button remains visible. This customization focuses the dialog on find functionality only.
 */
export function customizeSourceFindDialog() {

  const dialogLabel = document.querySelector('.ck-form__header__label');
    if (dialogLabel) {
        dialogLabel.textContent = 'Find in english';
    }

    const replaceInput = document.querySelector('.ck-labeled-field-replace');
    if (replaceInput) replaceInput.remove();

    const replaceButtons = document.querySelectorAll('.ck-button-replace, .ck-button-replaceall');
    replaceButtons.forEach(button => button.remove());

    const actionsDiv = document.querySelector('.ck-find-and-replace-form__actions');
    if (actionsDiv && actionsDiv.children.length === 1) {
        const findButton = actionsDiv.querySelector('.ck-button-find');
        if (findButton) {
            findButton.style.float = 'right';
            findButton.style.margin = '11.7px';
            const findButtonSpan = actionsDiv.querySelector('.ck-button-find span');
            findButtonSpan.style.width="3.5rem";
            findButtonSpan.style.textAlign="center";
            
            actionsDiv.parentNode.appendChild(findButton);
            actionsDiv.remove();
        }
    }


}



export function removeButton(toolbarElement,query) {
  const button= toolbarElement.querySelector(query);
  button?.remove()  
}