import React, { useState ,useEffect} from "react";

import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import ArrowLeftOutlinedIcon from "@mui/icons-material/ArrowLeftOutlined";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as editorActions from "../../App/editor/editor.actions";

import "./BookHeader.scss";
import AlertDialog from "../AlertDialog/AlertDialog";
import { getFireStoreData, userLastActiveTime } from "../../utils/firebaseActions";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { updateUserActiveTime } from "../../utils/translationUtil";
import { CONCURRENT_EDITS_TRACKER } from "../../utils/fireStoreTypes";

function BookHeader({
  sourceLanguage,
  translatedLanguage,
  currentChapter,
  totalChapters,
  handleChapterChange,
  setIsLayoutFixed,
  isTranslatedContentEdited,
  setIsTranslatedContentEdited,
  afterHandledUnsavedChangesAction,
  setAfterHandledUnsavedChangesAction,
  translatedChapterInfo,
  applyGlossary,
  fetchGlossarySuggestionsData
  // updateUserActiveTime

}) {
  const [localCurrentChapter, setCurrentChapter] = useState(currentChapter);
  const [isOpenDialogBox, setIsOpenDialogBox] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch=useDispatch()
  const navPrevDisabled = currentChapter <= 1;
  const navNextDisabled = currentChapter >= totalChapters;
  const { loggedInUserEmail ,concurrentEditsTrackerData} = useSelector((state) => state.main);
  const {glossarySuggestionData} = useSelector((state) => state.editor);

  const navigate = useNavigate();
  const [cookies] = useCookies([]);
  const params = useParams();

  useEffect(() => {
    const navigateChapter=async()=>{
      if(!loggedInUserEmail) return 
      const loggedInUserData = concurrentEditsTrackerData?.bookMarkData
        ? concurrentEditsTrackerData?.bookMarkData?.[loggedInUserEmail]
        : {};
      const currentBookBookMarkData=loggedInUserData?.[params.id] ?? {}
      if(currentBookBookMarkData?.lastOpenedChapterNo){
        searchParams.set("chapter", currentBookBookMarkData?.lastOpenedChapterNo);
        setSearchParams(searchParams);
      }
    }
    navigateChapter()

    return ()=> dispatch(editorActions.setGlossarySuggestionsData([]))
  }, [])
  

  useEffect(() => {
    if(afterHandledUnsavedChangesAction?.actionName && isTranslatedContentEdited ){
      const findAndReplaceOverLay=document.querySelector('.ck.ck-dialog-overlay.ck-dialog-overlay__transparent ')
      if(findAndReplaceOverLay){
        findAndReplaceOverLay.style.zIndex='9'
      }
      setIsOpenDialogBox(true)
    }
  }, [afterHandledUnsavedChangesAction])
  
  useEffect(() => {
    setCurrentChapter(currentChapter)
  }, [currentChapter])

  const handlePreviousClick = (isHandledUnsavedChanges) => {
    if (currentChapter > 1) {
      if (isTranslatedContentEdited && !isHandledUnsavedChanges) {
        setAfterHandledUnsavedChangesAction({
          actionName: "previousClick",
          actionData: "",
        });
        return;
      } else {
        setIsTranslatedContentEdited(false);
      }
      handleChapterChange(currentChapter - 1);
      setCurrentChapter(currentChapter - 1);
      setIsLayoutFixed(false);
    }
  };

  const handleNextClick = (isHandledUnsavedChanges) => {
    if (currentChapter < totalChapters) {
      if (isTranslatedContentEdited && !isHandledUnsavedChanges) {
        setAfterHandledUnsavedChangesAction({
          actionName: "nextClick",
          actionData: "",
        });
        return;
      } else {
        setIsTranslatedContentEdited(false);
      }
      handleChapterChange(currentChapter + 1);
      setCurrentChapter(currentChapter + 1);
      setIsLayoutFixed(false);
    }
  };

  const handlePaginationFormSubmit = (e,isHandledUnsavedChanges) => {
    e.preventDefault();
    e.stopPropagation();
    let finalChapter = localCurrentChapter;
    if (localCurrentChapter < 1 || localCurrentChapter > totalChapters)
      finalChapter = currentChapter;
    
   if (Number(finalChapter) !== Number(currentChapter)) {
     if (isTranslatedContentEdited && !isHandledUnsavedChanges) {
       setAfterHandledUnsavedChangesAction({
        actionName: "inputChangeForm",
        actionData: e,
       });
       return;
     } else {
       setIsTranslatedContentEdited(false);
     }
   }   
    
    setCurrentChapter(finalChapter);
    handleChapterChange(finalChapter);
    setIsLayoutFixed(false);
  };

  const handleInputBlur = (e,isHandledUnsavedChanges) => {
    let finalChapter = e.target.value;
    console.log("dialogBoxCallBack",finalChapter,currentChapter)
    if (localCurrentChapter < 1 || localCurrentChapter > totalChapters)
      finalChapter = currentChapter;
    
    if (Number(finalChapter) !== Number(currentChapter)) {
      if (isTranslatedContentEdited && !isHandledUnsavedChanges) {
        setAfterHandledUnsavedChangesAction({
          actionName: "inputChangeBlur",
          actionData: e,
        });
        return;
      } else {
        setIsTranslatedContentEdited(false);
      }
    }
    
    handleChapterChange(finalChapter);
    setCurrentChapter(finalChapter);
    setIsLayoutFixed(false);
  };

  const handleInputChange = (e) => {
    setCurrentChapter(e.target.value);
    setIsLayoutFixed(false);
  };

  const performAction= () => {
    const { actionName, actionData ,nextChapterNo} = afterHandledUnsavedChangesAction;
    switch (actionName) {
      case "previousClick":
        if(nextChapterNo){
          handleChapterChange(nextChapterNo)
          setIsTranslatedContentEdited(false);
          break;
        }
        handlePreviousClick(true);
        break;
      case "nextClick":
        if(nextChapterNo){
          handleChapterChange(nextChapterNo)
          setIsTranslatedContentEdited(false);
          break;
        }
        handleNextClick(true);
        break;
      case "inputChangeBlur":
        handleInputBlur(actionData, true);
        break;
      case "inputChangeForm":
        handlePaginationFormSubmit(actionData, true);
        break;
      case "goBack":
        // const activeUserEmail=cookies["email"];
        userLastActiveTime(params.id,"remove",loggedInUserEmail)
        navigate(`/${actionData}`);
       break;
      case "downloadTranslatedBook":
        // setIsTranslatedContentEdited(true);
        dispatch(editorActions.setIsTranslationContentEdited(false))
        const downloadButton = document.querySelector(
          '[data-cke-tooltip-text="Download translation"]'
        );
        downloadButton?.click()
       break;
      case 'applyGlossary':
        const {glossaryPayload,applyType,selectedGlossaryItem,selectedGlossary,chapterData}=actionData;
        applyGlossary(glossaryPayload,applyType,chapterData,selectedGlossaryItem,selectedGlossary,chapterData)
        dispatch(editorActions.setIsTranslationContentEdited(false))
        setIsTranslatedContentEdited(false);
        break;
      case 'applySuggestedGlossary':
          const {applyPayload,applyType:glossaryApplyType,chapterData:chapter}=actionData;
          applyGlossary(applyPayload,glossaryApplyType,chapter)
          dispatch(editorActions.setIsTranslationContentEdited(false))
          setIsTranslatedContentEdited(false);
          const updatedSuggestedGlossaries = glossarySuggestionData.filter(
            (suggestion) => suggestion.object_id !== applyPayload.object_id
          );
  
          dispatch( editorActions.setGlossarySuggestionsData(updatedSuggestedGlossaries));
          fetchGlossarySuggestionsData(params.id,updatedSuggestedGlossaries)
      break;
    }
    setAfterHandledUnsavedChangesAction({ actionName: "", actionData: "" });
  };

  const handleDiscardChanges = () => {
    const findAndReplaceOverLay=document.querySelector('.ck.ck-dialog-overlay.ck-dialog-overlay__transparent ')
    if(findAndReplaceOverLay){
      findAndReplaceOverLay.style.zIndex='99999'
    }
    setIsOpenDialogBox(false);
    performAction();
  };

  const handleSaveChanges = () => {
    const saveButton = document.querySelector(
      '[data-cke-tooltip-text="Save chapter"]'
    );
    saveButton?.click();
    const findAndReplaceOverLay=document.querySelector('.ck .ck-dialog-overlay ')
      if(findAndReplaceOverLay){
        findAndReplaceOverLay.style.zIndex='99999'
      }
    setTimeout(() => {
      setIsOpenDialogBox(false);
      performAction();
    }, 1000);
  };

  useEffect(() => {
     if(isOpenDialogBox){
       const glossaryElem=document.querySelector(".glossaryContainer") || {style:{}}
       glossaryElem.style.zIndex="0"
     }else{
      const glossaryElem=document.querySelector(".glossaryContainer") || {style:{}}
      glossaryElem.style.zIndex="10"
     }
  }, [isOpenDialogBox])
  

  return (
    <div className="book-header-container w-full h-[42px] flex justify-center">
      <div className="w-full flex flex-row items-center justify-evenly">
        <div className="font-bold text-hc-title-blue flex items-center gap-3 uppercase pl-[78px]">
          {sourceLanguage}
          <div className={`sourceFindButton ${sourceLanguage?'opacity-1':'opacity-0'} text-sm`}></div>
        </div>
        <div className="flex items-center gap-2">
          <Tooltip title={navPrevDisabled ? "" : "Previous chapter"}>
            <IconButton
              disabled={navPrevDisabled}
              color="primary"
              sx={{ p: 0.25 }}
              onClick={(e) => handlePreviousClick()}
            >
              <ArrowLeftOutlinedIcon fontSize="large" />
            </IconButton>
          </Tooltip>
          <div className="flex items-center gap-2">
            <form onSubmit={handlePaginationFormSubmit}>
              <TextField
                min={1}
                max={totalChapters}
                type="number"
                value={localCurrentChapter}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                // onKeyPress={handleKeyPress}
                // inputProps={{ min: 1, max: maxPages}}
                InputProps={{
                  style: {
                    width: currentChapter?.toString().length + 2 + "ch",
                  },
                  classes: {
                    root: "min-w-6 max-w-16 h-6 p-0 rounded-[3px]",
                    input: "p-0 text-base text-center",
                    notchedOutline: "border-[#1e1c80]",
                  },
                }}
              />
            </form>
            <div>{"/"}</div>
            <div>{totalChapters}</div>
          </div>
          <Tooltip title={navNextDisabled ? "" : "Next chapter"}>
            <IconButton
              disabled={navNextDisabled}
              color="primary"
              sx={{ p: 0.25 }}
              onClick={(e) => handleNextClick()}
            >
              <ArrowRightOutlinedIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </div>
        <div className="font-bold text-hc-title-blue uppercase pr-[74px]">
          <div className="flex items-center gap-3">
            {translatedLanguage}
            <div className={`translateFindAndReplaceButton ${translatedLanguage?'opacity-1':'opacity-0'} text-sm`}></div>
          </div>
        </div>
      </div>
      {isOpenDialogBox && (
        <AlertDialog
          message={
            "You have unsaved changes. Are you sure you want to proceed without saving your changes?"
          }
          onSave={handleSaveChanges}
          onDiscard={handleDiscardChanges}
          type={"unsavedChanges"}
        />
      )}
    </div>
  );
}

export default BookHeader;
