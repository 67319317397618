import React, { useState } from "react";
import TranslationEditTextAreaComponent from "../TranslationEditTextAreaComponent/TranslationEditTextAreaComponent";
import TranslationEditMarkdownComponent from "../TranslationEditMarkdownComponent/TranslationEditMarkdownComponent";

function TranslationEditCompent({
  translatedPairParagraph,
  chapterKey,
  paragraphKey,
  textAreaRef,
  calculateTextareaHeight,
  editedContent,
  paraTranslated,
  handleParagraphHower,
  handleChange,
  paragraphId,
  setEditedContent,
  editorMode
}) {

  const [content, setcontent] = useState(translatedPairParagraph.join(""))


  const handleOnSave=(markdown)=>{
      setcontent(markdown)
  }

  return (
    <div>
      { editorMode ==="textarea"  && translatedPairParagraph?.map((sentence, i) => (

        <TranslationEditTextAreaComponent 
         sentence={sentence}
         i={i}
         chapterKey={chapterKey}
         paragraphKey={paragraphKey}
         textAreaRef={textAreaRef}
         calculateTextareaHeight={
           calculateTextareaHeight
         }
         editedContent={editedContent}
         paraTranslated={paraTranslated}
         handleParagraphHower={handleParagraphHower}
         handleChange={handleChange}
         paragraphId={paragraphId}
         setEditedContent={setEditedContent}
         translatedPairParagraph={translatedPairParagraph}
         />
      ))}

      {editorMode ==="markdown" && 
         <TranslationEditMarkdownComponent  content={content} handleOnSave={handleOnSave} />
       }
    </div>
  );
}

export default TranslationEditCompent;
