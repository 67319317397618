import { Plugin } from "@ckeditor/ckeditor5-core";
import {
  addToolbarToDropdown,
  ButtonView,
  createDropdown,
} from "@ckeditor/ckeditor5-ui";

const zoomSizes = [1, 1.25, 1.5, 2.0];
class ZoomDropdown extends Plugin {
  currentSize = 1;

  init() {
    const editor = this.editor;
      const utils = editor.config.get("utils") as any;

      const toolbarDropdown = createDropdown(editor.locale);
    
      toolbarDropdown.buttonView.set({
        label: `${this.currentSize * 100}%`,
        withText: true,
      });

    const buttons = zoomSizes.map((size) => {
      const button = new ButtonView();

      button.isOn=size===this.currentSize;
      
      button.set({
        label: typeof size === "number" ? `${size * 100}%` : size,
        withText: true,
      });

      button.on("execute", async () => {
        button.isEnabled = false;
        this.currentSize = size;
        toolbarDropdown.buttonView.label = `${this.currentSize * 100}%`;
        await utils.zoomEditor(size);
        button.isEnabled = true;
      });
      return button;
    });

    toolbarDropdown.on('execute',(e)=>{

      buttons.forEach((b)=>{
        b.isOn=false;
      });
      
      (e.source as any).isOn=true;
    })

    addToolbarToDropdown(toolbarDropdown, buttons, { isVertical: true,enableActiveItemFocusOnDropdownOpen:true });

    editor.ui.componentFactory.add("zoomDropdown", () => {
      return toolbarDropdown;
    });
  }
}

export default ZoomDropdown;
