import { forwardRef } from "react";

import cx from "clsx";

import styles from "./Button.module.scss";

const variants = {
  "primary-text": styles.btnPrimary,
  "primary-text-disabled": styles.btnPrimaryDisabled,
  "primary-contained": styles.btnPrimaryContained,
  "primary-contained-disabled": styles.btnPrimaryContainedDisabled,
  "primary-outlined": styles.btnPrimaryOutlined,
};

const Button = forwardRef(
  (
    {
      variant = "contained",
      color = "primary",
      disabled,
      className,
      onClick,
      ...restProps
    },
    ref
  ) => {

    const handleClick = async (e) => {
      typeof onClick === "function" && onClick(e);
    };

    const renderButton = () => {
      return (
        <button
          ref={ref}
          disabled={disabled}
          className={cx(
            styles.button,
            disabled && styles.btnDisabled,
            disabled
              ? variants[`${color}-${variant}-disabled`]
              : variants[`${color}-${variant}`],
            className
          )}
          onClick={handleClick}
          {...restProps}
        />
      );
    };

    return renderButton();
  }
);

Button.displayName = "Button";

export default Button;
