import React from "react";
import "./AlertDialog.scss";

const AlertDialog = ({ message, onDiscard, onSave,type }) => {
  return (
    <div className="alertDialog-overlay">
      <div className="alertDialog-box">
        {type==="applyingGlossary" && <div className="text-lg pb-2 font-semibold">Warning</div>}
        <p className="alertDialog-message">{message}</p>
        {type==="applyingGlossary" && <div className="">Please confirm if you wish to continue. </div>}

        <div className="flex gap-4 justify-end mt-4">
          {onSave && (
            <button className="alertDialog-save"  onClick={onSave}>
             {type==="applyingGlossary"?"Cancel":"Save Changes"}
            </button>
          )}
          <button className="alertDialog-close" onClick={onDiscard}>
            {type==="applyingGlossary"?"Continue":"Discard Changes"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AlertDialog;
