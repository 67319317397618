import React, { useEffect, useRef, useState } from "react";
import {
  AiOutlineFolder,
  AiOutlineClose,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { MdUpload } from "react-icons/md";
import "./FileUploadInput.scss";

const FileUploadInput = ({
  onChange,
  placeholder,
  formSubmitted,
  onDragDrop,
  clearInputField,
  uploadFileError
}) => {
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log("from FileUpl", selectedFile);
    setFileName(selectedFile ? selectedFile.name : "");
    setFile(selectedFile);
    onChange(selectedFile);
  };
  console.log("filee_out", file);
  const handleClearFile = () => {
    setFile(null);
    setFileName("");
    clearInputField();
  };

  const handleButtonClick = () => {
    if (!file) {
      fileInputRef.current.click();
    }
    // fileInputRef.current.click();
  };

  const handleIconClick = () => {
    handleButtonClick();
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const selectedFile = event.dataTransfer.files[0];
    if (
      selectedFile &&
      (selectedFile.type === "application/pdf" ||
        selectedFile.type === "application/msword" ||
        selectedFile.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    ) {
      setFileName(selectedFile.name);
      setFile(selectedFile);
      onChange(selectedFile);
    } else {
      onDragDrop();
      setFileName("");
      setFile(null);
    }
  };

  useEffect(() => {
    if (formSubmitted) {
      setFileName("");
    }
  }, [formSubmitted]);

  return (
    <div
      className={`file-upload-input ${isDragging ? "dragging" : ""}`}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div style={{borderColor:uploadFileError?"rgb(211,47,47)":"#1e1c80"}} className="input-wrapper">
        <input
          type="text"
          className="file-input"
          placeholder={placeholder}
          value={fileName}
          readOnly
          onClick={handleButtonClick}
        />
        {file && (
          <AiOutlineCloseCircle
            className="clear-icon"
            onClick={handleClearFile}
          />
        )}
        {!file && (
          <div className="icon-container" onClick={handleIconClick}>
            {/* <AiOutlineFolder className="folder-icon" />
            <span>|</span>
            <MdUpload className="upload-icon" /> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="27"
              viewBox="0 0 27 27"
              fill="none"
            >
              <mask
                id="mask0_878_411"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="27"
                height="27"
              >
                <rect width="27" height="27" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_878_411)">
                <path
                  d="M4.64062 22.2188C4.06054 22.2188 3.56395 22.0122 3.15087 21.5991C2.73779 21.186 2.53125 20.6895 2.53125 20.1094V6.89062C2.53125 6.31054 2.73779 5.81395 3.15087 5.40087C3.56395 4.98779 4.06054 4.78125 4.64062 4.78125H11.3906L13.5 6.89062H22.3594C22.9395 6.89062 23.436 7.09717 23.8491 7.51025C24.2622 7.92333 24.4688 8.41991 24.4688 9V20.1094C24.4688 20.6895 24.2622 21.186 23.8491 21.5991C23.436 22.0122 22.9395 22.2188 22.3594 22.2188H4.64062ZM12.4453 18.8438H14.5547V14.2734L16.3688 16.0875L17.8594 14.5969L13.5 10.2375L9.14062 14.5969L10.6313 16.0875L12.4453 14.2734V18.8438Z"
                  fill={uploadFileError?"#D32F2F":"#3A37FF"}
                />
              </g>
            </svg>
          </div>
        )}
        {!file && (
          <input
            type="file"
            accept=".doc, .docx"
            className="hidden-input"
            onChange={handleFileChange}
            ref={fileInputRef}
          />
        )}
      </div>
      { uploadFileError && <p className="absolute left-[14px] top-[31px] text-[0.75rem] text-[rgb(211,47,47)]">
        {uploadFileError}
      </p>}
      <p className="upload-size">
        Upload document of up to 14 MB in DOC/DOCX format
      </p>
    </div>
  );
};

export default FileUploadInput;
