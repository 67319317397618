const apiUrls = {
  fileConversion: "/file_conversion",
  getPairs: "/get_pair_matched?unique_string=",
  googleApiTranslateDutchToEnglish: "/translate_list_og_language_to_target",
  getLatestParagraph: "/translate_latest_paragraph",
  downloadBooksLink: "/download_book",
  v2ExportDocument: "/v2/export/document",
  updatepairs: "/update_pairing_resource",
  getBooksList: `/get_unique_book_list?status="['Queued','Translating','Sending to RLEF','Completed','Error','Translated']"&type=Translation`,
  // getBooksList: `/get_unique_book_list?status="['Queued','Translating','Sending to RLEF','Completed','Error','Translated']"&type=`,
  getGlossaryItem: "/get_glossary_item",
  addKeyWordApiURL: "/add_glossary_item",
  applyGlossaryItemToBook: "/apply_glossary_item_to_book",
  getAssistants: "/egptassistants/",
  deleteBookData: "/delete_book_data/",
  mergeParagraphs: "/merge_paragraphs_of_book",
  getTranslation: "translation",
  updateTranslation: "update_translated_data",
  updateTranslation: "update_translated_data",
  findClosestMatch: "find_closest_match",
  replaceOverBook:"/v2/replace_word_in_complete_book",
  instantLearningToggle:"/v2/change_instant_learning_toggle",
  translateParticularChapters:"/translate_particular_chapters_in_book",
  serverTimestamp:"/v2/get_server_timestamp",
  appsecAuthSessionUrl:'https://appsecauth-ai.techo.camp/api/auth/session',
  searchOverBook:"/v2/find_word_in_complete_book",
  createGlossaryItem:"/glossary/create/",
  getBookGlossary:"/glossary/all/",
  updateBookGlossary:"/glossary/update/",
  deleteBookGlossary:"/glossary/delete/",
  applyGlossary:"/glossary/apply/",
  getGlossarySuggestion:"/v2/get_all_pending_applied_glossary_for_book",
 applySuggestedGlossary:"/v2/change_pending_glossary_status"

};

export default apiUrls;
