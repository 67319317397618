import React, { useState, useCallback, useEffect } from "react";
import ReactFlow, { addEdge, useNodesState, useEdgesState } from "reactflow";
import "./PostComponent.scss";
import "reactflow/dist/style.css";
import apiUrls from "../../api/apiUrl";
import useApi from "../../api/useAPi";
import likeIcon from "../../assets/Frame 1.svg";
import thumbUpFill from "../../assets/thumb_upfill.svg";
import thumbUpInActive from "../../assets/thumbinactive_up.svg";
import editIcon from "../../assets/Frame 4.svg";
import editIconInActive from "../../assets/editborderinactive_color.svg";
import rightArrow from "../../assets/rightArrow.svg";
import leftArrow from "../../assets/leftArrow.svg";
import ParagraphPairComponent from "../ParagraphPairComponent/ParagraphPairComponent";
import NodeSidebar from "../NodeSidebar/NodeSidebar";
import Loader from "../Loader/Loader";
import { useSearchParams } from "react-router-dom";

const onNodeDragStart = (event, node) => console.log("drag start", node);
const onNodeDragStop = (event, node) => console.log("drag stop", node);
const onPaneClick = (event) => console.log("onPaneClick", event);
const onPaneScroll = (event) => console.log("onPaneScroll", event);
const onPaneContextMenu = (event) => console.log("onPaneContextMenu", event);

const PostComponent = ({
  showEditPairing,
  onEditPairingClick,
  pairingDatas,
  sourceColumnHeader,
  targetColumnHeader,
  showViewAsEnglish,
}) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [selectedParagraphIndex, setSelectedParagraphIndex] = useState(null);
  const onConnect = useCallback((params) => {
    setEdges((els) => addEdge(params, els));
  }, []);
  

  const [isSelectable, setIsSelectable] = useState(true);
  const [isDraggable, setIsDraggable] = useState(false);
  const [isConnectable, setIsConnectable] = useState(true);
  const [zoomOnScroll, setZoomOnScroll] = useState(false);
  const [panOnScroll, setPanOnScroll] = useState(true);
  const [panOnScrollMode, setPanOnScrollMode] = useState("vertical");
  const [zoomOnDoubleClick, setZoomOnDoubleClick] = useState(false);
  const [panOnDrag, setpanOnDrag] = useState(false);
  const [captureZoomClick, setCaptureZoomClick] = useState(false);
  const [captureZoomScroll, setCaptureZoomScroll] = useState(false);
  const [captureElementClick, setCaptureElementClick] = useState(false);

  const [checkedParagraphs, setCheckedParagraphs] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    generateNodesAndEdges(selectedParagraphIndex);
  }, [selectedParagraphIndex]);

  const handleEditClick = (paragraphIndex) => {
    setSelectedParagraphIndex(paragraphIndex);
    onEditPairingClick();
  };

  const generateNodesAndEdges = (selectedParagraphIndexes) => {
    const newNodes = [];
    const newEdges = [];

    const resources = pairingDatas.auto_ai_resource.resources;

    let currentY = 60;
    const yOffsetChapter = 20;
    const yOffsetParagraph = 20;

    const containerWidth = document.querySelector(".container").clientWidth;
    const dutchPositionX = (50 / 100) * containerWidth;

    let lastSentenceY = currentY;
    resources.forEach((item) => {
      const chapterKey = item.chapter.sequenceNumber;
      const paragraphKey = item.paragraph.sequenceNumber;

      const englishParagraph = item.textOriginalLanguageSentences;
      const dutchParagraph = item.textTranslatedLanguageSentences;

      if (
        (Array.isArray(selectedParagraphIndexes) &&
          selectedParagraphIndexes.includes(item._id)) ||
        item._id === selectedParagraphIndexes
      ) {
        englishParagraph.forEach((englishSentenceData, index) => {
          const english = englishSentenceData.sentence.text;
          const dutch = dutchParagraph[index].sentence.text;

          const englishNodeId = `english-${englishSentenceData.sentenceId}`;
          const dutchNodeId = `dutch-${dutchParagraph[index].sentenceId}`;

          const englishNode = {
            id: englishNodeId,
            type: "input",
            data: { label: english },
            position: { x: 20, y: lastSentenceY },
            sourcePosition: "right",
            chapterKey: chapterKey,
            paragraphKey: paragraphKey,
            sentenceSequence: englishSentenceData.sequenceNumber,
            // style: { borderLeft: "15px solid #87B7FF" },
          };

          const dutchNode = {
            id: dutchNodeId,
            type: "output",
            data: { label: dutch },
            position: { x: dutchPositionX, y: lastSentenceY },
            targetPosition: "left",
            chapterKey: chapterKey,
            paragraphKey: paragraphKey,
            sentenceSequence: dutchParagraph[index].sequenceNumber,
            // style: { borderRight: "15px solid #FF9ABB;" },
          };

          // const edge = {
          //   id: `interaction-e-${chapterKey}-${paragraphKey}-${index}`,
          //   source: englishNodeId,
          //   target: dutchNodeId,
          //   style: { stroke: "", strokeWidth: 3 },
          // };

          newNodes.push(englishNode);
          newNodes.push(dutchNode);
          // newEdges.push(edge);

          lastSentenceY += 100;
        });

        lastSentenceY += yOffsetParagraph;
      }
    });

    resources.forEach((item) => {
      const chapterKey = item.chapter.sequenceNumber;
      const paragraphKey = item.paragraph.sequenceNumber;

      const dutchParagraph = item.textTranslatedLanguageSentences;

      dutchParagraph.forEach((dutchSentenceData) => {
        const targetSentenceId = dutchSentenceData.sentenceId;
        const sources =
          dutchSentenceData.connectedWithOriginalLanguageTextSentenceIds.map(
            (sourceItem) => sourceItem.sentenceId
          );

        sources.forEach((sourceSentenceId) => {
          const sourceNodeId = `english-${sourceSentenceId}`;
          const targetNodeId = `dutch-${targetSentenceId}`;

          const edge = {
            id: `interaction-e-${chapterKey}-${paragraphKey}-${sourceSentenceId}-${targetSentenceId}`,
            source: sourceNodeId,
            target: targetNodeId,
            style: { stroke: "", strokeWidth: 3 },
          };

          newEdges.push(edge);
        });
      });
    });

    currentY = lastSentenceY + yOffsetChapter;

    setNodes(newNodes);
    setEdges(newEdges);
  };

  const handleMutipleParagraph = (paragraphs) => {
    setSelectedParagraphIndex(paragraphs);
    onEditPairingClick();
  };

  const handleCheckboxChange = (paragraphNumber) => {
    if (checkedParagraphs.includes(paragraphNumber)) {
      setCheckedParagraphs(
        checkedParagraphs.filter((p) => p !== paragraphNumber)
      );
    } else {
      setCheckedParagraphs([...checkedParagraphs, paragraphNumber]);
    }
  };

  const chapters = pairingDatas.auto_ai_resource.resources.reduce(
    (chapters, resource) => {
      const chapterNumber = resource.chapter.sequenceNumber;
      const paragraphNumber = resource.paragraph.sequenceNumber;
      const chapterKey = `Chapter ${chapterNumber}`;

      if (!chapters[chapterKey]) {
        chapters[chapterKey] = {};
      }

      if (!chapters[chapterKey][paragraphNumber]) {
        chapters[chapterKey][paragraphNumber] = [];
      }

      chapters[chapterKey][paragraphNumber].push(resource);

      return chapters;
    },
    {}
  );

  const chapterKeys = Object.keys(chapters);
  const totalParagraphs = chapterKeys.reduce((total, chapterKey) => {
    const paragraphs = chapters[chapterKey];
    return total + Object.keys(paragraphs).length;
  }, 0);

  const maxPages = Math.ceil(totalParagraphs / 7);

  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * 7;
  const endIndex = Math.min(startIndex + 7, totalParagraphs);

  const handleNextClick = () => {
    const pairedBookId = queryParameters.get("pairedBookId");
    if (currentPage < maxPages) {
      setCurrentPage(currentPage + 1);
      let pageNumber = currentPage + 1;
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?pairedBookId=" +
        pairedBookId +
        "&&pageNumber=" +
        pageNumber;

      window.history.pushState({ path: newurl }, "", newurl);
    }
  };

  const handlePreviousClick = () => {
    const pairedBookId = queryParameters.get("pairedBookId");
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      let pageNumber = currentPage - 1;
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?pairedBookId=" +
        pairedBookId +
        "&&pageNumber=" +
        pageNumber;

      window.history.pushState({ path: newurl }, "", newurl);
    }
  };

  const [isViewAsEnglishChecked, setIsViewAsEnglishChecked] = useState(false);
  const { fetchData } = useApi();

  const handleViewAsEnglishToggle = () => {
    setIsViewAsEnglishChecked((prevValue) => !prevValue);
  };
  const [translatedSentences, setTranslatedSentences] = useState({});

  const handleDutchToEnglishTranslate = async () => {
    try {
      const selectedIndexes = Array.isArray(selectedParagraphIndex)
        ? selectedParagraphIndex
        : [selectedParagraphIndex];

      for (const selectedIndex of selectedIndexes) {
        const selectedChapterId = selectedIndex;

        const selectedChapter = pairingDatas.auto_ai_resource.resources.find(
          (chapter) => chapter._id === selectedChapterId
        );

        const selectedChapterKey = selectedChapter?.chapter.sequenceNumber;
        const selectedParagraphKey = selectedChapter?.paragraph.sequenceNumber;

        const dutchValues =
          selectedChapter?.textTranslatedLanguageSentences.map(
            (sentence) => sentence.sentence.text
            //   {
            //   dutch: sentence.sentence.text,
            //   sentence_no: sentence.sequenceNumber,
            // }
          );

        const translateRequest = {
          sentences: dutchValues,
          originaLanguage: "dutch",
          targetLanguage: "english",
          target_language_unicode: "en",
        };
        try {
          if (isViewAsEnglishChecked) {
            const res = await fetchData(
              apiUrls.googleApiTranslateDutchToEnglish,
              "POST",
              translateRequest
            );

            // const translatedParagraph =
            //   res[`Chapter ${selectedChapterKey}`][
            //     `Paragraph ${selectedParagraphKey}`
            //   ];
            const translatedParagraph = res.sentences;

            setTranslatedSentences((prevTranslations) => ({
              ...prevTranslations,
              translatedParagraph,
              // [selectedChapterKey]: {
              //   ...prevTranslations[selectedChapterKey],
              //   [selectedParagraphKey]: translatedParagraph.sentence,
              // },
            }));
          }
        } catch (err) {
          console.log(err);
        }
      }

      if (!isViewAsEnglishChecked) {
        for (const selectedIndex of selectedIndexes) {
          const selectedChapterId = selectedIndex;

          const selectedChapter = pairingDatas.auto_ai_resource.resources.find(
            (chapter) => chapter._id === selectedChapterId
          );

          const selectedChapterKey = selectedChapter?.chapter.sequenceNumber;
          const selectedParagraphKey =
            selectedChapter?.paragraph.sequenceNumber;

          setTranslatedSentences((prevTranslations) => ({
            ...prevTranslations,
            [selectedChapterKey]: {
              ...prevTranslations[selectedChapterKey],
              [selectedParagraphKey]: null,
            },
          }));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleDutchToEnglishTranslate();
  }, [isViewAsEnglishChecked, nodes]);

  const [hoveredNode, setHoveredNode] = useState(null);
  const [hoveredDutchNode, setHoveredDutchNode] = useState(null);
  const [showOriginalDutch, setShowOriginalDutch] = useState(false);
  const [hoveredNodePosition, setHoveredNodePosition] = useState({
    x: 0,
    y: 0,
  });

  const updateTheNodeConnectionConfirmPair = async () => {
    const matchingResources = pairingDatas.auto_ai_resource.resources.filter(
      (resource) =>
        Array.isArray(selectedParagraphIndex)
          ? selectedParagraphIndex.includes(resource._id)
          : resource._id === selectedParagraphIndex
    );

    if (matchingResources.length > 0) {
      matchingResources.forEach((matchingResource) => {
        const connectedSentences =
          matchingResource.textTranslatedLanguageSentences;

        const connectedSourcesMap = new Map();

        edges.forEach((edge) => {
          const targetId = edge.target.split("-")[1];
          const sourceId = edge.source.split("-")[1];

          if (!connectedSourcesMap.has(targetId)) {
            connectedSourcesMap.set(targetId, []);
          }

          connectedSourcesMap.get(targetId).push(sourceId);
        });

        connectedSentences.forEach((sentence) => {
          const targetId = sentence.sentenceId;
          const connectedSources = connectedSourcesMap.get(targetId);

          if (connectedSources) {
            sentence.connectedWithOriginalLanguageTextSentenceIds =
              connectedSources.map((sourceId) => ({ sentenceId: sourceId }));
          } else {
            sentence.connectedWithOriginalLanguageTextSentenceIds = [];
          }
        });

        matchingResource.status = "approval";
      });

      const updateData = {
        author_name: pairingDatas.author_name,
        auto_ai_resource: {
          resources: matchingResources,
        },
        book_title: pairingDatas.book_title,
        dutch_file_name: pairingDatas.dutch_file_name,
        english_file_name: pairingDatas.english_file_name,
        pair_style: pairingDatas.pair_style,
        pair_type: pairingDatas.pair_type,
        unique_string: pairingDatas.unique_string,
      };

      try {
        const res = await fetchData(apiUrls.updatepairs, "POST", updateData);

        onEditPairingClick();
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log("No matching resource found for the selectedParagraphIndex.");
    }
  };

  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedConnectedNodes, setSelectedConnectedNodes] = useState([]);

  const onNodeClick = (event, node) => {
    const connectedNodes = edges
      .filter((edge) => edge.source === node.id || edge.target === node.id)
      .map((edge) => {
        if (edge.source === node.id) {
          return nodes.find((n) => n.id === edge.target);
        } else {
          return nodes.find((n) => n.id === edge.source);
        }
      });

    const selectedColor = "#ebfefe";
    const defaultColor = "white";

    const updatedNodes = nodes.map((n) => {
      if (n.id === node.id || connectedNodes.some((cn) => cn.id === n.id)) {
        return {
          ...n,
          data: {
            ...n.data,
          },
          style: {
            background: selectedColor,
            // width: 130 * 4.7,
          },
        };
      } else {
        return {
          ...n,
          data: {
            ...n.data,
          },
          style: {
            background: defaultColor,
            // width: 130 * 4.7,
          },
        };
      }
    });

    setNodes(updatedNodes);
    setSelectedNode(node);
    setSelectedConnectedNodes(connectedNodes);
  };

  const onCloseNodeSideBar = () => {
    const connectedNodes = edges
      .filter(
        (edge) =>
          edge.source === selectedNode.id || edge.target === selectedNode.id
      )
      .map((edge) => {
        if (edge.source === selectedNode.id) {
          return nodes.find((n) => n.id === edge.target);
        } else {
          return nodes.find((n) => n.id === edge.source);
        }
      });

    const selectedColor = "white";
    const defaultColor = "white";

    const updatedNodes = nodes.map((n) => {
      if (
        n.id === selectedNode.id ||
        connectedNodes.some((cn) => cn.id === n.id)
      ) {
        return {
          ...n,
          data: {
            ...n.data,
          },
          style: {
            background: selectedColor,
          },
        };
      } else {
        return {
          ...n,
          data: {
            ...n.data,
          },
          style: {
            background: defaultColor,
          },
        };
      }
    });

    setNodes(updatedNodes);
    setSelectedNode(null);
    setSelectedConnectedNodes(connectedNodes);
  };

  const updateNodeLabel = async (
    nodeId,
    selectedNodeLabel,
    connectedNodeLabels
  ) => {
    const updatedNodes = nodes.map((node) => {
      if (node.id === nodeId) {
        return {
          ...node,
          data: {
            ...node.data,
            label: selectedNodeLabel,
          },
          style: {
            background: "white",
          },
        };
      }
      return node;
    });

    const connectedNodeIds = Object.keys(connectedNodeLabels);

    updatedNodes.forEach((node) => {
      if (connectedNodeIds.includes(node.id)) {
        node.data.label = connectedNodeLabels[node.id];
        node.style.background = "white";
      }
    });

    const updatedEdges = edges.map((edge) => {
      if (edge.source === nodeId) {
        const targetNode = updatedNodes.find((node) => node.id === edge.target);
        if (targetNode) {
          const connectedNodeLabel = connectedNodeLabels[targetNode.id];
          return {
            ...edge,
            data: {
              ...edge.data,
              label: connectedNodeLabel,
            },
          };
        }
      }
      return edge;
    });

    setNodes(updatedNodes);
    setEdges(updatedEdges);
    setSelectedNode(null);

    const updatedResources = updatedNodes.map((node) => {
      const sentenceId = node.id.startsWith("english-")
        ? node.id.slice("english-".length)
        : node.id.startsWith("dutch-")
        ? node.id.slice("dutch-".length)
        : null;

      if (sentenceId) {
        const resourcesToUpdate =
          pairingDatas.auto_ai_resource.resources.filter((res) =>
            selectedParagraphIndex.includes(res._id)
          );

        resourcesToUpdate.forEach((resource) => {
          if (node.id.startsWith("english-")) {
            const sentenceToUpdate =
              resource.textOriginalLanguageSentences.find(
                (sentence) => sentence.sentenceId === sentenceId
              );
            if (sentenceToUpdate) {
              sentenceToUpdate.sentence.text = node.data.label;
            }
          } else if (node.id.startsWith("dutch-")) {
            const sentenceToUpdate =
              resource.textTranslatedLanguageSentences.find(
                (sentence) => sentence.sentenceId === sentenceId
              );
            if (sentenceToUpdate) {
              sentenceToUpdate.sentence.text = node.data.label;
            }
          }
        });

        return resourcesToUpdate;
      }
      return null;
    });

    const validUpdatedResources = Array.from(
      new Set(updatedResources.flat())
    ).filter((resource) => resource);

    const updateData = {
      author_name: pairingDatas.author_name,
      auto_ai_resource: {
        resources: validUpdatedResources,
      },
      book_title: pairingDatas.book_title,
      dutch_file_name: pairingDatas.dutch_file_name,
      english_file_name: pairingDatas.english_file_name,
      pair_style: pairingDatas.pair_style,
      pair_type: pairingDatas.pair_type,
      unique_string: pairingDatas.unique_string,
    };

    try {
      const res = await fetchData(apiUrls.updatepairs, "POST", updateData);
      console.log("res", res);
    } catch (err) {
      console.log(err);
    }
  };

  const [queryParameters] = useSearchParams();

  useEffect(() => {
    const pageNumber = queryParameters.get("pageNumber");

    setCurrentPage(Number(pageNumber));
  }, []);

  const updateStatusPairing = async (paragraphId) => {
    const resourcesToUpdate = pairingDatas.auto_ai_resource.resources;
    const updatedResources = [];

    resourcesToUpdate.forEach((resource) => {
      if (paragraphId.includes(resource._id)) {
        resource.status = "approval";
        updatedResources.push(resource);
      }
    });

    const updateData = {
      author_name: pairingDatas.author_name,
      auto_ai_resource: {
        resources: updatedResources,
      },
      book_title: pairingDatas.book_title,
      dutch_file_name: pairingDatas.dutch_file_name,
      english_file_name: pairingDatas.english_file_name,
      pair_style: pairingDatas.pair_style,
      pair_type: pairingDatas.pair_type,
      unique_string: pairingDatas.unique_string,
    };

    try {
      const res = await fetchData(apiUrls.updatepairs, "POST", updateData);
      console.log("res", res);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="container">
      <div className="text-header">
        <div style={{ width: "40%" }}>{sourceColumnHeader}</div>
        {!showEditPairing && (
          <div className="pagination">
            <img
              src={leftArrow}
              alt="left-arrow"
              onClick={handlePreviousClick}
              className="arrow-icon"
            />
            &nbsp;&nbsp;
            <div className="pagination-content">
              <p className="pagination-page-count">
                {currentPage}/{maxPages}
              </p>
              <p className="pagination-page">Page</p>
            </div>
            &nbsp;&nbsp;
            <img
              src={rightArrow}
              alt="right-arrow"
              onClick={handleNextClick}
              className="arrow-icon"
            />
          </div>
        )}
        <div style={{ width: "40%" }}>{targetColumnHeader}</div>
        {!showEditPairing && (
          <div className="icon-header">
            {checkedParagraphs?.length > 0 ? (
              <img
                src={likeIcon}
                alt="approve"
                onClick={updateTheNodeConnectionConfirmPair}
                className="like-icon"
              />
            ) : (
              <img src={thumbUpInActive} alt="approve" className="like-icon " />
            )}

            {checkedParagraphs?.length > 0 ? (
              <img
                src={editIcon}
                onClick={() => handleMutipleParagraph(checkedParagraphs)}
                alt="EditActive"
                className="like-icon"
              />
            ) : (
              <img
                src={editIconInActive}
                alt="EditActive"
                className="like-icon"
              />
            )}
          </div>
        )}
        {showEditPairing && (
          <div className="toogle-container">
            {/* <label className="switch">
              <input
                type="checkbox"
                onChange={handleViewAsEnglishToggle}
                checked={isViewAsEnglishChecked}
              />
              <span className="slider round"></span>
            </label> */}
            {/* <span className="toogleLabel"> View as English</span> */}
          </div>
        )}
      </div>

      <div className="line"></div>

      {!showEditPairing && (
        <ParagraphPairComponent
          chapterKeys={chapterKeys}
          startIndex={startIndex}
          endIndex={endIndex}
          onEditClick={handleEditClick}
          onClickMutipleParagraph={handleMutipleParagraph}
          handleCheckboxChange={handleCheckboxChange}
          checkedParagraphs={checkedParagraphs}
          pairingData={pairingDatas}
          showViewAsEnglish={showViewAsEnglish}
          updateStatusPairing={updateStatusPairing}
          pairingEdges={edges}
        />
      )}
      {showEditPairing &&
        (isLoading ? (
          <Loader />
        ) : (
          <ReactFlow
            // nodes={nodes.map((node) => {
            //   const nodeParts = node.id.split("-");
            //   const language = nodeParts[0];
            //   const chapterKey = nodeParts[1];
            //   const paragraphKey = nodeParts[2];

            //   const translatedSentencesForChapter =
            //     translatedSentences[chapterKey] || {};
            //   const translatedSentencesForParagraph =
            //     translatedSentencesForChapter[paragraphKey] || [];

            //   const translatedLabel =
            //     isViewAsEnglishChecked && language === "dutch"
            //       ? translatedSentencesForParagraph[nodeParts[3] || 0]
            //           ?.english || node.data.label
            //       : node.data.label;

            //   return {
            //     ...node,
            //     data: {
            //       ...node.data,
            //       label: translatedLabel,
            //     },
            //   };
            // })}
            nodes={nodes.map((node, index) => {
              const [language] = node.id.split("-");
              // translatedSentences?.translatedParagraph[index] !== undefined &&
              // console.log(
              //   "translatedSentences?.translatedParagraph[index]",
              //   translatedSentences?.translatedParagraph,
              //   index
              // );
              const sentenceTranslation =
                isViewAsEnglishChecked &&
                language === "dutch" &&
                translatedSentences?.translatedParagraph !== undefined
                  ? translatedSentences?.translatedParagraph[index] || null
                  : null;

              return {
                ...node,
                data: {
                  ...node.data,
                  label: sentenceTranslation || node.data.label,
                },
              };
            })}
            // onNodeMouseEnter={(event, node) => {
            //   const hoveredEnglishLabel = node.id;
            //   setHoveredNode(hoveredEnglishLabel);

            //   if (hoveredEnglishLabel.startsWith("dutch-")) {
            //     const englishParts = hoveredEnglishLabel.split("-");
            //     const chapterKey = englishParts[1];
            //     const paragraphKey = englishParts[2];
            //     const sentenceIndex = parseInt(englishParts[3]);

            //     const selectedChapter =
            //       pairingDatas.auto_ai_resource.resources.find(
            //         (chapter) =>
            //           chapter.chapter.sequenceNumber === Number(chapterKey) &&
            //           chapter.paragraph.sequenceNumber === Number(paragraphKey)
            //       );

            //     const dutchParagraph =
            //       selectedChapter.textTranslatedLanguageSentences.map(
            //         (sentence) => sentence.sentence.text
            //       );

            //     const hoveredDutchLabel = dutchParagraph[sentenceIndex];

            //     setHoveredDutchNode(hoveredDutchLabel);
            //     setShowOriginalDutch(true);

            //     const nodePosition = {
            //       x: node.position.x,
            //       y: node.position.y,
            //     };
            //     console.log("nodePosition", nodePosition);
            //     setHoveredNodePosition(nodePosition);
            //   }
            // }}
            // onNodeMouseLeave={() => {
            //   setHoveredNode(null);
            //   setHoveredDutchNode(null);
            //   setShowOriginalDutch(false);
            // }}
            edges={[...edges]}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            elementsSelectable={isSelectable}
            nodesConnectable={isConnectable}
            nodesDraggable={isDraggable}
            zoomOnScroll={zoomOnScroll}
            panOnScroll={panOnScroll}
            panOnScrollMode={panOnScrollMode}
            zoomOnDoubleClick={zoomOnDoubleClick}
            onConnect={onConnect}
            // onNodeClick={captureElementClick ? onNodeClick : undefined}
            onNodeDragStart={onNodeDragStart}
            onNodeDragStop={onNodeDragStop}
            panOnDrag={panOnDrag}
            onPaneClick={captureZoomClick ? onPaneClick : undefined}
            onPaneScroll={captureZoomScroll ? onPaneScroll : undefined}
            onPaneContextMenu={captureZoomClick ? onPaneContextMenu : undefined}
            attributionPosition="top-right"
            onNodeClick={onNodeClick}
          >
            {nodes.map((node, index) => (
              <div key={index}>
                {index === 0 && (
                  <div className="chap-para-reactflow-header">
                    <div>Chapter {node.chapterKey}</div>
                    <div> Paragraph {node.paragraphKey}</div>
                  </div>
                )}
              </div>
            ))}
            {showOriginalDutch && isViewAsEnglishChecked && (
              <div
                className="extra-div"
                style={{
                  position: "absolute",
                  top: hoveredNodePosition.y - 60,
                  left: hoveredNodePosition.x,
                }}
              >
                <div>{hoveredDutchNode}</div>
              </div>
            )}
            {selectedNode && (
              <NodeSidebar
                selectedNode={selectedNode}
                onClose={
                  onCloseNodeSideBar
                  // () => setSelectedNode(null)
                }
                onNodeUpdate={updateNodeLabel}
                connectedNodes={selectedConnectedNodes}
              />
            )}
            <button
              onClick={updateTheNodeConnectionConfirmPair}
              className="confirm-post-comp"
            >
              Confirm Button
            </button>
          </ReactFlow>
        ))}
    </div>
  );
};

export default PostComponent;
