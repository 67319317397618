
import { Plugin } from "@ckeditor/ckeditor5-core";
import { ButtonView } from "@ckeditor/ckeditor5-ui";

import scrollLockIcon from "../assets/scroll-lock.svg";
import scrollUnlockIcon from "../assets/scroll-unlock.svg";


class ToggleScroll extends Plugin {
    isToggled=false
    init() {
      const editor = this.editor;
      const toggleScroll = (editor.config.get("toggleScroll") as any)
        .toggleScroll as any;
      editor.ui.componentFactory.add("toggleScrollButton", () => {
        const button = new ButtonView();
  
        button.set({
          withText: false,
          class: "plugin-button",
          tooltip: this.isToggled?"Lock scroll":"Unlock scroll",
          icon: this.isToggled?scrollUnlockIcon:scrollLockIcon,
        });
  
        // Execute a callback function when the button is clicked.
        button.on("execute", () => {
         this.isToggled=!this.isToggled
         button.set({
            withText: false,
            class: "plugin-button",
            tooltip: this.isToggled?"Lock scroll":"Unlock scroll",
            icon: this.isToggled?scrollUnlockIcon:scrollLockIcon,
          });
          toggleScroll(editor,this.isToggled);
        });
  
        return button;
      });
    }
  }

export default ToggleScroll