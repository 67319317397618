import React, { useEffect, useState } from "react";
import "./ChapterTranslationStatusBox.scss";
import DialogBox from "../DialogBox/DialogBox";
import { ReactComponent as AlertIcon } from "../../assets/alertIcon.svg";

function ChapterTranslationStatusBox({
  currentChapter,
  chaptersTranslationStatusList,
  translationId,
  translateChapter,
  setChaptersTranslationStatusList,
  toggleDialogBox,
  handleChapterChange,
  translateEditor
}) {

  
  useEffect(() => {
    if(translateEditor){
      translateEditor.enableReadOnlyMode(translateEditor.id)
      return ()=> translateEditor.disableReadOnlyMode(translateEditor.id)
    }
  }, [translateEditor])
  
   
  const handleTranslateNowButton = () => {
    translateChapter(translationId, currentChapter);
    setChaptersTranslationStatusList((prev) => {
      return { ...prev, [currentChapter]: "translation_in_progress" };
    });
    toggleDialogBox();
  };

  const handleReviewPreviousChapterButton = () => {
    let prevKey = "1";
    for (const chapter in chaptersTranslationStatusList) {
      const status = chaptersTranslationStatusList[chapter].status;
      if (status === "not_translated" || status === "translation_in_progress") {
        break;
      }
      prevKey = chapter;
    }
    console.log("chapterChange :::", prevKey);
    handleChapterChange(Number(prevKey));
  };

  return (
    <div
      className="absolute flex justify-center bg-white"
      id="notTranslatedBox"
    >
      {chaptersTranslationStatusList[currentChapter]?.status ===
      "not_translated" ? (
        <div className="flex flex-col  mt-20 gap-3 items-center w-[80%]">
          <p className="boxHeading">
            <AlertIcon /> Pending Review for previous chapter/s
          </p>
          <p className="boxPara">
            Review previous chapters or Generate this chapter now!
          </p>
          <button
            onClick={handleTranslateNowButton}
            className="translateNowButton"
          >
            Translate Now!
          </button>
          <button
            className="ReviewPreviousButtonChapter"
            onClick={handleReviewPreviousChapterButton}
          >
            Review Previous Chapters
          </button>
        </div>
      ) : (
        <p className="pt-14">Translating...</p>
      )}
    </div>
  );
}

export default ChapterTranslationStatusBox;
