import { forwardRef } from "react";
import cx from "clsx";

import styles from "./Switch.module.scss";

const Switch = forwardRef(({ id, label, disabled ,className,...restProps }, ref) => {
  const renderSwitch = () => {
    return (
      <label className={cx(styles.toggle, disabled && styles.toggleDisabled)} onclick="">
        <input
          type="checkbox"
          className={cx(styles.toggleCheckBox, className)}
          ref={ref}
          disabled={disabled}
          {...restProps}
        />
        <div className={styles.toggleSwitch}></div>
        <span className={styles.toggleLabel}>{label}</span>
      </label>
    );
  };

  return renderSwitch();
});

Switch.displayName = "Switch";


export default Switch;
