import { Plugin } from "@ckeditor/ckeditor5-core";
import { ButtonView } from "@ckeditor/ckeditor5-ui";

import downloadIcon from "../assets/downloadIcon.svg";

class DownloadButton extends Plugin {
  init() {
    const editor = this.editor;

    const utils = editor.config.get("utils") as any;

    editor.ui.componentFactory.add("downloadTranslationsButton", () => {
      const button = new ButtonView();

      button.set({
        label: "Download",
        withText: true,
        tooltip: "Download translation",
        class: "plugin-button",
        icon: downloadIcon,
      });

      // Execute a callback function when the button is clicked.
      button.on("execute", async () => {
        button.isEnabled = false;
        await utils.downloadTranslation(editor);
        button.isEnabled = true;
      });

      return button;
    });
  }
}

export default DownloadButton;