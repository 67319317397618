
import { Plugin } from "@ckeditor/ckeditor5-core";
import { ButtonView } from "@ckeditor/ckeditor5-ui";

const glossaryIcon=`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M15.8333 2.70833H5.62501C5.08928 2.69261 4.56917 2.88991 4.17866 3.25699C3.78815 3.62407 3.55909 4.13099 3.54167 4.66666V15C3.56341 15.6285 3.83356 16.2228 4.29286 16.6524C4.75215 17.082 5.36309 17.3119 5.99167 17.2917H15.8333C15.9984 17.2895 16.1561 17.223 16.2729 17.1062C16.3896 16.9895 16.4562 16.8318 16.4583 16.6667V3.33333C16.4562 3.16824 16.3896 3.01052 16.2729 2.89378C16.1561 2.77703 15.9984 2.71049 15.8333 2.70833ZM15.2083 16.0417H5.99167C5.69449 16.0623 5.40127 15.9642 5.17631 15.7689C4.95135 15.5737 4.81302 15.2971 4.79167 15C4.81302 14.7029 4.95135 14.4263 5.17631 14.2311C5.40127 14.0358 5.69449 13.9377 5.99167 13.9583H15.2083V16.0417ZM15.2083 12.7083H5.99167C5.57214 12.7088 5.15946 12.8148 4.79167 13.0167V4.66666C4.81076 4.46324 4.90857 4.27532 5.06425 4.14299C5.21994 4.01066 5.42116 3.9444 5.62501 3.95833H15.2083V12.7083Z" fill="#1E1C80"/>
  <path d="M7.29167 7.29166H12.7083C12.8741 7.29166 13.0331 7.22581 13.1503 7.1086C13.2675 6.99139 13.3333 6.83242 13.3333 6.66666C13.3333 6.5009 13.2675 6.34192 13.1503 6.22471C13.0331 6.1075 12.8741 6.04166 12.7083 6.04166H7.29167C7.12591 6.04166 6.96694 6.1075 6.84973 6.22471C6.73252 6.34192 6.66667 6.5009 6.66667 6.66666C6.66667 6.83242 6.73252 6.99139 6.84973 7.1086C6.96694 7.22581 7.12591 7.29166 7.29167 7.29166ZM7.29167 10.2083H12.7083C12.8741 10.2083 13.0331 10.1425 13.1503 10.0253C13.2675 9.90805 13.3333 9.74908 13.3333 9.58332C13.3333 9.41756 13.2675 9.25859 13.1503 9.14138C13.0331 9.02417 12.8741 8.95832 12.7083 8.95832H7.29167C7.12591 8.95832 6.96694 9.02417 6.84973 9.14138C6.73252 9.25859 6.66667 9.41756 6.66667 9.58332C6.66667 9.74908 6.73252 9.90805 6.84973 10.0253C6.96694 10.1425 7.12591 10.2083 7.29167 10.2083Z" fill="#1E1C80"/>
</svg>`


class ToggleGlossary extends Plugin {
    isToggled=false
    init() {
      const editor = this.editor;
      const toggleGlossary = (editor.config.get("toggleGlossary") as any)
        .toggleGlossary as any;
      editor.ui.componentFactory.add("toggleGlossaryButton", () => {
        const button = new ButtonView();
  
        button.set({
          label: "Glossary",
          withText: true,
          class: "plugin-button",
        //   tooltip: this.isToggled?"Lock scroll":"Unlock scroll",
          icon: glossaryIcon,
          tooltip:"Glossary"
        });
  
        // Execute a callback function when the button is clicked.
        button.on("execute", () => {
         this.isToggled=!this.isToggled
         button.set({
            label: "Glossary",
            withText: true,
            class: "plugin-button",
            icon: glossaryIcon,
            tooltip:"Glossary"
          });
          button.isOn = this.isToggled;
          toggleGlossary(editor,this.isToggled);
        });
  
        return button;
      });
    }
  }

export default ToggleGlossary