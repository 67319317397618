import React, { useEffect, useState, useRef, useCallback, use } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import css from "./TranslateBoxComponent.module.css";
import "./TranslateBoxComponent.scss";

import ReactMarkdown from 'react-markdown';

import TextareaComponent from "../TextareaComponent/TextareaComponent";
import likeIcon from "../../assets/Frame 1.svg";
import marker from "../../assets/Frame 4.svg";
import deleteIcon from "../../assets/deleteIcon.svg";
import addComment from "../../assets/add_comment.svg";
import palette from "../../assets/palette.svg";
import comapreIcon from "../../assets/compare.svg";
import closeIcon from "../../assets/close-icon.svg";
import tickIcon from "../../assets/tick-icon.svg";
import checkedCircle from "../../assets/check_circle.svg";
import issueIcon from "../../assets/issuelist.svg";
import mergeIcon from "../../assets/mergeIcon.svg";
import regenerateIcon from "../../assets/regenerateIcon.svg";
import splitIcon from "../../assets/splitIcon.svg";
import download from "../../assets/download_icon.svg";
import regenerate from "../../assets/translation refresh icon.svg";
import compareIcon from "../../assets/icon_compare.svg";

import apiUrls from "../../api/apiUrl";
import useApi from "../../api/useAPi";
import Loader from "../../Components/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RangeSlider from "../Slider/RangeSlider";
import PostComponent from "../PostComponent/PostComponent";
import MergeComponent from "../MergeComponent/mergeComponent";
import paginationTypes from "../../Constants/PaginationTypes";
import { Tooltip, Typography } from "@mui/material";
import { Button as MUIButton } from "@mui/material";

import Button from "../Button";
import Switch from "../Switch";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import {
  setGlossaryItem,
  setIsItemLoad,
  setPairedBookData,
} from "../../App/DashboardActions/action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { convertJsonData, findChangesToMatchBase } from "../../utils/getWords";
import {
  addKeywords,
  applyGlossaryToBook,
  setAddKeyWord,
  setGlossaryItemWithKeyword,
  setIsGlossaryCalledFalse,
} from "../../App/TranslationActions/action";
import {
  faCaretDown,
  faCaretUp,
  faCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import DOMPurify from "dompurify";
import { async } from "@firebase/util";
import { BorderStyle } from "@mui/icons-material";
import shortcuts from "../../Constants/Shortcuts";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";
import WordDropDown, { WordVariantPopover } from "../WordDropdown/WordDropdown";
import useStatus from "../../hooks/useStatus";

// import { Button, Typography } from "@mui/material";
import { set } from "firebase/database";
// import ParagraphCompareModel from "./ParagraphCompareModel/ParagraphCompareModel";

// import pairingData from "../../test.json";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TranslationEditCompent from "../TranslationEditComponent/TranslationEditCompent";
import { useSearchParams } from "react-router-dom";

function TranslateBoxComponent({
  index,
  chapterKey,
  paragraphKey,
  pairingData,
  paragraphsToShow,
  selectedLanguage,
  selectedModelName,
  selectedTemperature,
  showCompare,
  editingParagraphs,
  editingSentences,
  compareModeParagraphs,
  paletteModeParagraphs,
  showConfirmTranslation,
  editedContent,
  selectedComparisionModelName,
  selectedComparisionTemperature,
  historyVisible,
  sentenceEditMode,
  refs,
  isGlossaryVisible,
  colorCode,
  showEditPairing,
  mergeParaMode,
  mergeParagraphData,
  notify,
  setMergeParagraphData,
  textSize,
  isHoverOriginalParaId,
  calculateTextareaHeight,
  currentKeyWordWithVariant,
  loadingParagraphs,
  showViewAsEnglish,
  paragraphToggleStates,
  translatedDutchToEnglish,
  setEditedContent,
  handleCloseTranslatedText,
  loadingLatestParagraphs,
  sentenceEditFeedbackMode,
  feedbackContent,
  sentenceEditConfirm,
  handleClickOnDutchSentence,
  setIsOriginalParaId,
  handleParagraphHower,
  toggleEditFeedbackMode,
  toggleConfirmMode,
  toggleConfirmButton,
  adjustTextareaHeight,
  setEditingColumn,
  generateTextWithSpans,
  textAreaRef,
  handleChange,
  errorLatestParagraphs,
  updateLatestParagraph,
  automateTranslationFetchHandler,
  loadingComparisionParagraphs,
  loadingLatestComparisionParagraphs,
  updateLatestParagraphCompare,
  handleHistoryToggle,
  showDifference,
  sentenceColors,
  handleEditPairingClick,
  updateTheMatch,
  glossaryIndex,
  userEnteringGlossaryForVariantState,
  setEnableVariantInputBox,
  setUserEnteringGlossaryForVariantState,
  setCurrentKeywordWithVariant,
  setIsGlossaryVisible,
  handleGlossary,
  setbyPopupAddWordInGlossaryScenario,
  updateTheNodeConnectionConfirmPair,
  setFeedbackSelectorVisible,
  editingColumn,
  updateTheNodeConnectionConfirmPairComparision,
  setEditingSentences,
  setSentenceEditMode,
  showModelSelection,
  selectedModel,
  handleModelChange,
  assistantOptions,
  renderViewAsEnglishAction,
  loader,
  paragraphWords,
  byPopupAddWordInGlossaryScenario,
  setEnableInputBox,
  setParagraphWords,
  errorLatestComparisionParagraphs,
  handleCloseEditededText,
  glossaryItem,
  enableGlossaryWord,
  setEnableGlossaryWord,
  setWordVariant,
  wordVariant,
  setApplyGlossary,
  setSelectedVariant,
  enableInputBox,
  paraWord,
  setParaWrod,
  handleAddKeyWordOnEnter,
  enableVariantInputBox,
  setCurrentVarientText,
  setprevVariantText,
  currentVariant,
  addVariantToKeyword,
  prevVariantText,
  handleRadioChange,
  selectedVariant,
  applyGlossary,
  applyGlossaryToWholeBook,
}) {

  const [selectedEditorMode, setSelectedEditorMode] = useState("markdown")
  const [queryParameters] = useSearchParams();

   useEffect(() => {
    const editorMode = queryParameters.get("editorMode")
    console.log("edito mode", editorMode)
    setSelectedEditorMode(editorMode)
   }, [])
   

  

  const chapter = pairingData?.auto_ai_resource?.resources?.find(
    (chapter) =>
      chapter.chapter.sequenceNumber === chapterKey &&
      chapter.paragraph.sequenceNumber === paragraphKey
  );

  let sentencesToShow = [];
  const paragraphId = chapter?._id;
  const currParaToFetch = chapter;

  const currParaKey = `${
    paragraphsToShow[0]?.chapterKey + "-" + paragraphsToShow[0]?.paragraphKey
  }`;

  const translatedParagraph = chapter?.textTranslatedLanguageSentences
    ?.filter((sentence) => {
      return (
        sentence.target_language === selectedLanguage &&
        sentence.model_name === selectedModelName &&
        +sentence.temperature === +selectedTemperature
      );
    })
    .map((sentence) => sentence?.sentence?.text)
    .join(" ");

  if (paragraphKey == 1) console.log("ChapterChapter", chapter);

  let translatedParagraphCompare;
  if (showCompare) {
    translatedParagraphCompare = chapter?.textTranslatedLanguageSentences
      ?.filter((sentence) => {
        return (
          sentence.target_language === selectedLanguage &&
          sentence.model_name === selectedModelName &&
          +sentence.temperature === +selectedTemperature
        );
      })
      .map((sentence) => sentence?.sentence?.text)
      .join(" ");
  }

  // console.log("III", translatedParagraph)

  const isEditing = editingParagraphs[`${chapterKey}-${paragraphKey}`];
  const isEditingPara = editingSentences[`${chapterKey}-${paragraphKey}`];
  const isInCompareMode = compareModeParagraphs.includes(
    `${chapterKey}-${paragraphKey}`
  );

  const isInPaletteMode = paletteModeParagraphs.includes(
    `${chapterKey}-${paragraphKey}`
  );

  const isShowConfirmTranslation = showConfirmTranslation.includes(
    `${chapterKey}-${paragraphKey}`
  );

  const originalPairParagraph = chapter?.textOriginalLanguageSentences?.map(
    (sentence) => sentence?.sentence?.text
  );

  let translatedPairParagraph =
    chapter?.textTranslatedLanguageSentences?.filter(
      (sentence) =>
        sentence.target_language === selectedLanguage &&
        sentence.model_name === selectedModelName &&
        +sentence.temperature === +selectedTemperature
    );
  let paraTranslated = translatedPairParagraph;
  // console.log("www", translatedPairParagraph);
  translatedPairParagraph = translatedPairParagraph.map(
    (sentence) => sentence?.sentence?.text
  );

  const editedParagraph = paraTranslated
    ?.map(
      (sentence) =>
        editedContent[`${sentence?.sentenceId}`] || sentence?.sentence?.text
    )
    .join(" ");

  let translatedPairParagraphCompare;
  let paraTranslatedCompare;
  translatedPairParagraphCompare =
    chapter?.textTranslatedLanguageSentences?.filter(
      (sentence) =>
        sentence.target_language === selectedLanguage &&
        sentence.model_name === selectedComparisionModelName &&
        +sentence.temperature === +selectedComparisionTemperature
    );
  paraTranslatedCompare = translatedPairParagraphCompare;
  translatedPairParagraphCompare = translatedPairParagraphCompare.map(
    (sentence) => sentence?.sentence?.text
  );

  // console.log("www", translatedPairParagraph);

  const isHistoryVisible = historyVisible[`${chapterKey}-${paragraphKey}`];
  const predictedTranslatedContentForQuill = paraTranslated
    ?.map(
      (sentence) =>
        sentence?.sentence?.predictedText || sentence?.sentence?.text
    )
    .join(" ");

  const translatedContentForQuill = translatedPairParagraph?.join(" ");
  let isEditingSentencePara = sentenceEditMode?.includes(
    `${chapterKey}-${paragraphKey}`
  );

  // const isCompareHistoryVisible = historyVisible[`${chapterKey}-${paragraphKey}`];
  const predictedTranslatedContentForQuillCompare = paraTranslatedCompare
    ?.map(
      (sentence) =>
        sentence?.sentence?.predictedText || sentence?.sentence?.text
    )
    .join(" ");

  const translatedContentForQuillCompare =
    translatedPairParagraphCompare?.join(" ");
  return (
    <>
      {chapter?.textOriginalLanguageSentences.length > 0 && (
        <div
          ref={refs[paragraphKey]}
          key={`chapter-${chapterKey}-paragraph-${paragraphKey}`}
          style={{
            borderLeft:
              translatedPairParagraph?.[0] === ""
                ? "15px solid #C9C4C4"
                : chapter?.status !== "approved"
                ? chapter?.confidence_score === 100 ||
                  chapter?.confidence_score >= 60
                  ? "15px solid #3EB05E"
                  : "15px solid #3EB05E"
                : "15px solid #3EB05E",

            minHeight: isGlossaryVisible ? "149px" : "149px",
            backgroundColor: `${
              isEditingSentencePara ? colorCode : "rgba(255, 255, 255, 0.5)"
            } `,
          }}
          className="paragraph-translate"
        >
          {!(showEditPairing === chapter?._id) ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
              }}
            >
              <div className="paraNoWrapper">
                {!mergeParaMode && (
                  <div className="paraNoWrapper_content">{index + 1}</div>
                )}
              </div>
              <div className="checkbox">
                {typeof chapter?.status !== "object" &&
                  chapter?.status === "approved" &&
                  !mergeParaMode && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={checkedCircle} alt="checked" />
                    </div>
                  )}

                {typeof chapter?.status === "object" &&
                  chapter?.status[selectedLanguage] &&
                  chapter?.status[selectedLanguage][selectedTemperature] &&
                  selectedTemperature &&
                  chapter.status[selectedLanguage][selectedTemperature] ===
                    "approved" &&
                  !mergeParaMode && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={checkedCircle} alt="checked" />
                    </div>
                  )}

                {mergeParaMode &&
                  !(
                    translatedPairParagraph?.length === 0 ||
                    !translatedPairParagraph[0]
                  ) && (
                    <div
                      style={{
                        width: "16px",
                        height: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: "black",
                        backgroundColor:
                          mergeParagraphData?.length > 0 &&
                          mergeParagraphData.includes(index)
                            ? "#3b37ff"
                            : "white",
                      }}
                      onClick={() => {
                        const nextPara =
                          pairingData?.auto_ai_resource?.resources[index + 1];
                        if (
                          nextPara === undefined ||
                          nextPara?.textTranslatedLanguageSentences[0]?.sentence
                            ?.text === ""
                        ) {
                          notify(
                            "Generate the next paragraph's translation to merge them together."
                          );
                          return;
                        }
                        if (mergeParagraphData?.length == 2) {
                          setMergeParagraphData([]);
                        } else {
                          setMergeParagraphData([index, index + 1]);
                        }
                      }}
                    >
                      {/* <img src={checkedCircle} alt="checked" /> */}
                    </div>
                  )}
              </div>
              <div
                className={
                  !isInPaletteMode
                    ? "english-paragraph"
                    : "english-paragraph-inPalatteMode"
                }
              >
                {isInPaletteMode && <div className="title-header">ENGLISH</div>}
                {!isInPaletteMode && (
                  <>
                    <p className="paragraph-sentence">
                      {chapter?.textOriginalLanguageSentences?.map((d, i) => (
                        <>
                          {!isEditingSentencePara ? (
                            <span
                              style={{
                                fontSize: textSize + "px",
                                backgroundColor:
                                  isHoverOriginalParaId?.[0]?.sentenceId ===
                                  d?.sentenceId
                                    ? "#d7d7ff"
                                    : "",
                              }}
                            >
                              {d.sentence.text}
                            </span>
                          ) : (
                            <textarea
                              key={i}
                              className="textarea"
                              id={`${chapterKey}-${paragraphKey}-${i}`}
                              value={d.sentence.text}
                              style={{
                                height: calculateTextareaHeight(
                                  d.sentence.text
                                ),
                                backgroundColor:
                                  isHoverOriginalParaId?.[0]?.sentenceId ===
                                  d?.sentenceId
                                    ? "#d7d7ff"
                                    : "#FFFFE5",
                                //  backgroundColor:"#FFFFE5",
                                fontSize: "16px",
                                overflow: "hidden",
                                textAlign: "justify",
                              }}
                            />
                          )}
                        </>
                      ))}
                    </p>
                  </>
                )}
                {isInPaletteMode && (
                  <>
                    <p className="paragraph-sentence">
                      {originalPairParagraph.map((sentence, i) => {
                        const isEditingSentence = sentenceEditMode.includes(
                          chapter.textTranslatedLanguageSentences[i].sentenceId
                        );
                        return (
                          <span
                            key={i}
                            style={{
                              backgroundColor: isEditingSentence
                                ? "#1E1C80"
                                : sentenceColors[
                                    chapter.textOriginalLanguageSentences[i]
                                      .sentenceId
                                  ] || "#FFF",
                              color: isEditingSentence ? "#FFF" : "#000000",
                              border: "1px solid #80808060",
                              borderRadius: "3px",
                              padding: "0 5px",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            {sentence}
                          </span>
                        );
                      })}
                    </p>
                  </>
                )}
              </div>

              <div className="dutch-paragraph wrapper">
                {loadingParagraphs[
                  `Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`
                ] ? (
                  <div className="loader-sentence compare"></div>
                ) : (
                  <div style={{ width: "100%", textAlign: "left" }}>
                    {isInPaletteMode && (
                      <div className="title-header">AI TRANSLATION</div>
                    )}
                    {isEditing ? (
                      <TextareaComponent
                        value={
                          editedContent[`${chapterKey}-${paragraphKey}`] ||
                          (showViewAsEnglish &&
                            paragraphToggleStates[
                              `${chapterKey}-${paragraphKey}`
                            ] &&
                            translatedDutchToEnglish[
                              `Chapter ${chapterKey}-Paragraph ${paragraphKey}`
                            ]?.join(" ")) ||
                          translatedParagraph
                        }
                        onChange={(e) =>
                          setEditedContent({
                            ...editedContent,
                            [`${chapterKey}-${paragraphKey}`]: e.target.value,
                          })
                        }
                        placeholder="Edit translation"
                        handleCloseTranslatedText={() =>
                          handleCloseTranslatedText(chapterKey, paragraphKey)
                        }
                      />
                    ) : (
                      <>
                        {!isInPaletteMode && !isHistoryVisible && (
                          <>
                            {loadingLatestParagraphs[
                              `Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`
                            ] ? (
                              <>
                                <span className="paragraph-loading">
                                  <span className="loader-sentence latest-para"></span>
                                  We are fetching the translation for you. This
                                  can take a couple of seconds...
                                </span>
                              </>
                            ) : (
                              translatedPairParagraph.length > 0 &&
                              !!translatedPairParagraph[0] &&
                              translatedPairParagraph?.map((sentence, i) => {
                                const isEditingSentence =
                                  sentenceEditMode.includes(
                                    `${chapterKey}-${paragraphKey}`
                                  );
                                const isFeedbackEditMode =
                                  sentenceEditFeedbackMode ===
                                  chapter.textTranslatedLanguageSentences[i]
                                    .sentenceId;
                                const feedbackList =
                                  feedbackContent[
                                    `${chapter.textTranslatedLanguageSentences[i].sentenceId}`
                                  ];

                                const showEditedContent =
                                  sentenceEditConfirm.includes(
                                    chapter.textTranslatedLanguageSentences[i]
                                      .sentenceId
                                  );

                                const translatedEnSentences =
                                  translatedDutchToEnglish[
                                    `Chapter ${chapterKey}-Paragraph ${paragraphKey}`
                                  ];

                                const translatedEnSentence =
                                  translatedEnSentences?.[i];

                                return !isEditingSentence && selectedEditorMode==="textarea" ? (
                                  <span
                                    key={i}
                                    onDoubleClick={(e) => {
                                      handleClickOnDutchSentence(
                                        e,
                                        chapter.textTranslatedLanguageSentences[
                                          i
                                        ],
                                        chapterKey,
                                        paragraphKey
                                      );
                                    }}
                                    onMouseLeave={() => {
                                      setIsOriginalParaId([]);
                                    }}
                                    onMouseOver={() => {
                                      handleParagraphHower(paraTranslated[i]);
                                    }}
                                    // className="paragraph-sentence"
                                    className={`
                                                  ${"paragraph-sentence"} 
                                                  ${
                                                    isHoverOriginalParaId?.[0]
                                                      ?.sentenceId ===
                                                    chapter
                                                      ?.textOriginalLanguageSentences[
                                                      i
                                                    ]?.sentenceId
                                                      ? css.backgroundOrange
                                                      : ""
                                                  }`}
                                    style={{
                                      fontSize: textSize + "px",
                                      margin: 0,
                                      backgroundColor:
                                        isHoverOriginalParaId?.[0]
                                          ?.sentenceId ===
                                        chapter?.textOriginalLanguageSentences[
                                          i
                                        ]?.sentenceId
                                          ? "#d7d7ff"
                                          : isFeedbackEditMode
                                          ? ""
                                          : "",
                                      color:
                                        chapter.status !== "approved"
                                          ? chapter.confidence_score === 100 ||
                                            chapter.confidence_score >= 60
                                            ? "#000"
                                            : "#000"
                                          : "#000",
                                    }}
                                    onContextMenu={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      toggleEditFeedbackMode(
                                        paraTranslated[i].sentenceId,
                                        chapterKey,
                                        paragraphKey
                                      );
                                      toggleConfirmMode(
                                        chapterKey,
                                        paragraphKey
                                      );
                                      toggleConfirmButton(
                                        chapterKey,
                                        paragraphKey
                                      );
                                      adjustTextareaHeight(e);
                                    }}
                                    onClick={(e) => {
                                      setEditingColumn(1);
                                      handleClickOnDutchSentence(
                                        e,
                                        paraTranslated[i],
                                        chapterKey,
                                        paragraphKey
                                      );
                                    }}
                                  >
                                    {/* view mode translate paragraph */}
                                    {(showEditedContent &&
                                      editedContent[
                                        `${paraTranslated[i].sentenceId}`
                                      ]) ||
                                      (showViewAsEnglish &&
                                        paragraphToggleStates[
                                          `${chapterKey}-${paragraphKey}`
                                        ] &&
                                        translatedEnSentence) ||
                                      generateTextWithSpans(
                                        sentence,
                                        i,
                                        originalPairParagraph,
                                        translatedPairParagraph,
                                        chapter,
                                        isHoverOriginalParaId?.[0]
                                          ?.sentenceId ===
                                          chapter
                                            ?.textOriginalLanguageSentences[i]
                                            ?.sentenceId,
                                        chapterKey,
                                        paragraphKey,
                                        paraTranslated?.[i]?.sentenceId,
                                        false,
                                        index
                                      )}
                                  </span>
                                ) :!isEditingSentence && selectedEditorMode==="markdown"? 
                                <span 
                                key={i}
                                onDoubleClick={(e) => {
                                  handleClickOnDutchSentence(
                                    e,
                                    chapter.textTranslatedLanguageSentences[
                                      i
                                    ],
                                    chapterKey,
                                    paragraphKey
                                  );
                                }}
                                onMouseLeave={() => {
                                  setIsOriginalParaId([]);
                                }}
                                onMouseOver={() => {
                                  handleParagraphHower(paraTranslated[i]);
                                }}
                                // className="paragraph-sentence"
                                className={`
                                              ${"paragraph-sentence"} 
                                              ${
                                                isHoverOriginalParaId?.[0]
                                                  ?.sentenceId ===
                                                chapter
                                                  ?.textOriginalLanguageSentences[
                                                  i
                                                ]?.sentenceId
                                                  ? css.backgroundOrange
                                                  : ""
                                              }`}
                                style={{
                                  fontSize: textSize + "px",
                                  margin: 0,
                                  backgroundColor:
                                    isHoverOriginalParaId?.[0]
                                      ?.sentenceId ===
                                    chapter?.textOriginalLanguageSentences[
                                      i
                                    ]?.sentenceId
                                      ? "#d7d7ff"
                                      : isFeedbackEditMode
                                      ? ""
                                      : "",
                                  color:
                                    chapter.status !== "approved"
                                      ? chapter.confidence_score === 100 ||
                                        chapter.confidence_score >= 60
                                        ? "#000"
                                        : "#000"
                                      : "#000",
                                }}
                                onContextMenu={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  toggleEditFeedbackMode(
                                    paraTranslated[i].sentenceId,
                                    chapterKey,
                                    paragraphKey
                                  );
                                  toggleConfirmMode(
                                    chapterKey,
                                    paragraphKey
                                  );
                                  toggleConfirmButton(
                                    chapterKey,
                                    paragraphKey
                                  );
                                  adjustTextareaHeight(e);
                                }}
                                onClick={(e) => {
                                  setEditingColumn(1);
                                  handleClickOnDutchSentence(
                                    e,
                                    paraTranslated[i],
                                    chapterKey,
                                    paragraphKey
                                  );
                                }}
                                 >
                                
                                 <ReactMarkdown
                              
                                  >{translatedPairParagraph[i]}</ReactMarkdown> </span> :null ;
                              })
                            )}
                            {isEditingSentencePara && (
                              <TranslationEditCompent
                                editorMode={selectedEditorMode}
                                translatedPairParagraph={
                                  translatedPairParagraph
                                }
                                chapterKey={chapterKey}
                                paragraphKey={paragraphKey}
                                textAreaRef={textAreaRef}
                                calculateTextareaHeight={
                                  calculateTextareaHeight
                                }
                                editedContent={editedContent}
                                paraTranslated={paraTranslated}
                                handleParagraphHower={handleParagraphHower}
                                handleChange={handleChange}
                                paragraphId={paragraphId}
                                setEditedContent={setEditedContent}
                              />
                            )}
                            {(translatedPairParagraph?.length === 0 ||
                              !translatedPairParagraph[0]) &&
                              !loadingLatestParagraphs[
                                `Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`
                              ] &&
                              !errorLatestParagraphs[
                                `Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`
                              ] && (
                                <span className="generate_latest_para">
                                  This paragraph is not yet translated,{" "}
                                  <a
                                    href=""
                                    onClick={(e) => {
                                      e.preventDefault();
                                      updateLatestParagraph(
                                        currParaToFetch,
                                        chapterKey,
                                        paragraphKey,
                                        "",
                                        index
                                      );
                                      automateTranslationFetchHandler(
                                        paragraphId,
                                        false
                                      );
                                    }}
                                  >
                                    click here to translate
                                  </a>
                                </span>
                              )}
                            {(translatedPairParagraph?.length === 0 ||
                              !translatedPairParagraph[0]) &&
                              errorLatestParagraphs[
                                `Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`
                              ] && (
                                <span className="generate_latest_para error">
                                  There was an error fetching the translation,{" "}
                                  <a
                                    href=""
                                    onClick={(e) => {
                                      e.preventDefault();
                                      updateLatestParagraph(
                                        currParaToFetch,
                                        chapterKey,
                                        paragraphKey,
                                        index
                                      );
                                    }}
                                  >
                                    click here to try again
                                  </a>
                                </span>
                              )}
                          </>
                        )}
                        {!isInPaletteMode && isHistoryVisible && (
                          <>
                            <div
                              onClick={() =>
                                handleHistoryToggle(
                                  chapterKey,
                                  paragraphKey,
                                  predictedTranslatedContentForQuill
                                )
                              }
                              style={{ fontSize: textSize }}
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  showDifference(
                                    predictedTranslatedContentForQuill,
                                    translatedContentForQuill
                                  )
                                ),
                              }}
                            />
                          </>
                        )}
                        {isInPaletteMode && (
                          <div
                            style={{
                              textAlign: "left",
                              width: "100%",
                            }}
                          >
                            {translatedPairParagraph?.map((sentence, i) => {
                              const isEditingSentence =
                                sentenceEditMode.includes(
                                  `${chapterKey}-${paragraphKey}`
                                );

                              const translatedEnSentences =
                                translatedDutchToEnglish[
                                  `Chapter ${chapterKey}-Paragraph ${paragraphKey}`
                                ];

                              const translatedEnSentence =
                                translatedEnSentences?.[i];

                              return !isEditingSentence ? (
                                <span
                                  key={i}
                                  className="paragraph-sentence"
                                  style={{
                                    margin: 0,
                                    backgroundColor:
                                      sentenceColors[
                                        chapter.textTranslatedLanguageSentences[
                                          i
                                        ].sentenceId
                                      ] || "#FFF",
                                    border: "1px solid #80808060",
                                    fontSize: "16px",
                                    // fontWeight: "500",
                                  }}
                                >
                                  {editedContent[
                                    `${chapter.textTranslatedLanguageSentences[i].sentenceId}`
                                  ] || sentence}
                                </span>
                              ) : (
                                <textarea
                                  key={i}
                                  className={`textarea active`}
                                  style={{
                                    height: calculateTextareaHeight(sentence),
                                    backgroundColor: "transparent",
                                  }}
                                  onChange={(e) => {
                                    setEditedContent({
                                      ...editedContent,
                                      [`${chapter.textTranslatedLanguageSentences[i].sentenceId}`]:
                                        e.target.value,
                                    });
                                  }}
                                  value={
                                    editedContent[
                                      `${chapter.textTranslatedLanguageSentences[i].sentenceId}`
                                    ] ||
                                    (showViewAsEnglish &&
                                      paragraphToggleStates[
                                        `${chapterKey}-${paragraphKey}`
                                      ] &&
                                      translatedEnSentence) ||
                                    sentence
                                  }
                                ></textarea>
                              );
                            })}
                          </div>
                        )}
                        {isInPaletteMode && (
                          <div style={{ textAlign: "left", width: "100%" }}>
                            {translatedPairParagraph?.map((sentence, i) => {
                              const isEditingSentence =
                                sentenceEditMode.includes(
                                  chapter.textTranslatedLanguageSentences[i]
                                    .sentenceId
                                );

                              const translatedEnSentences =
                                translatedDutchToEnglish[
                                  `Chapter ${chapterKey}-Paragraph ${paragraphKey}`
                                ];

                              const translatedEnSentence =
                                translatedEnSentences?.[i];

                              return !isEditingSentence ? (
                                <span
                                  key={i}
                                  className="paragraph-sentence"
                                  style={{
                                    margin: 0,
                                    backgroundColor:
                                      sentenceColors[
                                        chapter.textTranslatedLanguageSentences[
                                          i
                                        ].sentenceId
                                      ] || "#FFF",
                                    border: "1px solid #80808060",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {editedContent[
                                    `${chapter.textTranslatedLanguageSentences[i].sentenceId}`
                                  ] || sentence}
                                </span>
                              ) : (
                                <textarea
                                  key={i}
                                  // onChange={onChange}
                                  className={`textarea active`}
                                  // rows="4"
                                  onChange={(e) =>
                                    setEditedContent({
                                      ...editedContent,
                                      [`${chapter.textTranslatedLanguageSentences[i].sentenceId}`]:
                                        e.target.value,
                                    })
                                  }
                                  value={
                                    editedContent[
                                      `${chapter.textTranslatedLanguageSentences[i].sentenceId}`
                                    ] ||
                                    (showViewAsEnglish &&
                                      paragraphToggleStates[
                                        `${chapterKey}-${paragraphKey}`
                                      ] &&
                                      translatedEnSentence) ||
                                    sentence
                                  }
                                ></textarea>
                              );
                            })}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>

              {showCompare && (
                <div
                  style={{
                    display: "flex",
                    flex: "1 1",
                    position: "relative",
                  }}
                >
                  {loadingComparisionParagraphs[
                    `Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedComparisionModelName}-Temperature ${selectedComparisionTemperature}`
                  ] ? (
                    <div className="loader-sentence compare"></div>
                  ) : (
                    <div style={{ width: "100%", textAlign: "left" }}>
                      {isInPaletteMode && (
                        <div className="title-header">AI TRANSLATION</div>
                      )}
                      {isEditing ? (
                        <TextareaComponent
                          value={
                            editedContent[`${chapterKey}-${paragraphKey}`] ||
                            (showViewAsEnglish &&
                              paragraphToggleStates[
                                `${chapterKey}-${paragraphKey}`
                              ] &&
                              translatedDutchToEnglish[
                                `Chapter ${chapterKey}-Paragraph ${paragraphKey}`
                              ]?.join(" ")) ||
                            translatedParagraphCompare
                          }
                          onChange={(e) =>
                            setEditedContent({
                              ...editedContent,
                              [`${chapterKey}-${paragraphKey}`]: e.target.value,
                            })
                          }
                          placeholder="Edit translation"
                          handleCloseTranslatedText={() =>
                            handleCloseTranslatedText(chapterKey, paragraphKey)
                          }
                        />
                      ) : (
                        <>
                          {!isInPaletteMode && !isHistoryVisible && (
                            <>
                              {loadingLatestComparisionParagraphs[
                                `Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedComparisionModelName}-Temperature ${selectedComparisionTemperature}`
                              ] ? (
                                <>
                                  <span className="paragraph-loading">
                                    <span className="loader-sentence latest-para"></span>
                                    We are fetching the translation for you.
                                    This can take a couple of seconds...
                                  </span>
                                </>
                              ) : (
                                // translatedPairParagraphCompare[0] !== "" &&
                                translatedPairParagraphCompare?.map(
                                  (sentence, i) => {
                                    const isEditingSentence =
                                      sentenceEditMode.includes(
                                        `${chapterKey}-${paragraphKey}`
                                      );
                                    const isFeedbackEditMode =
                                      sentenceEditFeedbackMode ===
                                      chapter.textTranslatedLanguageSentences[i]
                                        .sentenceId;
                                    const feedbackList =
                                      feedbackContent[
                                        `${chapter.textTranslatedLanguageSentences[i].sentenceId}`
                                      ];

                                    const showEditedContent =
                                      sentenceEditConfirm.includes(
                                        chapter.textTranslatedLanguageSentences[
                                          i
                                        ].sentenceId
                                      );

                                    const translatedEnSentences =
                                      translatedDutchToEnglish[
                                        `Chapter ${chapterKey}-Paragraph ${paragraphKey}`
                                      ];

                                    const translatedEnSentence =
                                      translatedEnSentences?.[i];

                                    sentencesToShow =
                                      chapter?.textTranslatedLanguageSentences.filter(
                                        (sentence) =>
                                          sentence.target_language ===
                                            selectedLanguage &&
                                          sentence.model_name ===
                                            selectedComparisionModelName &&
                                          +sentence.temperature ===
                                            +selectedComparisionTemperature
                                      );

                                    return !isEditingSentence ? (
                                      <span
                                        key={i}
                                        onMouseLeave={() => {
                                          setIsOriginalParaId([]);
                                        }}
                                        onMouseOver={() => {
                                          handleParagraphHower(
                                            chapter
                                              .textTranslatedLanguageSentences[
                                              i
                                            ]
                                          );
                                        }}
                                        className="paragraph-sentence"
                                        style={{
                                          fontSize: textSize + "px",
                                          margin: 0,
                                          backgroundColor:
                                            isHoverOriginalParaId?.[0]
                                              ?.sentenceId ===
                                            chapter
                                              ?.textOriginalLanguageSentences[i]
                                              ?.sentenceId
                                              ? "#d7d7ff"
                                              : isFeedbackEditMode
                                              ? ""
                                              : "",
                                          color:
                                            chapter.status !== "approved"
                                              ? chapter.confidence_score ===
                                                  100 ||
                                                chapter.confidence_score >= 60
                                                ? "#000"
                                                : "#000"
                                              : "#000",
                                        }}
                                        onContextMenu={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          toggleEditFeedbackMode(
                                            chapter
                                              .textTranslatedLanguageSentences[
                                              i
                                            ].sentenceId,
                                            chapterKey,
                                            paragraphKey
                                          );
                                          toggleConfirmMode(
                                            chapterKey,
                                            paragraphKey
                                          );
                                          toggleConfirmButton(
                                            chapterKey,
                                            paragraphKey
                                          );
                                          adjustTextareaHeight(e);
                                        }}
                                        onClick={(e) => {
                                          setEditingColumn(2);
                                          handleClickOnDutchSentence(
                                            e,
                                            chapter
                                              .textTranslatedLanguageSentences[
                                              i
                                            ],
                                            chapterKey,
                                            paragraphKey
                                          );
                                        }}
                                      >
                                        {(showEditedContent &&
                                          editedContent[
                                            `${sentencesToShow[i].sentenceId}`
                                          ]) ||
                                          (showViewAsEnglish &&
                                            paragraphToggleStates[
                                              `${chapterKey}-${paragraphKey}`
                                            ] &&
                                            translatedEnSentence) ||
                                          generateTextWithSpans(
                                            sentence,
                                            i,
                                            originalPairParagraph,
                                            translatedPairParagraphCompare,
                                            chapter,
                                            isHoverOriginalParaId?.[0]
                                              ?.sentenceId ===
                                              chapter
                                                ?.textOriginalLanguageSentences[
                                                i
                                              ]?.sentenceId,
                                            chapterKey,
                                            paragraphKey,
                                            paraTranslated?.[i]?.sentenceId,
                                            true
                                          )}
                                      </span>
                                    ) : null;
                                  }
                                )
                              )}

                              {isEditingSentencePara &&
                                translatedPairParagraphCompare?.map(
                                  (sentence, i) => (
                                    <textarea
                                      key={`${chapterKey}-${paragraphKey}-${i}`}
                                      className={`textarea active`}
                                      ref={textAreaRef[i]}
                                      id={`${chapterKey}-${paragraphKey}-${i}`}
                                      style={{
                                        height: calculateTextareaHeight(
                                          editedContent[
                                            `${chapter?.textTranslatedLanguageSentences[i].sentenceId}`
                                          ]
                                            ? editedContent[
                                                `${chapter?.textTranslatedLanguageSentences[i].sentenceId}`
                                              ]
                                            : sentence
                                        ),
                                        backgroundColor: "transparent",
                                        overflow: "hidden",
                                        textAlign: "justify",
                                        overflowWrap: "break-word",
                                        width: "100%",
                                      }}
                                      onClick={() => {
                                        handleParagraphHower(
                                          chapter
                                            ?.textTranslatedLanguageSentences[i]
                                        );
                                      }}
                                      onKeyDown={(e) =>
                                        handleChange(
                                          i,
                                          e,
                                          chapter?.textTranslatedLanguageSentences,
                                          paragraphId,
                                          chapterKey,
                                          paragraphKey,
                                          translatedPairParagraphCompare
                                        )
                                      }
                                      autoFocus={i === 0}
                                      onChange={(e) => {
                                        handleChange(
                                          i,
                                          e,
                                          chapter?.textTranslatedLanguageSentences
                                        );
                                        setEditedContent({
                                          ...editedContent,
                                          [`${sentencesToShow[i].sentenceId}`]:
                                            e.target.value,
                                        });
                                      }}
                                      value={
                                        editedContent[
                                          `${sentencesToShow[i]?.sentenceId}`
                                        ] !== undefined
                                          ? editedContent[
                                              `${sentencesToShow[i]?.sentenceId}`
                                            ]
                                          : sentence // If not edited, display original sentence
                                      }
                                    ></textarea>
                                  )
                                )}
                              {(translatedPairParagraphCompare?.length === 0 ||
                                !translatedPairParagraphCompare[0]) &&
                                !loadingLatestComparisionParagraphs[
                                  `Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedComparisionModelName}-Temperature ${selectedComparisionTemperature}`
                                ] &&
                                !errorLatestComparisionParagraphs[
                                  `Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedComparisionModelName}-Temperature ${selectedComparisionTemperature}`
                                ] && (
                                  <span className="generate_latest_para">
                                    This paragraph is not yet translated,{" "}
                                    <a
                                      href=""
                                      onClick={(e) => {
                                        e.preventDefault();
                                        updateLatestParagraphCompare(
                                          currParaToFetch,
                                          chapterKey,
                                          paragraphKey,
                                          "",
                                          index
                                        );

                                        automateTranslationFetchHandler(
                                          paragraphId,
                                          true
                                        );
                                      }}
                                    >
                                      click here to translate
                                    </a>
                                  </span>
                                )}
                              {(translatedPairParagraphCompare?.length === 0 ||
                                !translatedPairParagraphCompare[0]) &&
                                errorLatestComparisionParagraphs[
                                  `Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedComparisionModelName}-Temperature ${selectedComparisionTemperature}`
                                ] && (
                                  <span className="generate_latest_para error">
                                    There was an error fetching the translation,{" "}
                                    <a
                                      href=""
                                      onClick={(e) => {
                                        e.preventDefault();
                                        updateLatestParagraphCompare(
                                          currParaToFetch,
                                          chapterKey,
                                          paragraphKey,
                                          "",
                                          index
                                        );
                                      }}
                                    >
                                      click here to try again
                                    </a>
                                  </span>
                                )}
                            </>
                          )}
                          {!isInPaletteMode && isHistoryVisible && (
                            <>
                              <div
                                onClick={() =>
                                  handleHistoryToggle(
                                    chapterKey,
                                    paragraphKey,
                                    predictedTranslatedContentForQuillCompare
                                  )
                                }
                                style={{ fontSize: textSize }}
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    showDifference(
                                      predictedTranslatedContentForQuillCompare,
                                      translatedContentForQuillCompare
                                    )
                                  ),
                                }}
                              />
                            </>
                          )}
                          {isInPaletteMode && (
                            <div
                              style={{
                                textAlign: "left",
                                width: "100%",
                              }}
                            >
                              {translatedPairParagraphCompare?.map(
                                (sentence, i) => {
                                  const isEditingSentence =
                                    sentenceEditMode.includes(
                                      `${chapterKey}-${paragraphKey}`
                                    );

                                  const translatedEnSentences =
                                    translatedDutchToEnglish[
                                      `Chapter ${chapterKey}-Paragraph ${paragraphKey}`
                                    ];

                                  const translatedEnSentence =
                                    translatedEnSentences?.[i];

                                  return !isEditingSentence ? (
                                    <span
                                      key={i}
                                      className="paragraph-sentence"
                                      style={{
                                        margin: 0,
                                        backgroundColor:
                                          sentenceColors[
                                            chapter
                                              .textTranslatedLanguageSentences[
                                              i
                                            ].sentenceId
                                          ] || "#FFF",
                                        border: "1px solid #80808060",
                                        fontSize: "16px",
                                        // fontWeight: "500",
                                      }}
                                    >
                                      {editedContent[
                                        `${chapter.textTranslatedLanguageSentences[i].sentenceId}`
                                      ] || sentence}
                                    </span>
                                  ) : (
                                    <textarea
                                      key={i}
                                      className={`textarea active`}
                                      style={{
                                        height:
                                          calculateTextareaHeight(sentence),
                                        backgroundColor: "transparent",
                                      }}
                                      onChange={(e) =>
                                        setEditedContent({
                                          ...editedContent,
                                          [`${chapter.textTranslatedLanguageSentences[i].sentenceId}`]:
                                            e.target.value,
                                        })
                                      }
                                      value={
                                        editedContent[
                                          `${chapter.textTranslatedLanguageSentences[i].sentenceId}`
                                        ] ||
                                        (showViewAsEnglish &&
                                          paragraphToggleStates[
                                            `${chapterKey}-${paragraphKey}`
                                          ] &&
                                          translatedEnSentence) ||
                                        sentence
                                      }
                                    ></textarea>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              )}
              {/* end of compare */}

              <div className="like-button-translate">
                {/* <img src={likeIcon} alt="Like" className="like-icon" /> */}
                {!showCompare &&
                  !(
                    translatedPairParagraph?.length === 0 ||
                    translatedPairParagraph?.[0] === ""
                  ) && (
                    <Tooltip title="Merge Sentences">
                      <img
                        src={mergeIcon}
                        alt="Marker"
                        onClick={() => {
                          handleEditPairingClick(
                            paragraphId,
                            chapterKey,
                            paragraphKey
                          );
                        }}
                        className="merge-icon"
                      />
                    </Tooltip>
                  )}

                {
                  <Tooltip title="Regenerate Translation">
                    <img
                      src={regenerate}
                      alt="info"
                      onClick={() => {
                        updateLatestParagraph(
                          currParaToFetch,
                          chapterKey,
                          paragraphKey,
                          "",
                          index
                        );
                        if (showCompare)
                          updateLatestParagraphCompare(
                            currParaToFetch,
                            chapterKey,
                            paragraphKey,
                            "test",
                            index
                          );
                      }}
                      className="like-icon"
                    />
                  </Tooltip>
                }

                {predictedTranslatedContentForQuill !== editedParagraph ? (
                  !isHistoryVisible ? (
                    <Tooltip title="View Changes">
                      <RemoveRedEyeIcon
                        sx={{
                          color:
                            isEditingSentencePara ||
                            loadingLatestParagraphs[
                              `Chapter ${chapterKey}-Paragraph ${paragraphKey}`
                            ]
                              ? "#e0e0e0"
                              : "blue",
                          cursor:
                            isEditingSentencePara ||
                            loadingLatestParagraphs[
                              `Chapter ${chapterKey}-Paragraph ${paragraphKey}`
                            ]
                              ? "default"
                              : "pointer",
                        }}
                        onClick={() => {
                          !isEditingSentencePara &&
                            !loadingLatestParagraphs[
                              `Chapter ${chapterKey}-Paragraph ${paragraphKey}`
                            ] &&
                            handleHistoryToggle(
                              chapterKey,
                              paragraphKey,
                              predictedTranslatedContentForQuill
                            );
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Hide Changes">
                      <VisibilityOffIcon
                        sx={{ color: "blue", cursor: "pointer" }}
                        onClick={() => {
                          handleHistoryToggle(
                            chapterKey,
                            paragraphKey,
                            predictedTranslatedContentForQuill
                          );

                          handleCloseEditededText(
                            chapterKey,
                            paragraphKey,
                            chapter.textTranslatedLanguageSentences,
                            "Close"
                          );
                        }}
                      />
                    </Tooltip>
                  )
                ) : null}
              </div>

              {isInCompareMode && (
                <div className="dutch-paragraph-human-translated">
                  <div>HUMAN TRANSLATED DUTCH </div>
                  <p className="paragraph-sentence">{translatedParagraph}</p>
                </div>
              )}
            </div>
          ) : (
            <MergeComponent
              pairingDatas={pairingData}
              originalPairParagraph={originalPairParagraph}
              translatedPairParagraph={paraTranslated}
              paraId={showEditPairing}
              onEditPairingClick={handleEditPairingClick}
              onUpdateMatch={updateTheMatch}
            />
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "2rem",
              width: "100%",
              marginTop: "2rem",
              marginBottom:
                isGlossaryVisible && index === glossaryIndex
                  ? "1rem"
                  : undefined,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                justifyContent: "space-between",
                width: "50%",
              }}
            >
              {isGlossaryVisible && index === glossaryIndex ? (
                <div />
              ) : (
                <div></div>
              )}

              <div>
                <Button
                  variant="text"
                  onClick={() => {
                    if (userEnteringGlossaryForVariantState.isOn) {
                      setEnableVariantInputBox("");
                      setUserEnteringGlossaryForVariantState((oldState) => {
                        return {
                          ...oldState,
                          isOn: false,
                        };
                      });
                    }
                    if (isGlossaryVisible && index === glossaryIndex) {
                      setCurrentKeywordWithVariant({});
                      setIsGlossaryVisible(false);
                    } else {
                      setIsGlossaryVisible(true);
                      setCurrentKeywordWithVariant({});
                      handleGlossary(index, chapter);
                    }
                    setbyPopupAddWordInGlossaryScenario(false);
                  }}
                >
                  Glossary{" "}
                  <FontAwesomeIcon
                    icon={
                      isGlossaryVisible && index === glossaryIndex
                        ? faCaretUp
                        : faCaretDown
                    }
                    style={{ marginLeft: 10 }}
                  />
                </Button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                justifyContent: "space-between",
                width: "50%",
              }}
            >
              {(isEditingPara &&
                isShowConfirmTranslation &&
                !loadingParagraphs[
                  `Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`
                ] &&
                !isInPaletteMode) ||
              (isEditingPara &&
                !isInPaletteMode &&
                showConfirmTranslation.length !== 0) ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    width: "50%",
                  }}
                >
                  {((isEditingPara &&
                    isShowConfirmTranslation &&
                    !loadingParagraphs[
                      `Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`
                    ] &&
                    !isInPaletteMode) ||
                    (!isInPaletteMode &&
                      showConfirmTranslation.length === 0)) && (
                    <>
                      {isGlossaryVisible && index === glossaryIndex ? (
                        <div>
                          <Button
                            style={{
                              backgroundColor: "#3B37FF",
                              border: "1px solid #3B37FF",
                              color: "#FFF",
                              width: "80px",
                            }}
                            onClick={() => {
                              updateTheNodeConnectionConfirmPair(
                                paragraphId,
                                chapterKey,
                                paragraphKey,
                                "confirm",
                                translatedPairParagraph
                              );
                              setFeedbackSelectorVisible("");
                              // handleClick(paragraphKey);
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      ) : (
                        <div>
                          <Button
                            style={{
                              backgroundColor: "#3B37FF",
                              border: "1px solid #3B37FF",
                              color: "#FFF",
                              width: "80px",
                            }}
                            onClick={() => {
                              if (editingColumn === 2) {
                                updateTheNodeConnectionConfirmPairComparision(
                                  paragraphId,
                                  chapterKey,
                                  paragraphKey,
                                  "confirm",
                                  translatedPairParagraph
                                );
                              } else if (editingColumn === 1) {
                                updateTheNodeConnectionConfirmPair(
                                  paragraphId,
                                  chapterKey,
                                  paragraphKey,
                                  "confirm",
                                  translatedPairParagraph
                                );
                              } else {
                                console.error("Something Went Wrong");
                              }
                              setFeedbackSelectorVisible("");
                              // handleClick(paragraphKey);
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                  {((isEditingPara &&
                    isShowConfirmTranslation &&
                    !loadingParagraphs[
                      `Chapter ${chapterKey}-Paragraph ${paragraphKey}-Model ${selectedModelName}-Temperature ${selectedTemperature}`
                    ] &&
                    !isInPaletteMode) ||
                    (!isInPaletteMode &&
                      showConfirmTranslation.length === 0)) && (
                    <>
                      {isGlossaryVisible && index === glossaryIndex ? (
                        <div>
                          <Button
                            style={{
                              backgroundColor: "#FFF",
                              border: "1px solid #3B37FF",
                              color: "#3B37FF",
                              width: "80px",
                            }}
                            onClick={() => {
                              setEditingSentences({});
                              setSentenceEditMode([]);
                              setIsOriginalParaId([]);
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      ) : (
                        <div>
                          <Button
                            style={{
                              backgroundColor: "#FFF",
                              border: "1px solid #3B37FF",
                              color: "#3B37FF",
                              width: "80px",
                            }}
                            onClick={() => {
                              setEditingSentences({});
                              setSentenceEditMode([]);
                              setIsOriginalParaId([]);
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              ) : null}
              {showModelSelection && (
                <FormControl sx={{ m: 1, minWidth: 150 }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Model
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={selectedModel[index]}
                    label="Language"
                    sx={{ color: "green" }}
                    onChange={(e) => {
                      handleModelChange(
                        e,
                        chapter,
                        chapterKey,
                        paragraphKey,
                        index
                      );
                    }}
                  >
                    {assistantOptions
                      .filter(
                        (option) => option.target_language === selectedLanguage
                      )
                      .map((option) => (
                        <MenuItem value={option.model_name}>
                          {option.model_name}
                        </MenuItem>
                      ))}
                  </Select>
                  {/* <FormHelperText>Translate into language</FormHelperText> */}
                </FormControl>
              )}

              {renderViewAsEnglishAction({
                chapterKey,
                paragraphKey,
                translatedPairParagraph,
              })}
            </div>
          </div>

          {isGlossaryVisible && index === glossaryIndex && (
            <div
              className="glossary_Box"
              style={{
                marginTop: isGlossaryVisible ? "0" : "48px",
              }}
            >
              {loader ? (
                <div className="loader-glossary"></div>
              ) : (
                <>
                  <div className="glossary_Left">
                    <h5>Scope Levels</h5>
                    <ul>
                      <li>
                        <span style={{ backgroundColor: "#FFEFC1" }}></span>Book
                      </li>
                      <li>
                        <span style={{ backgroundColor: "#FFDDDD" }}></span>
                        Author
                      </li>
                      <li>
                        <span style={{ backgroundColor: "#E1EDFF" }}></span>
                        Genre
                      </li>
                      <li>
                        <span style={{ backgroundColor: "#E4FFDB" }}></span>
                        Language
                      </li>
                    </ul>
                    <div className="glossary_content">
                      {glossaryItem.book_level &&
                        Object.keys(glossaryItem["book_level"])?.length === 0 &&
                        paragraphWords?.length === 0 &&
                        !byPopupAddWordInGlossaryScenario && (
                          <>
                            <p>Your glossary is empty</p>
                            <p>(Please create a keyword)</p>
                            <button
                              onClick={() => {
                                setEnableInputBox(true);
                                setParagraphWords([1, 2]);
                              }}
                            >
                              + Add Keywords
                            </button>
                          </>
                        )}
                      {((glossaryItem.book_level &&
                        Object.keys(glossaryItem["book_level"])?.length > 0) ||
                        paragraphWords?.length > 0 ||
                        byPopupAddWordInGlossaryScenario) && (
                        <p style={{ textAlign: "left" }}>
                          {glossaryItem.book_level.length > 0 &&
                            glossaryItem["book_level"]?.map((item, i) => (
                              <>
                                {i === enableGlossaryWord ? (
                                  <Tooltip
                                    title={
                                      Object.values(item)[0][
                                        "application_scope"
                                      ] === ""
                                        ? "Not applied to any level"
                                        : `Applied at ${
                                            Object.values(item)[0][
                                              "glossary_scope"
                                            ]
                                          } level`
                                    }
                                    arrow
                                  >
                                    <span
                                      key={i}
                                      onClick={(event) => {
                                        setEnableGlossaryWord(i);
                                        setWordVariant(!wordVariant);
                                      }}
                                      style={{
                                        textAlign: "center",
                                        backgroundColor: `${
                                          i === enableGlossaryWord
                                            ? "#3B37FF"
                                            : Object.values(item)[0][
                                                "glossary_scope"
                                              ].includes("Book")
                                            ? "#FFEFC1"
                                            : Object.values(item)[0][
                                                "glossary_scope"
                                              ].includes("Author")
                                            ? "#FFDDDD"
                                            : Object.values(item)[0][
                                                "glossary_scope"
                                              ].includes("Genre")
                                            ? "#E1EDFF"
                                            : Object.values(item)[0][
                                                "glossary_scope"
                                              ].includes("Language")
                                            ? "#E4FFDB"
                                            : "#e1f9ff"
                                        }`,
                                        color: `${
                                          i === enableGlossaryWord
                                            ? "#FFFFFF"
                                            : "#000000"
                                        }`,
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div>{Object.keys(item)[0]}</div>
                                      </div>
                                    </span>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title={
                                      Object.values(item)[0][
                                        "application_scope"
                                      ] === ""
                                        ? "Not applied to any level"
                                        : `Applied at ${
                                            Object.values(item)[0][
                                              "glossary_scope"
                                            ]
                                          } level`
                                    }
                                    arrow
                                  >
                                    <span
                                      key={i}
                                      onClick={(event) => {
                                        if (
                                          userEnteringGlossaryForVariantState.isOn
                                        ) {
                                          setEnableVariantInputBox("");
                                          setUserEnteringGlossaryForVariantState(
                                            (oldState) => {
                                              return {
                                                ...oldState,
                                                isOn: false,
                                              };
                                            }
                                          );
                                        }
                                        setApplyGlossary(false);
                                        setSelectedVariant("");
                                        setCurrentKeywordWithVariant(item);
                                        setSelectedVariant(
                                          item[Object.keys(item)[0]].values[0]
                                        );
                                        setEnableGlossaryWord(i);
                                        if (wordVariant)
                                          setWordVariant(!wordVariant);
                                        else setWordVariant(!wordVariant);
                                        //setCurrentKeywordWithVariant(item)
                                      }}
                                      style={{
                                        backgroundColor: Object.values(item)[0][
                                          "glossary_scope"
                                        ].includes("Book")
                                          ? "#FFEFC1"
                                          : Object.values(item)[0][
                                              "glossary_scope"
                                            ].includes("Author")
                                          ? "#FFDDDD"
                                          : Object.values(item)[0][
                                              "glossary_scope"
                                            ].includes("Genre")
                                          ? "#E1EDFF"
                                          : Object.values(item)[0][
                                              "glossary_scope"
                                            ].includes("Language")
                                          ? "#E4FFDB"
                                          : "#fff",
                                        color: "#000000",
                                        textAlign: "center",
                                        border:
                                          Object.values(item)[0][
                                            "glossary_scope"
                                          ] !== "book_level" && "none",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div>{Object.keys(item)[0]}</div>
                                      </div>
                                    </span>
                                  </Tooltip>
                                )}
                              </>
                            ))}
                          {enableInputBox && (
                            <p className="inputBox">
                              <input
                                type="text"
                                style={{
                                  marginRight: "0.85rem",
                                  width: paraWord.length * 13 + "px",
                                  minWidth: "125px",
                                }}
                                value={paraWord}
                                placeholder="Type Here"
                                onChange={(el) =>
                                  setParaWrod(
                                    el.target.value.replace(/^\s+/g, "")
                                  )
                                }
                                onKeyDown={handleAddKeyWordOnEnter}
                                autoFocus={true}
                              />
                              <span>
                                <FontAwesomeIcon
                                  icon={faXmark}
                                  style={{
                                    marginLeft: 10,
                                    color: "red",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setEnableInputBox(false);
                                    setParagraphWords([]);
                                    setParaWrod("");
                                  }}
                                />
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  style={{
                                    marginLeft: 7,
                                    marginRight: 5,
                                    color: "green",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => handleAddKeyWordOnEnter(e)}
                                />
                              </span>
                            </p>
                          )}

                          <button
                            className="AddKeywords"
                            onClick={() => setEnableInputBox(true)}
                          >
                            + Add Keywords
                          </button>
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="glossary_Right">
                    <h2 style={{ display: "block" }}>
                      {(userEnteringGlossaryForVariantState?.isOn
                        ? userEnteringGlossaryForVariantState.keywordWithVariant
                        : currentKeyWordWithVariant) &&
                        Object.keys(
                          userEnteringGlossaryForVariantState?.isOn
                            ? userEnteringGlossaryForVariantState.keywordWithVariant
                            : currentKeyWordWithVariant
                        ).length === 0 && <p>No Variants present</p>}
                      {(userEnteringGlossaryForVariantState?.isOn
                        ? userEnteringGlossaryForVariantState.keywordWithVariant
                        : currentKeyWordWithVariant) &&
                        Object.keys(
                          userEnteringGlossaryForVariantState?.isOn
                            ? userEnteringGlossaryForVariantState.keywordWithVariant
                            : currentKeyWordWithVariant
                        ).length !== 0 && (
                          <div className="Variants">
                            <p style={{ fontWeight: "bold", color: "black" }}>
                              Variants For Keyword "
                              {userEnteringGlossaryForVariantState?.isOn
                                ? paraWord
                                : Object.keys(currentKeyWordWithVariant)[0]}
                              "
                            </p>
                            <div className="VariantCard">
                              {(userEnteringGlossaryForVariantState?.isOn
                                ? userEnteringGlossaryForVariantState?.keywordWithVariant
                                : currentKeyWordWithVariant[
                                    Object.keys(currentKeyWordWithVariant)[0]
                                  ]
                              )?.values?.length > 0 &&
                                (userEnteringGlossaryForVariantState?.isOn
                                  ? userEnteringGlossaryForVariantState?.keywordWithVariant
                                  : currentKeyWordWithVariant[
                                      Object.keys(currentKeyWordWithVariant)[0]
                                    ]
                                )?.values.map((item, i) => (
                                  <>
                                    {enableVariantInputBox === item ? (
                                      <div className="TypeInput">
                                        <FontAwesomeIcon
                                          icon={faXmark}
                                          style={{
                                            color: "red",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setCurrentVarientText("");
                                            setprevVariantText(
                                              enableVariantInputBox
                                            );
                                            setEnableVariantInputBox("");
                                          }}
                                        />
                                        <input
                                          style={{ color: "black" }}
                                          type="text"
                                          id="louie"
                                          placeholder="Type here"
                                          value={currentVariant}
                                          onChange={(e) => {
                                            setCurrentVarientText(
                                              e.target.value
                                            );
                                            if (!e.target.value) {
                                              setprevVariantText(
                                                enableVariantInputBox
                                              );
                                              setEnableVariantInputBox("");
                                            }
                                          }}
                                          autoFocus={
                                            userEnteringGlossaryForVariantState?.isOn
                                              ? false
                                              : true
                                          }
                                          onKeyDown={(event) =>
                                            event?.key === "Enter" &&
                                            addVariantToKeyword(
                                              Object.keys(
                                                currentKeyWordWithVariant
                                              )[0],
                                              currentVariant,
                                              "updateVariant",
                                              item,
                                              chapter,
                                              pairingData?.genre
                                            )
                                          }
                                        />
                                        <FontAwesomeIcon
                                          icon={faCheck}
                                          style={{
                                            color: "green",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            addVariantToKeyword(
                                              Object.keys(
                                                currentKeyWordWithVariant
                                              )[0],
                                              currentVariant,
                                              "updateVariant",
                                              item,
                                              chapter,
                                              pairingData?.genre
                                            )
                                          }
                                        />
                                      </div>
                                    ) : item === prevVariantText ? (
                                      <div></div>
                                    ) : (
                                      <div key={i} className="VariantsBox">
                                        <input
                                          type="radio"
                                          id="huey"
                                          name="drone"
                                          value={item}
                                          onChange={() =>
                                            handleRadioChange(item)
                                          }
                                          checked={selectedVariant === item}
                                        />
                                        <label for="huey">{item} </label>
                                        <div className="EditBtn">
                                          <img
                                            src={marker}
                                            alt="Marker"
                                            className="like-icon"
                                            onClick={() => {
                                              setCurrentVarientText(item);
                                              setEnableVariantInputBox(item);
                                              // setSelectedVariant("");
                                            }}
                                          />
                                          <img
                                            src={deleteIcon}
                                            alt="Marker"
                                            className="like-icon"
                                            onClick={() =>
                                              addVariantToKeyword(
                                                Object.keys(
                                                  currentKeyWordWithVariant
                                                )[0],
                                                item,
                                                "deleteVariant",
                                                currentVariant,
                                                chapter,
                                                pairingData?.genre
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </>
                                ))}
                            </div>
                            {enableVariantInputBox === "" && (
                              <div className="TypeInput">
                                <FontAwesomeIcon
                                  icon={faXmark}
                                  style={{ color: "red", cursor: "pointer" }}
                                  onClick={() => setCurrentVarientText("")}
                                />
                                <input
                                  style={{ color: "black" }}
                                  type="text"
                                  id="louie"
                                  placeholder="Type here"
                                  value={currentVariant}
                                  onChange={(e) => {
                                    setCurrentVarientText(e.target.value);
                                  }}
                                  autoFocus={true}
                                  onKeyDown={(event) =>
                                    (event?.key === "Enter" ||
                                      event?.type === "click") &&
                                    currentVariant.length > 0 &&
                                    addVariantToKeyword(
                                      Object.keys(currentKeyWordWithVariant)[0],
                                      currentVariant,
                                      "addVariant",
                                      "",
                                      chapter,
                                      pairingData?.genre
                                    )
                                  }
                                />
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  style={{ color: "green", cursor: "pointer" }}
                                  onClick={() =>
                                    currentVariant.length > 0 &&
                                    addVariantToKeyword(
                                      Object.keys(currentKeyWordWithVariant)[0],
                                      currentVariant,
                                      "addVariant",
                                      "",
                                      chapter,
                                      pairingData?.genre
                                    )
                                  }
                                />
                              </div>
                            )}
                            <div className="ApplyBtnDiv">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "flex-start",
                                  flexDirection: "column",
                                }}
                              >
                                <button
                                  className={`${
                                    (userEnteringGlossaryForVariantState?.isOn
                                      ? userEnteringGlossaryForVariantState.keywordWithVariant
                                      : currentKeyWordWithVariant[
                                          Object.keys(
                                            currentKeyWordWithVariant
                                          )[0]
                                        ]
                                    )?.values?.length > 0 &&
                                    selectedVariant !== ""
                                      ? "ApplytoEnable"
                                      : "Applyto"
                                  }`}
                                  onClick={() => {
                                    setApplyGlossary(!applyGlossary);
                                  }}
                                  disabled={
                                    !(
                                      (userEnteringGlossaryForVariantState?.isOn
                                        ? userEnteringGlossaryForVariantState.keywordWithVariant
                                        : currentKeyWordWithVariant[
                                            Object.keys(
                                              currentKeyWordWithVariant
                                            )[0]
                                          ]
                                      )?.values?.length > 0 &&
                                      selectedVariant !== ""
                                    )
                                  }
                                >
                                  Apply to{" "}
                                  <FontAwesomeIcon
                                    icon={
                                      isGlossaryVisible
                                        ? faCaretDown
                                        : faCaretUp
                                    }
                                    style={{ marginLeft: 10 }}
                                  />
                                </button>
                              </div>
                              {applyGlossary && (
                                <div className="dropdown">
                                  <ul>
                                    <li>
                                      <p
                                        onClick={() => {
                                          applyGlossaryToWholeBook(
                                            "Book",
                                            chapter
                                          );
                                        }}
                                      >
                                        This Book
                                      </p>
                                    </li>
                                    <li>
                                      <p
                                        onClick={() => {
                                          applyGlossaryToWholeBook(
                                            "Author",
                                            chapter
                                          );
                                        }}
                                      >
                                        This Author
                                      </p>
                                    </li>
                                    <li>
                                      <p
                                        onClick={() => {
                                          applyGlossaryToWholeBook(
                                            "Genre",
                                            chapter
                                          );
                                        }}
                                      >
                                        This Genre
                                      </p>
                                    </li>
                                    <li>
                                      <p
                                        onClick={() => {
                                          applyGlossaryToWholeBook(
                                            "Language",
                                            chapter
                                          );
                                        }}
                                      >
                                        Whole Language
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                    </h2>
                    {currentKeyWordWithVariant &&
                      Object.keys(currentKeyWordWithVariant).length === 0 && (
                        <p style={{ marginLeft: "50px" }}>
                          (You need to add keywords first)
                        </p>
                      )}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default TranslateBoxComponent;
