import axios from 'axios';
const useStatus = () => {

    const checkStatus = (userId) => {
        const url = 'https://appsecauth-ai.techo.camp/api/user/get-status';
          console.log('useStatus',{userId});
            if(userId){
                axios.post(url,
                  { id: userId },
              ).then((response) => {
                  if(response?.data?.status !== 'ACTIVE')
                    window.location.href = process.env.REACT_APP_AUTH_URL_LOGOUT;
              }).catch((error) => {
                  console.log(error);
              })
            }else {
              // window.location.href = process.env.REACT_APP_AUTH_URL;
            }
    }
   
      return {
        checkStatus
      }
}

export default useStatus
