import React from "react";
import "./DialogBox.scss";

const DialogBox = ({ message, onClose }) => {
  return (
    <div className="dialog-overlay">
      <div className="dialog-box">
        <p className="dialog-message">{message}</p>
        <button className="dialog-close" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default DialogBox;
