import { get, push, ref, set } from "firebase/database";
import { database, firestore } from "../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { getCurrentTime, getFormattedDate } from "./translationUtil";
import {
  ADD_APIS_RESPONSE_STATUS,
  ADD_EDITING_END_TIME,
  ADD_EDITING_START_TIME,
  CONCURRENT_EDITS_TRACKER,
  DOWNLOAD_BUTTON_CLICKS,
  FIND_AND_REPLACE_INTERACTIONS,
  SAVE_BUTTON_CLICKS,
  SCROLL_LOCK_BUTTON_CLICKS,
  USER_ACTIVITY_ANALYTICS,
} from "./fireStoreTypes";
import store from "../App/store";

export const recordEventActivity = async (userId, data = {}) => {
  try {
    const { bookId, paragraphId, paragraphNo, event, chapterNo } = data;
    const timestamp = new Date().toISOString();
    let newEventActivty = {
      bookId,
      paragraphNo,
      paragraphId,
      event,
      chapterNo,
      timestamp,
    };

    const realTimeEventActivityRef = ref(
      database,
      "/real-time-activities/" + userId + "/history/"
    );
    const newActivityRef = push(realTimeEventActivityRef);

    await set(newActivityRef, newEventActivty);
  } catch (e) {
    console.log(e);
  }
};

export const fetchUserFirebaseById = async (userId) => {
  try {
    // const userId = cookies['__Secure-next-auth.session-token'];
    const snapshot = await get(
      ref(database, `/real-time-activities/${userId}`)
    );
    const userDetails = snapshot.val();
    return userDetails;
  } catch (e) {
    console.log(e);
  }
};

export const updateUserFirebaseFileCount = async (userId) => {
  try {
    let userFileCountRef = ref(
      database,
      `/real-time-activities/${userId}/fileCount`
    );
    const snapshot = await get(userFileCountRef);
    let exisitingFileCount = snapshot.val();
    exisitingFileCount = exisitingFileCount || 0;
    await set(userFileCountRef, ++exisitingFileCount);
  } catch (e) {
    console.log(e);
  }
};

const getCurrentEnvironment = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "dev":
      return "dev_data";
    case "qa":
      return "qa_data";
    case "prod":
      return "prod_data";
    default:
      return "dev_data";
  }
};

/**
 * This function performs multiple actions such as it stores or add new active user
 * ,get last active time of user and remove the user from firestore
 * @param {*} translationId
 * @param {*} operation  "update" | "get" | "remove"
 * @param {*} userEmail   loggedIn user email
 * @param {*} lastActiveTime  user active time (optional)
 * @returns
 */

export const userLastActiveTime = async (
  translationId,
  operation,
  userEmail,
  lastActiveTime = "null",
  bookMarkData
) => {
  try {
    const envData = getCurrentEnvironment();
    let storeData = await getFireStoreData(CONCURRENT_EDITS_TRACKER);
    const currentBookUserList=storeData[translationId] ?? []
    let updatedUserList = [];
    const activeUserData = currentBookUserList?.find(
      (u) => u.user === userEmail
    );
    let updatedBookMarkData=storeData.bookMarkData ?? {}
    if (operation === "update") {
      if (activeUserData) {
        activeUserData.lastActiveTime = lastActiveTime;
        updatedUserList = currentBookUserList;
      } else {
        updatedUserList = [
          { user: userEmail, lastActiveTime: lastActiveTime },
          ...currentBookUserList,
        ];
        console.log("updatedUserList", updatedUserList);
      }
      if(bookMarkData?.lastOpenedChapterNo)
        updatedBookMarkData[userEmail]={[translationId]:bookMarkData}
    } else if (operation === "remove") {
      updatedUserList = currentBookUserList.filter(
        (userData) => userData.user !== userEmail
      );

      loggedInUserActivity({
        bookId: translationId,
        activity: ADD_EDITING_END_TIME,
      });
 
    } else if (operation === "get") {
      if (activeUserData) {
        return activeUserData.lastActiveTime;
      }
      return;
    } else {
      return;
    }

    if (operation === "remove" || operation === "update") {
      let updatedData={ [translationId]: updatedUserList }

      if(bookMarkData?.lastOpenedChapterNo)
        updatedData.bookMarkData=updatedBookMarkData

      const dataDocRef = doc(firestore, CONCURRENT_EDITS_TRACKER, envData);
      await setDoc(
        dataDocRef,
        updatedData,
        { merge: true }
      );
    }
  } catch (error) {
    console.log("error in updating last active time in firestore", error);
  }
};


// export const storeBookMarkData= async ( translationId,
//   userEmail,bookMarkData)=>{
//     if (!translationId || !userEmail || bookMarkData) return;

//     const envData = getCurrentEnvironment();
//     let storeData = await getFireStoreData(CONCURRENT_EDITS_TRACKER);
//     let updatedBookMarkData = storeData.bookMarkData ?? {};
//     if (bookMarkData?.lastOpenedChapterNo)
//       updatedBookMarkData[translationId] = bookMarkData;

//     const dataDocRef = doc(firestore, CONCURRENT_EDITS_TRACKER, envData);
//     await setDoc(
//       dataDocRef,
//       { bookMarkData: updatedBookMarkData },
//       { merge: true }
//     );
// }

export const getFireStoreData = async (storeName, feild) => {
  try {
    const envData = getCurrentEnvironment();
    const docRef = doc(firestore, storeName, envData);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data(); 
      return data;
    } else {
      if (storeName === USER_ACTIVITY_ANALYTICS) return {};
      console.log("No such document!");
      // create doc
      const dataDocRef = doc(firestore, storeName, envData);
      await setDoc(dataDocRef, { [feild]: [] }, { merge: true });
    }
  } catch (error) {
    console.log("error in getting user list from firestore", error);
  }
};

const getAvailableBookDetails = (
  userOpenedBookDetails,
  userUploadedBookDetails
) => {
  return {
    translationId:
      userOpenedBookDetails?.link ??
      userUploadedBookDetails?.translationId ??
      "none",
    bookTitle:
      userOpenedBookDetails?.title ??
      userUploadedBookDetails?.bookTitle ??
      "none",
    authorName:
      userOpenedBookDetails?.author ??
      userUploadedBookDetails?.authorName ??
      "none",
  };
};

export const loggedInUserActivity = async ({
  bookId,
  activity,
  userEditingEndTime,
  apiResponse,
}) => {
  try {
    const reduxStore = store?.getState();
    const loggedInUserEmail = reduxStore?.main?.loggedInUserEmail;
    const openedBookInfo = reduxStore?.main?.userOpenedBookInfo ?? {};
    let bookDetails = getAvailableBookDetails(openedBookInfo, apiResponse);
    const translationId = bookId ?? bookDetails.translationId;
    console.log(
      "count calls",
      activity,
      openedBookInfo,
      translationId
    );
    if (!loggedInUserEmail) return;
    const envData = getCurrentEnvironment();
    const storeData = await getFireStoreData(USER_ACTIVITY_ANALYTICS);
    console.log("available store data", storeData);
    const currentTime = new Date().toISOString();
    const formattedDate = getFormattedDate(currentTime);
    let currentDateData = storeData[formattedDate] ?? [];
    // const userActivityLogs=storeData.userActivityLogs ?? []
    // let userActivityDescription = "";
    let loggedInUserData = currentDateData.find(
      (userData) => userData.userEmail === loggedInUserEmail
    );

    if (!loggedInUserData) {
      loggedInUserData = {
        userEmail: loggedInUserEmail,
        editingTime: [],
        downloadButtonClicks: 0,
        findAndReplaceInteractions: 0,
        scrollLockButtonclicks: 0,
        saveButtonClicks: 0,
        apisResponseStatus: { success: [], error: [] },
      };
      currentDateData.push(loggedInUserData);
    }

    // console.log("check Data" ,translationId,formattedDate,userData,currentDateData)
    switch (activity) {
      case ADD_EDITING_START_TIME:
        if (!currentTime) return;
        loggedInUserData.editingTime.push({
          translationId: translationId,
          startTime: currentTime,
          endTime: "",
          bookTitle:bookDetails.bookTitle,
          authorName:bookDetails.authorName
        });
        //  userActivityDescription=`opened ${openedBookTitle ?? "anonymous"} book`
        break;
      case ADD_EDITING_END_TIME:
        if (!loggedInUserData.editingTime.length || bookDetails?.bookTitle!=="none") return;
        loggedInUserData.editingTime = loggedInUserData.editingTime.map(
          (editingTime) => {
            if (!editingTime.endTime && editingTime.translationId === translationId)
              editingTime.endTime = userEditingEndTime
                ? userEditingEndTime
                : currentTime;

            return editingTime;
          }
        );
        // userActivityDescription=`closed ${openedBookTitle ?? "anonymous"} book`
        break;
      case FIND_AND_REPLACE_INTERACTIONS:
        loggedInUserData.findAndReplaceInteractions += 1;
        //  userActivityDescription=`opened find and replace dialog in ${openedBookTitle ?? "anonymous"} book`
        break;
      case SCROLL_LOCK_BUTTON_CLICKS:
        loggedInUserData.scrollLockButtonclicks += 1;
        // userActivityDescription=`clicked scroll lock button in ${openedBookTitle ?? "anonymous"} book`
        break;
      case ADD_APIS_RESPONSE_STATUS:
        loggedInUserData.apisResponseStatus[apiResponse.status]?.push({
          apiEndPoint: apiResponse.apiEndPoint,
          responseMessage: apiResponse.responseMessage,
          date: currentTime,
          bookDetails: {
            translationId: translationId,
            bookTitle: bookDetails.bookTitle,
            authorName: bookDetails.authorName,
          },
        });

        if (apiResponse.apiEndPoint === "/v2/export/document")
          loggedInUserData.downloadButtonClicks += 1;

        if (apiResponse.apiEndPoint === "update_translated_data")
          loggedInUserData.saveButtonClicks += 1;

        break;
    }

    // userActivityLogs.push({
    //   user: loggedInUserEmail,
    //   bookId: translationId,
    //   activity: activity,
    //   description: userActivityDescription,
    // });

    currentDateData = currentDateData.map((userData) =>
      userData.userEmail === loggedInUserEmail ? loggedInUserData : userData
    );

    const dataDocRef = doc(firestore, USER_ACTIVITY_ANALYTICS, envData);
    await setDoc(
      dataDocRef,
      {
        [formattedDate]: currentDateData,
        // userActivityLogs:userActivityLogs
      },
      { merge: true }
    );
  } catch (error) {
    console.log("error in storing logged in user activity",error.message)
  }
};
