import { toast } from "react-toastify";
import apiUrls from "../api/apiUrl";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";
import axios from "axios";

const getDownloadLink = async (pairedBookId, language, assistant_name, temperature, option, email) => {
  const id = option ? toast.loading(`Book translation is in progress, email will be sent once completed`) :  toast.loading("Downloading Book...")

  if(option) {
    toast.update(id, {
      // render: "Download Successful!",
      type: "success",
      isLoading: false,
      autoClose: 3000,
    });
  }

  try {
    const data = JSON.stringify({
      unique_string: pairedBookId,
      download_book_language: "translated",
      assistant_name: assistant_name,
      custom_temperature: temperature,
      generate_paras_if_not_exist: `${option}`,
      stakeholder_email: email?[email]:[]
    });
    // const response = await fetchData(
    //   "https://hc-book-loadbalancer-gki2roh76a-uc.a.run.app/download_book",
    //   "POST",
    //   JSON.stringify({
    //     unique_string: pairedBookId,
    //   })
    // );

    const response = await axios.post(apiUrls.downloadBooksLink, data, {
      baseURL: process.env.REACT_APP_BACKEND_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });


    if (response?.data?.signed_url) {
      if(option)
        return
      // const data = await response.json();
      const signedUrl = response?.data?.signed_url
      console.log("signedUrl", signedUrl);
      // setDownloadLink(signedUrl);
      const link = document.createElement("a");
      link.href = signedUrl;
      link.setAttribute("download", ""); // Name of the file when downloaded
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // return signedUrl;
      toast.update(id, {
        render: "Download Successful!",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      // toast('Download Successfull!')
      logEvent(analytics, 'download_success', {success: true})
    }
  } catch (error) {
    console.error("Error:", error);
    toast.update(id, {
      render: 'Download Failed!',
      type: "warning",
      isLoading: false,
      autoClose: 1000,
    });
    // toast('Download Failed!')
    logEvent(analytics, 'download_failed', {error: error.message})
    // return null;
  }
};

export default getDownloadLink;


// onClick={() => {
//   dispatch(addKeywords({
//     payload: {
//       unique_string: pairedBookId,
//       glossary_item: {
//         book_level: [
//           {
//             Test: {
//               values: ["TEST", "Testing"],
//               scope: "book_level",
//             },
//           },
//         ],
//       },
//     },
//   fetchData
//   }))

// }}
