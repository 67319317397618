import { logEvent } from "firebase/analytics";
import apiUrls from "../../api/apiUrl";
import { ADD_GLOSSARY_KEYWORD, SET_GLOSSARY_ITEM_WITH_KEYWORD, SET_IS_GLOSSARY_CALLED_FALSE } from "../type";
import { analytics } from "../../firebase";

export const addKeywords =
  ({ payload ,fetchData,forReduxUpdate}) =>
  async (dispatch) => {

    console.log("Keycalled",payload)
    try {
      // const response = await fetchData(apiUrls.getBooksList + encodedStatus);
      const response = await fetchData(apiUrls.addKeyWordApiURL,"POST",payload);
      console.log("Keyresponse",response)
      if(response.message === "Glossary item added successfully"){
        logEvent(analytics, 'add_glossary_keyword_success', {success: true})
        dispatch(setAddKeyWord(payload.glossary_item.book_level))
      }


      // const response = await fetchData(apiUrls.getBooksList + type);
    } catch (error) {
      console.log("Key Error",error)
      logEvent(analytics, 'add_glossary_keyword_failed', {error: error.message})
    }
  };

  export const applyGlossaryToBook=
  ({ payload ,fetchData,notifyCallback}) =>
  async (dispatch) => {

    try {
      // const response = await fetchData(apiUrls.getBooksList + encodedStatus);
      const response = await fetchData(apiUrls.applyGlossaryItemToBook,"POST",payload);
      if(response){
        // dispatch(setAddKeyWord(payload.glossary_item.book_level))
        // notifyCallback()
        logEvent(analytics, 'apply_glossary_to_book_success', {success: true})
        return response
      }


      // const response = await fetchData(apiUrls.getBooksList + type);
    } catch (error) {
      logEvent(analytics, 'apply_glossary_to_book_failed', {error: error.message})
      console.log("Key Error",error)
    }
  };



  export const setAddKeyWord= (payload) => (dispatch) => {
    dispatch({
      type: ADD_GLOSSARY_KEYWORD,
      payload,
    });
  };

  export const setGlossaryItemWithKeyword= (payload) => (dispatch) => {
    dispatch({
      type: SET_GLOSSARY_ITEM_WITH_KEYWORD,
      payload,
    });
  };
  
  export const setIsGlossaryCalledFalse=()=>(dispatch)=>{
    dispatch({
      type: SET_IS_GLOSSARY_CALLED_FALSE,
    });
  }