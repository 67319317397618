import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import apiUrls from "../api/apiUrl";
import { ADD_APIS_RESPONSE_STATUS } from "../utils/fireStoreTypes";
import { loggedInUserActivity } from "../utils/firebaseActions";

const fetchChapter = (translationId, chapter) => {
  // @todo move v2 to the env file.
  return axios
    .post(
      ` ${process.env.REACT_APP_BACKEND_URL}/v2/${apiUrls.getTranslation}?translationId=${translationId}&chapter=${chapter}`,
      {}
    )
    .then((response) => {
      loggedInUserActivity({
        activity: ADD_APIS_RESPONSE_STATUS,
        apiResponse: {
          status: "success",
          apiEndPoint: apiUrls?.getTranslation,
          translationId: translationId,
          responseMessage: "Translation fetched successfully",
        },
      });
      return response.data;
    });
};

const updateTranslatedChapter = (translated_data, chapter, translation_id) => {
  return axios
    .post(
      ` ${process.env.REACT_APP_BACKEND_URL}/v2/${apiUrls.updateTranslation}`,
      {
        translated_data,
        chapter,
        translation_id,
      }
    )
    .then((response) => {
      loggedInUserActivity({
        activity: ADD_APIS_RESPONSE_STATUS,
        apiResponse: {
          status: "success",
          apiEndPoint: apiUrls?.updateTranslation,
          translationId: translation_id.translation_id,
          responseMessage: "Translation updated successfully",
        },
      });
      return response.data;
    });
};

function useFetchTranslatedChapter({ successHandler }) {
  const mutation = useMutation({
    mutationFn: ({ translationId, currentChapter }) =>
      fetchChapter(translationId, currentChapter),
    onSuccess: (res) => {
      successHandler(res);
    },
  });

  if (mutation.isError) {
    loggedInUserActivity({
      activity: ADD_APIS_RESPONSE_STATUS,
      apiResponse: {
        status: "error",
        apiEndPoint: apiUrls?.getTranslation,
        translationId: mutation?.variables?.translationId,
        responseMessage: mutation?.error ?? "error in fetching translation",
      },
    });
  }
  return mutation;
}

function useSaveTranslatedChapter({ successHandler }) {
  const mutation = useMutation({
    mutationFn: ({ translated_data, chapter, translation_id }) =>
      updateTranslatedChapter(translated_data, chapter, translation_id),
    onSuccess: (res) => {
      successHandler(res);
    },
  });

  if (mutation.isError) {
    loggedInUserActivity({
      activity: ADD_APIS_RESPONSE_STATUS,
      apiResponse: {
        status: "error",
        apiEndPoint: apiUrls?.updateTranslation,
        translationId: mutation?.variables?.translation_id,
        responseMessage: mutation?.error ?? "error in fetching translation",
      },
    });
  }

  return mutation;
}

export { useFetchTranslatedChapter, useSaveTranslatedChapter };
