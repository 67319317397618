import "./App.scss";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from "@mui/material/styles";
import ScopedCssBaseline from "@mui/material/ScopedCssBaseline";
import { StyledEngineProvider } from '@mui/material/styles';

import UploadScreen from "./Pages/UploadScreen/UploadScreen";
import PairsPage from "./Pages/PairsPage/PairsPage";
import Dashboard from "./Pages/Dashboard/Dashboard";
import { useCookies } from "react-cookie";
import useStatus from "./hooks/useStatus";
import ReviewTranslationPage from "./Pages/ReviewTranslation/ReviewTranslationPage";
import TranslatePage from "./Pages/TranslatePage/TranslatePage";
import { CacheContextProvider } from "./context";
import { useDispatch } from "react-redux";
import { setLoggedInUserEmail } from "./App/DashboardActions/action";
import { getEmailThroughSession } from "./utils/translationUtil";
const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: "#3B37FF",
          dark: "#1E1C80",
        },
      },
    },
    dark: {
      palette: {
        primary: {
          main: "#3B37FF",
          dark: "#1E1C80",
        },
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    button: {
      textTransform: "none",
    },
  },
});

function App() {
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const statusHook = useStatus();
  const dispatch=useDispatch()

  const email = cookies["email"];
  let isNotSignIn = !cookies["__Secure-next-auth.session-token"];

  if (process.env.REACT_APP_SKIP_LOGIN === "true") {
    isNotSignIn = false;
  }

  const userId = cookies["userId"];
  // const userId = '65dc9c4dfa03a1e0b7338ce2'

  if (userId) {
    localStorage.setItem("userId", userId);
  }

  const handleRedirect = () => {
    window.location.href = process.env.REACT_APP_AUTH_URL;
  };

  useEffect(() => {
    const setUserEmail = async () => {
      let loggedInUserEmail = email;
      if (!loggedInUserEmail)
        loggedInUserEmail = await getEmailThroughSession();

      if (!loggedInUserEmail && process.env.REACT_APP_SKIP_LOGIN != "true")
        handleRedirect();

      dispatch(setLoggedInUserEmail(loggedInUserEmail));
    };

    setUserEmail();    
    statusHook.checkStatus();
  }, [userId]);

  return (
    // Allow tailwind to override MUI CSS/styles by applying `injectFirst`
    <StyledEngineProvider injectFirst>
    <CssVarsProvider theme={theme}>
      <ScopedCssBaseline className="App">
        <div className="container">
          {isNotSignIn ? (
            handleRedirect()
          ) : (
            <Routes>
              <Route exact path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route
                exact
                path="/upload-books"
                element={<UploadScreen popupEmail={email} />}
              />
              <Route path="/pairsPage" element={<PairsPage />} />
              <Route
                path="/review-translation/:id"
                element={<ReviewTranslationPage />}
              />
              <Route path="/translatePage" element={<TranslatePage />} />
            </Routes>
          )}
        </div>
      </ScopedCssBaseline>
    </CssVarsProvider>
    </StyledEngineProvider>
  );
}

export default App;
