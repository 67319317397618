import React, { useState, useCallback, useEffect } from "react";
import ReactFlow, { addEdge, useNodesState, useEdgesState } from "reactflow";
import "./TranslateComponent.scss";
import "reactflow/dist/style.css";
import apiUrls from "../../api/apiUrl";
import useApi from "../../api/useAPi";
import likeIcon from "../../assets/Frame 1.svg";
import { RiArrowLeftLine } from "react-icons/ri";
import marker from "../../assets/Frame 4.svg";
import rightArrow from "../../assets/rightArrow.svg";
import leftArrow from "../../assets/leftArrow.svg";
import TranslateParagraphPairComponent from "../TranslateParagraphPairComponent/TranslateParagraphPairComponent";
import Loader from "../Loader/Loader";
import bookview from "../../assets/bookview.svg";
import infoImg from "../../assets/info.svg";
import linebr from "../../assets/linebr.svg";
import optmor from "../../assets/optmor.svg";
import { styled } from "@mui/material/styles";
import download from "../../assets/download_icon.svg";
import downloadwhite from "../../assets/download_white.svg";
import getDownloadLink from "../../utils/downloadBook";
import MergeIcon from "../../assets/mergeIcon.svg";
import WhiteMergeIcon from "../../assets/mergewhite.svg";
import TickIcon from "../../assets/tick-icon.svg";
import SidetoolBar from "../SideToolbar/Sidetoolbar";
import LockOutlined from '@mui/icons-material/Lock';

import user from "../../assets/contactIcon.svg";
import ConfidenceRatingInfo from "../ConfidenceRatingInfo/ConfidenceRatingInfo";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import paginationTypes from "../../Constants/PaginationTypes";
import { IconButton, TextField, Tooltip } from "@mui/material";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from "@mui/base";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ToastContainer, toast } from "react-toastify";
import { fetchPairedDataRedux } from "../../App/DashboardActions/action";
import { useDispatch } from "react-redux";
import DownloadModal from "../DownloadModal/DownloadModal";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  margin: "0px 15px",
  // borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    // borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#224e8e" : "#224e8e",
  },
}));

const onNodeDragStart = (event, node) => console.log("drag start", node);
const onNodeDragStop = (event, node) => console.log("drag stop", node);
const onNodeClick = (event, node) => console.log("click node", node);
const onPaneClick = (event) => console.log("onPaneClick", event);
const onPaneScroll = (event) => console.log("onPaneScroll", event);
const onPaneContextMenu = (event) => console.log("onPaneContextMenu", event);

const TranslateComponent = ({
  showEditPairing,
  onEditPairingClick,
  pairingDatas,
  sourceColumnHeader,
  targetColumnHeader,
  showViewAsEnglish,
  bookTitle,
  authorName,
  handleGoBack,
  setPairingData,
  paginationMode,
  infiniteScroll,
  paragraphPerPage = 4,
  setCurrentPage,
  currentPage,
  popupEmail
}) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const dispatch = useDispatch();
  const [selectedParagraphIndex, setSelectedParagraphIndex] = useState(null);
  const [mergeParagraphData, setMergeParagraphData] = useState([]);
  const onConnect = useCallback((params) => {
    setEdges((els) => addEdge(params, els));
  }, []);
  const [isSelectable, setIsSelectable] = useState(true);
  const [mergeParaMode, setMergeParaMode] = useState(false);
  const [isDraggable, setIsDraggable] = useState(true);
  const mySelectedLanguage = localStorage.getItem('myLang')
  const mySelectedTemperature = localStorage.getItem('myTemp')
  const mySelectedModel = localStorage.getItem('myModel')
  const [isConnectable, setIsConnectable] = useState(true);
  const [zoomOnScroll, setZoomOnScroll] = useState(false);
  const [panOnScroll, setPanOnScroll] = useState(false);
  const [panOnScrollMode, setPanOnScrollMode] = useState("free");
  const [zoomOnDoubleClick, setZoomOnDoubleClick] = useState(false);
  const [panOnDrag, setpanOnDrag] = useState(false);
  const [captureZoomClick, setCaptureZoomClick] = useState(false);
  const [captureZoomScroll, setCaptureZoomScroll] = useState(false);
  const [captureElementClick, setCaptureElementClick] = useState(false);
  const [textSize, setTextSize] = useState(16);

  const [checkedParagraphs, setCheckedParagraphs] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [progressBar, setProgressBar] = useState(0);
  const [showConfirmTranslation, setShowConfirmTranslation] = useState([]);
  const [currentPageForInfiniteScroll, setCurrentPageForInfiniteScroll] = useState(1);

  const [assistantOptions, setAssistantOptions] = useState([])
  const [selectedLanguage, setSelectedLanguage] = React.useState(mySelectedLanguage);
  const [selectedModelName, setSelectedModelName] = React.useState(mySelectedModel)
  const [selectedTemperature, setSelectedTemperature] = React.useState(mySelectedTemperature);
  const [toggleCompare, setToggleCompare] = React.useState(false);
  const [pageInput, setPageInput] = useState(currentPage);

  const [selectedComparisionModelName, setSelectedComparisionModelName] = React.useState('')
  const [selectedComparisionTemperature, setSelectedComparisionTemperature] = React.useState("0.5");

  const [downloadModalVisible, setDownloadModalVisible] = useState(false)

  const getAllAssistants = async () => {
    const assistants = await fetchData(apiUrls.getAssistants, "GET");
    const resultArray = assistants.map(({ model_name, assistant_name, target_language, temperature }) => ({
      model_name,
      assistant_name,
      target_language,
      temperature
    }));
    setAssistantOptions(resultArray);
  };

  useEffect(() => {
    getAllAssistants();
  }, []);
  
  // Get the URL of the current page
  const currentURL = window.location.href;

  // Get the 'pairedBookId' query parameter from the URL
  const pairedBookId = getQueryParam(currentURL, "pairedBookId");

  

  const downloadAssistants = assistantOptions.find((assistant) => 
      assistant.target_language===selectedLanguage && 
      assistant.model_name===selectedModelName
    );

  const getDownload = (option, email) => {
    getDownloadLink(pairedBookId, selectedLanguage, downloadAssistants?.assistant_name, selectedTemperature, option, email);
  };

  // const splitArray = pairingDatas?.assistant_name.split('-');
  // const secondElement = splitArray.slice(1).join('-');

  
  const getSelectedTranslationModeForFirst = pairingDatas?.auto_ai_resource.resources[0]?.textTranslatedLanguageSentences?.
  find((sentence) => (
    sentence.target_language===selectedLanguage
    && sentence.model_name===selectedModelName
    && +sentence.temperature === +selectedTemperature
    ))?.sentence?.text
    const getSelectedTranslationModeForSecond = pairingDatas?.auto_ai_resource.resources[1]?.textTranslatedLanguageSentences?.
  find((sentence) => (
    sentence.target_language===selectedLanguage
    && sentence.model_name===selectedModelName
    && +sentence.temperature === +selectedTemperature
    ))?.sentence?.text
   
    const disableMergePara=getSelectedTranslationModeForFirst && getSelectedTranslationModeForSecond
  const getFirstTwoParagraphs=!!(pairingDatas?.auto_ai_resource.resources[0]?.textTranslatedLanguageSentences[0]?.sentence?.
  text && pairingDatas?.auto_ai_resource.resources[1]?.textTranslatedLanguageSentences[0]?.sentence?.
  text)


  const handleMergeParagraph=()=>{
    let currentPara=pairingDatas.auto_ai_resource.resources[mergeParagraphData[0]]
    let nextPara=pairingDatas.auto_ai_resource.resources[mergeParagraphData[1]]
  

   
    // const newData = appendSentences(currentPara, nextPara);
    // const modifiedObject = clearTranslationSentences(nextPara)
    // const newPairingData= {...pairingDatas,auto_ai_resource:{...pairingDatas?.auto_ai_resource,resources:pairingDatas?.auto_ai_resource?.resources?.map((data,ind)=> ind===mergeParagraphData[0]?newData:data)}}
    // const newPairingData2= {...pairingDatas,auto_ai_resource:{...pairingDatas?.auto_ai_resource,resources:pairingDatas?.auto_ai_resource?.resources?.map((data,ind)=> ind===mergeParagraphData[1]?modifiedObject:data)}}
   
    

    // setPairingData(newPairingData)
    const dataForMerge={
      "unique_string":pairingDatas.unique_string,
      "first_paragraph_id":currentPara._id,
      "second_paragraph_id":nextPara._id 
      }
      const book_id=pairingDatas.unique_string
      updateBook(dataForMerge)
    // updateTheMatch(newPairingData)
    // updateTheMatch(newPairingData2)
    // setMergeParagraphData([])
    // setMergeParaMode(false)
    // toast("Paragraphs merged")
  }

  function clearTranslationSentences(object) {
    if (object.hasOwnProperty('textTranslatedLanguageSentences')) {
        object.textTranslatedLanguageSentences = [];
    }

    if (object.hasOwnProperty('textOriginalLanguageSentences')) {
        object.textOriginalLanguageSentences = [];
    }

    return object;
}
// const modifiedObject = clearTranslationSentences(inputObject);
// console.log(modifiedObject);






  function appendSentences(data1, data2) {
    // Get the last sequence number from data1.textOriginalLanguageSentences
    let lastOriginalSequenceNumber = data1.textOriginalLanguageSentences.length > 0 ?
        data1.textOriginalLanguageSentences[data1.textOriginalLanguageSentences.length - 1].sequenceNumber : 0;

    // Get the last sequence number from data2.textTranslatedLanguageSentences
    let lastTranslatedSequenceNumber = data2.textTranslatedLanguageSentences.length > 0 ?
        data2.textTranslatedLanguageSentences[data2.textTranslatedLanguageSentences.length - 1].sequenceNumber : 0;

    // Loop through each sentence in data2.textOriginalLanguageSentences
    for (const sentence of data2.textOriginalLanguageSentences) {
        // Increment the sequence number and assign it to the current sentence
        sentence.sequenceNumber += lastOriginalSequenceNumber;

        // Append the sentence to data1.textOriginalLanguageSentences
        data1.textOriginalLanguageSentences.push(sentence);
    }

    // Loop through each sentence in data2.textTranslatedLanguageSentences
    for (const sentence of data2.textTranslatedLanguageSentences) {
        // Increment the sequence number and assign it to the current sentence
        sentence.sequenceNumber += lastTranslatedSequenceNumber;

        // Append the sentence to data1.textTranslatedLanguageSentences
        data1.textTranslatedLanguageSentences.push(sentence);
    }

    return data1;
}

// Test the function with your data


const updateTheMatch = async (data) => {
  try {
   const res = await fetchData(apiUrls.updatepairs, "POST", data);
    if (res.status === "success") {
    }
    // onEditPairingClick();
  } catch (err) {
    console.log(err);
  }
};
const updateBook = async (data) => {
  const id = toast.loading("Please wait...");
  try {

   const res = await fetchData(apiUrls.mergeParagraphs, "POST", data);
    if (res.message=== "merge successful") {
      dispatch(fetchPairedDataRedux({ fetchData, pairedBookId, currentPage },false));
      
      setMergeParagraphData([])
      setMergeParaMode(false)
      
      setTimeout(function() {
        toast.update(id, {
          render: "Merge Applied Successfully",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });
     }, 3000);
    }
    // onEditPairingClick();
  } catch (err) {
    toast.update(id, {
      render: "Please try again",
      type: "warning",
      isLoading: false,
      autoClose: 5000,
    });
    console.log(err);
  }
};




// Test the function with your data





  useEffect(() => {
    let modelName=""
    let temperatureHere=""
    let language = ""
    assistantOptions?.forEach((assistant) => {
      if (pairingDatas?.assistant_name === assistant?.assistant_name) {
        modelName=assistant.model_name;
        language=assistant.target_language
        temperatureHere=assistant.temperature
      }
    })

    if(mySelectedLanguage === null) {
      setSelectedLanguage(language)
    }
    if(mySelectedTemperature === null) {
      setSelectedTemperature(pairingDatas?.temperature)
    }

    if(mySelectedModel === null) {
      setSelectedModelName(modelName)
    }
    setSelectedComparisionTemperature([0.1,0.5,1,"0.1","0.5","1.0"].find((temperature) => temperature !== temperatureHere))
    setSelectedComparisionModelName(modelName)
    // setSelectedComparisionModelName(assistantOptions.find((assistant) => assistant.model_name !== modelName)?.model_name)
  },[assistantOptions])

  const handleChange = (event) => {
    localStorage.setItem('myLang', event.target.value)
    setSelectedLanguage(event.target.value);
  };

  const handleModel = (event) => {
    localStorage.setItem('myModel', event.target.value)
    setSelectedModelName(event.target.value);
  }

  const handleTemperature = (event) => {
    localStorage.setItem('myTemp', event.target.value)
    setSelectedTemperature(event.target.value);
  }

  const handleComparisionModel = (event) => {
    setSelectedComparisionModelName(event.target.value);
  }

  const handleComparisionTemperature = (event) => {
    setSelectedComparisionTemperature(event.target.value);
  }


  useEffect(() => {
  },[selectedLanguage])

  useEffect(() => {
    generateNodesAndEdges(selectedParagraphIndex);
  }, [selectedParagraphIndex]);

  const handleEditClick = (paragraphIndex) => {
    setSelectedParagraphIndex(paragraphIndex);
    onEditPairingClick();
  };

  const toggleTooltip = () => {
    setShowTooltip((prev) => !prev);
  };

  const truncatedTitle = bookTitle.length > 28 ? bookTitle.substring(0, 28) + '...' : bookTitle;
  const truncatedName= authorName.length > 28 ? authorName.substring(0, 28) + '...' : authorName;

  // const generateNodesAndEdges = (selectedParagraphIndexes) => {
  //   const newNodes = [];
  //   const newEdges = [];

  //   const resources = pairingDatas.auto_ai_resource.resources;

  //   let currentY = 60;
  //   const yOffsetChapter = 20;
  //   const yOffsetParagraph = 20;

  //   const containerWidth = document.querySelector(".container").clientWidth;
  //   const dutchPositionX = (50 / 100) * containerWidth;

  //   let lastSentenceY = currentY;
  //   resources.forEach((item) => {
  //     const chapterKey = item.chapter.sequenceNumber;
  //     const paragraphKey = item.paragraph.sequenceNumber;

  //     const englishParagraph = item.textOriginalLanguageSentences;
  //     const dutchParagraph = item.textTranslatedLanguageSentences;

  //     if (
  //       (Array.isArray(selectedParagraphIndexes) &&
  //         selectedParagraphIndexes.includes(item._id)) ||
  //       item._id === selectedParagraphIndexes
  //     ) {
  //       englishParagraph.forEach((englishSentenceData, index) => {
  //         const english = englishSentenceData.sentence.text;
  //         const dutch = dutchParagraph[index].sentence.text;

  //         const englishNodeId = `english-${englishSentenceData.sentenceId}`;
  //         const dutchNodeId = `dutch-${dutchParagraph[index].sentenceId}`;

  //         const englishNode = {
  //           id: englishNodeId,
  //           type: "input",
  //           data: { label: english },
  //           position: { x: 20, y: lastSentenceY },
  //           sourcePosition: "right",
  //         };

  //         const dutchNode = {
  //           id: dutchNodeId,
  //           type: "output",
  //           data: { label: dutch },
  //           position: { x: dutchPositionX, y: lastSentenceY },
  //           targetPosition: "left",
  //         };

  //         const edge = {
  //           id: `interaction-e-${chapterKey}-${paragraphKey}-${index}`,
  //           source: englishNodeId,
  //           target: dutchNodeId,
  //           style: { stroke: "", strokeWidth: 3 },
  //         };

  //         newNodes.push(englishNode);
  //         newNodes.push(dutchNode);
  //         newEdges.push(edge);

  //         lastSentenceY += 100;
  //       });

  //       lastSentenceY += yOffsetParagraph;
  //     }
  //   });

  //   currentY = lastSentenceY + yOffsetChapter;

  //   console.log("insidde generate", newEdges);
  //   setNodes(newNodes);
  //   setEdges(newEdges);
  // };

  const generateNodesAndEdges = (selectedParagraphIndexes) => {
    const newNodes = [];
    const newEdges = [];

    const resources = pairingDatas.auto_ai_resource.resources;

    let currentY = 60;
    const yOffsetChapter = 20;
    const yOffsetParagraph = 20;

    const containerWidth = document.querySelector(".container").clientWidth;
    const dutchPositionX = (50 / 100) * containerWidth;

    let lastSentenceY = currentY;
    resources?.forEach((item) => {
      const chapterKey = item.chapter.sequenceNumber;
      const paragraphKey = item.paragraph.sequenceNumber;

      const englishParagraph = item.textOriginalLanguageSentences;
      const dutchParagraph = item.textTranslatedLanguageSentences;

      if (
        (Array.isArray(selectedParagraphIndexes) && selectedParagraphIndexes.includes(item._id)) ||
        item._id === selectedParagraphIndexes
      ) {
        englishParagraph?.forEach((englishSentenceData, index) => {
          const english = englishSentenceData.sentence.text;
          const dutch = dutchParagraph[index].sentence.text;

          const englishNodeId = `english-${englishSentenceData.sentenceId}`;
          const dutchNodeId = `dutch-${dutchParagraph[index].sentenceId}`;

          const englishNode = {
            id: englishNodeId,
            type: "input",
            data: { label: english },
            position: { x: 20, y: lastSentenceY },
            sourcePosition: "right",
            chapterKey: chapterKey,
            paragraphKey: paragraphKey,
            sentenceSequence: englishSentenceData.sequenceNumber,
            // style: { borderLeft: "15px solid #87B7FF" },
          };

          const dutchNode = {
            id: dutchNodeId,
            type: "output",
            data: { label: dutch },
            position: { x: dutchPositionX, y: lastSentenceY },
            targetPosition: "left",
            chapterKey: chapterKey,
            paragraphKey: paragraphKey,
            sentenceSequence: dutchParagraph[index].sequenceNumber,
            // style: { borderRight: "15px solid #FF9ABB;" },
          };

          // const edge = {
          //   id: `interaction-e-${chapterKey}-${paragraphKey}-${index}`,
          //   source: englishNodeId,
          //   target: dutchNodeId,
          //   style: { stroke: "", strokeWidth: 3 },
          // };

          newNodes.push(englishNode);
          newNodes.push(dutchNode);
          // newEdges.push(edge);

          lastSentenceY += 100;
        });

        lastSentenceY += yOffsetParagraph;
      }
    });

    resources?.forEach((item) => {
      const chapterKey = item.chapter.sequenceNumber;
      const paragraphKey = item.paragraph.sequenceNumber;

      const dutchParagraph = item.textTranslatedLanguageSentences;

      dutchParagraph?.forEach((dutchSentenceData) => {
        const targetSentenceId = dutchSentenceData.sentenceId;
        const sources = dutchSentenceData.connectedWithOriginalLanguageTextSentenceIds?.map(
          (sourceItem) => sourceItem.sentenceId
        );

        sources?.forEach((sourceSentenceId) => {
          const sourceNodeId = `english-${sourceSentenceId}`;
          const targetNodeId = `dutch-${targetSentenceId}`;

          const edge = {
            id: `interaction-e-${chapterKey}-${paragraphKey}-${sourceSentenceId}-${targetSentenceId}`,
            source: sourceNodeId,
            target: targetNodeId,
            style: { stroke: "", strokeWidth: 3 },
          };

          newEdges.push(edge);
        });
      });
    });

    currentY = lastSentenceY + yOffsetChapter;

    setNodes(newNodes);
    setEdges(newEdges);
  };

  const handleMutipleParagraph = (paragraphs) => {
    setSelectedParagraphIndex(paragraphs);
    onEditPairingClick();
  };

  const handleCheckboxChange = (paragraphNumber) => {
    if (checkedParagraphs.includes(paragraphNumber)) {
      setCheckedParagraphs(checkedParagraphs.filter((p) => p !== paragraphNumber));
    } else {
      setCheckedParagraphs([...checkedParagraphs, paragraphNumber]);
    }
  };

  const chapters = pairingDatas?.auto_ai_resource?.resources.reduce((chapters, resource) => {
    const chapterNumber = resource.chapter.sequenceNumber;
    const paragraphNumber = resource.paragraph.sequenceNumber;
    const chapterKey = `Chapter ${chapterNumber}`;

    if (!chapters[chapterKey]) {
      chapters[chapterKey] = {};
    }

    if (!chapters[chapterKey][paragraphNumber]) {
      chapters[chapterKey][paragraphNumber] = [];
    }

    chapters[chapterKey][paragraphNumber].push(resource);

    return chapters;
  }, {});

  const chapterKeys = Object.keys(chapters);
  let totalParagraphs;
  let maxPages;
  if (paginationMode === paginationTypes.CHAPTER) {
    totalParagraphs = currentPage && chapters[`Chapter ${currentPage}`] && Object.keys(chapters[`Chapter ${currentPage}`]).length;
    maxPages = pairingDatas?.total_chapters;
  } else {
    totalParagraphs = chapterKeys.reduce((total, chapterKey) => {
      const paragraphs = chapters[chapterKey];
      return total + Object.keys(paragraphs).length;
    }, 0);
    maxPages = Math.ceil(totalParagraphs / paragraphPerPage);
  }

  const startIndex = (currentPage - 1) * paragraphPerPage;
  const endIndex = Math.min(startIndex + paragraphPerPage, totalParagraphs);

  const handleNextClick = () => {
    if (currentPage < maxPages) {
      window.scrollTo({ top: 0, left: 0 });
      if (infiniteScroll && paginationMode === paginationTypes.CHAPTER) {
        setParagraphsToShow([]);
        setCurrentPageForInfiniteScroll(1);
      }
      setCurrentPage(currentPage + 1);
      const pageData={pageNo:currentPage + 1,pairedBookId:pairedBookId}
      localStorage.setItem('pageData', JSON.stringify(pageData))
      setPairingData({});
    }
    setShowConfirmTranslation([]);
  };

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      window.scrollTo({ top: 0, left: 0 });
      if (infiniteScroll && paginationMode === paginationTypes.CHAPTER) {
        setParagraphsToShow([]);
        setCurrentPageForInfiniteScroll(1);
      }
      setCurrentPage(currentPage - 1);
      const pageData={pageNo:currentPage - 1,pairedBookId:pairedBookId}
      localStorage.setItem('pageData',JSON.stringify(pageData))
      setPairingData({});
    }
    setShowConfirmTranslation([]);
  };

  const [paragraphsToShow, setParagraphsToShow] = useState([]);

  useEffect(() => {
    let infiniteScrollStartIndex = 0;
    let infiniteScrollEndIndex = 0;
    if (infiniteScroll && paginationMode === paginationTypes.PARAGRAPH) {
      let currentCount = 0;
      for (let chapter of pairingDatas.auto_ai_resource.resources) {
        const chapterNumber = chapter.chapter.sequenceNumber;
        const paragraphNumber = chapter.paragraph.sequenceNumber;

        if (currentCount >= startIndex && currentCount < endIndex) {
          paragraphsToShow.push({
            chapterKey: chapterNumber,
            paragraphKey: paragraphNumber,
          });
        }

        currentCount++;
      }
      setParagraphsToShow(paragraphsToShow);
    }
    if (infiniteScroll && paginationMode === paginationTypes.CHAPTER) {
      let currentCount = 0;
      const paragraphsToShowTemp = [];

      /**
       * Clear existing paragraphs if chapter number got changed
       */
      if (
        paragraphsToShow &&
        paragraphsToShow.length &&
        paragraphsToShow[0].chapterKey !== `${currentPage}`
      ) {
        console.warn("CHAPTER CHANGED");
      } else {
        for (let key in chapters[`Chapter ${currentPage}`]) {
          let chapter = chapters[`Chapter ${currentPage}`][key][0];
          const chapterNumber = chapter.chapter.sequenceNumber;
          const paragraphNumber = chapter.paragraph.sequenceNumber;
          
          infiniteScrollStartIndex = (currentPageForInfiniteScroll - 1) * paragraphPerPage;
          infiniteScrollEndIndex = Math.min(infiniteScrollStartIndex + paragraphPerPage, totalParagraphs);
          if (currentCount >= infiniteScrollStartIndex && currentCount < infiniteScrollEndIndex) {
            paragraphsToShowTemp.push({
              chapterKey: chapterNumber,
              paragraphKey: paragraphNumber,
            });
          }
          currentCount++;
        }
        // Remove duplicates by filtering paragraphsToShow based on chapterKey
        setParagraphsToShow((prevParagraphs) => [...prevParagraphs, ...paragraphsToShowTemp]);
      }
    }
  }, [currentPage, currentPageForInfiniteScroll]);

  const handleScroll = () => {
    const scrolledToBottom = window.innerHeight + Math.round(window.scrollY) >= document.body.offsetHeight;
    if (scrolledToBottom && paragraphsToShow.length < totalParagraphs) {
      if (paginationMode === paginationTypes.CHAPTER) {
        console.log("scrolling true ====================For CHAPTER");
        setCurrentPageForInfiniteScroll((prevVisibleItems) => prevVisibleItems + 1);
      } else {
        console.log("scrolling true ====================For PARAGRAPH");
        setCurrentPage((prevVisibleItems) => prevVisibleItems + 1);
      }
    }
  };

  useEffect(() => {
    if (infiniteScroll) {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const [isViewAsEnglishChecked, setIsViewAsEnglishChecked] = useState(false);
  const { fetchData } = useApi();

  const handleViewAsEnglishToggle = () => {
    setIsViewAsEnglishChecked((prevValue) => !prevValue);
  };
  const [translatedSentences, setTranslatedSentences] = useState({});

  const handleDutchToEnglishTranslate = async () => {
    try {
      const selectedIndexes = Array.isArray(selectedParagraphIndex)
        ? selectedParagraphIndex
        : [selectedParagraphIndex];

      for (const selectedIndex of selectedIndexes) {
        const selectedChapterId = selectedIndex;

        const selectedChapter = pairingDatas.auto_ai_resource.resources.find(
          (chapter) => chapter?._id === selectedChapterId
        );

        const selectedChapterKey = selectedChapter?.chapter?.sequenceNumber;
        const selectedParagraphKey = selectedChapter.paragraph.sequenceNumber;

        const dutchValues = selectedChapter.textTranslatedLanguageSentences.map(
          (sentence) => sentence.sentence.text
          //   {
          //   dutch: sentence.sentence.text,
          //   sentence_no: sentence.sequenceNumber,
          // }
        );

        // console.log("dutchValues", dutchValues);
        // const req = {
        //   [`Chapter ${selectedChapterKey}`]: {
        //     [`Paragraph ${selectedParagraphKey}`]: { sentence: dutchValues },
        //   },
        // };
        // const req = dutchValues;

        const translateRequest = {
          sentences: dutchValues,
          originaLanguage: "dutch",
          targetLanguage: "english",
          target_language_unicode: "en",
        };
        try {
          if (isViewAsEnglishChecked) {
            const res = await fetchData(apiUrls.googleApiTranslateDutchToEnglish, "POST", translateRequest);

            // const translatedParagraph =
            //   res[`Chapter ${selectedChapterKey}`][
            //     `Paragraph ${selectedParagraphKey}`
            //   ];
            const translatedParagraph = res.sentences;

            setTranslatedSentences((prevTranslations) => ({
              ...prevTranslations,
              [selectedChapterKey]: {
                ...prevTranslations[selectedChapterKey],
                [selectedParagraphKey]: translatedParagraph.sentence,
              },
            }));
          }
        } catch (err) {
          console.log(err);
        }
      }

      if (!isViewAsEnglishChecked) {
        for (const selectedIndex of selectedIndexes) {
          const selectedChapterId = selectedIndex;

          const selectedChapter = pairingDatas.auto_ai_resource.resources.find(
            (chapter) => chapter._id === selectedChapterId
          );

          const selectedChapterKey = selectedChapter.chapter.sequenceNumber;
          const selectedParagraphKey = selectedChapter.paragraph.sequenceNumber;

          setTranslatedSentences((prevTranslations) => ({
            ...prevTranslations,
            [selectedChapterKey]: {
              ...prevTranslations[selectedChapterKey],
              [selectedParagraphKey]: null,
            },
          }));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleProgressBar = (number) => {
    if (number)
      setProgressBar(
        ((pairingDatas.auto_ai_resource.resources.filter((obj) => obj.status === "approved").length +
          number) /
          pairingDatas.auto_ai_resource.resources.length) *
          100
      );
    else
      setProgressBar(
        (pairingDatas.auto_ai_resource.resources.filter((obj) => obj.status === "approved").length /
          pairingDatas.auto_ai_resource.resources.length) *
          100
      );
  };

  const handleInputChange = (event) => {
    setPageInput(event.target.value);
  };
  const handleKeyPress = (event) => {
    event.preventDefault()
    // if (event.key === 'Enter') {
      console.log("entererr");
      if(pageInput > maxPages || pageInput <= 0) {
        toast('Please enter a valid section number between 1 and ' + maxPages + ' inclusive')
        return
      }
      const pageData={pageNo:parseInt(pageInput),pairedBookId:pairedBookId}
      localStorage.setItem('pageData', JSON.stringify(pageData))
      setCurrentPage(parseInt(pageInput))
    // }
  };
  // Determine the size of the TextField based on the length of the pageInput
  // const inputSize = Math.max(4, pageInput.toString().length);


  useEffect(() => {
    handleDutchToEnglishTranslate();
    handleProgressBar("");
  }, [isViewAsEnglishChecked, nodes]);

  const [hoveredNode, setHoveredNode] = useState(null);
  const [hoveredDutchNode, setHoveredDutchNode] = useState(null);
  const [showOriginalDutch, setShowOriginalDutch] = useState(false);
  const [hoveredNodePosition, setHoveredNodePosition] = useState({
    x: 0,
    y: 0,
  });

  const updateTheNodeConnection = () => {
    const matchingResource = pairingDatas.auto_ai_resource.resources.find(
      (resource) => resource._id === selectedParagraphIndex
    );

    if (matchingResource) {
      // console.log("Matching Resource Found:", matchingResource);

      edges?.forEach((edge) => {
        const sourceId = edge.source.split("-")[1];
        const targetId = edge.target.split("-")[1];

        const connectedSentence = matchingResource.textTranslatedLanguageSentences.find(
          (sentence) =>
            sentence.connectedWithOriginalLanguageTextSentenceIds[0].sentenceId === sourceId &&
            sentence.sentenceId === targetId
        );
        // console.log("sentenceToUpdate1", connectedSentence);
        if (!connectedSentence) {
          const sentenceToUpdate = matchingResource.textTranslatedLanguageSentences.find(
            (sentence) => sentence.sentenceId === targetId
          );
          // console.log("sentenceToUpdate2", sentenceToUpdate);
          if (sentenceToUpdate) {
            sentenceToUpdate.connectedWithOriginalLanguageTextSentenceIds[0].sentenceId = sourceId;
          }
        }
      });

      // Now the matchingResource contains the required changes
    } else {
      console.log("No matching resource found for the selectedParagraphIndex.");
    }
  };

  function getQueryParam(url, param) {
    const queryString = url.split("?")[1];
    if (!queryString) return null;

    const params = new URLSearchParams(queryString);
    return params.get(param);
  }

  return (
    <div className="container">
      {/* <SidetoolBar toggleTip={toggleTooltip} /> */}
      <div className="main-header">
        {/* <div className="header">
          {!showEditPairing && (
            <p className="header-title">
              <RiArrowLeftLine className="back-arrow" onClick={handleGoBack} />
              <div className="content-header">
                <p>
                  <strong>Title: </strong>
                  <span>{bookTitle}</span>
                </p>
                <p>
                  <strong>Author: </strong>
                  {authorName}
                </p>
              </div>
            </p>
          )}
          {showEditPairing && (
            <p className="header-title-edit">
              <RiArrowLeftLine className="back-arrow" onClick={onEditPairingClick} />
              Edit Pairing
            </p>
          )}
          <div className="content-main-container">
            {/* <img
              src={infoImg}
              alt="info-img"
              height={"26px"}
              width={"26px"}
              style={{ marginRight: "20px" }}
            /> */}
        {/* <button className="download-btn" onClick={getDownload}>
              Download Book
              <img src={download} alt="Download" className="download-icon" />
            </button>
            <div className="user-menu">
              <img src={user} alt="user" />
              <img src={linebr} alt="user" />
              <img src={optmor} alt="user" />
            </div>
          </div>
        </div>  */}
        {downloadModalVisible && <DownloadModal downloadModalVisible={downloadModalVisible} setDownloadModalVisible={setDownloadModalVisible} getDownload={getDownload} popupEmail={popupEmail}/>}
        <div className="text-header">
          <div className="translated-text-header-zoombox-container">
       
            {!showEditPairing && (
              <p className="header-title">
                <RiArrowLeftLine
                  className="back-arrow"
                  style={{ width: "28px", height: "28px" }}
                  onClick={handleGoBack}
                />

                <div className="content-header">
                  <p>
                    <strong>Title: </strong>
                    <Tooltip title={bookTitle}><span style={{marginRight:'10px'}}>{truncatedTitle}</span></Tooltip>
                    <strong>Author: </strong>
                    <Tooltip title={authorName}>{truncatedName}</Tooltip>
                  </p>
                </div>
              </p>
            )}
            {/* <div className="source-header-text"> */}
              {/* <p style={{marginRight:"50px"}}>{sourceColumnHeader}</p> */}
            {/* </div> */}
          </div>
          {!showEditPairing && !(paginationMode === `${paginationTypes.PARAGRAPH}` && infiniteScroll) && (
            <div className="pagination">
              <Tooltip title="Previous Chapter">
                <img src={leftArrow} alt="left-arrow" onClick={handlePreviousClick} className="arrow-icon" />
              </Tooltip>
              &nbsp;&nbsp;
              <div className="pagination-content">
                <div className="page_wrapper_edit" style={{display:"flex"}}>
                  {/* <TextField
                      type="number"
                      value={pageInput}
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPress}
                      inputProps={{ min: 1, max: maxPages}}
                      
                      variant="outlined"
                      size="small"
                      sx={{minWidth:'45px'}}
                    /> */}
                    <form  onSubmit={handleKeyPress} className="form-chapter">
                      <input 
                        type="number"
                        value={pageInput}
                        onChange={handleInputChange}
                        // onKeyPress={handleKeyPress}
                        // inputProps={{ min: 1, max: maxPages}}
                        className="chapter-input"
                        />
                      </form>
                    <p className="pagination-page-count" style={{margin:"0px"}}>
                    <span style={{margin: '0px 3px'}}>/</span>{maxPages}
                    </p>
                </div>
                <p className="pagination-page">{paginationMode === "Chapter" ? "Section" : "Page"}</p>
              </div>
              {/* <div className="pagination-content">
                <p className="pagination-page-count">
                  {currentPage}/{maxPages}
                </p>
                <p className="pagination-page">{paginationMode === "Chapter" ? "Section" : "Page"}</p>
              </div> */}
              &nbsp;&nbsp;
              <Tooltip title="Next Chapter">
                <img src={rightArrow} alt="right-arrow" onClick={handleNextClick} className="arrow-icon" />
              </Tooltip>
            </div>
          )}
          <div className="translated-text-header-zoombox-container">
            <div
              style={{
                width: "50%",
                justifyContent: "center",
                display: "flex",
              }}
            >
              {/* <p style={{marginLeft:"50px"}}>{targetColumnHeader}</p> */}
            </div>
            {/* <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="demo-select-small-label">AI Model</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectedModelName}
                label="AI Model"
                onChange={(e) => {
                  handleModel(e)
                }}
                sx={{color:'green'}}
              >
                {assistantOptions.map((option)=>
                  <MenuItem value={option.model_name}>{option.model_name}</MenuItem>
                )}
              </Select>
            </FormControl> */}
          {/* {
            process.env.REACT_APP_ENVIRONMENT === "dev" &&
            <Button
              onClick={() => setToggleCompare(!toggleCompare)}
            >
              Compare
            </Button>
          } */}
                        

            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel shrink="true" id="demo-select-small-label">Translate To</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Translate To"
                value={selectedLanguage}
                style={{zIndex: 999}}
                onChange={(e) => {
                  handleChange(e)
                }}
                sx=
                  {
                    {
                      color:'green',
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid grey', // Example focus styles
                      },
                    }
                  }
              >
                <MenuItem value="dutch">Dutch</MenuItem>
                {process.env.REACT_APP_ENVIRONMENT === "dev" ? 
                  <MenuItem value="french">French</MenuItem>
                :  <MenuItem value="french" disabled>French <LockOutlined style={{marginLeft: 'auto', fontSize: '12px'}}/></MenuItem>
                }
                {process.env.REACT_APP_ENVIRONMENT === "dev" ? 
                  <MenuItem value="swedish">Swedish</MenuItem>
                :  <MenuItem value="swedish" disabled>Swedish <LockOutlined style={{marginLeft: 'auto', fontSize: '12px'}}/></MenuItem>
                }

                {/* hindi , Hinglish should not go to QA or Prod */}
                <MenuItem value="hindi">Hindi</MenuItem>
                <MenuItem value="hinglish">Hinglish</MenuItem>
                
              </Select>
            </FormControl>

            {/* {process.env.REACT_APP_ENVIRONMENT === "dev" &&
            <IconButton
              variant="outlined"
              onClick={() => setToggleCompare(!toggleCompare)}
              style={{
                backgroundColor: '#1e1c80',
                color: 'white',
                borderRadius: '32px',
                fontFamily: 'Poppins',
                fontSize: '15px',
                padding: '10px 20px',
                marginRight: '10px'
              }}
            >
              {!toggleCompare ? <VisibilityIcon fontSize="small" /> : <VisibilityOffIcon fontSize="small"/> }
              <span style={{marginLeft:"10px"}}>{'Compare'}</span>
            </IconButton>
            } */}

            <div style={{ display: "flex", alignItems: "center", paddingRight: "1rem" }}>
              <div className="zoom-box">
                <div
                  className="box1"
                  onClick={() => {
                    setTextSize((prev) => {
                      if (prev === 16) {
                        return prev - 2;
                      } else if (prev === 20) {
                        return prev - 4;
                      } else {
                        return prev;
                      }
                    });
                  }}
                >
                  <p>A-</p>
                </div>
                <div
                  className="box2"
                  onClick={() => {
                    setTextSize((prev) => {
                      if (prev === 14) {
                        return prev + 2;
                      } else if (prev === 16) {
                        return prev + 4;
                      } else {
                        return prev;
                      }
                    });
                  }}
                >
                  <p>A+</p>
                </div>
              </div>
              <div onClick={() => setDownloadModalVisible(true)} className="user-menu-trans">
                <img
                  src={downloadwhite}
                  alt="user"
                />
                {/* <img src={optmor} alt="user" /> */}
              </div>
            </div>
          </div>
          {/* {!showEditPairing && ( */}

          <div className="icon-header">
            {/* <img
            src={likeIcon}
            alt="Like"
            onClick={updateTheNodeConnection}
            className="like-icon "
          /> */}
            {/* {!showEditPairing && (
            <img
              src={marker}
              onClick={() => handleMutipleParagraph(checkedParagraphs)}
              alt="Marker"
              className="like-icon"
            />
          )} */}
          </div>
          {/* )} */}
          {showEditPairing && (
            <div className="toogle-container">
              <label className="switch">
                <input
                  type="checkbox"
                  onChange={handleViewAsEnglishToggle}
                  checked={isViewAsEnglishChecked}
                />
                <span className="slider round"></span>
              </label>
              <span className="toogleLabel"> View as English</span>
            </div>
          )}
        </div>

        <div style={{display:'flex', justifyContent:'space-around', backgroundColor:'#efefef', paddingLeft:'10vw', paddingTop: '6px'}}>
            <div style={{display:'flex', flexBasis:'100%', textAlign:'center', alignItems:'center', fontFamily:'Poppins', flex:'1',}}>
            <div className="book-view-container">
              <div className="merge-text-container" style={{backgroundColor:mergeParaMode?'#3B37FF':'#fff'}}>
                <p style={{color:mergeParaMode?'white':'inherit',marginRight:'10px'}}>Merge</p>
                <Tooltip 
                title={!disableMergePara ? "Generate the first paragraph's translation to start using this feature!" : ""}
                >
                  <img 
                  onClick={()=>{
                    if(!disableMergePara) return
                    setMergeParaMode(prev=> !prev)
                    setMergeParagraphData([])
                  }
                } 
                  src={mergeParaMode?WhiteMergeIcon:MergeIcon} alt="left-arrow"  className="arrow-icon" style={{
                    cursor:disableMergePara?'pointer':'not-allowed',color:mergeParaMode?'white':'inherit'
                    // cursor: 'pointer'
                    }}/>
                </Tooltip>
               {mergeParagraphData?.length>0 && <div className="tick-circle">
                <img src={TickIcon} alt="left-arrow" onClick={handleMergeParagraph} className="arrow-icon" />
                </div>}
              </div>
  
            </div>
              <p style={{marginLeft:'28%'}}>{sourceColumnHeader}</p>
            </div>
            <div
            style={{
              display:'flex', justifyContent:'space-between', backgroundColor:'#efefef',
              flex: toggleCompare? '2' : '1'
            }}>
            <div style={{display:'flex', flexBasis:'100%', justifyContent:'center', textAlign:'center', alignItems:'center', marginLeft:toggleCompare?'30px':'80px'}}>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel shrink="true" id="demo-select-small-label">AI Model</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={selectedModelName}
                  style={{zIndex: 999}}
                  label="AI Model"
                  onChange={(e) => {
                    handleModel(e)
                  }}
                  sx=
                  {
                    {
                      color:'green',
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid grey', // Example focus styles
                      },
                    }
                  }
                  onClose={() => {
                    console.log('jjjj');
                  }}
                >
                  {assistantOptions.filter((option)=>option.target_language===selectedLanguage).map((option, i)=>
                    <MenuItem value={option.model_name} disabled={process.env.REACT_APP_ENVIRONMENT === "qa" && i !==0 }>{option.model_name} {(process.env.REACT_APP_ENVIRONMENT === "qa" && i !== 0) && <LockOutlined style={{marginLeft: 'auto', fontSize: '12px'}}/>}</MenuItem>
                  )}
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel shrink="true" id="demo-select-small-label">Creativity</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small-01"
                  value={selectedTemperature}
                  label="Creativity"
                  style={{zIndex: 999}}
                  onChange={(e) => {
                    handleTemperature(e)
                  }}
                  sx={
                      {
                        color:'green',
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          border: '1px solid grey', // Example focus styles
                        },
                      }
                    }
                >
                  <MenuItem value="0.1">Low</MenuItem>
                  <MenuItem value="0.5">Medium</MenuItem>
                  <MenuItem value="1">High</MenuItem>
                </Select>
              </FormControl>
            </div>
            {toggleCompare &&
              <div style={{display:'flex', flexBasis:'100%', justifyContent:'center', textAlign:'center', alignItems:'center', marginLeft:'120px'}}>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel shrink="true" id="demo-select-small-label">AI Model 2</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={selectedComparisionModelName}
                  label="AI Model 2"
                  onChange={(e) => {
                    handleComparisionModel(e)
                  }}
                  sx={{color:'green'}}
                >
                  {assistantOptions.filter((option)=>option.target_language===selectedLanguage).map((option, i)=>
                    <MenuItem value={option.model_name} disabled={i !==0 }>{option.model_name} {i !== 0 && <LockOutlined style={{marginLeft: 'auto', fontSize: '12px'}}/>}</MenuItem>
                  )}
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel shrink="true" id="demo-select-small-label">Creativity</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small-01"
                  value={selectedComparisionTemperature}
                  label="Creativity 2"
                  onChange={(e) => {
                    handleComparisionTemperature(e)
                  }}
                  sx={{color:'green'}}
                >
                  <MenuItem value="0.1">Low</MenuItem>
                  <MenuItem value="0.5">Medium</MenuItem>
                  <MenuItem value="1">High</MenuItem>
                </Select>
              </FormControl>
              </div>            
            }
            <div style={{display:'flex', flexBasis:'100%', alignItems:'center', justifyContent:'flex-end', marginLeft:'auto'}}>
            <IconButton
              variant="outlined"
              onClick={() => setToggleCompare(!toggleCompare)}
              style={{
                backgroundColor: '#1e1c80',
                color: 'white',
                borderRadius: '32px',
                fontFamily: 'Poppins',
                fontSize: '15px',
                padding: '10px 20px',
                marginRight: '10px'
              }}
            >
              {!toggleCompare ? <VisibilityIcon fontSize="small" /> : <VisibilityOffIcon fontSize="small"/> }
              <span style={{marginLeft:"10px", fontFamily:'Poppins'}}>{'Compare'}</span>
            </IconButton>
            </div>
            </div>
        </div>
        {
          //
        }

        {/* <div className="line"></div> */}
        <BorderLinearProgress variant="determinate" value={progressBar} />
        {showTooltip && <ConfidenceRatingInfo handleTooltip={toggleTooltip} />}
      </div>

      {!showEditPairing && (
        <>
          <TranslateParagraphPairComponent
            chapterKeys={chapterKeys}
            startIndex={startIndex}
            endIndex={endIndex}
            onEditClick={handleEditClick}
            onClickMutipleParagraph={handleMutipleParagraph}
            handleCheckboxChange={handleCheckboxChange}
            handleProgressBar={handleProgressBar}
            checkedParagraphs={checkedParagraphs}
            pairingData={pairingDatas?.auto_ai_resource?.resources && pairingDatas}
            setPairingData={setPairingData}
            showViewAsEnglish={showViewAsEnglish}
            pairingEdges={edges}
            textSize={textSize}
            getDownloadLink={getDownload}
            totalParagraphs={totalParagraphs}
            showConfirmTranslation={showConfirmTranslation}
            setShowConfirmTranslation={setShowConfirmTranslation}
            paragraphsToShowForScroll={infiniteScroll ? paragraphsToShow : []}
            infiniteScroll={infiniteScroll}
            paginationMode={paginationMode}
            currentPage={currentPage}
            paragraphPerPage={paragraphPerPage}
            pairedBookId={pairedBookId}
            mergeParaMode={mergeParaMode}
            mergeParagraphData={mergeParagraphData}
            setMergeParagraphData={setMergeParagraphData}
            selectedLanguage={selectedLanguage}
            selectedModelName={selectedModelName}
            selectedTemperature={selectedTemperature}
            selectedComparisionModelName={selectedComparisionModelName}
            selectedComparisionTemperature={selectedComparisionTemperature}
            showCompare={toggleCompare}
            setDownloadModalVisible={setDownloadModalVisible}
          />
        </>
      )}
      {showEditPairing &&
        (isLoading ? (
          <Loader />
        ) : (
          <ReactFlow
            // nodes={nodes.map((node) => {
            //   const nodeParts = node.id.split("-");
            //   const language = nodeParts[0];
            //   const chapterKey = nodeParts[1];
            //   const paragraphKey = nodeParts[2];

            //   const translatedSentencesForChapter =
            //     translatedSentences[chapterKey] || {};
            //   const translatedSentencesForParagraph =
            //     translatedSentencesForChapter[paragraphKey] || [];

            //   const translatedLabel =
            //     isViewAsEnglishChecked && language === "dutch"
            //       ? translatedSentencesForParagraph[nodeParts[3] || 0]
            //           ?.english || node.data.label
            //       : node.data.label;

            //   return {
            //     ...node,
            //     data: {
            //       ...node.data,
            //       label: translatedLabel,
            //     },
            //   };
            // })}
            nodes={nodes.map((node) => {
              const [language, chapterKey, paragraphKey, sentenceIndex] = node.id.split("-");
              const translatedSentencesForChapter = translatedSentences[chapterKey] || {};
              const translatedSentencesForParagraph = translatedSentencesForChapter[paragraphKey] || [];
              const sentenceTranslation =
                isViewAsEnglishChecked && language === "dutch"
                  ? (translatedSentencesForParagraph[sentenceIndex] || {}).english
                  : undefined;

              return {
                ...node,
                data: {
                  ...node.data,
                  label: sentenceTranslation || node.data.label,
                },
              };
            })}
            // onNodeMouseEnter={(event, node) => {
            //   const hoveredEnglishLabel = node.id;
            //   setHoveredNode(hoveredEnglishLabel);

            //   if (hoveredEnglishLabel.startsWith("dutch-")) {
            //     const englishParts = hoveredEnglishLabel.split("-");
            //     const chapterKey = englishParts[1];
            //     const paragraphKey = englishParts[2];
            //     const sentenceIndex = parseInt(englishParts[3]);

            //     const selectedChapter =
            //       pairingDatas.auto_ai_resource.resources.find(
            //         (chapter) =>
            //           chapter.chapter.sequenceNumber === Number(chapterKey) &&
            //           chapter.paragraph.sequenceNumber === Number(paragraphKey)
            //       );

            //     const dutchParagraph =
            //       selectedChapter.textTranslatedLanguageSentences.map(
            //         (sentence) => sentence.sentence.text
            //       );

            //     const hoveredDutchLabel = dutchParagraph[sentenceIndex];

            //     setHoveredDutchNode(hoveredDutchLabel);
            //     setShowOriginalDutch(true);

            //     const nodePosition = {
            //       x: node.position.x,
            //       y: node.position.y,
            //     };
            //     console.log("nodePosition", nodePosition);
            //     setHoveredNodePosition(nodePosition);
            //   }
            // }}
            // onNodeMouseLeave={() => {
            //   setHoveredNode(null);
            //   setHoveredDutchNode(null);
            //   setShowOriginalDutch(false);
            // }}
            edges={[...edges]}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            elementsSelectable={isSelectable}
            nodesConnectable={isConnectable}
            nodesDraggable={isDraggable}
            zoomOnScroll={zoomOnScroll}
            panOnScroll={panOnScroll}
            panOnScrollMode={panOnScrollMode}
            zoomOnDoubleClick={zoomOnDoubleClick}
            onConnect={onConnect}
            onNodeClick={captureElementClick ? onNodeClick : undefined}
            onNodeDragStart={onNodeDragStart}
            onNodeDragStop={onNodeDragStop}
            panOnDrag={panOnDrag}
            onPaneClick={captureZoomClick ? onPaneClick : undefined}
            onPaneScroll={captureZoomScroll ? onPaneScroll : undefined}
            onPaneContextMenu={captureZoomClick ? onPaneContextMenu : undefined}
            attributionPosition="top-right"
          >
            {showOriginalDutch && isViewAsEnglishChecked && (
              <div
                className="extra-div"
                style={{
                  position: "absolute",
                  top: hoveredNodePosition.y - 60,
                  left: hoveredNodePosition.x,
                }}
              >
                <div>{hoveredDutchNode}</div>
              </div>
            )}
          </ReactFlow>
        ))}
    </div>
  );
};

export default TranslateComponent;
