import React, { useState } from "react";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { styled } from "@mui/system";
import { grey, blue } from "@mui/material/colors";
import TablePagination from "@mui/material/TablePagination";
const Footer = ({
    data,
    page,
    rowsPerPage,
    setRowsPerPage,
    setPage
}) => {
    
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const CustomTablePagination = styled(TablePagination)(
        ({ theme }) => `
      
        .MuiTablePagination-root {
           border: 1px solid white; 
      }
        border-bottom: none !important;
      
        & .MuiTablePagination-spacer {
          display: flex;
        }
      
        & .MuiTablePagination-toolbar {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          padding: 4px 0;
          justify-content: center;
          margin:1px;
      
          @media (min-width: 768px) {
            flex-direction: row;
          }
        }
      
        & .MuiTablePagination-selectLabel {
          margin: 2px;
          color: #6C757D;
          font-family: Poppins;
          font-size: 16px;
          padding:8px 0px 8px 0px;
          border-radius: 5px;
          
      
        }
      
        & .MuiTablePagination-select {
          margin: 2px;
          font-family: Poppins, sans-serif;
          padding: 0px 8px;
          height: 36px!important; /* Set the height to match buttons */
          border: 1px solid #D1D5DB;
          border-radius: 5px; 
          background-color: transparent;
          color: #6C757D;
          font-size: 16px;
          display: flex;
          align-items: center; /* Center the text vertically */
          box-sizing: border-box; /* Include padding and border in height */
         
          &:hover {
            background-color: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
          }
          &:focus {
           border-radius: 5px;
          }
        }
      
        & .MuiTablePagination-actions {
          display: flex;
          gap: 0.25rem;
        }
      
        & .MuiTablePagination-actions > button {
          border: none;
          border-radius: 4px;
          background-color: transparent;
          color: #6B7280;
        //   margin: 1px;
          height: 36px  !important /* Set the height to match the select box */
          width: 36px; /* Ensure the width is consistent for buttons */
          display: flex;
          align-items: center; /* Center the text/icon vertically */
          justify-content: center; /* Center the text/icon horizontally */
      
          &:hover {
            background-color: ${grey[50]};
            
          }
      
         
      }
          
          
        `,
      );
    return (
        <div className="flex px-4 justify-between items-center overflow-x-auto min-h-[58px]" style={{width: '100%', backgroundColor:'white',textAlign:"center", position: 'fixed',bottom: 0, left: 0, right: 0, borderTop: "1px solid #D1D5DB", zIndex: 10}}>
            <div className={`text-[#6C757D] ${!data?.length && "w-full"} text-center xl:text-sm lg:text-xs`} style={{ fontFamily: 'Poppins'}}>In case of any queries, please reach out to us at <a href="mailto:translationsupport@techolution.com" className=" underline text-[#002766]">
            translationsupport@techolution.com
                </a></div>
            {data?.length > 0 && <div className="flex items-center gap-4">

           {   <div className="flex text-[#6C757D] min-w-32 ">Total Books : {data.length}</div>}
            { <div  className=" flex min-w-[26rem] justify-center">
              <CustomTablePagination
                rowsPerPageOptions={[10, 25, 50, { label: 'All', value: data.length }]}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                slotProps={{
                  select: {
                    'aria-label': 'rows per page',
                  },
                  actions: {
                    showFirstButton: true,
                    showLastButton: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
            }
            </div>}
        </div>
    )
}

export default Footer   