import * as React from "react";
import "./ConfidenceRatingInfo.scss";
import closeIcon from "../../assets/close_icon_black.svg";

function ConfidenceRatingInfo({ handleTooltip }) {
  const toggleTooltipVisibility = () => {
    handleTooltip();
  };
  return (
    <>
      <div className="confidence_info_wrapper">
        <img
          src={closeIcon}
          alt="Close"
          className="confidence_info_crossIcon"
          onClick={toggleTooltipVisibility}
        />
        <div className="confidence_info_description_wrapper">
          <div className="slightly_low_confidence_wrapper">
            <div className="slightly_low_child_1" />
            <div className="slightly_low_child_2">
              Slightly low confidence on the translation, some human
              <br />
              involvement is needed
            </div>
          </div>
          <div className="very_low_confidence_wrapper">
            <div className="very_low_child_1" />
            <div className="very_low_child_2">
              Very low confidence on the translation, major human
              <br />
              involvement is needed
            </div>
          </div>
          <div className="low_accuracy">
            <div className="low_accuracy_child_1" />
            <div className="low_accuracy_child_2">Paragraphs with low translation accuracy.</div>
          </div>
          <div className="high_accuracy">
            <div className="high_accuracy_child_1" />
            <div className="high_accuracy_child_2">Paragraphs with high translation accuracy.</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfidenceRatingInfo;
