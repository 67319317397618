import React, { useState } from 'react';
import difflib from 'difflib';
// var difflib = require('difflib');

const splitIntoSentences = (text) => {
    // return text?.split('.').map(sentence => sentence.trim()).filter(sentence => sentence);
    return text?.split('.').map(sentence => sentence).filter(sentence => sentence);
};

const compareSentences = (baseSentence, comparedSentence) => {
    const baseWords = baseSentence.split(' ');
    const comparedWords = comparedSentence.split(' ');
    const sm = new difflib.SequenceMatcher(null, baseWords, comparedWords);
    const changes = [];

    sm.getOpcodes().forEach(([tag, i1, i2, j1, j2]) => {
        if (tag === 'replace') {
            changes.push({
                action: 'replace',
                base: baseWords.slice(i1, i2),
                basePosition: [i1, i2],
                with: comparedWords.slice(j1, j2),
                withPosition: [j1, j2]
            });
        } else if (tag === 'delete') {
            changes.push({
                action: 'delete',
                words: baseWords.slice(i1, i2),
                position: [i1, i2]
            });
        } else if (tag === 'insert') {
            changes.push({
                action: 'insert',
                at: i1,
                words: comparedWords.slice(j1, j2),
                position: [j1, j2]
            });
        }
    });

    return changes;
};

export const findChangesToMatchBase = (baseText, textsToCompare) => {
    const baseSentences = splitIntoSentences(baseText);
    const allChanges = {};

    textsToCompare.forEach((text, textIdx) => {
        const comparedSentences = splitIntoSentences(text);
        const textChanges = {};

        comparedSentences.forEach((comparedSentence, sentenceIdx) => {
            const baseSentence = baseSentences[sentenceIdx] || '';
            const sentenceChanges = compareSentences(baseSentence, comparedSentence);

            if (sentenceChanges.length) {
                textChanges[sentenceIdx] = sentenceChanges;
            }
        });

        allChanges[textIdx] = textChanges;
    });
    let newres = JSON.stringify(allChanges, null, 4)
    //transformJSON(allChanges)
    

    return convertJsonData(allChanges);
};


function transformJson(originalJson) {
    let result = [];
    //onsole.log("jsss",originalJson)
    // Iterate through the keys in the original JSON
    for (let key in originalJson) {
        if (originalJson.hasOwnProperty(key)) {
            // Assuming there is another nested key, we'll iterate through its keys
            for (let nestedKey in originalJson[key]) {
                if (originalJson[key].hasOwnProperty(nestedKey)) {
                    // Now we can iterate through the array
                    originalJson[key][nestedKey].forEach(action => {
                        let entry = {
                            word: [],
                            position: [],
                            variant: []
                        };

                        // Handling different actions
                        switch (action.action) {
                            case "replace":
                                entry.word = action.base;
                                entry.position = action.basePosition;
                                entry.variant.push({
                                    variant: action.with,
                                    position: action.withPosition,
                                    model: "replace_action"
                                });
                                break;
                            case "delete":
                                entry.word = action.words;
                                entry.position = action.position;
                                entry.variant.push({
                                    variant: [], // delete action has no variant
                                    position: action.position,
                                    model: "delete_action"
                                });
                                break;
                            case "insert":
                                entry.word = []; // insert action starts with no base word
                                entry.position = [action.at, action.at];
                                entry.variant.push({
                                    variant: action.words,
                                    position: action.position,
                                    model: "insert_action"
                                });
                                break;
                            default:
                                // Handle other actions if necessary
                                break;
                        }

                        result.push(entry);
                    });
                }
            }
        }
    }

    return result;
}


let convertedData = []

function addOrUpdateWord(word, wordPosition, variant, variantPosition, model, action) {

    let existingWord = convertedData.find(item => JSON.stringify(item.word) === JSON.stringify(word));

    let variantEntry = {
        variant: variant,
        position: variantPosition,
        model: model,
        action: action + "_action"
    };

    if (existingWord) {
        existingWord.variant.push(variantEntry);
    } else {
        let newWordEntry = {
            word: word,
            position: wordPosition,
            variant: [variantEntry]
        };
        convertedData.push(newWordEntry);
    }
}


export const convertJsonData = (originalJson) => {
    let convertedData = [];

    function addOrUpdateWord(word, wordPosition, variant, variantPosition, model, action) {
        let existingWord = convertedData.find(item => JSON.stringify(item.word) === JSON.stringify(word));

        let variantEntry = {
            variant: variant,
            position: variantPosition,
            model: model,
            action: action + "_action"
        };

        if (existingWord) {
            existingWord.variant.push(variantEntry);
        } else {
            let newWordEntry = {
                word: word,
                position: wordPosition,
                variant: [variantEntry]
            };
            convertedData.push(newWordEntry);
        }
    }

    for (const model in originalJson) {
        for (const actionList of Object.values(originalJson[model])) {
            for (const action of actionList) {
                switch (action.action) {
                    case 'replace':
                        addOrUpdateWord(
                            action.base, 
                            action.basePosition, 
                            action.with, 
                            action.withPosition, 
                            model, 
                            action.action
                        );
                        break;
                    case 'delete':
                        addOrUpdateWord(
                            action.words, 
                            action.position, 
                            [], 
                            action.position, 
                            model, 
                            action.action
                        );
                        break;
                    case 'insert':
                        addOrUpdateWord(
                            [], 
                            [action.at, action.at], 
                            action.words, 
                            action.position, 
                            model, 
                            action.action
                        );
                        break;
                }
            }
        }
    }

    return convertedData;
}


// const result = findChangesToMatchBase("We hebben weer een moeilijke zaak toebedeeld gekregen", ["We vebben weer hc een moeilijke haak toebedeeld gekregen", "We mebben weer hc een moeilijke kaak toebedeeld gekregen"]);

// export default result



  

  