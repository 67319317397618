import { useState, useEffect } from "react";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BACKEND_URL || "https://hc-backend-dev.techo.camp";

// console.log("processENV",process.env)
// console.log("processENV",process.env.REACT_APP_BACKEND_URL)

const useApi = (initialUrl) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async (url, method = "GET", body = null) => {
    setLoading(true);
    setError(null);

    const URL = baseUrl + url;

    try {
      const response = await axios({
        method,
        url: URL,
        data: body,
      });
      setData(response.data);
      return response.data;
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialUrl) {
      fetchData(initialUrl);
    }
  }, [initialUrl]);

  return { data, loading, error, fetchData };
};

export default useApi;
