const shortcuts = {
  "Shift-F5": {
    action: "updateParagraph",
    keys: ["Shift", "F5"],
  },
  "Ctrl-M": {
    action: "mergeSentences",
    keys: ["Ctrl", "m"],
  },
  // Add more shortcuts here
};

export default shortcuts;