import React, { useState, useEffect } from "react";
import "./ParagraphPairComponent.scss";
import likeIcon from "../../assets/Frame 1.svg";
import marker from "../../assets/Frame 4.svg";
import checkedCircle from "../../assets/check_circle.svg";
import thumbUpFill from "../../assets/thumb_upfill.svg";
import thumbUpInActive from "../../assets/thumbinactive_up.svg";
import editIconInActive from "../../assets/editborderinactive_color.svg";
import apiUrls from "../../api/apiUrl";
import useApi from "../../api/useAPi";

const ParagraphPairComponent = ({
  onEditClick,
  handleCheckboxChange,
  checkedParagraphs,
  chapterKeys,
  startIndex,
  endIndex,
  pairingData,
  showViewAsEnglish,
  updateStatusPairing,
  pairingEdges,
}) => {
  const { fetchData } = useApi();
  const [translatedDutchToEnglish, setTranslatedDutchToEnglish] = useState([]);
  const [loadingParagraphs, setLoadingParagraphs] = useState({});
  const [sentenceColors, setSentenceColors] = useState({});

  const paragraphsToShow = [];
  let currentCount = 0;

  for (let chapter of pairingData.auto_ai_resource.resources) {
    const chapterNumber = chapter.chapter.sequenceNumber;
    const paragraphNumber = chapter.paragraph.sequenceNumber;

    paragraphsToShow.push({
      chapterKey: chapterNumber,
      paragraphKey: paragraphNumber,
    });
  }

  paragraphsToShow.sort((a, b) => {
    if (a.chapterKey === b.chapterKey) {
      return a.paragraphKey - b.paragraphKey;
    }
    return a.chapterKey - b.chapterKey;
  });

  const getParagraph = async (chapterKey, paragraphKey) => {
    setLoadingParagraphs((prevState) => ({
      ...prevState,
      [`Chapter ${chapterKey}-Paragraph ${paragraphKey}`]: true,
    }));

    const selectedChapter = pairingData.auto_ai_resource.resources.find(
      (chapter) =>
        chapter.chapter.sequenceNumber === chapterKey &&
        chapter.paragraph.sequenceNumber === paragraphKey
    );
    const dutchParagraph = selectedChapter.textTranslatedLanguageSentences.map(
      (sentence) => sentence.sentence.text
    );

    const translateRequest = {
      sentences: dutchParagraph,
      originaLanguage: "dutch",
      targetLanguage: "english",
      target_language_unicode: "en",
    };

    try {
      const res = await fetchData(
        apiUrls.googleApiTranslateDutchToEnglish,
        "POST",
        translateRequest
      );

      const translatedParagraph = res.sentences.map((sentence) => sentence);
      const translatedCombined = translatedParagraph.join(" ");

      setTranslatedDutchToEnglish((prevState) => [
        ...prevState,
        { chapterKey, paragraphKey, sentences: translatedParagraph },
      ]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingParagraphs((prevState) => ({
        ...prevState,
        [`Chapter ${chapterKey}-Paragraph ${paragraphKey}`]: false,
      }));
    }
  };

  const [paragraphToggleStates, setParagraphToggleStates] = useState({});

  useEffect(() => {
    const newSentenceColors = {};

    pairingEdges.forEach((edge) => {
      const sourceId = edge.source.split("-")[1];
      const targetId = edge.target.split("-")[1];

      if (!newSentenceColors[sourceId]) {
        const color = getRandomColor();
        newSentenceColors[sourceId] = color;
        newSentenceColors[targetId] = color;
      }
    });

    setSentenceColors(newSentenceColors);
  }, [pairingEdges]);

  const getRandomColor = () => {
    const colors = [
      "#D3D2FF75",
      "#FFE28F75",
      "#C2DAFF75",
      "#FFBDD375",
      "#C7FFD675",
      "#BEBEBE75",
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const toggleLanguageForParagraph = (chapterKey, paragraphKey) => {
    setParagraphToggleStates((prevState) => ({
      ...prevState,
      [`${chapterKey}-${paragraphKey}`]:
        !prevState[`${chapterKey}-${paragraphKey}`],
    }));
    if (!paragraphToggleStates[`${chapterKey}-${paragraphKey}`]) {
      getParagraph(chapterKey, paragraphKey);
    }
  };

  return (
    <div>
      <div className="paragraph-container">
        {paragraphsToShow
          .slice(startIndex, endIndex)
          .map(({ chapterKey, paragraphKey }, index) => {
            const resource = pairingData.auto_ai_resource.resources.find(
              (resource) =>
                resource.chapter.sequenceNumber === chapterKey &&
                resource.paragraph.sequenceNumber === paragraphKey
            );

            const paragraphId = resource._id;

            const translatedParagraph =
              resource.textTranslatedLanguageSentences.map(
                (sentence) => sentence.sentence.text
              );

            const showEnglishForParagraph =
              paragraphToggleStates[`${chapterKey}-${paragraphKey}`] || false;

            const originalParagraph =
              resource.textOriginalLanguageSentences.map(
                (sentence) => sentence.sentence.text
              );

            const originalSentences = originalParagraph;

            const translatedSentences =
              translatedDutchToEnglish.find(
                (item) =>
                  item.chapterKey === chapterKey &&
                  item.paragraphKey === paragraphKey
              )?.sentences || [];

            return (
              <div key={index}>
                {index === 0 && (
                  <div className="chapter-header">Chapter {chapterKey}</div>
                )}
                <div
                  key={`chapter-${chapterKey}-paragraph-${paragraphKey}`}
                  style={
                    resource.confidence_score === 100 ||
                    resource.confidence_score >= 70
                      ? {
                          borderLeft: "15px solid #3EB05E",
                        }
                      : {
                          borderLeft: "15px solid #FB4E4E",
                        }
                  }
                  className="paragraph"
                >
                  <div className="para-header">
                    <div>Pairing Accuracy :{resource.confidence_score}%</div>
                    <div>
                      Chapter {chapterKey} {">"} Paragraph {paragraphKey}
                    </div>
                  </div>
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      id={`checkbox-${paragraphId}`}
                      checked={checkedParagraphs.includes(paragraphId)}
                      onChange={() => handleCheckboxChange(paragraphId)}
                    />
                  </div>
                  {resource.status === "approval" && (
                    <img
                      className="checked-img"
                      src={checkedCircle}
                      alt="checked"
                    />
                  )}
                  <div className="english-paragraph">
                    <div className="paragraph-sentence">
                      {originalSentences.map((sentence, i) => (
                        <span
                          key={i}
                          style={{
                            backgroundColor:
                              sentenceColors[
                                resource.textOriginalLanguageSentences[i]
                                  .sentenceId
                              ] || "#FFF",
                            border: "1px solid #80808060",
                            borderRadius: "3px",
                            padding: "0 5px",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          {sentence}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="dutch-paragraph-pair">
                    {showViewAsEnglish && (
                      <div className="toogle-container">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onChange={() =>
                              toggleLanguageForParagraph(
                                chapterKey,
                                paragraphKey
                              )
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                        <span className="toogleLabel"> View as English</span>
                        &nbsp;
                      </div>
                    )}
                    {loadingParagraphs[
                      `Chapter ${chapterKey}-Paragraph ${paragraphKey}`
                    ] ? (
                      <div className="loader"></div>
                    ) : (
                      <div style={{ textAlign: "left", width: "100%" }}>
                        {showEnglishForParagraph
                          ? translatedSentences.map((sentence, i) => (
                              <span
                                key={i}
                                className="paragraph-sentence"
                                style={{
                                  margin: 0,
                                  backgroundColor:
                                    sentenceColors[
                                      resource.textTranslatedLanguageSentences[
                                        i
                                      ].sentenceId
                                    ] || "#FFF",
                                  border: "1px solid #80808060",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                {sentence}
                              </span>
                            ))
                          : translatedParagraph.map((sentence, i) => (
                              <span
                                key={i}
                                className="paragraph-sentence"
                                style={{
                                  margin: 0,
                                  backgroundColor:
                                    sentenceColors[
                                      resource.textTranslatedLanguageSentences[
                                        i
                                      ].sentenceId
                                    ] || "#FFF",
                                  border: "1px solid #80808060",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                {sentence}
                              </span>
                            ))}
                      </div>
                    )}
                  </div>
                  <div className="like-button">
                    {resource.status !== "approval" &&
                      !checkedParagraphs.includes(paragraphId) && (
                        <img
                          src={likeIcon}
                          alt="Like"
                          onClick={() => updateStatusPairing(paragraphId)}
                          className="like-icon"
                        />
                      )}
                    {resource.status !== "approval" &&
                      checkedParagraphs.includes(paragraphId) && (
                        <img
                          src={thumbUpInActive}
                          alt="Like"
                          className="like-icon"
                        />
                      )}

                    {resource.status === "approval" && (
                      <img src={thumbUpFill} alt="Like" className="like-icon" />
                    )}

                    {!checkedParagraphs.includes(paragraphId) && (
                      <img
                        src={marker}
                        alt="Marker"
                        onClick={() => onEditClick(paragraphId)}
                        className="like-icon"
                      />
                    )}
                    {resource.status !== "approval" &&
                      checkedParagraphs.includes(paragraphId) && (
                        <img
                          src={editIconInActive}
                          alt="Edit Inactive"
                          className="like-icon"
                        />
                      )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ParagraphPairComponent;
