import React from "react";
import "./Textarea.scss";
import closeIcon from "../../assets/close-icon.svg";
import tickIcon from "../../assets/tick-icon.svg";

const TextareaComponent = ({
  placeholder = "",
  value = "",
  onChange,
  rows = 4,
  handleUpdateTranslatedText,
  handleCloseTranslatedText,
}) => {
  return (
    <div className="textarea-container">
      <textarea
        value={value}
        onChange={onChange}
        className={`textarea ${value ? "active" : ""}`}
        rows={rows}
      ></textarea>
      <label className="textarea-label">{placeholder}</label>
      <div className="icons-container">
        <img
          src={tickIcon}
          alt="Tick"
          className="icon tick-icon"
          onClick={handleUpdateTranslatedText}
        />
        <img
          src={closeIcon}
          alt="Close"
          className="icon close-icon"
          onClick={handleCloseTranslatedText}
        />
      </div>
    </div>
  );
};

export default TextareaComponent;
