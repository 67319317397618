import cx from "clsx";
import {twMerge} from 'tailwind-merge';

/**
 * 
 * @param  {...cx.ClassValue} classes 
 * @returns {string}
 */
export function cn(...classes){
    return twMerge(cx(classes));
}