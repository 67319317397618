import apiClient from "./api-client";
import apiUrls from "../api/apiUrl";

export const getBookSignedUrl = ({
  translationId,
  contentLevel,
  ifCke
}) => {

  const body = {
      translation_id: translationId,
      content_level: contentLevel,
      if_cke: ifCke
  };

  return apiClient.post(apiUrls.v2ExportDocument, body);
};
