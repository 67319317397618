import React, { useState, useEffect } from "react";
import "./NodeSidebar.scss";

const NodeSidebar = ({
  selectedNode,
  connectedNodes,
  onClose,
  onNodeUpdate,
}) => {
  const sortedConnectedNodes = [...connectedNodes].sort((a, b) => {
    if (a.chapterKey !== b.chapterKey) {
      return a.chapterKey - b.chapterKey;
    }
    if (a.paragraphKey !== b.paragraphKey) {
      return a.paragraphKey - b.paragraphKey;
    }
    return a.sentenceSequence - b.sentenceSequence;
  });

  const [updatedSelectedLabel, setUpdatedSelectedLabel] = useState(
    selectedNode ? selectedNode.data.label : ""
  );

  const [updatedConnectedLabels, setUpdatedConnectedLabels] = useState(
    sortedConnectedNodes.map((node) => node.data.label)
  );

  const handleSelectedLabelChange = (event) => {
    setUpdatedSelectedLabel(event.target.value);
  };

  const handleConnectedLabelChange = (event, index) => {
    const updatedLabels = [...updatedConnectedLabels];
    updatedLabels[index] = event.target.value;
    setUpdatedConnectedLabels(updatedLabels);
  };

  const handleNodeSubmit = () => {
    const nodeId = selectedNode ? selectedNode.id : "";
    const connectedNodeLabels = {};

    sortedConnectedNodes.forEach((node, index) => {
      connectedNodeLabels[node.id] = updatedConnectedLabels[index];
    });

    onNodeUpdate(nodeId, updatedSelectedLabel, connectedNodeLabels);
  };

  useEffect(() => {
    if (selectedNode) {
      console.log("selectedNode", selectedNode);
      setUpdatedSelectedLabel(selectedNode.data.label);
      setUpdatedConnectedLabels(
        sortedConnectedNodes.map((node) => node.data.label)
      );
    }
  }, [selectedNode]);

  return (
    <div className="node-sidebar">
      <div className="node-sidebar-header">
        <p className="header-top-text-nodesidebar">Edit sentence:</p>
      </div>
      <div className="separator-nodesidebar"></div>
      <div className="chap-para-nodesidebar">
        Chapter {selectedNode.chapterKey} {">>"} Paragraph{" "}
        {selectedNode.paragraphKey}
      </div>

      {selectedNode.id.split("-")[0] === "english" && (
        <>
          <p className="header-text-nodesidebar">
            {selectedNode ? selectedNode.id.split("-")[0].toUpperCase() : ""}
          </p>
          <div className="node-sidebar-content">
            <textarea
              type="textarea"
              className="label-textarea"
              style={{ width: "359px", height: "100px" }}
              value={updatedSelectedLabel}
              onChange={handleSelectedLabelChange}
            />
          </div>
        </>
      )}

      <div className="connected-nodes-container">
        {sortedConnectedNodes.map((node, index) => (
          <div key={node.id} className="connected-node">
            <p className="header-text-nodesidebar">
              {" "}
              {node.id.split("-")[0].toUpperCase()}
            </p>
            <textarea
              type="textarea"
              className="label-textarea"
              style={{ width: "359px", height: "100px", marginBottom: "10px" }}
              value={updatedConnectedLabels[index]}
              onChange={(event) => handleConnectedLabelChange(event, index)}
            />
          </div>
        ))}
      </div>

      {selectedNode.id.split("-")[0] === "dutch" && (
        <>
          <div className="view-english-toogle-container">
            <p className="header-text-nodesidebar">
              {selectedNode ? selectedNode.id.split("-")[0].toUpperCase() : ""}
            </p>
            <div className="toogle-container">
              <label className="switch">
                <input
                  type="checkbox"
                  // onChange={() =>
                  //   toggleLanguageForParagraph(
                  //     chapterKey,
                  //     paragraphKey
                  //   )
                  // }
                />
                <span className="slider round"></span>
              </label>
              <span className="toogleLabel"> View as English</span>
              &nbsp;
            </div>
          </div>
          <div className="node-sidebar-content">
            <textarea
              type="textarea"
              className="label-textarea"
              style={{ width: "359px", height: "100px" }}
              value={updatedSelectedLabel}
              onChange={handleSelectedLabelChange}
            />
          </div>
        </>
      )}

      <div className="btn-container-node">
        <button onClick={onClose} className="close-button">
          Close
        </button>
        &nbsp;&nbsp;
        <button onClick={handleNodeSubmit} className="submit-node-button">
          Submit
        </button>
      </div>
    </div>
  );
};

export default NodeSidebar;
