import React, { useEffect, useState, useRef } from "react";
// import ClassicEditor  from '@ckeditor/ckeditor5-build-classic';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

import { CKEditor } from "@ckeditor/ckeditor5-react";
import { marked } from "marked";

// const editorConfiguration = {
//   toolbar: ["bold", "italic"],
// };

function TranslationEditMarkdownComponent({ content, handleOnSave }) {
  const [markdown, setMarkdown] = useState(marked(content));

  return (
    <div>
      <CKEditor
        editor={Editor}
        // config={editorConfiguration}
        data={markdown}
        // onReady={(editor) => {
        //   // You can store the "editor" and use when it is needed.
        //   console.log("Editor is ready to use!", editor);
        // }}
        onChange={(event,editor) => {
          const data = editor.getData();
          console.log("data",data)
        }}
        // onBlur={(event, editor) => {
        //   console.log("Blur.", editor);
        // }}
        // onFocus={(event, editor) => {
        //   console.log("Focus.", editor);
        // }}
      />
    </div>
  );
}

export default TranslationEditMarkdownComponent;
