import React from "react";
import "./SourceEditor.scss";
import "../../Pages/ReviewTranslation/ReviewTranslationPage.scss"
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import WordCounter from "../WordCounter/WordCounter";
import { customizeSourceFindDialog, removeButton, scrollToHighlightedText } from "../../utils/translationUtil";

function SourceEditor({
  sourceContentData,
  editorConfig,
  setSourceEditorr,
  setIsSourceEditorReady,
  sourceWordCountData,
  translatedChapterInfo,
  currentChapter,
  handleFindAndReplaceWordOverBook
}) {
  const {
    data: chapter,
    isPending: fetchTranslatedChapterPending,
    isError: fetchTranslatedChapterError,
    isSuccess: fetchTranslatedChapterSuccess,
  } = translatedChapterInfo;


  const handleSourceEditorReady = (editor) => {
    setSourceEditorr(editor)
    editor.enableReadOnlyMode(editor.id);
    const toolbarElement = editor.ui.view.toolbar.element;
    const buttons = toolbarElement.querySelectorAll('.ck-button');
    let findAndReplaceButton=null;
    buttons.forEach(button => {
      const label = button.querySelector('.ck-button__label');
      console.log("debugging content",label?.textContent,label,label?.innerText)
      if (label && label.textContent.trim() === 'Find and replace') {
        findAndReplaceButton=button
      }
    });
    console.log("debugging",buttons,findAndReplaceButton)
  //   const findAndReplaceButton = toolbarElement.querySelector('[data-cke-tooltip-text="Find and replace (Ctrl+F)"]');
   if(findAndReplaceButton){
        // To change the tooltip
    findAndReplaceButton?.setAttribute('data-cke-tooltip-text', 'Find');
    removeButton(toolbarElement, '.ck .ck-toolbar__items');
    const sourceToolBarContainer = document.querySelector('.sourceFindButton');
    sourceToolBarContainer.append(findAndReplaceButton);


    // To change the icon
    const button = document.querySelector('.ck.ck-button[data-cke-tooltip-text="Find"]') ?? {style:""};
    button.style.cursor = 'pointer';
    const svgElem = button?.querySelector('svg');
    if (svgElem) {
      svgElem.outerHTML = `
        <svg class="${svgElem.getAttribute('class')}" viewBox="0 0 24 24"  width="24" height="24" fill="#1e1c80">
         <circle cx="8.5" cy="8.5" r="5.5" stroke="#1e1c80" stroke-width="2" fill="none"></circle>
  <line x1="12.5" y1="12.5" x2="18" y2="18" stroke="#1e1c80" stroke-width="2" stroke-linecap="round"></line>
        </svg>
    `;
    }
   }
    

    // To hide the replace input
    const findAndReplaceUiPlugin = editor.plugins.get("FindAndReplaceUI");
    const dialogPlugin = editor.plugins.get("Dialog");
    dialogPlugin.on("show", (evt, data) => {
      const findAndReplaceDialogue = document.querySelector(
        '[aria-label="Find and replace"]'
      );
      findAndReplaceDialogue.style.opacity="0";
      setTimeout(()=>{
        dialogPlugin.view.moveTo(700,200);
        findAndReplaceDialogue.style.opacity="1";

      },100)
      customizeSourceFindDialog();
      const { formView: findAndReplaceDialogFormView } = findAndReplaceUiPlugin;
      const checkBookOverCountEleQuery =
      ".ck.ck-find-and-replace-form .ck-find-and-replace-form__inputs .sourcecountBookOver";
      handleFindAndReplaceWordOverBook(findAndReplaceDialogFormView,checkBookOverCountEleQuery,"english")
  

      const { formView: sourceFindDialogFormView } = findAndReplaceUiPlugin;
      sourceFindDialogFormView.on("findNext", async (event, data) => {
        const scrollLocked = document.querySelector(
          '[data-cke-tooltip-text="Unlock scroll"]'
        );
        const individualScroll=scrollLocked?false:true
        scrollToHighlightedText("source",individualScroll);
      })
      sourceFindDialogFormView.on("change:_resultsCounterText", async (event, data) => {
        const scrollLocked = document.querySelector(
          '[data-cke-tooltip-text="Unlock scroll"]'
        );
        const individualScroll=scrollLocked?false:true
        scrollToHighlightedText("source",individualScroll);
      })
    })

    setIsSourceEditorReady(true);
  };


  return (
    <div id="source" className="">
      <CKEditor
        editor={Editor}
        data={sourceContentData?.length > 0 ? sourceContentData : ""}
        config={editorConfig}
        onChange={(event, editor) => {
          const data = editor.getData();
        }}
        onReady={handleSourceEditorReady}
      />
      <WordCounter
        bookWords={sourceWordCountData.bookWords}
        chapterWords={sourceWordCountData.chapterWords}
        currentChapterNo={currentChapter}
        fetchTranslatedChapterPending={fetchTranslatedChapterPending}
        editorType={"sourced"}
        fetchTranslatedChapterError={fetchTranslatedChapterError}
      />
    </div>
  );
}

export default SourceEditor;
