import React, { useEffect, useState } from "react";
import "./PairPage.scss";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { RiArrowLeftLine } from "react-icons/ri";
import PostComponent from "../../Components/PostComponent/PostComponent";
import useApi from "../../api/useAPi";
import apiUrls from "../../api/apiUrl";
import Loader from "../../Components/Loader/Loader";
import linebr from "../../assets/linebr.svg";
import optmor from "../../assets/optmor.svg";
import user from "../../assets/contactIcon.svg";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";

const PairsPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const { data, loading, error, fetchData } = useApi();
  const [isLoading, setIsLoading] = useState(true);

  const [authorName, setAuthorName] = useState("");
  const [bookTitle, setBookTitle] = useState("");

  const [queryParameters] = useSearchParams();

  const [pairingData, setPairingData] = useState({});

  const [showEditPairing, setShowEditPairing] = useState(false);

  useEffect(() => {
    const pairedBookId = queryParameters.get("pairedBookId");

    const fetchPairedData = async () => {
      try {
        const response = await fetchData(apiUrls.getPairs + pairedBookId);
        console.log("response", response);
        setPairingData(response);
        setAuthorName(response.author_name);
        setBookTitle(response.book_title);
        setIsLoading(false);
        logEvent(analytics, 'get_pairs_success', {success: true})
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
        logEvent(analytics, 'get_pairs_failed', {error: error.message})
      }
    };

    fetchPairedData();
  }, []);

  const handleEditPairingClick = () => {
    setShowEditPairing(!showEditPairing);
  };

  return (
    <div className="container-pair-page">
      <div className="header">
        {!showEditPairing && (
          <p className="header-title">
            <RiArrowLeftLine className="back-arrow" onClick={handleGoBack} />
            Pairing check
          </p>
        )}
        {showEditPairing && (
          <p className="header-title-edit">
            <RiArrowLeftLine
              className="back-arrow"
              onClick={handleEditPairingClick}
            />
            Edit Pairing
          </p>
        )}
        <div className="content-main-container">
          <div className="content-header">
            <p>
              Title: <span>{bookTitle}</span>
            </p>
            <p>Author: {authorName}</p>
          </div>
          <div className="hr-line"></div>
          <div className="user-menu">
            <img src={user} alt="user" />
            <img src={linebr} alt="user" />
            <img src={optmor} alt="user" />
          </div>
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <div className="error-container">
          <div className="error-message">Error in loading data</div>
        </div>
      ) : (
        <>
          <div className="line-container">
            <p className="title">
              Please go through the translation pairs and perform suitable
              actions:
            </p>
            {pairingData && (
              <PostComponent
                sourceColumnHeader={"ENGLISH"}
                targetColumnHeader={"UPLOADED DUTCH"}
                pairingDatas={pairingData}
                showEditPairing={showEditPairing}
                showViewAsEnglish={true}
                onEditPairingClick={handleEditPairingClick}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PairsPage;
