import axios from "axios";
import apiUrls from "../api/apiUrl";
export const addGlossarytoTheBook = async (glossaryItem) => {
  try {
    console.log("glossaryItem", glossaryItem);
    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL + apiUrls.createGlossaryItem}`,
      glossaryItem
    );
  } catch (error) {
    console.log("error in creating glossary");
  }
};

export const getGlossaryItem = async (translationId) => {
  try {
    const response = await axios.get(
      `${
        process.env.REACT_APP_BACKEND_URL + apiUrls.getBookGlossary
      }?language=dutch&unique_string=${translationId}`
    );

    if (response.data.glossary_items) {
      return [];
    }

    return response.data ?? [];
  } catch (error) {
    console.log("error in getting glossary");
  }
};

export const updateGlossarytoTheBook = async (glossaryItem, glossaryId) => {
  try {
    console.log("glossaryItem", glossaryItem);
    await axios.put(
      `${
        process.env.REACT_APP_BACKEND_URL +
        apiUrls.updateBookGlossary +
        glossaryId
      }/`,
      glossaryItem
    );
  } catch (error) {
    console.log("error in updating glossary");
  }
};

export const deleteGlossaryFromTheBook = async (glossaryId) => {
  try {
    await axios.delete(
      `${
        process.env.REACT_APP_BACKEND_URL +
        apiUrls.deleteBookGlossary +
        glossaryId
      }/`
    );
  } catch (error) {
    console.log("error in deketing glossary");
  }
};

export const applySelectedGlossary = async (
  variantIndex,
  glossaryId,
  translationId,
  chapterNo,
  userEmail
) => {
  try {
    if (
      variantIndex === -1 ||
      !glossaryId ||
      !translationId ||
      !chapterNo ||
      !userEmail
    )
      return;

    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL + apiUrls.applyGlossary}`,
      {
        unique_string: translationId,
        glossary_item: glossaryId,
        variant_index: variantIndex,
        // priority_chapter_number: chapterNo,
        applied_by: userEmail,
      }
    );
  } catch (error) {
    console.log("error in updating glossary");
  }
};

export const getGlossarySuggestionData = async (translationId) => {
  try {
    const response = await axios.get(
      `${
        process.env.REACT_APP_BACKEND_URL + apiUrls.getGlossarySuggestion
      }?unique_string=${translationId}`
    );
    console.log("suggestion data", response?.data);
    if (response?.data?.Pending) return response.data.Pending;

    return [];
  } catch (error) {
    console.log("error in getting timestamp", error);
    return [];
  }
};

export const applySuggestedGlossary = async (glossaryPayload) => {
  try {
    if (
      !glossaryPayload.object_id ||
      !glossaryPayload?.updated_status ||
      !glossaryPayload?.priority_chapter_number
    )
      return;

    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL + apiUrls.applySuggestedGlossary}`,
      glossaryPayload
    );
  } catch (error) {
    console.log("error in updating glossary");
  }
};
