import * as React from 'react';
import Box from '@mui/material/Box';
// import Modal from '@mui/material/Modal';
// import Button from '@mui/material/Button';
import { Button, Dialog, DialogContent, DialogActions, DialogTitle } from "@mui/material"
import './DownloadModal.scss';
import { useState } from 'react';
import { Input } from '@mui/joy';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function DownloadModal({downloadModalVisible, setDownloadModalVisible, getDownload, popupEmail}) {
    const [downloadOption, setDownloadOption] = useState('entireBook');
    const [email, setEmail] = useState(popupEmail);
    const [error, setError] = React.useState(false)

    const handleClose = () => {
        setDownloadModalVisible(false);
    };

    const handleOptionChange = (event) => {
        setDownloadOption(event.target.value);
    };

    function validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    }

    const handleDownloadBook = () => {
      if (downloadOption === "entireBook") {
        if (!email || !validateEmail(email)) {
          setError(true);
          return;
        }
        getDownload(true, email);
      } else {
        getDownload(false, null);
      }

      handleClose();
    };

  return (
    <div>
      <Dialog open={downloadModalVisible} onClose={handleClose}>
        <DialogTitle>{'Download Book'}</DialogTitle>
        <DialogContent>
        <Box>
          {/* <p id="parent-modal-description">
            Select one of the below options to download book.
          </p> */}
          

            <div>
                <p>
                    <label>
                        <input
                            type="radio"
                            name="downloadOption"
                            value="entireBook"
                            checked={downloadOption === 'entireBook'}
                            onChange={handleOptionChange}
                        />
                        Download the entire book
                        {/* <small style={{color: 'grey'}}></small> */}
                    </label>
                    <Input sx={{marginTop: '5px', marginLeft: '12px'}} value={email} onChange={(e)=> setEmail(e.target.value)}/>
                    {error && <><small style={{color: 'red',marginLeft:"12px"}}>Please enter a valid email.</small><br/></>}
                     <small style={{color: 'grey', fontSize: '12px', marginLeft: '12px'}}>The book generation will require 1-2 hours & will be delivered to you on this email address.</small> 
                </p>
                <p>
                    <label>
                        <input
                            type="radio"
                            name="downloadOption"
                            value="specificChapters"
                            checked={downloadOption === 'specificChapters'}
                            onChange={handleOptionChange}
                        />
                         Download only the translated section of the book
                        {/* Download book from chapter */}
                        {/* <input
                            type="number"
                            className="chapterInput"
                            value={startChapter}
                            min="1"
                            onChange={(e) => setStartChapter(e.target.value)}
                        />
                        to chapter
                        <input
                            type="number"
                            className="chapterInput"
                            value={endChapter}
                            min="1"
                            onChange={(e) => setEndChapter(e.target.value)}
                        /> */}
                    </label>
                </p>
            </div>

          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
            <Button onClick={handleDownloadBook}>Download</Button>
            <Button color='error' sx={{marginLeft: '20px'}} onClick={handleClose}>Close</Button>
          </Box>             
        </Box>
        </DialogContent>
      </Dialog>
      {/* <Modal
        open={downloadModalVisible}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        
      </Modal> */}
    </div>
  );
}