import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import "./ReviewTranslationPage.scss";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import debounce from 'lodash.debounce'; 
import Header from "../../Components/Header/Header";
import BookHeader from "../../Components/BookHeader/BookHeader";
import BookEditor from "../../Components/BookEditor/BookEditor";
import { useFetchTranslatedChapter } from "../../hooks/useReviewTranslation";
import {
  fixParagraphAlignment,
  getCurrentTime,
  getHTMLContentForEditor,
  updateUserActiveTime,
  enableSourceHighlightingOnHover,
  scaleEditor, 
  updateElementStyles,
  setEditorLayout,
  scrollBarData
} from "../../utils/translationUtil";
import { ToastContainer, toast } from "react-toastify";
// import ChapterData from "../../assets/JSON/translate.json";

import { getBookSignedUrl } from "../../services/book.service";
import { downloadFile } from "../../services/download.service";
import {
  handleAddNewChapter,
  setExternalLookupFunction,
} from "../../context/cache.service";

import * as editorActions from "../../App/editor/editor.actions";
import store from "../../App/store";
import { loggedInUserActivity, userLastActiveTime } from "../../utils/firebaseActions";
import { ADD_APIS_RESPONSE_STATUS, DOWNLOAD_BUTTON_CLICKS } from "../../utils/fireStoreTypes";
import { setUserOpenedBookInfo } from "../../App/DashboardActions/action";
import apiUrls from "../../api/apiUrl";
import { applySelectedGlossary, applySuggestedGlossary, getGlossarySuggestionData, updateGlossarytoTheBook } from "../../utils/glossaryUtils";

function ReviewTranslationPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [chapter, setChapter] = useState({});
  const [sourceContent, setSourceContent] = useState();
  const [translatedContent, setTranslatedContent] = useState();
  const [isLayoutFixed, setIsLayoutFixed] = useState(false);
  const [individualScroll, setIndividualScroll] = useState(false);
  const [pairFinder, setPairFinder] = useState(() => null);
  const [afterHandledUnsavedChangesAction, setAfterHandledUnsavedChangesAction] = useState({actionName:"",actionData:""})
  const [isTranslatedContentEdited, setIsTranslatedContentEdited] = useState(false);
  const { loggedInUserEmail ,userOpenedBookInfo} = useSelector((state) => state.main);
  const isPairingDone = searchParams.get("align") === "true";
  const currentChapter = Number(searchParams.get("chapter")) || 1;
  const [zoomLevel,setZoomLevel]=useState(1);
  const [enableHighlightingState, setEnableHighlightingState] = useState("")


  const {
    book_info: bookInfo,
    chapter_number: chapterNumber,
    paragraphs,
    translation_info: translationInfo,
  } = chapter || {};

  const translationId = params.id;

  /**
   * handleChapterChange function handles chapter change 
   * accepts chapter number and redirects to that chapter
   * @param {*} val  : Chapter number
   */
  const handleChapterChange = (val) => {
    if(!val) return ;
    const bookMarkData={
      lastOpenedChapterNo:val,
      scrollBarDetails: ""
    }
    updateUserActiveTime(params.id,loggedInUserEmail,bookMarkData)
    searchParams.set("chapter", val);
    setSearchParams(searchParams);
    dispatch(editorActions.setCurrentChapter(Number(val)));
  };

  /**
   * The `successHandler` function sets chapter data, source and translated content based on pairing
   * status, sets external lookup function, and handles adding a new chapter.
   * @param data - The `data` parameter in the `successHandler` function likely contains information
   * related to a chapter or content data. It is used to set various states and functions within the
   * function, such as `setChapter`, `setSourceContent`, `setTranslatedContent`,
   * `setExternalLookupFunction`, and `
   */
  const successHandler = (data) => {
    console.log("isPairingDone",isPairingDone)
    if(data?.chapter_number!==currentChapter) return

    console.log("counter 2",data)
    setChapter(data);
    setSourceContent(
      isPairingDone
        ? getHTMLContentForEditor(data?.paragraphs, "sourceSentences")
        : data?.chapter_data?.English
    );
    setTranslatedContent(
      isPairingDone
        ? getHTMLContentForEditor(data?.paragraphs, "translatedSentences")
        : data?.chapter_data?.Dutch
    );
    setExternalLookupFunction(getPairFinder(data?.paragraphs));
    handleAddNewChapter({ currentChapter, translationId });
  };

  /**
   * Creates a pairing dictionary and returns a function to query the same;
   * @param {*} paragraphs
   * @returns
   */
  const getPairFinder = (paragraphs) => {
    const getTextFromHTML = (HTML) => {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = HTML;
      const innerText = (tempDiv.innerText || tempDiv.textContent)
        ?.trim()
        .normalize("NFKD")
        .replace(/\u2060\u201c\u2019\u201d/g, "");
      return innerText;
    };
    const pairingDictionary = {};
    let errorInTranslationCount = 0;
    paragraphs.forEach((paragraph, index) => {
      let key = getTextFromHTML(
        paragraph.translatedSentences[0]["sentence"].text
      );
      if (key === "ERROR IN TRANSLATION or BLOCKED TRANSLATION") {
        key = key + errorInTranslationCount;
        errorInTranslationCount++;
      }
      pairingDictionary[key] = {
        sourceSentence: paragraph.sourceSentences[0].sentence.text,
        sourceIndex: index,
      };
    });
    window.pairingDictionary = pairingDictionary;
    console.log("pairingDictionary", pairingDictionary);

    return (query) => ({
      result: pairingDictionary[getTextFromHTML(query)],
      dict: pairingDictionary,
      query: getTextFromHTML(query),
    });
  };

  const translatedChapterInfo = useFetchTranslatedChapter({ successHandler });
  const { mutate: fetchChapterById ,isPending: fetchTranslatedChapterPending,} = translatedChapterInfo;

  /**
   * The function `downloadTranslation` asynchronously downloads a book file and displays a toast
   * message indicating success or failure.
   */
  const downloadTranslation = async () => {
    const isTranslatedContentEdited =
      store?.getState()?.editor?.isTranslationContentEdited;
    if (isTranslatedContentEdited) {
      setAfterHandledUnsavedChangesAction({
        actionName: "downloadTranslatedBook",
      });
      return;
    }

    const toastId = toast.loading("Downloading book...", { autoClose: false });

    try {
      const res = await getBookSignedUrl({
        translationId,
        contentLevel: "chapter",
        ifCke: searchParams.get("cke") === "true" ? true : undefined,
      });

      if (!res.data) throw new Error();

      downloadFile({ url: res.data.signed_url });

      toast.update(toastId, {
        render: "Download successful!",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });

      loggedInUserActivity({
        activity: ADD_APIS_RESPONSE_STATUS,
        apiResponse: {
          status: "success",
          apiEndPoint: apiUrls?.v2ExportDocument,
          responseMessage: "Book downloaded successfully",
        },
      });
      
    } catch (e) {
      console.log(`e->`, e);
      toast.update(toastId, {
        render: "Download failed!",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
      loggedInUserActivity({
        activity: ADD_APIS_RESPONSE_STATUS,
        apiResponse: {
          status: "error",
          apiEndPoint: apiUrls?.v2ExportDocument,
          responseMessage: e.message ?? "failed to download book",
        },
      });
    }
  };

  /**
   * Feature : Zoom in and out 
   * handleZoomEditor function changes scale of source and translate editor ,
   * and sets width and height of their parent ele
   * @param {*} scale : scale value
   * @returns 
   */
  const handleZoomEditor=async(scale)=>{
    setZoomLevel(scale)
    const sourceRect =  await scaleEditor('source',scale)
    const translatedRect = await scaleEditor('translated',scale);
    if(!sourceRect || !translatedRect) return 
    const maxHeight = Math.max(sourceRect?.height, translatedRect?.height);
  
    if(!translatedContent){
      updateElementStyles('#translated', {height: `${maxHeight}px`,width:`${sourceRect?.width}px`})
    }else{
      updateElementStyles('#translated', {height: `${maxHeight}px`})
    }
    updateElementStyles('#source', {height: `${maxHeight}px`})
  }

  const editorConfig = useMemo(() => {
    return {
      store: {
        getState: store.getState,
      },
      utils: {
        downloadTranslation,
        zoomEditor: handleZoomEditor,
      },
    };
  }, [downloadTranslation]);
   
  

  useEffect(() => {
    if (currentChapter < 1) return;
    fetchChapterById({
      currentChapter,
      translationId,
    });
    dispatch(editorActions.setCurrentChapter(currentChapter));
  }, [currentChapter]);

  useEffect(() => {
      dispatch(editorActions.setFetchTranslatedChapterPending(fetchTranslatedChapterPending))
      dispatch(editorActions.setIsTranslationContentEdited(isTranslatedContentEdited))
       
      const resetEditorLayout = async () =>{
         handleZoomEditor(zoomLevel)
         setEditorLayout(fetchTranslatedChapterPending,translatedContent,sourceContent)
      }
      
      resetEditorLayout()
      window.addEventListener('resize', resetEditorLayout);

      return ()=>{
        window.removeEventListener('resize', resetEditorLayout);
      }
      
  }, [fetchTranslatedChapterPending,isTranslatedContentEdited,translatedContent,zoomLevel])

  useEffect(() => {
    if (bookInfo) {
      dispatch(
        setUserOpenedBookInfo({
          author: bookInfo.author_name,
          title: bookInfo.book_title,
          link: chapter.translation_id,
        })
      );
    }
  }, [bookInfo]);

  const debouncedUpdateUserActiveTime = useCallback(
    debounce((translationId,loggedInUserEmail,scrollData) =>{
      if(scrollData){
        const bookMarkData={
        lastOpenedChapterNo: scrollData.currentChapterNo,
        scrollBarDetails: scrollBarData("get",scrollData.individualScroll)
        }
       return updateUserActiveTime(translationId,loggedInUserEmail,bookMarkData)
      }
      return updateUserActiveTime(translationId,loggedInUserEmail)
      }, 1000),
    []
  );

  const fetchGlossarySuggestionsData= async (translationId,updatedGlossaryData)=>{
    const glossarySuggestionData=updatedGlossaryData ? updatedGlossaryData: await getGlossarySuggestionData(translationId)
    console.log(glossarySuggestionData,"glossarySuggestionData")
    const alertElem=document.querySelector(
     ".glossarySuggestionAlert"
   );
    if(glossarySuggestionData.length){
       if(alertElem)
          return ;
     
       const  glossaryButtonElem= document.querySelector(
         '[data-cke-tooltip-text="Glossary"]'
       );
       const suggestionAlertElem=document.createElement('div');
       suggestionAlertElem?.classList?.add("glossarySuggestionAlert")
       glossaryButtonElem?.append(suggestionAlertElem)
      dispatch(editorActions.setGlossarySuggestionsData(glossarySuggestionData ?? []))
    }else{
      if(!alertElem)
         return ;
      alertElem?.remove()
      dispatch(editorActions.setGlossarySuggestionsData([]))
    }
  }
  


  const applyGlossary = async (
    glossaryPayload,
    applicationType,
    chapterData,
    selectedGlossaryItem,
    selectedGlossary,
  ) => {
    dispatch(editorActions.setIsGlossaryApplying(true))
    const toastId = toast.loading("Applying glossary...");
    
    // when applying glossary
    if (applicationType === "glossary") {
      await updateGlossarytoTheBook(glossaryPayload, selectedGlossaryItem.id);

      const selectedVariantIndex =
        selectedGlossaryItem?.dutch_word_array.findIndex(
          (variant) =>
            variant.replacement_dutch_word === selectedGlossary.variant
        );
        const userEmail= store.getState().main.loggedInUserEmail;
        console.log( "apply glossary data", selectedVariantIndex,
          selectedGlossaryItem.id,
          chapterData?.translation_id,
          chapterData.chapter_number,
          userEmail
        )

      await applySelectedGlossary(
        selectedVariantIndex,
        selectedGlossaryItem.id,
        chapterData?.translation_id,
        chapterData.chapter_number,
        loggedInUserEmail
      );
    }

    console.log("check data",currentChapter,params.id)

   // when applying glossary suggestion
    if(applicationType==="glossarySuggestion"){
     await applySuggestedGlossary(glossaryPayload)
    }
   
    console.log("counter 1",currentChapter,params.id)
    setSourceContent("");
    setTranslatedContent("");
     const updatedChapterNo=store.getState().editor.currentChapter
     const userOpenedBookInfo=store.getState().main.userOpenedBookInfo
    fetchChapterById({
      currentChapter:updatedChapterNo,
      translationId: chapter.translation_id,
    });
    
    setIsLayoutFixed(false);

    dispatch(editorActions.setCurrentChapter(chapterData?.chapter_number));
    dispatch(editorActions.setIsGlossaryApplying(false))
    console.log("its applied",toastId,chapterData?.translation_id,chapterData?.chapter_number)
    toast.dismiss(toastId);
    toast.success("Glossary applied successfully", {
        autoClose: 3000,
    });
    setIsTranslatedContentEdited(false);
  };



  
  return (
    <>
      <ToastContainer hideProgressBar={true} />
      <div className="w-full fixed top-0 left-0 right-0 z-10 bg-white">
        <Header
         title={bookInfo?.book_title} 
         author={bookInfo?.author_name} 
         isTranslatedContentEdited={isTranslatedContentEdited}
         setAfterHandledUnsavedChangesAction={setAfterHandledUnsavedChangesAction}
         translatedChapterInfo={translatedChapterInfo}
        />
        <BookHeader
          sourceLanguage={translationInfo?.translated_from}
          translatedLanguage={translationInfo?.translated_to}
          currentChapter={currentChapter}
          totalChapters={bookInfo?.total_chapters || 0}
          handleChapterChange={handleChapterChange}
          setIsLayoutFixed={setIsLayoutFixed}
          isTranslatedContentEdited={isTranslatedContentEdited}
          setIsTranslatedContentEdited={setIsTranslatedContentEdited}
          afterHandledUnsavedChangesAction={afterHandledUnsavedChangesAction}
          setAfterHandledUnsavedChangesAction={setAfterHandledUnsavedChangesAction}
          translatedChapterInfo={translatedChapterInfo}
          applyGlossary={applyGlossary}
          fetchGlossarySuggestionsData={fetchGlossarySuggestionsData}
        />
        <div id="translation-editor-toolbar"></div>
      </div>
      {/* <div
        className={
          "w-full overflow-x-auto relative mt-[132px] containerScroll book-editor-container" +
          (individualScroll && " individual-scroll")
        }
      > */}
        <BookEditor
          sourceContent={sourceContent}
          translatedContent={translatedContent}
          fixParagraphAlignment={fixParagraphAlignment}
          setSourceContent={setSourceContent}
          setTranslatedContent={setTranslatedContent}
          isLayoutFixed={isLayoutFixed}
          setIsLayoutFixed={setIsLayoutFixed}
          pageLayoutConfig={chapter?.metatdata || {}}
          editorConfig={editorConfig}
          translatedChapterInfo={translatedChapterInfo}
          setIndividualScroll={setIndividualScroll}
          currentChapter={currentChapter}
          setIsTranslatedContentEdited={setIsTranslatedContentEdited}
          isTranslatedContentEdited={isTranslatedContentEdited}
          handleChapterChange={handleChapterChange}
          setAfterHandledUnsavedChangesAction={setAfterHandledUnsavedChangesAction}
          individualScroll={individualScroll}
          zoomLevel={zoomLevel}
          onZoomChange={handleZoomEditor}
          debouncedUpdateUserActiveTime={debouncedUpdateUserActiveTime}
          applyGlossary={applyGlossary}
          fetchGlossarySuggestionsData={fetchGlossarySuggestionsData}
          enableHighlightingState={enableHighlightingState}
        />
      {/* </div> */}
    </>
  );
}

export default ReviewTranslationPage;
