import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ReactComponent as GlossaryViewIcon } from "../../assets/glossaryViewIcon.svg";
import { ReactComponent as GlossaryDeleteIcon } from "../../assets/glossaryDeleteIcon.svg";
import { ReactComponent as GlossaryEditIcon } from "../../assets/glossaryEditIcon.svg";
import { ReactComponent as GlossaryInfoIcon } from "../../assets/glossaryInfoIcon.svg";
import { ReactComponent as GlossaryChevronUp } from "../../assets/glossaryChevronUp.svg";
import { ReactComponent as AddGlossaryIcon } from "../../assets/addGlossaryIcon.svg";
import { getUserNameFromEmail } from "../../utils/translationUtil";
import DoneIcon from "@mui/icons-material/Done";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { styled, Tooltip } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  deleteGlossaryFromTheBook,
  updateGlossarytoTheBook,
} from "../../utils/glossaryUtils";
import GlossaryWarningModal from "../glosssaryWarningModal/GlossaryWarningModal";

function GlossaryAccordion({
  glossaryItem,
  chapter,
  englishWord,
  getBookGlossaryData,
  setSelectedEnglishWord,
  setOpenGlossaryModal,
  setOpenedGlossaryItem,
  openedGlossaryItem,
  setSelectedGlossary,
  selectedGlossary,
  // setShowInputFeildError
}) {
  const [expanded, setExpanded] = useState(false);

  const [showVariantInputFeild, setShowVariantInputFeild] = useState(false);
  const [inputVariant, setInputVariant] = useState("");
  const [selectedVariantForEdit, setSelectedVariantForEdit] = useState("");
  const [deleteWarningData, setDeleteWarningData] = useState(null)

  const handleRadioButtonChange = (event) => {
    setSelectedGlossary({
      englishWord: glossaryItem?.english_word,
      variant: event.target.value,
    });
  };

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "black",
      color: "white",
    },
    "& .MuiTooltip-arrow": {
      color: "black", // Arrow color
    },
  }));

  const resetAccordianValues = () => {
    setInputVariant(false);
    setShowVariantInputFeild(false);
    setSelectedVariantForEdit("");
    setSelectedGlossary({ englishWord: "", variant: "" });
    setOpenedGlossaryItem("");
  };

  const handleChange = () => {
    if (expanded) {
      resetAccordianValues();
    } else {
      setOpenedGlossaryItem(glossaryItem);
    }
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (openedGlossaryItem?.english_word === glossaryItem?.english_word) {
      setExpanded(true);
    } else {
      setExpanded(false);
      setSelectedGlossary({ englishWord: "", variant: "" });
    }
  }, [openedGlossaryItem]);

  const handleUpdateGlossary = async (action, type, variant) => {
    console.log("glossaryItem", glossaryItem);
    const updatedDutchVariants = glossaryItem?.dutch_word_array?.filter(
      (dutchVariant) => dutchVariant?.replacement_dutch_word !== variant
    );

    if(variant)
      setSelectedGlossary({ englishWord: "", variant: "" });

    if (
      updatedDutchVariants.length &&
      action === "delete" &&
      type === "variant"
    ) {
      const glossaryPayload = {
        author: chapter.book_info.author_name,
        language: "dutch",
        genre: chapter.book_info.genre,
        english_word: glossaryItem.english_word,
        dutch_word_array: updatedDutchVariants,
        glossary_item_scope: "Book",
        application_scope: "book_level",
        chapter_numbers: [],
        is_active: true,
        is_part_of_collection: false,
        application_history: null,
      };
      await updateGlossarytoTheBook(glossaryPayload, glossaryItem.id);
      getBookGlossaryData();
      return;
    }

    if (action === "update" && type === "variant") {
      if (!inputVariant) {
        toast(`Input field can not be empty`, {
          type: "warning",
          autoClose: 3000,
        });
        return;
      }
      let isVariantAlreadyExist = false;
      const updatedDutchVariants = glossaryItem.dutch_word_array.map(
        (dutchVariant) => {
          if (
            dutchVariant?.replacement_dutch_word?.toLowerCase() ===
            inputVariant?.trim()?.toLowerCase()
          ) {
            toast(`Variant already exists`, {
              type: "warning",
              autoClose: 3000,
            });
            isVariantAlreadyExist = true;
          }

          if (dutchVariant.replacement_dutch_word === variant) {
            dutchVariant.replacement_dutch_word = inputVariant;
          }
          return dutchVariant;
        }
      );
      if (isVariantAlreadyExist) return;
      const glossaryPayload = {
        author: chapter.book_info.author_name,
        language: "dutch",
        genre: chapter.book_info.genre,
        english_word: glossaryItem.english_word,
        dutch_word_array: updatedDutchVariants,
        glossary_item_scope: "Book",
        application_scope: "book_level",
        chapter_numbers: [],
        is_active: true,
        is_part_of_collection: false,
        application_history: null,
      };
      await updateGlossarytoTheBook(glossaryPayload, glossaryItem.id);
      setShowVariantInputFeild(false);
      setInputVariant("");
      getBookGlossaryData();
      return;
    }
    if (
      (type === "glossaryItem" ||
        (type === "variant" && !updatedDutchVariants.length)) &&
      action === "delete"
    ) {
      await deleteGlossaryFromTheBook(glossaryItem.id);
      await getBookGlossaryData();
      resetAccordianValues();
    }
  };


  const handleDeleteGlossaryThroughPopup=()=>{
     if(deleteWarningData.type==="Glossary"){
      handleUpdateGlossary("delete", "glossaryItem")
    }else{
      handleUpdateGlossary(
        "delete",
        "variant",
        deleteWarningData.variant
      )
    }


     setDeleteWarningData(null);
  }

  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        sx={{
          borderBottomLeftRadius: "0",
          borderBottomLeftRadius: "0",
          "&:last-of-type": {
            borderRadius: "0px",
          },
          "&::before": {
            height: 0, // Removes the height
          },
          boxShadow: "none",
          borderBottom: "1px solid #BFBFBF",
          "& .MuiAccordion-region": { height: "100%" },
        }}
      >
        <AccordionSummary
          expandIcon={<GlossaryViewIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            display: expanded ? "none" : "flex",
            borderBottomLeftRadius: "0",
            borderBottomLeftRadius: "0",
          }}
        >
          <div className="flex justify-between gap-6 w-full items-center pr-2">
            <span className="text-[10px] w-[50%] text-[#000]">
              {glossaryItem.english_word}
            </span>
            <div className="w-[45%] flex justify-between items-center">
              <span className="text-[10px]  text-[#1E1C80]">
                {glossaryItem.dutch_word_array.length}{" "}
                {glossaryItem.dutch_word_array.length === 1
                  ? "Variant"
                  : "Variants"}
              </span>
              <span className="text-[10px] text-[#3B37FF]">View All </span>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "0",
            border: "none",
            boxShadow: "none",
            borderBottomLeftRadius: "0",
            borderBottomLeftRadius: "0",
            height: "100%",
          }}
        >
          <div className="flex items-start pb-2  h-full gap-5 w-full">
            <div className="py-2 pr-2 flex-col flex justify-between text-xs w-1/2 h-full bg-[#F1F3F4] pl-4">
              {glossaryItem.english_word}
              <div className="flex items-end  justify-end gap-2">
                <CustomTooltip
                  sx={{
                    "& .MuiTooltip-tooltip": {
                      backgroundColor: "black", // Change to your desired color
                      color: "white", // Optional: Change text color
                    },
                  }}
                  placement="top-end"
                  arrow
                  title={
                    glossaryItem.dutch_word_array[0]?.metadata_storage
                      ?.creation_user &&
                    `Added by: ${getUserNameFromEmail(
                      glossaryItem.dutch_word_array[0]?.metadata_storage
                        ?.creation_user
                    )}`
                  }
                >
                  <GlossaryInfoIcon className="cursor-pointer w-[14px] h-[14px]" />
                </CustomTooltip>
                <GlossaryDeleteIcon
                  onClick={() => setDeleteWarningData({ type: "Glossary",englishWord:glossaryItem.english_word})}
                  // handleUpdateGlossary("delete", "glossaryItem")
                  // }
                  className="cursor-pointer w-[14px] h-[14px]"
                />
              </div>
            </div>
            <div className="flex w-1/2 gap-2 h-full flex-col">
              <div className="py-2 pr-2 text-xs w-full flex justify-between  bg-[#F1F3F4] pl-4">
                <div className="flex gap-[6px] items-center">
                  <span className="text-[#1E1C80] text-[10px]">
                    {glossaryItem.dutch_word_array.length}{" "}
                    {glossaryItem.dutch_word_array.length === 1
                      ? "Variant"
                      : "Variants"}
                  </span>
                  <AddGlossaryIcon
                    onClick={() => {
                      setSelectedEnglishWord(glossaryItem.english_word);
                      setOpenGlossaryModal(true);
                      // setShowInputFeildError(false)
                    }}
                    className="cursor-pointer"
                  />
                </div>
                <div
                  onClick={handleChange}
                  className="flex cursor-pointer text-[10px] text-[#3B37FF] gap-[6px] items-center"
                >
                  View less
                  <GlossaryChevronUp />
                </div>
              </div>
              {glossaryItem?.dutch_word_array?.map((varient, i) => {
                return (
                  <div className="py-2 px-2 text-xs flex gap-1 justify-between flex-col  bg-[#F1F3F4] ">
                    {!showVariantInputFeild ||
                    selectedVariantForEdit !==
                      varient?.replacement_dutch_word ? (
                      <>
                        <div className="flex items-center gap-2">
                          <input
                            value={varient?.replacement_dutch_word}
                            checked={
                              selectedGlossary?.variant ===
                              varient?.replacement_dutch_word
                            }
                            type="radio"
                            onChange={handleRadioButtonChange}
                          />
                          {varient?.replacement_dutch_word}
                        </div>
                        <div className="flex items-end  justify-end gap-2">
                          <CustomTooltip
                            sx={{
                              "& .MuiTooltip-tooltip": {
                                backgroundColor: "black", // Change to your desired color
                                color: "white", // Optional: Change text color
                              },
                            }}
                            placement="top-end"
                            arrow
                            title={
                              varient?.metadata_storage?.creation_user &&
                              `Added by: ${getUserNameFromEmail(
                                varient?.metadata_storage?.creation_user
                              )}`
                            }
                          >
                            <GlossaryInfoIcon className="cursor-pointer w-[14px] h-[14px]" />
                          </CustomTooltip>
                          <GlossaryDeleteIcon
                            onClick={() => {
                              if (
                                glossaryItem?.dutch_word_array?.length === 1
                              ) {
                                setDeleteWarningData({
                                  type: "Last Variant",
                                  variant: varient?.replacement_dutch_word,
                                });
                                return;
                              }
                              setDeleteWarningData({
                                type: "Variant",
                                variant: varient?.replacement_dutch_word,
                              });
                            }}
                            className="cursor-pointer w-[14px] h-[14px]"
                          />
                          <GlossaryEditIcon
                            onClick={() => {
                              setShowVariantInputFeild(true);
                              setInputVariant(varient.replacement_dutch_word);
                              setSelectedVariantForEdit(
                                varient.replacement_dutch_word
                              );
                            }}
                            className="cursor-pointer w-[14px] h-[14px]"
                          />
                        </div>
                      </>
                    ) : (
                      <div
                        style={{ border: "1px solid #BFBFBF" }}
                        className="text-xs flex flex-col  bg-white pr-1"
                      >
                        <input
                          type="text"
                          maxLength={100}
                          placeholder="Type here..."
                          className="border-none text-xs  bg-transparent w-full py-1 px-2 outline-none"
                          value={inputVariant}
                          onChange={(e) => {
                            setInputVariant(e.target.value);
                          }}
                        />

                        <div className="flex w-full gap-2 justify-end">
                          <CloseIcon
                            onClick={() => {
                              setShowVariantInputFeild(false);
                              setInputVariant(false);
                              setSelectedVariantForEdit("");
                            }}
                            className="cursor-pointer w-[18px]  h-[18px] text-[#FF3B30]"
                          />
                          <DoneIcon
                            onClick={() => {
                              handleUpdateGlossary(
                                "update",
                                "variant",
                                varient?.replacement_dutch_word
                              );
                            }}
                            style={{
                              color: "#52C41A",
                              cursor: "pointer",
                              width: "18px",
                              height: "18px",
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      {deleteWarningData && (
        <GlossaryWarningModal
          deleteWarningData={deleteWarningData}
          closePopup={() => setDeleteWarningData(null)}
          handleDelete={handleDeleteGlossaryThroughPopup}
        />
      )}
    </>
  );
}

export default GlossaryAccordion;
