import React from 'react'

function TranslationEditTextAreaComponent({
    sentence,
    i,
    chapterKey,
    paragraphKey,
    textAreaRef,
    calculateTextareaHeight,
    editedContent,
    paraTranslated,
    handleParagraphHower,
    handleChange,
    paragraphId,
    setEditedContent,
    translatedPairParagraph
}) {
  return (
    <textarea
    key={`${chapterKey}-${paragraphKey}-${i}`}
    className={`textarea active`}
    ref={textAreaRef[i]}
    id={`${chapterKey}-${paragraphKey}-${i}`}
    style={{
      height: calculateTextareaHeight(
        editedContent[`${paraTranslated[i].sentenceId}`]
          ? editedContent[`${paraTranslated[i].sentenceId}`]
          : sentence
      ),
      backgroundColor: "transparent",
      overflow: "hidden",
      textAlign: "justify",
      overflowWrap: "break-word",
      width: "100%",
    }}
    onClick={() => {
      handleParagraphHower(paraTranslated[i]);
    }}
    onKeyDown={(e) =>
      handleChange(
        i,
        e,
        paraTranslated,
        paragraphId,
        chapterKey,
        paragraphKey,
        translatedPairParagraph
      )
    }
    autoFocus={i === 0}
    onChange={(e) => {
      handleChange(i, e, paraTranslated);
      setEditedContent({
        ...editedContent,
        [`${paraTranslated[i].sentenceId}`]: e.target.value,
      });
    }}
    value={
      editedContent[`${paraTranslated[i].sentenceId}`] !== undefined
        ? editedContent[`${paraTranslated[i].sentenceId}`]
        : sentence // If not edited, display original sentence
    }
  ></textarea>
  )
}

export default TranslationEditTextAreaComponent